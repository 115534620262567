import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateSkillRequest,
  IdsArray,
  IFilter,
  ISkillModel,
  IUpdateSkill,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushSkillState = createAction('Flush skill data in store');

// Creation actions
export const createSkillRequest = createAction<
  PayloadWithFilters<Partial<ICreateSkillRequest>>
>('Make request to create a new skill');
export const createSkillSuccess = createAction<ISkillModel>(
  'New skill has been created successfully',
);
export const createSkillFailed = createAction('Cannot create a new skill');

// Fetch skills actions
export const getSkillListRequest = createAction<IFilter>(
  'Make request to fetch skill list',
);
export const getSkillListSuccess = createAction<ISkillModel[]>(
  'Skills list has been fetched successfully',
);
export const getSkillListFailed = createAction('Cannot fetch skill list');

// get skills count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getSkillCountRequest = createAction<IFilter>(
  'Make request to get skill count',
);
export const getSkillCountSuccess = createAction<ICountResponse>(
  'Skills count has been fetched successfully',
);
export const getSkillCountFailed = createAction('Cannot get skill count');

// delete actions
export const deleteSkillRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete skills',
);
export const deleteSkillSuccess = createAction('Skills successfully deleted');
export const deleteSkillFailed = createAction('Cannot delete skills');

// Updating actions
export const updateSkillsRequest = createAction<
  PayloadWithFilters<Omit<IUpdateSkill, 'skillTerm'>[]>
>('Make request to update skills');
export const updateSkillsSuccess = createAction('Skills successfully updated');
export const updateSkillsFailed = createAction('Cannot update skills');

// Fetch combobox list actions
export const getSkillComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of skills',
);
export const getSkillComboboxListSuccess = createAction<Partial<ISkillModel>[]>(
  'Combobox list of skills has been fetched successfully',
);
export const getSkillComboboxListFailed = createAction(
  'Cannot fetch combobox skills list',
);

// export actions
export const {
  exportReportRequest: exportSkillRequest,
  exportReportSuccess: exportSkillSuccess,
  exportReportFailed: exportSkillFailed,
} = new ExportActionCreator('Skill').takeExportActions();
