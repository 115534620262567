import React, { useEffect, useRef } from 'react';
import {
  useFetchSitesCombobox,
  useNewHistoricalPayboardWeekDefaultFilter,
} from '../../../../../modules/utils';
import { useReportWithOwnState } from '../../../../../modules/utils/hooks/common/reports';
import { useReportExportProps } from '../../../../../modules/utils/hooks/reports.hooks';
import { roles } from '../../../../../config';

import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserRoles } from '../../../../../modules/selectors/user';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import {
  ListWithSummary,
  NewPayboardWeek,
  SummaryInfo,
} from '../../../../../modules/types';
import {
  formatKronosPayboardItems,
  formatKronosPayboardSummary,
} from '../../../../../modules/utils/helpers/formatters';

const reportUrl = '/payboard-historical/general';
const ignoreGlobalSites = true;
const DEFAULT_ORDER_BY = 'yearWeek';
const DEFAULT_ORDER = 'desc';

const allowedDeleteRoles = [roles.SUPER_ADMIN];

const PayboardKronosHistorical = () => {
  const { t } = useTranslation();
  const abortControllerRef = useRef<AbortController | null>(null);

  const { data, fetchData, isDataLoading } = useReportWithOwnState<
    ListWithSummary<NewPayboardWeek>
  >(reportUrl);
  const exportProps = useReportExportProps(reportUrl, ignoreGlobalSites);

  const fetchSitesComboBox = useFetchSitesCombobox();
  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesComboBox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  // for Daily and Weekly reports different hooks
  const getDefaultFilterHook = useNewHistoricalPayboardWeekDefaultFilter;
  const defaultFilter = getDefaultFilterHook();

  // define is user can delete rows
  let selectIndex = '';
  const userRoles = useSelector(getUserRoles, shallowEqual) ?? [];
  const hasUserPermission = userRoles.some((role) =>
    allowedDeleteRoles.includes(role),
  );
  if (hasUserPermission) {
    selectIndex = 'id'; // define index for selection
  }

  const headCells: HeadCell[] = [
    { id: 'endDate', disablePadding: false, label: t('payboard.end_date') },
    {
      id: 'year',
      disablePadding: false,
      label: t('payboard.year'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('payboard.week'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'companyShortName',
      disablePadding: false,
      label: t('payboard.company_short_name'),
    },
    { id: 'badgeId', disablePadding: false, label: t('payboard.badge_id') },
    {
      id: 'lastName',
      disablePadding: false,
      label: t('payboard.last_name'),
    },
    {
      id: 'firstName',
      disablePadding: false,
      label: t('payboard.first_name'),
    },
    {
      id: 'employeeId',
      disablePadding: false,
      label: t('payboard.employee_id'),
    },
    {
      id: 'eBaseRate',
      disablePadding: false,
      label: t('payboard.e_base_rate'),
    },
    {
      id: 'siteId',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.site_id'),
    },
    {
      id: 'siteName',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.site_name'),
    },
    {
      id: 'payStatementType',
      disablePadding: false,
      label: t('payboard.pay_statement_type'),
    },
    {
      id: 'edt',
      disablePadding: false,
      label: t('payboard.e_d_t'),
    },
    {
      id: 'edtCode',
      disablePadding: false,
      label: t('payboard.e_d_t_code'),
      disableSorting: true,
    },
    {
      id: 'eHours',
      disablePadding: false,
      label: t('payboard.e_hours'),
    },
    {
      id: 'edtAmount',
      disablePadding: false,
      label: t('payboard.e_d_t_amount'),
    },
    {
      id: 'totalPay',
      disablePadding: false,
      label: t('payboard.total_pay'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: sites as ComboBoxOption[],
    },
    {
      name: 'lastName',
      label: t('payboard.last_name'),
      operator: 'like',
    },
    {
      name: 'firstName',
      label: t('payboard.first_name'),
      operator: 'like',
    },
    {
      name: 'badgeId',
      label: t('payboard.badge_id'),
      operator: 'like',
    },
    {
      name: 'employeeId',
      label: t('payboard.employee_id'),
      operator: 'like',
    },
    {
      name: 'endDate',
      label: t('payboard.end_date'),
      operator: 'eq',
      type: 'date',
    },
    {
      name: 'yearWeek',
      label: t('payboard.year_week'),
      operator: 'eq',
      type: 'comboboxYearWeek',
    },
    {
      name: 'companyShortName',
      label: t('payboard.company_short_name'),
      operator: 'like',
    },
    {
      name: 'payStatementType',
      label: t('payboard.pay_statement_type'),
      operator: 'like',
    },
    {
      name: 'edtCode',
      label: t('payboard.e_d_t_code'),
      operator: 'like',
    },
    {
      name: 'eHours',
      label: t('payboard.e_hours'),
      operator: 'like',
    },
  ];

  /**
   * @Todo !!IMPORTANT!!
   * If you use 'disableQsFilters' as true for <EnhancedTable/>
   * You do NOT need useEffect additionally to fetch data on first mount
   * because it use onSync callback inside EnhancedTable inside
   * src/components/Filter/index.tsx:275
   * which cause reset filter which call 'handlerFilterReset' in EnhancedTable itself
   * But you should left cancelling request anyway for unmount event
   */
  // fetch report on mount
  React.useEffect(() => {
    // abortControllerRef.current?.abort();
    // abortControllerRef.current = new AbortController();
    //
    // fetchData(filterList, filterCount, {
    //   signalList: abortControllerRef.current?.signal,
    //   signalCount: abortControllerRef.current?.signal,
    // });

    return () => {
      abortControllerRef.current?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    fetchData(
      {
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      },
      { filter: { where } },
      {
        signalList: abortControllerRef.current?.signal,
        signalCount: abortControllerRef.current?.signal,
      },
    );
  };

  const { items, count, summary } = data;

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={formatKronosPayboardItems(
          (items as unknown) as NewPayboardWeek[],
        )}
        count={count}
        summaryRows={formatKronosPayboardSummary(
          (summary as unknown) as SummaryInfo[],
        )}
        isTableDataLoading={isDataLoading}
        selectIndex={selectIndex}
        defaultOrder={DEFAULT_ORDER}
        defaultOrderBy={DEFAULT_ORDER_BY as any}
        tableName={t('payboard.new_historical_week_table_name')}
        headCells={headCells}
        filters={filters}
        disableSelection={true}
        onSync={onSync}
        onRowClick={noop}
        exportProps={exportProps}
        initialFilterData={defaultFilter.where}
        additionalWhereForExport={defaultFilter.where}
        disablePagination
      />
    </>
  );
};

export default PayboardKronosHistorical;
