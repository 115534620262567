import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { getUserObjectFromStorage } from '../../utils/user';
import {
  IUserState,
  IUserModel,
  IUserUpdateProfileRequest,
  IUserUpdateResponse,
  ICountResponse,
  LockUnlockUserRequestType,
  LockActivateUserRequestType,
  ICompleteCreationResponse,
  IUserPolicy,
  IMe,
  INotificationsPreferences,
} from '../../types';
import { cloneDeep, find } from 'lodash';
import { Lang } from '../../utils';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

const getDefaultState = () => {
  // get userdata from localStorage
  const { user: localUser = {} } = getUserObjectFromStorage();
  // default state
  return {
    refreshKey: 0,
    id: NaN,
    email: '',
    firstName: '',
    lastName: '',
    lang: Lang.EN,
    ...localUser,
    list: [],
    comboboxList: [],
    count: 0,
    profile: {
      webNotificationsEnabled: false,
      emailNotificationsEnabled: false,
      smsNotificationsEnabled: false,
      ...localUser.profile,
    },
    prefilledData: {},
    isUsersDataLoading: false,
    isUsersDataCountLoading: false,
    isUsersDataProcessing: false,
  };
};

const defaultState = getDefaultState();

/**
 * Flush actions
 */
const flushUserState = () => ({
  ...getDefaultState(),
  refreshKey: new Date().getTime(),
});

/**
 * Profile actions
 */
const meRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const meSuccess = (state: IUserState, payload: IMe) => ({
  ...state,
  ...payload,
  is_logged_in: true,
  error: false,
  is_data_requested: false,
});

const meFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Policy actions
 */
const myPolicyRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const myPolicySuccess = (state: IUserState, payload: IUserPolicy) => ({
  ...state,
  policy: payload,
  is_logged_in: true,
  error: false,
  is_data_requested: false,
});

const myPolicyFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating profile actions
 */
const meUpdateRequest = (
  state: IUserState,
  payload: IUserUpdateProfileRequest,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: true,
});

const meUpdateSuccess = (state: IUserState, payload: IUserUpdateResponse) => ({
  ...state,
  error: false,
  profileUpdateStatus: payload.status,
  is_data_requested: false,
});

const meUpdateFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating password actions
 */
const passwordUpdateRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const passwordUpdateSuccess = (
  state: IUserState,
  payload: IUserUpdateResponse,
) => ({
  ...state,
  error: false,
  passwordUpdateStatus: payload.status,
  is_data_requested: false,
});

const passwordUpdateFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getUserListRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataLoading: true,
});

const getUserListSuccess = (state: IUserState, payload: IUserModel[]) => ({
  ...state,
  list: payload,
  error: false,
  isUsersDataLoading: false,
});

const getUserListFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataLoading: false,
});

/**
 * Get count actions
 */
const getUserCountRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataCountLoading: true,
});

const getUserCountSuccess = (state: IUserState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  isUsersDataCountLoading: false,
});

const getUserCountFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataCountLoading: false,
});

/**
 * Deletion actions
 */
const deleteUserRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: true,
});

const deleteUserSuccess = (state: IUserState) => ({
  ...state,
  isUsersDataProcessing: false,
});

const deleteUserFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataProcessing: false,
});

/**
 * Get combobox list actions
 */
const getUserComboboxListRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getUserComboboxListSuccess = (
  state: IUserState,
  payload: Partial<IUserModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getUserComboboxListFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * reset password actions
 */
const handlerResetPasswordRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const handlerResetPasswordSuccess = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const handlerResetPasswordFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Lock / Unlock a user actions
 */
const handleLockedStateRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: true,
});

const handleLockedStateSuccess = (
  state: IUserState,
  payload: LockUnlockUserRequestType[],
) => {
  const list = cloneDeep(state.list);
  for (const item of list) {
    const result = find(payload, (p) => p.id === item.id);
    if (result) {
      item.locked = result.locked;
    }
  }

  return {
    ...state,
    list,
    error: false,
    isUsersDataProcessing: false,
  };
};

const handleLockedStateFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataProcessing: false,
});

/**
 * Activate / Deactivate a user actions
 */
const handleActivatedStateRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: true,
});

const handleActivatedStateSuccess = (
  state: IUserState,
  payload: LockActivateUserRequestType[],
) => {
  const list = cloneDeep(state.list);
  for (const item of list) {
    const result = find(payload, (p) => p.id === item.id);
    if (result) {
      item.activated = result.activated;
    }
  }

  return {
    ...state,
    list,
    error: false,
    isUsersDataProcessing: false,
  };
};

const handleActivatedStateFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataProcessing: false,
});

/**
 * Creation actions
 */
const createUserRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: true,
});

const createUserSuccess = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: false,
});

const createUserFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataProcessing: false,
});

const updateUsersRequest = (state: IUserState) => ({
  ...state,
  error: false,
  isUsersDataProcessing: true,
});

const updateUsersSuccess = (state: IUserState) => ({
  ...state,
  isUsersDataProcessing: false,
});

const updateUsersFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  isUsersDataProcessing: false,
});

/**
 * Updating actions
 */
// const updateUsersRequest = (state: IUserState) => ({
//   ...state,
//   error: false,
//   is_data_requested: true,
// });
//
// const updateUsersFailed = (state: IUserState, payload: string) => ({
//   ...state,
//   error: payload,
//   is_data_requested: false,
// });

/**
 * Complete creation actions
 */
const completeCreationUserRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const completeCreationUserSuccess = (
  state: IUserState,
  payload: ICompleteCreationResponse,
) => ({
  ...state,
  error: false,
  completeStatus: payload.status,
  is_data_requested: false,
});

const completeCreationUserFailed = (state: IUserState) => ({
  ...state,
  is_data_requested: false,
});

const changeNotificationsPreferencesRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const changeNotificationsPreferencesSuccess = (
  state: IUserState,
  notificationsPreferences: INotificationsPreferences,
) => ({
  ...state,
  profile: {
    ...state.profile,
    ...notificationsPreferences,
  },
  error: false,
  is_data_requested: false,
});

const changeNotificationsPreferencesFailed = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

/**
 * get prefilled data actions
 */
const getUserPrefilledDataRequest = (state: IUserState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getUserPrefilledDataSuccess = (
  state: IUserState,
  payload: Partial<IUserModel>,
) => ({
  ...state,
  error: false,
  prefilledData: payload,
  is_data_requested: false,
});

const getUserPrefilledDataFailed = (state: IUserState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IUserState>();

const exportUserRequest = exportRequest;
const exportUserSuccess = exportSuccess;
const exportUserFailed = exportFailed;

/**
 * User reducer
 */
export const user = createReducer<IUserState>({}, defaultState)
  // flush actions
  .on(actions.flushUserState, flushUserState)
  // profile actions
  .on(actions.meRequest, meRequest)
  .on(actions.meSuccess, meSuccess)
  .on(actions.meFailed, meFailed)
  // profile actions
  .on(actions.myPolicyRequest, myPolicyRequest)
  .on(actions.myPolicySuccess, myPolicySuccess)
  .on(actions.myPolicyFailed, myPolicyFailed)
  // updating profile actions
  .on(actions.meUpdateRequest, meUpdateRequest)
  .on(actions.meUpdateSuccess, meUpdateSuccess)
  .on(actions.meUpdateFailed, meUpdateFailed)
  // updating password actions
  .on(actions.passwordUpdateRequest, passwordUpdateRequest)
  .on(actions.passwordUpdateSuccess, passwordUpdateSuccess)
  .on(actions.passwordUpdateFailed, passwordUpdateFailed)
  // get list actions
  .on(actions.getUserListRequest, getUserListRequest)
  .on(actions.getUserListSuccess, getUserListSuccess)
  .on(actions.getUserListFailed, getUserListFailed)
  // get count actions
  .on(actions.getUserCountRequest, getUserCountRequest)
  .on(actions.getUserCountSuccess, getUserCountSuccess)
  .on(actions.getUserCountFailed, getUserCountFailed)
  // deletion actions
  .on(actions.deleteUserRequest, deleteUserRequest)
  .on(actions.deleteUserSuccess, deleteUserSuccess)
  .on(actions.deleteUserFailed, deleteUserFailed)
  // get combobox list actions
  .on(actions.getUserComboboxListRequest, getUserComboboxListRequest)
  .on(actions.getUserComboboxListSuccess, getUserComboboxListSuccess)
  .on(actions.getUserComboboxListFailed, getUserComboboxListFailed)
  // reset password by admin actions
  .on(actions.resetPasswordByAdminRequest, handlerResetPasswordRequest)
  .on(actions.resetPasswordByAdminSuccess, handlerResetPasswordSuccess)
  .on(actions.resetPasswordByAdminFailed, handlerResetPasswordFailed)
  // lock user actions
  .on(actions.lockUserRequest, handleLockedStateRequest)
  .on(actions.lockUserSuccess, handleLockedStateSuccess)
  .on(actions.lockUserFailed, handleLockedStateFailed)
  // unlock user actions
  .on(actions.unlockUserRequest, handleLockedStateRequest)
  .on(actions.unlockUserSuccess, handleLockedStateSuccess)
  .on(actions.unlockUserFailed, handleLockedStateFailed)
  // activate user actions
  .on(actions.activateUserRequest, handleActivatedStateRequest)
  .on(actions.activateUserSuccess, handleActivatedStateSuccess)
  .on(actions.activateUserFailed, handleActivatedStateFailed)
  // deactivate user actions
  .on(actions.deactivateUserRequest, handleActivatedStateRequest)
  .on(actions.deactivateUserSuccess, handleActivatedStateSuccess)
  .on(actions.deactivateUserFailed, handleActivatedStateFailed)
  // create a new user
  .on(actions.createUserRequest, createUserRequest)
  .on(actions.createUserSuccess, createUserSuccess)
  .on(actions.createUserFailed, createUserFailed)
  // update users
  .on(actions.updateUsersRequest, updateUsersRequest)
  .on(actions.updateUsersSuccess, updateUsersSuccess)
  .on(actions.updateUsersFailed, updateUsersFailed)
  // complete creation actions
  .on(actions.completeCreationUserRequest, completeCreationUserRequest)
  .on(actions.completeCreationUserSuccess, completeCreationUserSuccess)
  .on(actions.completeCreationUserFailed, completeCreationUserFailed)
  // export actions
  .on(actions.exportUserRequest, exportUserRequest)
  .on(actions.exportUserSuccess, exportUserSuccess)
  .on(actions.exportUserFailed, exportUserFailed)
  // get prefilled data actions
  .on(actions.getUserPrefilledDataRequest, getUserPrefilledDataRequest)
  .on(actions.getUserPrefilledDataSuccess, getUserPrefilledDataSuccess)
  .on(actions.getUserPrefilledDataFailed, getUserPrefilledDataFailed)
  // notifications preferences actions
  .on(
    actions.changeNotificationsPreferencesRequest,
    changeNotificationsPreferencesRequest,
  )
  .on(
    actions.changeNotificationsPreferencesSuccess,
    changeNotificationsPreferencesSuccess,
  )
  .on(
    actions.changeNotificationsPreferencesFailed,
    changeNotificationsPreferencesFailed,
  );

export default user;
