import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  IRecruitingReport,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { useFetchShiftsCombobox } from 'src/modules/utils';
import { getShiftsComboboxListBySiteId } from 'src/modules/selectors/shift';
import { ComboBoxOption } from 'src/components/ComboBox';

interface IProps {
  summaryRow?: IRecruitingReport;
}

export default function RecruitingDetailedReport({ summaryRow }: IProps) {
  const { t } = useTranslation();

  const reportUrl = `/sites/absenteeism/${summaryRow?.siteId}`;
  const fetchShiftsComboBox = useFetchShiftsCombobox();

  useEffect(() => {
    fetchShiftsComboBox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const where = React.useMemo(
    () => ({
      siteId: summaryRow?.siteId,
    }),
    [summaryRow?.siteId],
  );

  const getShiftsOptions = useSelector(
    getShiftsComboboxListBySiteId,
    shallowEqual,
  );

  const options = (React.useMemo(
    () =>
      getShiftsOptions(summaryRow!.siteId).map((s) => ({
        id: s.name,
        name: s.name,
      })),
    [getShiftsOptions, summaryRow],
  ) as unknown) as ComboBoxOption[];

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportUrl, {
    items: [],
    summary: [],
  });

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'siteName',
      disablePadding: false,
      label: t('recruiting_dashboards_report.siteName'),
    },
    {
      id: 'shiftName',
      disablePadding: false,
      label: t('recruiting_dashboards_report.shiftName'),
    },
    {
      id: 'desiredHeadCount',
      disablePadding: false,
      label: t('recruiting_dashboards_report.desiredHeadCount'),
    },
    {
      id: 'absenteeism',
      disablePadding: false,
      label: t('recruiting_dashboards_report.absenteeism'),
    },
    {
      id: 'optimalHeadCount',
      disablePadding: false,
      label: t('recruiting_dashboards_report.optimalHeadCount'),
    },
    {
      id: 'countOfActiveEmployees',
      disablePadding: false,
      label: t('recruiting_dashboards_report.countOfActiveEmployees'),
    },
    {
      id: 'openOrders',
      disablePadding: false,
      label: t('recruiting_dashboards_report.openOrders'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'shiftName',
      label: t('recruiting_dashboards_report.shiftName'),
      operator: 'eq',
      type: 'combobox',
      options,
    },
  ];

  useEffect(() => {
    fetchData({
      filter: {
        where,
      },
    });
    fetchCountData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: _where } = props;
    const offset = page * rowsPerPage;

    fetchData({
      filter: {
        limit: rowsPerPage,
        offset,
        where: _where,
        order,
      },
    });
    fetchCountData({ filter: { where: _where } });
  };

  return (
    <EnhancedTable
      hideTableName
      disableSelection
      data={data.items || []}
      filters={filters}
      defaultOrderBy="shiftName"
      defaultOrder="asc"
      count={countData.count ?? 0}
      selectIndex="shiftName"
      tableName={t('recruiting_dashboards_report.modal_title', {
        siteName: summaryRow?.siteName,
      })}
      isTableDataLoading={isDataLoading || isCountDataLoading}
      headCells={headCells}
      onSync={onSync}
    />
  );
}
