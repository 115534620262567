import React from 'react';
import map from 'lodash/map';
import { Drawer } from '@mui/joy';
import { useValidate } from 'src/modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { updateIncentiveTotalPaymentScheme } from 'src/modules/schemes';
import { Api } from 'src/modules/utils';
import { AnyObject } from 'src/modules/types';
import { useFormikInUpdateForm } from 'src/modules/utils/hooks/common/forms';
import FormikCustomNumberField from 'src/components/Formik/FormikCustomNumberField';

interface IPayboardIncentiveUpdateTotalPaymentProps {
  isOpen: boolean;
  rows: AnyObject[];
  onUpdate: () => void;
  onClose: () => void;
}

export const PayboardIncentiveUpdateTotalPayment: React.FC<IPayboardIncentiveUpdateTotalPaymentProps> = ({
  isOpen,
  rows,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(updateIncentiveTotalPaymentScheme);

  const formik = useFormikInUpdateForm({
    initialValues: rows.map(
      ({ id, totalPayment, firstName, lastName, approved }) => ({
        id,
        totalPayment,
        firstName,
        lastName,
        approved,
      }),
    ),
    validate,
    onSubmit: async (data) => {
      await Api.Incentive.updateTotalPayment(
        data.map(({ id, totalPayment }) => ({ id, totalPayment })),
      );
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('payboard.incentive.update_total_payment')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {map(formik.values, (incentive, index) => (
            <FormFieldContainer key={incentive.id}>
              <FormikCustomNumberField
                variant="outlined"
                fullWidth
                disabled={incentive.approved}
                id={`${index}.totalPayment`}
                label={t('payboard.incentive.total_payment', {
                  firstName: incentive.firstName,
                  lastName: incentive.lastName,
                  status: incentive.approved ? 'approved' : 'unapproved',
                })}
                name={`${index}.totalPayment`}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
