import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateStaffingProvidersRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { getStaffingProviderList } from 'src/modules/selectors/staffingProvider';
import { updateStaffingProvidersScheme } from 'src/modules/schemes/staffing-providers';

interface IUpdateStaffingProvidersProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  staffingProvidersToUpdateIds: IdsArray;
}

export const UpdateStaffingProviders = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  staffingProvidersToUpdateIds,
}: IUpdateStaffingProvidersProps) => {
  const { t } = useTranslation();

  const allStaffingProviders = useSelector(getStaffingProviderList);

  const staffingProvidersToUpdateFullData = allStaffingProviders.filter((sp) =>
    staffingProvidersToUpdateIds.includes(sp.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateStaffingProvidersScheme);

  const formik = useFormik({
    initialValues: staffingProvidersToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateStaffingProvidersRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('skills.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {staffingProvidersToUpdateFullData.map(
              (staffingProvider, index) => (
                <DrawerBodySectionContent key={staffingProvider.id}>
                  <DrawerBodySectionTitle>{`${staffingProvider.staffingProvider}`}</DrawerBodySectionTitle>

                  <FormFieldContainer>
                    <FormikTextField
                      required
                      variant="outlined"
                      fullWidth
                      id={`${index}.staffingProvider`}
                      label={t('staffing_prov.name')}
                      name={`${index}.staffingProvider`}
                      autoComplete="staffingProvider"
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      required
                      variant="outlined"
                      fullWidth
                      id={`${index}.markup`}
                      label={t('staffing_prov.markup')}
                      name={`${index}.markup`}
                      autoComplete="markup"
                      formik={formik}
                      type="number"
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      required
                      variant="outlined"
                      fullWidth
                      id={`${index}.ngroupMarkup`}
                      label={t('staffing_prov.ng_markup')}
                      name={`${index}.ngroupMarkup`}
                      autoComplete="ngroupMarkup"
                      formik={formik}
                      type="number"
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      id={`${index}.ptoMarkup`}
                      label={t('staffing_prov.pto_markup')}
                      name={`${index}.ptoMarkup`}
                      autoComplete="ptoMarkup"
                      formik={formik}
                      type="number"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      id={`${index}.holidayMarkup`}
                      label={t('staffing_prov.holiday_markup')}
                      name={`${index}.holidayMarkup`}
                      autoComplete="holidayMarkup"
                      formik={formik}
                      type="number"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      id={`${index}.etoMarkup`}
                      label={t('staffing_prov.eto_markup')}
                      name={`${index}.etoMarkup`}
                      autoComplete="etoMarkup"
                      formik={formik}
                      type="number"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      id={`${index}.securityName`}
                      label={t('staffing_prov.sec_name')}
                      name={`${index}.securityName`}
                      autoComplete="securityName"
                      formik={formik}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      id={`${index}.otMarkup`}
                      label={t('staffing_prov.ot_markup')}
                      name={`${index}.otMarkup`}
                      autoComplete="otMarkup"
                      formik={formik}
                      type="number"
                    />
                  </FormFieldContainer>
                </DrawerBodySectionContent>
              ),
            )}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
