import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IStorageUtilizationDataItem } from 'src/modules/types/sony';
import { paths } from 'src/config';
import { sonyInventoryOrder } from './sonyInventory.constants';
import StackedColumnChart from 'src/components/Charts/2d/StackedColumnChart';

interface ISonyInventoryChartProps {
  startDate?: string;
  endDate?: string;
}
export const SonyInventoryChart = ({
  startDate,
  endDate,
}: ISonyInventoryChartProps) => {
  const { t } = useTranslation();

  const chartColors = ['#5DA6E5', '#DE7866', '#807289'];

  const where = React.useMemo(() => {
    const formattedStartDate = startDate
      ? startDate.replaceAll('-', '')
      : undefined;
    const formattedEndDate = endDate ? endDate.replaceAll('-', '') : undefined;

    if (formattedStartDate && formattedEndDate) {
      return {
        'ID DATE': { between: [formattedStartDate, formattedEndDate] },
      };
    }

    if (formattedStartDate) {
      return { 'ID DATE': { gte: formattedStartDate } };
    }

    if (formattedEndDate) {
      return { 'ID DATE': { lte: formattedEndDate } };
    }

    return undefined;
  }, [endDate, startDate]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    Array<IStorageUtilizationDataItem>
  >(paths.SONY_INVENTORY_STORAGE_UTILIZATION, []);

  const categoriesSet = new Set<string>();
  data.forEach((item) => {
    categoriesSet.add(item['ID DATE']);
  });
  const categories = [...categoriesSet];

  const { seriesObj } = data.reduce(
    (all, item, i) => {
      if (!all.seriesObj[item.Gen]) {
        all.seriesObj[item.Gen] = {
          type: 'column' as const,
          name: item.Gen,
          color: chartColors[i],
          data: new Array(categories.length).fill(null),
        };
      }

      const dateIndex = categories.indexOf(item['ID DATE']);

      all.seriesObj[item.Gen].data[dateIndex] = item.cube;

      return all;
    },
    {
      seriesObj: {},
    },
  );

  React.useEffect(() => {
    fetchData({ filter: { where, order: sonyInventoryOrder } });
  }, [fetchData, where]);

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  return (
    <ChartContainer fullHeigh>
      <ChartContainerHeader title={t('charts.storage_utilization')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`downtime`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isDataLoading}
        showNoData={!data.length && !isDataLoading}
      >
        <StackedColumnChart
          ref={chartRef}
          height={'100%'}
          categories={categories}
          data={Object.values(seriesObj)}
          plotOptions={{
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              },
            },
          }}
          yAxis={[
            {
              title: {
                text: 'Cube ft',
              },
              labels: {
                format: '{value}',
              },
            },
          ]}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
