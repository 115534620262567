import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUsersByIds, getUsersComboboxList } from '../../selectors/user';
import {
  AnyObject,
  ICreateUserBaseFields,
  ICreateUserWithAttributesRequest,
  IdsArray,
  IFilter,
  IStoreState,
  IWhere,
  RolesType,
  UserConfigField,
} from '../../types';
import { has, isEmpty, map, omit, set } from 'lodash';
import {
  getUserComboboxListRequest,
  getUserListRequest,
} from '../../actions/user';
import { getAllowedRoles, roles, supervisorRoles } from '../../../config';
import {
  getGloballySelectedSites,
  getSiteIdsOfSitesGlobalDropdown,
} from 'src/modules/selectors/site';
import React, { useEffect } from 'react';
import { getCurrentRole } from '../../selectors/auth';
import {
  additionalUserFields,
  additionalUserProperties,
  commonUserFields,
} from '../../../config/userFields';
import { i18n } from '../i18n';
import { useFetchRolesCombobox } from './roles';
import { getRolesComboboxListByFieldId } from '../../selectors/role';
import { IInclusionObject } from 'src/modules/types/table';

export const userInclusions = [
  {
    relation: 'department',
    relationType: 'left',
  },
  {
    relation: 'shift',
    relationType: 'left',
  },
  {
    relation: 'supervisor',
    relationType: 'left',
    alias: 'supervisor',
  },
  {
    relation: 'userAttributes',
    relationType: 'left',
    scope: {
      include: [
        {
          relation: 'site',
          relationType: 'left',
        },
        {
          relation: 'staffing',
          relationType: 'left',
        },
        {
          relation: 'client',
          relationType: 'left',
        },
      ],
    },
  },
];

export const useUsersInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  const allSitesIds = useSelector(getSiteIdsOfSitesGlobalDropdown);

  const isAllSitesSelected =
    globallySelectedSiteIds.length === allSitesIds.length;

  return React.useMemo(
    () => [
      {
        relation: 'department',
        relationType: 'left',
      },
      {
        relation: 'shift',
        relationType: 'left',
      },
      {
        relation: 'supervisor',
        relationType: 'left',
        alias: 'supervisor',
      },
      {
        relation: 'userAttributes',
        relationType: 'left',
        scope: {
          include: [
            {
              relation: 'site',
              relationType: 'left',
              scope: isAllSitesSelected
                ? undefined
                : {
                    where: {
                      id: {
                        inq: globallySelectedSiteIds,
                      },
                    },
                  },
            },
            {
              relation: 'staffing',
              relationType: 'left',
            },
            {
              relation: 'client',
              relationType: 'left',
            },
          ],
        },
      },
    ],
    [globallySelectedSiteIds, isAllSitesSelected],
  );
};

export const useUsersInclusionForTable = (): IInclusionObject => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  const allSitesIds = useSelector(getSiteIdsOfSitesGlobalDropdown);

  const isAllSitesSelected =
    globallySelectedSiteIds.length === allSitesIds.length;

  return React.useMemo(
    () => ({
      department: {
        relationType: 'left',
      },
      shift: {
        relationType: 'left',
      },
      supervisor: {
        relationType: 'left',
        alias: 'supervisor',
      },
      userAttributes: {
        relationType: 'left',
        scope: {
          include: {
            site: {
              relationType: 'left',
              scope: isAllSitesSelected
                ? undefined
                : {
                    where: {
                      id: {
                        inq: globallySelectedSiteIds,
                      },
                    },
                  },
            },
            client: {
              relationType: 'left',
            },
            staffing: {
              relationType: 'left',
            },
          },
        },
      },
    }),
    [globallySelectedSiteIds, isAllSitesSelected],
  );
};

export const userComboboxFilter: IFilter = {
  filter: {
    fields: {
      id: true,
      firstName: true,
      lastName: true,
      role: true,
    },
    include: userInclusions,
    order: ['firstName ASC', 'lastName ASC'],
  },
};

interface OptionsAttributes {
  siteId?: boolean;
  clientId?: boolean;
  staffingId?: boolean;
}

export const useInitialDataWithSitesAttributes = () => ({
  email: '',
  firstName: '',
  lastName: '',
  lang: '',
  sites: [],
  clients: [],
  staffings: [],
});

export const usePrepareUserWithAttributes = <
  T = ICreateUserWithAttributesRequest
>() => (
  data: ReturnType<typeof useInitialDataWithSitesAttributes> | AnyObject,
  options: OptionsAttributes,
): T =>
  (({
    ...omit(data, ['sites', 'clients', 'staffings']),
    attributes: [
      ...(has(data, 'sites') && options.siteId
        ? map(data.sites, (site) => ({ siteId: site }))
        : []),
      ...(has(data, 'clients') && options.clientId
        ? map(data.clients, (client) => ({ clientId: client }))
        : []),
      ...(has(data, 'staffings') && options.staffingId
        ? map(data.staffings, (staffing) => ({ staffingId: staffing }))
        : []),
    ],
  } as unknown) as T);

/**
 * A custom hook to fetch users from store if they exist otherwise to make a request to fetch needed users from
 * the server
 */
export const useFetchUsers = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch users list from store
  const users = useSelector(
    (state) => getUsersByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(users)) {
      dispatcher(
        getUserListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: userInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch user combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchUsersCombobox = (where: IWhere = {}) => {
  const dispatcher = useDispatch();
  return () => {
    if (!isEmpty(where)) {
      userComboboxFilter!.filter!.where = where;
    }

    dispatcher(getUserComboboxListRequest(userComboboxFilter));
  };
};

/**
 * Custom hook for fetching supervisors
 */
export const useSupervisorCombobox = (activeOnly?: boolean) =>
  useFetchUsersCombobox({
    ...(activeOnly !== undefined
      ? { activated: activeOnly, locked: !activeOnly }
      : {}),
    role: {
      inq: supervisorRoles,
    },
  });

export const useSupervisorsComboBoxOptions = () => {
  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const fetchSupervisorsCombobox = useSupervisorCombobox();

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  return useSelector(getUsersComboboxList, shallowEqual);
};

export const useUsersDefaultFilters = () => {
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const allowedRoles = getAllowedRoles(currentRole as RolesType);
  const include = useUsersInclusion();

  return React.useMemo(
    () => ({
      // order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      where:
        currentRole === roles.GM
          ? { role: { inq: allowedRoles.filter((r) => r !== roles.GM) } }
          : {},
      include,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRole, include],
  );
};

export const useUsersTableDefaultWhere = () => {
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const allowedRoles = getAllowedRoles(currentRole as RolesType);

  return React.useMemo(
    () =>
      currentRole === roles.GM
        ? { role: { inq: allowedRoles.filter((r) => r !== roles.GM) } }
        : {},

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRole],
  );
};

export const useGetUserFieldsByRole = () => {
  const fetchRolesCombobox = useFetchRolesCombobox();

  // make request to fetch sites from the server if we don't have them in the store
  useEffect(() => {
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roles = useSelector(getRolesComboboxListByFieldId, shallowEqual)('key');

  const getConfig = React.useCallback(
    (role: string) => {
      const foundRole = roles.find((r) => r.key === role);

      return [
        ...commonUserFields,
        ...additionalUserProperties.reduce((acc, cur) => {
          if (foundRole && foundRole[cur]) {
            const foundField = additionalUserFields.find((f) => f.key === cur);
            if (foundField) {
              acc.push(foundField);
            }
          }
          return acc;
        }, [] as UserConfigField[]),
      ];
    },
    [roles],
  );

  return (role: string) => getConfig(role);
};

export const useBaseCreateUserValidationScheme = () => {
  const getConfig = useGetUserFieldsByRole();

  const getBaseSchema = React.useCallback(
    (role: string) => {
      return getConfig(role).reduce<AnyObject>(
        (acc, cur) => {
          if (cur.required) {
            acc.required.push(cur.validationKey);
          }

          switch (cur.type) {
            case 'text':
              acc.properties[cur.validationKey] = {
                default: '',
                isNotEmpty: cur.required,
                type: 'string',
                errorMessage: {
                  isNotEmpty: i18n.t('error.validation.required'),
                },
              };
              break;
            case 'email':
              acc.properties[cur.validationKey] = {
                default: '',
                isNotEmpty: cur.required,
                type: 'string',
                format: 'email',
                errorMessage: {
                  isNotEmpty: i18n.t('error.validation.required'),
                  format: i18n.t('error.validation.email'),
                },
              };
              break;
            case 'lang':
              acc.properties[cur.validationKey] = {
                default: '',
                isNotEmpty: cur.required,
                type: 'string',
                errorMessage: {
                  isNotEmpty: i18n.t('error.validation.required'),
                },
              };
              break;
            case 'sites':
            case 'staffings':
            case 'clients':
              acc.properties[cur.validationKey] = {
                type: 'array',
                minItems: 1,
                items: {
                  type: 'number',
                  errorMessage: {
                    type: i18n.t('error.validation.required'),
                  },
                },
                errorMessage: {
                  minItems: i18n.t('error.validation.required'),
                },
              };
              break;
            case 'shift':
            case 'department':
            case 'supervisor':
              acc.properties[cur.validationKey] = {
                type: 'number',
                nullable: true,
              };
              break;
          }
          return acc;
        },
        { properties: {}, required: [] },
      );
    },
    [getConfig],
  );

  return (role: string) => getBaseSchema(role);
};

export const useCreateUserValidationScheme = () => {
  const getBaseSchema = useBaseCreateUserValidationScheme();

  const getSchema = React.useCallback(
    (role: string) => {
      const { required, properties } = getBaseSchema(role);

      return {
        type: 'object',
        required: [...required, 'role'],
        additionalProperties: false,
        errorMessage: {
          required: i18n.t('error.validation.required'),
        },
        properties: {
          role: {
            type: 'string',
          },
          ...properties,
        },
      };
    },
    [getBaseSchema],
  );

  return (role: string) => getSchema(role);
};

export const useUpdateUserValidationScheme = (roles: string[]) => {
  const getSchema = useCreateUserValidationScheme();
  const schemas = React.useMemo(() => roles.map((role) => getSchema(role)), [
    getSchema,
    roles,
  ]);

  if (!schemas.length) return {};

  return {
    type: 'array',
    items: schemas,
    minItems: schemas.length,
    maxItems: schemas.length,
  };
};

export const useGenerateInitialValues = (role: string) => {
  const getConfig = useGetUserFieldsByRole();
  return React.useMemo(
    () =>
      getConfig(role).reduce(
        (acc, cur) => {
          switch (cur.type) {
            case 'text':
            case 'email':
            case 'lang':
              set(acc, cur.name, '');
              break;
            case 'sites':
            case 'staffings':
            case 'clients':
              set(acc, cur.name, []);
              break;
            case 'shift':
            case 'department':
            case 'supervisor':
              set(acc, cur.name, NaN);
              break;
            default:
              return {
                role,
                firstName: '',
                lastName: '',
                email: '',
                lang: '',
              };
          }

          return acc;
        },
        { role } as ICreateUserBaseFields,
      ),
    [getConfig, role],
  );
};
