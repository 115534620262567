import React from 'react';
import { format, getISOWeek, getWeek, getYear, sub, subDays } from 'date-fns';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  IDailyHrsChart,
  IFilterWhere,
  IPayboardCostByDay,
  IPayboardCostByDayAndDepartment,
  IPayboardCostByDepartment,
  IStoreState,
  ListWithSummary,
  PayboardDetailsRequest,
} from '../../types';
import { findIndex, isEmpty, omit } from 'lodash';
import {
  getPayboardPaymentsSumChartsData,
  getPayboardDetailsData,
  getPayboardDepartmentsChartsData,
} from '../../selectors/payboard';
import { getPayboardDetailsRequest } from '../../actions';
import { useTranslation } from 'react-i18next';
import {
  useDataFetcherWithData,
  useReportDefaultFilter,
} from './common/reports';
import { ROWS_PER_PAGE_ALL } from 'src/components/EnhancedTable';
import { IHeadCellWithOrderConfig } from '../../types/table';
import {
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useRowsPerPageOptions,
  useTableCommonHandlers,
  useTableData,
} from './table';
import {
  DATE_FORMAT,
  generateYearWeekRange,
  getDatesRange,
  getNow,
} from '../dateWrapper';
import { useFetchReportTableDataAndCountEffect } from './reportPage.hooks';
import { SeriesAreaOptions, SeriesLineOptions } from 'highcharts';

export const usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
      supervisorId: {
        value: '',
        property: 'supervisorId',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const usePayboardSummaryByWorkWeekTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
    };
  }, []);
};

/**
 * A custom hook to fetch payboard from store if it exists otherwise to make a request to fetch needed payboard
 * information from the server
 */
export const useFetchPayboardDetails = (payload: PayboardDetailsRequest) => {
  const dispatcher = useDispatch();
  // fetch payboard list from store
  const payboard = useSelector(
    (state) => getPayboardDetailsData(state as IStoreState)(payload),
    shallowEqual,
  );
  return () => {
    if (
      isEmpty(payboard.items) &&
      payload.year &&
      payload.week &&
      payload.badge
    ) {
      dispatcher(getPayboardDetailsRequest(payload));
    }
  };
};

export const usePayboardPaymentsSumChartsData = (): IDailyHrsChart[] => {
  const { t } = useTranslation();
  const { categories, series } = useSelector(getPayboardPaymentsSumChartsData);

  const createTooltip = (sumType: string) => ({
    headerFormat: `
    <span style="font-size:1.2em">
      Department: {series.name}
    </span>
    <br/>
    <span style="color: rgb(191, 191, 191)">
      {point.key}
    </span>
    <br/>
    <br/>
  `,
    pointFormat: `
      <span style="color:{series.color}">\u25CF</span>
      ${sumType} sum: {point.y} $
    `,
  });

  return [
    {
      categories,
      series: series.paySeries,
      yAxisTitle: `${t('common.sum')} ($)`,
      xAxisTitle: t('payboard.date'),
      title: t('payboard.pay'),
      tooltip: createTooltip(t('payboard.pay')),
    },
    {
      categories,
      series: series.stdMarkupSeries,
      yAxisTitle: `${t('common.sum')} ($)`,
      xAxisTitle: t('payboard.date'),
      title: t('payboard.std_markup'),
      tooltip: createTooltip(t('payboard.std_markup')),
    },
    {
      categories,
      series: series.costPlusSeries,
      yAxisTitle: `${t('common.sum')} ($)`,
      xAxisTitle: t('payboard.date'),
      title: t('payboard.cost_plus'),
      tooltip: createTooltip(t('payboard.cost_plus')),
    },
  ];
};

export const usePayboardDepartmentsChartsData = () => {
  const { t } = useTranslation();
  const data = useSelector(getPayboardDepartmentsChartsData);

  const createTooltip = () => ({
    headerFormat: `
    <span style="font-size:1.2em">
      {series.name}
    </span>
    <br/>
    <span style="color: rgb(191, 191, 191)">
      {point.key}
    </span>
    <br/>
    <br/>
  `,
    pointFormat: `
      <span style="color:{series.color}">\u25CF</span>
      {point.y} $ / {point.stackTotal} $
    `,
  });

  const getTitle = (seriesType: string) => {
    switch (seriesType) {
      case 'paySeries':
        return t('payboard.pay');
      case 'stdMarkupSeries':
        return t('payboard.std_markup');
      case 'costPlusSeries':
        return t('payboard.cost_plus');
    }

    return '';
  };

  return Object.keys(data).map((departmentName) => {
    const { categories, ...seriesTypes } = data[departmentName];
    const chartsDataList = Object.keys(seriesTypes).map((seriesType) => {
      const series = (seriesTypes as any)[seriesType];
      const title = getTitle(seriesType);

      return {
        series,
        title,
        categories: categories,
        tooltip: createTooltip(),
        xAxisTitle: t('payboard.date'),
        yAxisTitle: `${t('common.sum')} ($)`,
      };
    });

    return {
      department: departmentName,
      chartsDataList,
    };
  });
};

const DEFAULT_ORDER = ['date asc'];
const DEFAULT_LIMIT = ROWS_PER_PAGE_ALL;

export const useSumByDayReportDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: DEFAULT_ORDER,
      limit: DEFAULT_LIMIT,
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};

export const useSumByDepartmentDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  const today = new Date();
  const currentWeekNumber = getWeek(today, {
    weekStartsOn: 6,
    firstWeekContainsDate: 4,
  });
  const lastWeekNumber = getWeek(sub(today, { days: 7 }), {
    weekStartsOn: 6,
    firstWeekContainsDate: 4,
  });
  const currentYear = getYear(today);

  return React.useMemo(
    () => ({
      order: ['department asc'],
      where: {
        week: {
          between: [lastWeekNumber, currentWeekNumber],
        },
        year: {
          between: [currentYear, currentYear],
        },
        ...reportDefaultFilter.where,
      },
    }),
    [reportDefaultFilter, currentWeekNumber, currentYear, lastWeekNumber],
  );
};

export const useNewPayboardWeekDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`year desc`],
      limit: ROWS_PER_PAGE_ALL,
      where: {
        ...reportDefaultFilter.where,
      },
    }),
    [reportDefaultFilter],
  );
};

export const useNewHistoricalPayboardWeekDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(() => {
    const currentDate = getNow();
    return {
      order: [`year desc`],
      limit: ROWS_PER_PAGE_ALL,
      where: {
        ...reportDefaultFilter.where,
        yearWeek: {
          eq: Number(
            `${getYear(currentDate)}${String(getISOWeek(currentDate)).padStart(
              2,
              '0',
            )}`,
          ),
        },
      },
    };
  }, [reportDefaultFilter]);
};

export const usePayboardDailyHoursSummaryDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  const currentDate = new Date();

  const sevenDaysAgo = subDays(currentDate, 7);

  return React.useMemo(
    () => ({
      order: [`date desc, site asc`],
      ...reportDefaultFilter,
      where: {
        ...reportDefaultFilter.where,

        date: {
          between: [
            format(sevenDaysAgo, DATE_FORMAT),
            format(currentDate, DATE_FORMAT),
          ],
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportDefaultFilter],
  );
};
export const usePayboardDailyHoursDashboardDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`date desc`],
      ...reportDefaultFilter,
      where: {
        ...reportDefaultFilter.where,
        year: getYear(new Date()),
        week: getISOWeek(new Date()),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportDefaultFilter],
  );
};

export const usePayboardListDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();
  const defaultWhere = reportDefaultFilter?.where ?? {};
  const where = {
    ...defaultWhere,
    isArchived: { eq: false },
  };

  return React.useMemo(
    () => ({
      order: [`firstName ASC, lastName ASC, yearWeek DESC`],
      ...reportDefaultFilter,
      where,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportDefaultFilter],
  );
};

export const usePayboardWeekDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: ['yearWeek DESC'],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};

export const usePayboardDayDefaultFilter = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: ['day DESC, siteName ASC'],
      // order: [`day desc`],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};

export const usePayboardSummaryByWorkWeek = (
  where: IFilterWhere,
  reportUrlArg: string,
) => {
  const { reportUrl, headerMap, exclude } = React.useMemo(
    () => ({
      reportUrl: reportUrlArg,
      // reportUrl: '/payboard/daily-summary-by-work-week',
      exclude: ['supervisorId', 'blank'],
      headerMap: {
        firstName: 'payboard.summary_by_work_week.firstName',
        lastName: 'payboard.summary_by_work_week.lastName',
        employeeId: 'payboard.summary_by_work_and_supervisor.employeeId',
        blank: 'payboard.summary_by_work_week.blank',
        grandTotalCurrentWeek: 'payboard.summary_by_work_week.grand_total',
        grandTotalPrevWeek:
          'payboard.summary_by_work_week.grand_total_prev_week',
        signature: 'payboard.summary_by_work_week.signature',
      },
    }),
    [reportUrlArg],
  );

  const { t } = useTranslation();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    AnyObject[]
  >(reportUrl, []);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(() => {
    let report = data[0];

    if (exclude) {
      report = omit(report, exclude);
    }

    // split headers on three parts to keep header's sequence in table
    const firstAndLastName: IHeadCellWithOrderConfig[] = [];
    const daysOfWeek: IHeadCellWithOrderConfig[] = [];
    const blankGrandTotalSignature: IHeadCellWithOrderConfig[] = [];
    const blankGrandPrevWeekTotalSignature: IHeadCellWithOrderConfig[] = [];

    const { grandTotalCurrentWeek, grandTotalPrevWeek, ...rest } = headerMap;

    for (const i in rest) {
      firstAndLastName.push({
        id: i,
        label: t((headerMap as any)[i]),
        orderConfig: { orderBy: i },
      });
    }

    const workDays = omit(report, [
      'firstName',
      'lastName',
      'employeeId',
      'signature',
      'blank',
      'supervisor',
      'grandTotalCurrentWeek',
      'grandTotalPrevWeek',
    ]);

    // sort work days
    const workDaysKeys = Object.keys(workDays);
    const sortedWorkDaysList = workDaysKeys.sort();
    sortedWorkDaysList.forEach((day) =>
      daysOfWeek.push({
        id: day,
        label: day,
      }),
    );

    for (const i in { grandTotalCurrentWeek }) {
      if ((headerMap as any)[i]) {
        blankGrandTotalSignature.push({
          id: i,
          label: t((headerMap as any)[i]),
          orderConfig: { orderBy: i },
        });
      }
    }
    for (const i in { grandTotalPrevWeek }) {
      if ((headerMap as any)[i]) {
        blankGrandPrevWeekTotalSignature.push({
          id: i,
          label: t((headerMap as any)[i]),
          orderConfig: { orderBy: i },
        });
      }
    }

    // for keeping headers sequence
    return [
      ...firstAndLastName,
      ...blankGrandPrevWeekTotalSignature,
      ...daysOfWeek,
      ...blankGrandTotalSignature,
    ];
  }, [data, exclude, headerMap, t]);

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    setPage,
    where,
    limit,
    ...(where?.siteId ? { fetchData } : {}),
  });

  const filterFieldsConfiguration = usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    defaultWhere: where,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  return {
    data,
    isDataLoading,
    headCells,
    order,
    orderBy,
    limit,
    page,
    requestFilters,
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    filters: {
      getLabel,
      filterFields,
      onFiltersFormSubmit,
      getFilterCommonPropsByFilterName,
    },
  };
};

export const usePayboardSummaryByWorkAndSupervisorWeek = (
  where: IFilterWhere,
) => {
  const { reportUrl, headerMap, exclude } = React.useMemo(
    () => ({
      reportUrl: '/payboard/daily-summary-by-work-week-and-supervisor',
      exclude: ['supervisorId', 'blank'],
      headerMap: {
        firstName: 'payboard.summary_by_work_and_supervisor.firstName',
        lastName: 'payboard.summary_by_work_and_supervisor.lastName',
        employeeId: 'payboard.summary_by_work_and_supervisor.employeeId',
        supervisor: 'payboard.summary_by_work_and_supervisor.supervisor',
        grandTotal: 'payboard.summary_by_work_and_supervisor.grand_total',
      },
    }),
    [],
  );

  const { t } = useTranslation();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    AnyObject[]
  >(reportUrl, []);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(() => {
    let report = data[0];

    if (exclude) {
      report = omit(report, exclude);
    }

    // split headers on three parts to keep header's sequence in table
    const firstAndLastName: IHeadCellWithOrderConfig[] = [];
    const daysOfWeek: IHeadCellWithOrderConfig[] = [];
    const blankGrandTotalSignature: IHeadCellWithOrderConfig[] = [];

    const { grandTotal, ...rest } = headerMap;

    for (const i in rest) {
      firstAndLastName.push({
        id: i,
        label: t((headerMap as any)[i]),
        orderConfig: { orderBy: i },
      });
    }

    const workDays = omit(report, [
      'firstName',
      'lastName',
      'employeeId',
      'signature',
      'blank',
      'supervisor',
      'grandTotal',
    ]);

    // sort work days
    const workDaysKeys = Object.keys(workDays);
    const sortedWorkDaysList = workDaysKeys.sort();
    sortedWorkDaysList.forEach((day) =>
      daysOfWeek.push({
        id: day,
        label: day,
      }),
    );

    for (const i in { grandTotal }) {
      if ((headerMap as any)[i]) {
        blankGrandTotalSignature.push({
          id: i,
          label: t((headerMap as any)[i]),
          orderConfig: { orderBy: i },
        });
      }
    }

    // for keeping headers sequence
    return [...firstAndLastName, ...daysOfWeek, ...blankGrandTotalSignature];
  }, [data, exclude, headerMap, t]);

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    setPage,
    where,
    limit,
    ...(where?.siteId ? { fetchData } : {}),
  });

  const filterFieldsConfiguration = usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    defaultWhere: where,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  return {
    data,
    isDataLoading,
    headCells,
    order,
    orderBy,
    limit,
    page,
    requestFilters,
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    filters: {
      getLabel,
      filterFields,
      onFiltersFormSubmit,
      getFilterCommonPropsByFilterName,
    },
  };
};

export const usePayboardSummary = (count: number) => {
  const DEFAULT_LIMIT = 25;

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);

  const rowsPerPageOptions = useRowsPerPageOptions(count);

  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    v: number,
  ) => setPage(v);
  const onRowsPerPageChange = (value: number) => setLimit(Number(value));

  return {
    page,
    rowsPerPage: limit,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
  };
};

export const useCostByDepartment = ({
  yearWeekRange,
  siteId,
}: {
  siteId?: number;
  yearWeekRange?: { from: number; to: number };
}) => {
  const where = React.useMemo(() => {
    const startYear = `${yearWeekRange?.from}`.substring(0, 4);
    const startWeek = `${yearWeekRange?.from}`.substring(4, 6);

    const endYear = `${yearWeekRange?.to}`.substring(0, 4);
    const endWeek = `${yearWeekRange?.to}`.substring(4, 6);

    return {
      siteId,
      ...(yearWeekRange
        ? {
            week: {
              between: [startWeek, endWeek],
            },
            year: {
              between: [startYear, endYear],
            },
          }
        : {}),
    };
  }, [siteId, yearWeekRange]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<IPayboardCostByDepartment>
  >('/payboard/costs/sum-by-department', {
    items: [],
    summary: [],
  });

  const categories = yearWeekRange
    ? generateYearWeekRange(yearWeekRange.from, yearWeekRange.to).map(
        (i) => `${i}`,
      )
    : [];

  React.useEffect(() => {
    if (yearWeekRange) {
      fetchData({ filter: { where } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const series = data.items.reduce((acc, cur) => {
    acc[cur.site] = acc[cur.site] || {};
    acc[cur.site][cur.department] = acc[cur.site][cur.department] || {
      name: cur.department,
      data: new Array(categories.length).fill(0),
      type: 'column' as const,
    };

    const index = findIndex(
      categories,
      (c) => c === `${cur.year}${String(cur.week).padStart(2, '0')}`,
    );
    acc[cur.site][cur.department].data[index] = cur.payment;

    return acc;
  }, {} as AnyObject);

  return { series, isDataLoading, categories };
};

export const useCostByDay = ({
  siteId,
  dateFrom,
  dateTo,
}: {
  siteId?: number;
  dateFrom?: string;
  dateTo?: string;
}) => {
  const where = React.useMemo(() => {
    return {
      siteId,
      ...(dateFrom && dateTo
        ? {
            date: {
              between: [dateFrom, dateTo],
            },
          }
        : {}),
    };
  }, [dateFrom, dateTo, siteId]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<IPayboardCostByDay>
  >('/payboard/costs/sum-by-day', {
    items: [],
    summary: [],
  });

  const categories = dateFrom && dateTo ? getDatesRange(dateFrom, dateTo) : [];

  React.useEffect(() => {
    if (dateFrom && dateTo) {
      fetchData({ filter: { where } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const series = data.items.reduce((acc, cur) => {
    acc[cur.site] = acc[cur.site] || {
      name: cur.site,
      data: new Array(categories.length).fill(0),
      type: 'line' as const,
    };

    const index = findIndex(categories, (c) => c === cur.date);
    acc[cur.site].data[index] = cur.payment;

    return acc;
  }, {} as AnyObject);

  return {
    series: Object.values(series) as SeriesLineOptions[],
    isDataLoading,
    categories,
  };
};

export const useCostByDayAndDepartment = ({
  siteId,
  dateFrom,
  dateTo,
}: {
  siteId?: number;
  dateFrom?: string;
  dateTo?: string;
}) => {
  const where = React.useMemo(() => {
    return {
      siteId,
      ...(dateFrom && dateTo
        ? {
            date: {
              between: [dateFrom, dateTo],
            },
          }
        : {}),
    };
  }, [dateFrom, dateTo, siteId]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<IPayboardCostByDayAndDepartment>
  >('/payboard/costs/sum-by-day-and-department', {
    items: [],
    summary: [],
  });

  const categories = dateFrom && dateTo ? getDatesRange(dateFrom, dateTo) : [];

  React.useEffect(() => {
    if (dateFrom && dateTo) {
      fetchData({ filter: { where } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const series = data.items.reduce((acc, cur) => {
    acc[cur.site] = acc[cur.site] || {
      name: cur.site,
      data: new Array(categories.length).fill(0),
      type: 'area' as const,
    };

    const index = findIndex(categories, (c) => c === cur.date);
    acc[cur.site].data[index] = cur.payment;

    return acc;
  }, {} as AnyObject);

  return {
    series: Object.values(series) as SeriesAreaOptions[],
    isDataLoading,
    categories,
  };
};
