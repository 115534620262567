import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updatePointTypesRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { getPointTypeList } from 'src/modules/selectors/pointType';
import { updatePointTypesScheme } from 'src/modules/schemes/point-types';
import { omit } from 'lodash';
import { DEFAULT_POINTS_LIFETIME } from '../CreatePointType';
import FormikCustomNumberField from 'src/components/Formik/FormikCustomNumberField';

const initialValues = {
  siteId: NaN,
  reason: '',
  point: NaN,
  lifetime: DEFAULT_POINTS_LIFETIME,
};

interface IUpdatePointTypesProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  departmentsToUpdateIds: IdsArray;
}

export const UpdatePointTypes = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  departmentsToUpdateIds,
}: IUpdatePointTypesProps) => {
  const { t } = useTranslation();

  const allPointTypes = useSelector(getPointTypeList);

  const pointsToUpdateFullData = allPointTypes.filter((department) =>
    departmentsToUpdateIds.includes(department.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updatePointTypesScheme);

  const formik = useFormik({
    initialValues: pointsToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updatePointTypesRequest({
          data: data.map((item) => omit(item, 'site')),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('point_types.bulk_update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {pointsToUpdateFullData.map((pointType, index) => (
              <DrawerBodySectionContent key={pointType.id}>
                <DrawerBodySectionTitle>{`${pointType.reason} (${pointType.site?.name})`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <FormikTextarea
                    variant="outlined"
                    required
                    id={`${index}.reason`}
                    label={t('point_types.reason')}
                    name={`${index}.reason`}
                    formik={formik}
                    /**@@TODO: fix inputProps */
                    // inputProps={{
                    //   maxLength: REASON_MAX_LENGTH,
                    // }}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    label={t('point_types.site')}
                    placeholder={t('point_types.site')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormikCustomNumberField
                    required
                    fullWidth
                    formik={formik}
                    id={`${index}.point`}
                    variant="outlined"
                    label={t('point_types.point')}
                    name={`${index}.point`}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormikTextField
                    required
                    type="number"
                    variant="outlined"
                    fullWidth
                    id={`${index}.lifetime`}
                    label={t('point_types.points_lifetime')}
                    name={`${index}.lifetime`}
                    formik={formik}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
