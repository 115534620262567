import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getEmployeeSkillsByIds,
  getEmployeeSkillsComboboxList,
} from '../../selectors/employeeSkill';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getEmployeeSkillComboboxListRequest,
  getEmployeeSkillListRequest,
} from '../../actions';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { defaultOrderDetails } from '../helpers/filter';
import { useEmployeesInclusion } from '.';

export const employeeSkillsInclusions = [
  {
    relation: 'skill',
  },
  {
    relation: 'employee',
  },
  {
    relation: 'attachments',
    relationType: 'left',
  },
];

export const useEmployeeSkillsInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => [
      {
        relation: 'skill',
      },
      {
        relation: 'employee',
        scope: {
          where: {
            siteId: {
              inq: globallySelectedSiteIds,
            },
            active: {
              eq: true,
            },
          },
          include: [
            {
              relation: 'defaultShift',
            },
            {
              relation: 'site',
            },
          ],
        },
      },
      {
        relation: 'attachments',
        relationType: 'left',
      },
    ],
    [globallySelectedSiteIds],
  );
};

const useEmployeeSkillComboboxFilter = () => {
  const employeeSkillsInclusion = useEmployeesInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          skillId: true,
          employeeId: true,
        },
        include: employeeSkillsInclusion,
      },
    }),
    [employeeSkillsInclusion],
  );
};

/**
 * A custom hook to fetch employeeSkills from store if they exist otherwise to make a request to fetch needed employeeSkills from
 * the server
 */
export const useFetchEmployeeSkillsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch employeeSkills list from store
  const employeeSkills = useSelector(
    (state) => getEmployeeSkillsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(employeeSkills)) {
      dispatcher(
        getEmployeeSkillListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: employeeSkillsInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch employeeSkills combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchEmployeeSkillsCombobox = () => {
  const dispatcher = useDispatch();
  const employeeSkillsComboboxFilter = useEmployeeSkillComboboxFilter();

  const dispatchGetEmployeeSkillsComboboxListRequest = React.useCallback(() => {
    dispatcher(
      getEmployeeSkillComboboxListRequest(employeeSkillsComboboxFilter),
    );
  }, [employeeSkillsComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetEmployeeSkillsComboboxListRequest();
  }, [dispatchGetEmployeeSkillsComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getEmployeeSkillsComboboxList, shallowEqual);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetEmployeeSkillsComboboxListRequest();
    }
  };
};

export const useEmployeeSkillsDefaultFilters = () => {
  const include = useEmployeeSkillsInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};
