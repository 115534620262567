import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IDepartmentModel,
  IDepartmentState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IDepartmentState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  siteId: NaN,
  indirect: false,
  archived: false,
  includeToUph: true,
  includeToCostPlus: true,
  list: [],
  comboboxList: [],
  count: 0,
  isDepartmentsListInProgress: false,
  isDepartmentsCountInProgress: false,
  isManageDepartmentOperationInProgress: false,
};

/**
 * Flush actions
 */
const flushDepartmentState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createDepartmentRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  isManageDepartmentOperationInProgress: true,
});

const createDepartmentSuccess = (
  state: IDepartmentState,
  payload: IDepartmentModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isManageDepartmentOperationInProgress: false,
});

const createDepartmentFailed = (state: IDepartmentState) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

/**
 * Get list actions
 */
const getDepartmentListRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  isDepartmentsListInProgress: true,
});

const getDepartmentListSuccess = (
  state: IDepartmentState,
  payload: IDepartmentModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isDepartmentsListInProgress: false,
});

const getDepartmentListFailed = (state: IDepartmentState) => ({
  ...state,
  isDepartmentsListInProgress: false,
});

/**
 * Get count actions
 */
const getDepartmentCountRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  isDepartmentsCountInProgress: true,
});

const getDepartmentCountSuccess = (
  state: IDepartmentState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isDepartmentsCountInProgress: false,
});

const getDepartmentCountFailed = (state: IDepartmentState) => ({
  ...state,
  isDepartmentsCountInProgress: false,
});

/**
 * Deletion actions
 */
const deleteDepartmentRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  isManageDepartmentOperationInProgress: true,
});

const deleteDepartmentSuccess = (state: IDepartmentState) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

const deleteDepartmentFailed = (state: IDepartmentState) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

/**
 * Updating actions
 */
const updateDepartmentsRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  isManageDepartmentOperationInProgress: true,
});

const updateDepartmentsSuccess = (state: IDepartmentState) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

const updateDepartmentsFailed = (state: IDepartmentState) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

/**
 * UpdateDesiredEmployeesNumber actions
 */
const updateDepartmentsDesiredEmployeesNumberRequest = (
  state: IDepartmentState,
) => ({
  ...state,
  error: false,
  isManageDepartmentOperationInProgress: true,
});

const updateDepartmentsDesiredEmployeesNumberSuccess = (
  state: IDepartmentState,
) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

const updateDepartmentsDesiredEmployeesNumberFailed = (
  state: IDepartmentState,
) => ({
  ...state,
  isManageDepartmentOperationInProgress: false,
});

/**
 * Get combobox list actions
 */
const getDepartmentComboboxListRequest = (state: IDepartmentState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getDepartmentComboboxListSuccess = (
  state: IDepartmentState,
  payload: Partial<IDepartmentModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getDepartmentComboboxListFailed = (state: IDepartmentState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IDepartmentState>();

const exportDepartmentRequest = exportRequest;
const exportDepartmentSuccess = exportSuccess;
const exportDepartmentFailed = exportFailed;

/**
 * Department reducer
 */
export const department = createReducer<IDepartmentState>({}, defaultState)
  // flush actions
  .on(actions.flushDepartmentState, flushDepartmentState)
  // creation actions
  .on(actions.createDepartmentRequest, createDepartmentRequest)
  .on(actions.createDepartmentSuccess, createDepartmentSuccess)
  .on(actions.createDepartmentFailed, createDepartmentFailed)
  // get list actions
  .on(actions.getDepartmentListRequest, getDepartmentListRequest)
  .on(actions.getDepartmentListSuccess, getDepartmentListSuccess)
  .on(actions.getDepartmentListFailed, getDepartmentListFailed)
  // get count actions
  .on(actions.getDepartmentCountRequest, getDepartmentCountRequest)
  .on(actions.getDepartmentCountSuccess, getDepartmentCountSuccess)
  .on(actions.getDepartmentCountFailed, getDepartmentCountFailed)
  // deletion actions
  .on(actions.deleteDepartmentRequest, deleteDepartmentRequest)
  .on(actions.deleteDepartmentSuccess, deleteDepartmentSuccess)
  .on(actions.deleteDepartmentFailed, deleteDepartmentFailed)
  // updating actions
  .on(actions.updateDepartmentsRequest, updateDepartmentsRequest)
  .on(actions.updateDepartmentsSuccess, updateDepartmentsSuccess)
  .on(actions.updateDepartmentsFailed, updateDepartmentsFailed)
  // updateDepartmentsDesiredEmployeesNumber actions
  .on(
    actions.updateDepartmentsDesiredEmployeesNumberRequest,
    updateDepartmentsDesiredEmployeesNumberRequest,
  )
  .on(
    actions.updateDepartmentsDesiredEmployeesNumberSuccess,
    updateDepartmentsDesiredEmployeesNumberSuccess,
  )
  .on(
    actions.updateDepartmentsDesiredEmployeesNumberFailed,
    updateDepartmentsDesiredEmployeesNumberFailed,
  )
  // get combobox list actions
  .on(
    actions.getDepartmentComboboxListRequest,
    getDepartmentComboboxListRequest,
  )
  .on(
    actions.getDepartmentComboboxListSuccess,
    getDepartmentComboboxListSuccess,
  )
  .on(actions.getDepartmentComboboxListFailed, getDepartmentComboboxListFailed)
  // export actions
  .on(actions.exportDepartmentRequest, exportDepartmentRequest)
  .on(actions.exportDepartmentSuccess, exportDepartmentSuccess)
  .on(actions.exportDepartmentFailed, exportDepartmentFailed);

export default department;
