import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { exportStaffingProviderRequest } from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  Api,
  useCreateExportProps,
  useFetchMetatagsByIds,
} from 'src/modules/utils';
import {
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, IStandardMeta, IStandardModel } from 'src/modules/types';
import { map } from 'lodash';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';
import { CreateStandard } from './StandardsForms/CreateStandard';
import { UpdateStandards } from './StandardsForms/UpdateStandards';

export const StandardsList = () => {
  const { t } = useTranslation();

  const [selectedSiteId, setSelectedSiteId] = React.useState<null | number>(
    null,
  );

  const fetchMetatags = useFetchMetatagsByIds();

  const metatagNames = useSelector(getMetatagsNames);

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [isDataManaging, setIsDataManaging] = React.useState(false);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportStaffingProviderRequest);

  // const isDataLoading = useSelector(isStaffingProviderDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.standard.create.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.standard.update.id,
  );

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.standard.delete.id,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'id',
        orderConfig: { orderBy: 'id' },
        label: t('standards.id'),
      },
      {
        id: 'standard',
        orderConfig: { orderBy: 'standard' },
        label: t('standards.standard'),
      },
      {
        id: 'baseTime',
        orderConfig: { orderBy: 'baseTime' },
        label: t('standards.base_time'),
      },

      ...map(metatagNames[selectedSiteId as number] ?? [], (field) => ({
        id: field,
        label: field,
      })),
    ],
    [metatagNames, selectedSiteId, t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      id: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
      },
      standard: {
        value: '',
        property: 'standard',
        operator: 'eq' as const,
      },
      baseTime: {
        value: '',
        property: 'baseTime',
        operator: 'eq' as const,
      },
      ...(metatagNames[selectedSiteId as number] ?? []).reduce<{
        [key: string]: any;
      }>((all, field) => {
        all[field] = {
          value: '',
          property: field,
          operator: 'eq',
        };
        return all;
      }, {}),
    }),
    [metatagNames, selectedSiteId],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'id',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  const handleFiltersSubmit = (filters: AnyObject) => {
    const { siteId, ...restFilters } = filters;

    onFiltersFormSubmit(restFilters);
    setSelectedSiteId(siteId ?? null);
  };

  const { data: fullData, fetchData, isDataLoading } = useDataFetcherWithData<{
    count: number;
    data: Array<
      Pick<IStandardModel, 'id' | 'baseTime' | 'site' | 'siteId' | 'standard'> &
        IStandardMeta
    >;
  }>(`/standards/${selectedSiteId}`, { count: 0, data: [] });

  const list = fullData.data;
  const count = fullData.count;

  const selectedEntities = list.filter((item) =>
    selectedEntitiesIds.includes(item.id),
  );

  const onRequestStart = () => setIsDataManaging(true);
  const onRequestEnd = () => {
    setIsDataManaging(false);

    fetchData({
      filter,
    });
  };

  const onDelete = async () => {
    onRequestStart();

    await Api.Standard.delete({
      where: { id: { inq: selectedEntitiesIds } },
    });

    setSelectedEntitiesIds([]);

    onRequestEnd();
  };

  React.useEffect(() => {
    fetchMetatags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selectedSiteId) {
      fetchData({ filter });
    }
  }, [fetchData, filter, selectedSiteId]);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('standards.main')}</TableTitle>
            <TableActions>
              <Filter onSubmit={handleFiltersSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('skills.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'id',
                      labelPrefix: t('standards.id'),
                    })}
                    {...getFilterCommonPropsByFilterName('id')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'standard',
                      labelPrefix: t('standards.standard'),
                    })}
                    {...getFilterCommonPropsByFilterName('standard')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'baseTime',
                      labelPrefix: t('standards.base_time'),
                    })}
                    {...getFilterCommonPropsByFilterName('baseTime')}
                  />
                </FormFieldContainer>
                {(metatagNames[selectedSiteId as number] ?? []).map((field) => (
                  <FormFieldContainer key={field}>
                    <TextFieldFilter
                      label={getLabel({
                        filterName: field as any,
                        labelPrefix: field,
                      })}
                      {...getFilterCommonPropsByFilterName(field as any)}
                    />
                  </FormFieldContainer>
                ))}
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          {selectedSiteId && (
            <TableComponent
              tableUniqueKey="setup/standards"
              sx={{
                height:
                  isDataLoading || isDataManaging || !list.length
                    ? '100%'
                    : 'auto',
                maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
              }}
            >
              {(isDataLoading || isDataManaging) && <MainContentLoader />}

              <TableContent
                data={list as any}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                isDataLoading={isDataLoading}
                onRowCheckboxClick={(ids) => {
                  setSelectedEntitiesIds(ids);
                }}
                selectedRows={selectedEntitiesIds}
                onSort={(orderBy, order) => {
                  setOrder(order);
                  setOrderBy(orderBy);
                }}
              />

              {Boolean(selectedEntitiesIds.length) && (
                <TableSelectedItemsActions
                  selectedItemsQuantity={selectedEntitiesIds.length}
                  actionsConfiguration={actionsConfiguration}
                  deleteModelName={ModelsToDelete.Standard}
                  deleteActionConfiguration={
                    allowedToDelete
                      ? {
                          tooltip: t('common.delete'),
                          onClick: onDelete,
                          icon: <Trash04Svg />,
                        }
                      : undefined
                  }
                />
              )}

              <TableBottom>
                <Pagination
                  currentPageNumber={page}
                  count={count}
                  rowsPerPage={limit}
                  onPageChange={(_, page) => setPage(page)}
                  onRowsPerPageChange={setLimit}
                />
              </TableBottom>
            </TableComponent>
          )}

          {!selectedSiteId && (
            <RequiredFiltersAlert
              i18nKey="standards.site_filter_alert"
              values={{ site: 'Site' }}
            />
          )}
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateStandard
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
      />

      {selectedSiteId && (
        <UpdateStandards
          isOpen={isUpdateFormOpened}
          onClose={() => setIsUpdateFormOpened(false)}
          onRequestEnd={onRequestEnd}
          onRequestStart={onRequestStart}
          standards={selectedEntities}
        />
      )}
    </PageContentWithTopToolbar>
  );
};
