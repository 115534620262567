import React from 'react';
import { useTranslation } from 'react-i18next';

import { Report, ReportPage } from 'src/components/ReportPage';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { WeeklyGrossMargin } from './WeeklyGrossMargin';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';
import { clients } from '../../modules/constants';
import { TargetWeeklyCpu } from './WeeklyCpu';
import { TargetVirtualLocationsInventory } from './VirtualLocationsInventory';
import { TargetWeeklyProduction } from './WeeklyProduction';
import { TargetDailyCpu } from './DailyCpu';
import { TargetDailyProduction } from './DailyProduction';
import { TargetTop5Cpu } from './Top5Cpu';
import { ChartsContainer } from '../../components/ReportPage/ChartsContainer';
import Top5CpuChart from './Top5CpuChart';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import { TargetPulseSummary } from './PulseSummary';
import {
  ITargetReportsFilterPanelFilters,
  TargetReportsFilterPanel,
} from './TargetReportsFilterPanel';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';

const reportsDefaultFilters: ITargetReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

const targetClient = clients.target;

export const TargetReports = () => {
  const { t } = useTranslation();

  const reportProps = useReportsProps(targetClient);

  const hasUserAccessToUploadTargetProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.target].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof ITargetReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      { id: 'month', label: t('productions_uph_reports.month') },
      { id: 'date', label: t('productions_uph_reports.date') },
      { id: 'area', label: t('productions_uph_reports.area') },
      { id: 'category', label: t('productions_uph_reports.category') },
      { id: 'chargeType', label: t('productions_uph_reports.chargeType') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    ITargetReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={targetClient}>
        <ReportPageItemContainer>
          <ReportTableToolbar reportName={t('main_menu.reports.target_main')}>
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>

            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <ReportPageExport reportProps={reportProps} />
              </ReportPageActionsGroupItem>

              {hasUserAccessToUploadTargetProductionData && (
                <ReportPageActionsGroupItem>
                  <UploadImportingFile clientKey={clients.target} />
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
        </ReportPageItemContainer>

        <ReportPageItemContainer>
          <TargetReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportProps.weeklyCpu}>
          <ReportPageItemContainer fullHeight>
            <TargetWeeklyCpu
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              chargeType={appliedFilters.chargeType}
              week={appliedFilters.week}
              year={appliedFilters.year}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.weeklyProduction}>
          <ReportPageItemContainer fullHeight>
            <TargetWeeklyProduction
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              chargeType={appliedFilters.chargeType}
              year={appliedFilters.year}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.dailyCpu}>
          <ReportPageItemContainer fullHeight>
            <TargetDailyCpu
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              chargeType={appliedFilters.chargeType}
              week={appliedFilters.week}
              date={appliedFilters.date}
              year={appliedFilters.year}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.dailyProduction}>
          <ReportPageItemContainer fullHeight>
            <TargetDailyProduction
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              chargeType={appliedFilters.chargeType}
              week={appliedFilters.week}
              date={appliedFilters.date}
              year={appliedFilters.year}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.top5Cpu}>
          <ReportPageItemContainer fullHeight>
            <ChartsContainer>
              <Top5CpuChart
                year={appliedFilters.year}
                month={appliedFilters.month}
              />
            </ChartsContainer>
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <TargetTop5Cpu
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              chargeType={appliedFilters.chargeType}
              year={appliedFilters.year}
              month={appliedFilters.month}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.pulseSummary}>
          <ReportPageItemContainer fullHeight>
            <TargetPulseSummary siteId={appliedFilters?.siteId} />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.targetVirtualLocationsInventory}>
          <ReportPageItemContainer fullHeight>
            <TargetVirtualLocationsInventory
              siteId={appliedFilters.siteId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
