import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  IEnhancedTableProps,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import get from 'lodash/get';
import { deleteLogtimeRequest } from 'src/modules/actions';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { AnyObject, ILogtimeModel, IdsArray } from 'src/modules/types';
import {
  useCreateExportProps,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFetchStaffingProvidersCombobox,
  ExportFunction,
  useSupervisorCombobox,
  useLogtimeSupervisorDefaultFilters,
  usePunchTypesOptions,
} from 'src/modules/utils/hooks';
import { isEmpty, map, omit } from 'lodash';
import { ModelsToDelete, SCOPES, useHasUserAccessToResource } from 'src/config';
import { CloseActionMenuItemType } from 'src/components/EnhancedTable/EnhancedTableToolbarMenu';
import { MenuItemProps } from '@mui/material';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { ComboBoxOption } from 'src/components/ComboBox';
import { useTranslation } from 'react-i18next';
import {
  parse,
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
} from 'src/modules/utils/dateWrapper';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { getUsersComboboxList } from 'src/modules/selectors/user';
import { IEntityBySiteComboboxOption } from 'src/components/Formik/comboboxes-with-entities/EntitiesBySyteCombobox';
import { useDefaultHiddenHeadCells } from 'src/modules/utils/hooks/table';
import { TimePunchesInsertPunchForm } from '../TimePunchesForms/TimePunchesInsertPunchForm';
import { TimePunchesUpdatePunchForm } from '../TimePunchesForms/TimePunchesUpdatePunchForm';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { PeopleArrows, UserClock } from 'src/components/svgIcons';

interface TimePunchesTableProps {
  exportFunction: ExportFunction;
  tableSelector: any;
  countSelector: any;
  listAction: any;
  countAction: any;
  tableName: string;
  headCells?: HeadCell[];
  isTableDataLoadingSelector: any;
  handleComputedList?: (item: AnyObject) => AnyObject;
  hiddenColumns?: string[];
  customAction?: IEnhancedTableProps<any>['customAction'];
  customActions?: IEnhancedTableProps<any>['customActions'];
  renderActions?: (
    ids: IdsArray,
    closeCallback: CloseActionMenuItemType,
  ) => React.ReactElement<MenuItemProps>[];
}

export const TimePunchesTable: React.FC<TimePunchesTableProps> = ({
  exportFunction,
  tableSelector,
  countSelector,
  listAction,
  countAction,
  tableName,
  headCells,
  handleComputedList,
  hiddenColumns,
  isTableDataLoadingSelector,
  ...rest
}) => {
  const { t } = useTranslation();

  const [selectedPunchesIds, setSelectedPunchesIds] = React.useState<IdsArray>(
    [],
  );

  const [
    isInsertPunchFormVisible,
    setIsInsertPunchFormVisible,
  ] = React.useState(false);
  const [
    isUpdatePunchFormVisible,
    setIsUpdatePunchFormVisible,
  ] = React.useState(false);

  const hasUserAccessToCreateTimePunch = useHasUserAccessToResource(
    apiEndpoints.LOGTIMES_BULK,
    SCOPES.CREATE,
  );
  const hasUserAccessToUpdateTimePunch = useHasUserAccessToResource(
    apiEndpoints.LOGTIMES,
    SCOPES.UPDATE,
  );
  const hasUserAccessToDeleteTimePunch = useHasUserAccessToResource(
    apiEndpoints.LOGTIMES,
    SCOPES.DELETE,
  );

  const getDefaultFilterBySupervisor = useLogtimeSupervisorDefaultFilters();
  const initialFilter = getDefaultFilterBySupervisor();

  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();

  // make request to fetch employees from the server if we don't have them in the store
  useEffect(() => {
    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    fetchStaffingProvidersCombobox();
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const punchTypesOptions = usePunchTypesOptions();

  const isTableDataLoading = useSelector(isTableDataLoadingSelector) as boolean;
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // fetch shifts list from store
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  // fetch staffing list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  const supervisors: IEntityBySiteComboboxOption[] = useSelector(
    getUsersComboboxList,
    shallowEqual,
  );
  const exportProps = useCreateExportProps(exportFunction);

  // fetch logtime list
  const list = useSelector(tableSelector, shallowEqual) as AnyObject[];

  const getPunchTypeName = React.useCallback(
    (punchType: ILogtimeModel['punchType']) => {
      switch (punchType) {
        case 'holiday':
          return t('logtimes.holiday');
        case 'pto':
          return t('logtimes.pto');
        case 'sick':
          return t('logtimes.sick');
        case 'retroPay':
          return t('logtimes.retro_pay');
        default:
          return t('logtimes.regular');
      }
    },
    [t],
  );

  // computed list with icons
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        lunchAdj: get(item.shift, 'lunchAdj'),
        timeIn: item.timeIn
          ? composeDate(
              item.timeIn,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        timeOut: item.timeOut
          ? composeDate(
              item.timeOut,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        paidTimeIn: item.paidTimeIn
          ? composeDate(
              item.paidTimeIn,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        timeTotal: item.timeTotal ? item.timeTotal.split('.')[0] : '',
        punchType: getPunchTypeName(item.punchType),
        updatedAt: item.updatedAt
          ? composeDate(
              item.updatedAt,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        editUser: item.editUser?.id
          ? `${item.editUser.firstName} ${item.editUser.lastName} (${item.editUser.email})`
          : '',
        clockName: isEmpty(item.clockName) ? (
          <PeopleArrows width="24" height="24" />
        ) : (
          <UserClock width="24" height="24" />
        ),
        ...(handleComputedList ? handleComputedList(item) : {}),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPunchTypeName, list],
  );

  const count = useSelector(countSelector, shallowEqual) as number;

  // create dispatcher
  const dispatcher = useDispatch();

  // compose table header cells
  const baseHeadCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('logtimes.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('logtimes.emp_last_name'),
    },
    {
      id: 'employee.supervisor.firstName',
      disablePadding: false,
      label: t('logtimes.supervisor_firstName'),
    },
    {
      id: 'employee.supervisor.lastName',
      disablePadding: false,
      label: t('logtimes.supervisor_lastName'),
    },
    {
      id: 'employee.site.name',
      disablePadding: false,
      label: t('logtimes.site'),
    },
    { id: 'badge', disablePadding: false, label: t('logtimes.badge') },
    {
      id: 'timeIn',
      disablePadding: false,
      label: t('logtimes.login_datetime'),
    },
    {
      id: 'paidTimeIn',
      disablePadding: false,
      label: t('logtimes.paid_time_in'),
    },
    {
      id: 'timeOut',
      disablePadding: false,
      label: t('logtimes.logout_datetime'),
    },
    {
      id: 'shift.lunchAdj',
      disablePadding: false,
      label: t('logtimes.lunchAdj'),
    },
    { id: 'timeTotal', disablePadding: false, label: t('logtimes.total_time') },
    {
      id: 'approved',
      disablePadding: false,
      label: t('logtimes.approved'),
    },
    {
      id: 'department.name',
      disablePadding: false,
      label: t('logtimes.department'),
    },
    { id: 'shift.name', disablePadding: false, label: t('logtimes.shift') },
    {
      id: 'staffing.staffingProvider',
      disablePadding: false,
      label: t('logtimes.staffing_provider'),
    },
    {
      id: 'punchType',
      disablePadding: false,
      label: t('logtimes.punch_type'),
    },
    {
      id: 'clockName',
      disablePadding: false,
      label: t('logtimes.clockName'),
    },
    {
      id: 'comment',
      disablePadding: false,
      label: t('logtimes.comment'),
    },
    {
      id: 'updatedAt',
      disablePadding: false,
      label: t('logtimes.updatedAt'),
    },
    {
      id: 'editUser',
      disablePadding: false,
      disableSorting: true,
      label: t('logtimes.editUser'),
    },
    {
      id: 'isArchived',
      disablePadding: false,
      disableSorting: true,
      label: t('common.archived'),
    },
    ...(headCells ?? []),
  ];

  // Hide some columns by default
  useDefaultHiddenHeadCells({
    headCells: baseHeadCells,
    excludeHeadCellsList: hiddenColumns ?? [],
    tableName,
  });

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'employee.site.id',
      label: t('logtimes.filter.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'employeeId',
      label: t('logtimes.filter.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
      propertyAsID: 'id',
    },
    {
      name: 'badge',
      label: t('logtimes.filter.badge'),
      operator: 'like',
    },
    {
      name: 'employee.supervisor.id',
      label: t('applicant.supervisorId'),
      operator: 'eq',
      type: 'comboboxEntitiesBySite',
      options: supervisors,
    },
    {
      name: 'timeIn',
      label: t('logtimes.filter.timeIn'),
      operator: 'gte',
      type: 'datetime',
    },
    {
      name: 'timeOut',
      label: t('logtimes.filter.timeOut'),
      operator: 'lte',
      type: 'datetime',
    },
    {
      name: 'department.id',
      label: t('logtimes.filter.department'),
      operator: 'eq',
      type: 'combobox',
      options: departments as ComboBoxOption[],
    },
    {
      name: 'shift.id',
      label: t('logtimes.filter.shift'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'staffing.id',
      label: t('logtimes.filter.staffingProvider'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
    {
      name: 'punchType',
      label: t('logtimes.punch_type'),
      operator: 'eq',
      type: 'combobox',
      options: punchTypesOptions as ComboBoxOption[],
    },
    {
      name: 'isArchived',
      label: t('common.archived'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.archived') },
          { value: false, label: t('common.unarchived') },
        ],
      },
    },
  ];

  // make request to fetch logtimes when component is mounted
  useEffect(() => {
    const df = getDefaultFilterBySupervisor();
    dispatcher(listAction({ filter: df }));
    // get total count
    dispatcher(countAction({ filter: omit(df, ['limit', 'offset']) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      listAction({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(countAction({ filter: { where, include: newInclude } }));
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteLogtimeRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedPunchesIds(ids);
    setIsUpdatePunchFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        ignoreGlobalSitesOnExport
        data={computedList}
        count={count}
        selectIndex="id"
        tableName={tableName}
        headCells={baseHeadCells}
        filters={filters}
        isTableDataLoading={isTableDataLoading}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteTimePunch ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateTimePunch ? handleUpdating : undefined}
        include={initialFilter?.include}
        exportProps={exportProps}
        deleteModelName={ModelsToDelete.Logtime}
        createEntityBtnProps={
          hasUserAccessToCreateTimePunch
            ? {
                title: t('time_keeping.insert_punch'),
                onClick: () => setIsInsertPunchFormVisible((prev) => !prev),
              }
            : undefined
        }
        {...rest}
      />

      <TimePunchesInsertPunchForm
        isOpen={isInsertPunchFormVisible}
        onClose={() => setIsInsertPunchFormVisible(false)}
        filterCount={omit(getDefaultFilterBySupervisor(), ['limit', 'offset'])}
        filterList={getDefaultFilterBySupervisor()}
      />

      <TimePunchesUpdatePunchForm
        punchesIds={selectedPunchesIds}
        isOpen={isUpdatePunchFormVisible}
        onClose={() => setIsUpdatePunchFormVisible(false)}
        filterCount={omit(getDefaultFilterBySupervisor(), ['limit', 'offset'])}
        filterList={getDefaultFilterBySupervisor()}
      />
    </>
  );
};
