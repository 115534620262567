import { SagaIterator } from 'redux-saga';

import { call, put, takeEvery } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICreateStandardRequest,
  IFilter,
  ISagaAction,
  IStandardDeleteRequest,
  IStandardModel,
  IStandardWithMeta,
  ISagaActionBind,
  PayloadWithNavigateFunc,
  PayloadWithFilters,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';

/**
 * Create a new standard
 */
export const createStandardRequestSaga = function* ({
  payload: { data },
}: ISagaAction<PayloadWithFilters<ICreateStandardRequest>>): SagaIterator {
  try {
    const response: IStandardModel = yield call(Api.Standard.create, data);

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
    yield put(actions.createStandardSuccess(response));
  } catch (e) {
    yield put(actions.createStandardFailed());
  }
};

/**
 * Get standard list
 */
export const getStandardListRequestSaga = function* (
  action: ISagaAction<{
    siteId: number;
    filter: IFilter;
  }>,
): SagaIterator {
  try {
    const response: IStandardWithMeta = yield call(
      Api.Standard.list,
      action.payload.siteId,
      action.payload.filter,
    );
    yield put(
      actions.getStandardListSuccess({
        data: response,
        siteId: action.payload.siteId,
      }),
    );
  } catch (e) {
    yield put(actions.getStandardListFailed());
  }
};

/**
 * Delete standards
 */
export const deleteStandardRequestSaga = function* (
  action: ISagaAction<IStandardDeleteRequest>,
): SagaIterator {
  try {
    const { ids } = action.payload;
    yield call(Api.Standard.delete, {
      where: { id: { inq: ids } },
    });
    yield put(actions.flushStandardState());
  } catch (e) {
    yield put(actions.deleteStandardFailed());
  }
};

/**
 * Bulk update standards
 */
export const updateStandardsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IStandardModel[]>>): SagaIterator {
  try {
    yield call(Api.Standard.bulkUpdate, data);
    yield put(actions.flushStandardState());
    yield call(navigate, { pathname: '/standards' });
  } catch (e) {
    yield put(actions.updateStandardsFailed());
  }
};

/**
 * Get combobox list
 */
export const getStandardComboboxListRequestSaga = function* (): SagaIterator {
  // try {
  //   const response: Partial<IStandardModel>[] = yield call(
  //     Api.Standard.list,
  //     action.payload,
  //   );
  //   yield put(actions.getStandardComboboxListSuccess(response));
  // } catch (e) {
  //   yield put(actions.getStandardComboboxListFailed());
  // yield put(
  //   actions.createStandardNotification({
  //     severity: 'error',
  //     content: i18n.et('standard', (e as any).message),
  //   }),
  // );
  // }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportStandardRequest,
    saga: createExportSaga({
      apiCall: Api.Standard.export,
      actionSuccess: actions.exportStandardSuccess,
      actionFailed: actions.exportStandardFailed,
    }),
  },
  { action: actions.createStandardRequest, saga: createStandardRequestSaga },
  {
    bindFunc: takeEvery,
    action: actions.getStandardListRequest,
    saga: getStandardListRequestSaga,
  },
  { action: actions.deleteStandardRequest, saga: deleteStandardRequestSaga },
  // bulk updating standards
  { action: actions.updateStandardsRequest, saga: updateStandardsRequestSaga },
  {
    action: actions.getStandardComboboxListRequest,
    saga: getStandardComboboxListRequestSaga,
  },
];
