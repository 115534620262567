import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TableContextProvider } from 'src/components/Table/TableContext';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { CostByDepartment } from 'src/pages/Payboard/Costs/panels/CostByDepartment';
import { CostByDay } from 'src/pages/Payboard/Costs/panels/CostByDay';
import { CostByDayAndDepartment } from 'src/pages/Payboard/Costs/panels/CostByDayAndDepartment';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { useCreateReportFiltersConfigWithDefaultOptions } from 'src/modules/utils/hooks/reportPage.hooks';
import {
  CostsFilterPanel,
  ICostsFilterPanelFilters,
} from 'src/pages/Payboard/Costs/components/CostsFilterPanel';
import { ReportPage } from 'src/components/ReportPage';
import { TableTitle, TableToolbar } from 'src/components/Table';

const defaultFilters: ICostsFilterPanelFilters = {
  siteId: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  yearWeekRange: undefined,
};

export const Costs: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<number>(0);

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof ICostsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('payboard.site') },
      { id: 'dateFrom', label: t('payboard.date_from') },
      { id: 'dateTo', label: t('payboard.date_to') },
      { id: 'yearWeekRange', label: t('payboard.year_week') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    ICostsFilterPanelFilters
  >(defaultFilters);

  const hasUserAccessToCostByDepartmentTab = useHasUserAccessToViewTab(
    tabsPaths.COSTS_BY_DEPARTMENT,
  );

  const hasUserAccessToCostByDayTab = useHasUserAccessToViewTab(
    tabsPaths.COSTS_BY_DAY,
  );

  const hasUserAccessToCostByDayAndDepartmentTab = useHasUserAccessToViewTab(
    tabsPaths.COSTS_BY_DAY_AND_DEPARTMENT,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.payboard_costs.costs_by_department'),
          hasUserAccess: hasUserAccessToCostByDepartmentTab,
          component: (
            <CostByDepartment
              yearWeekRange={appliedFilters.yearWeekRange}
              siteId={appliedFilters.siteId}
            />
          ),
        },
        {
          title: t('main_menu.payboard_costs.costs_by_day'),
          hasUserAccess: hasUserAccessToCostByDayTab,
          component: (
            <CostByDay
              siteId={appliedFilters.siteId}
              dateFrom={appliedFilters.dateFrom}
              dateTo={appliedFilters.dateTo}
            />
          ),
        },
        {
          title: t('main_menu.payboard_costs.costs_by_day_and_department'),
          hasUserAccess: hasUserAccessToCostByDayAndDepartmentTab,
          component: (
            <CostByDayAndDepartment
              siteId={appliedFilters.siteId}
              dateFrom={appliedFilters.dateFrom}
              dateTo={appliedFilters.dateTo}
            />
          ),
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      appliedFilters.siteId,
      appliedFilters.yearWeekRange,
      appliedFilters.dateFrom,
      appliedFilters.dateTo,
      hasUserAccessToCostByDepartmentTab,
      hasUserAccessToCostByDayTab,
      hasUserAccessToCostByDayAndDepartmentTab,
      t,
    ],
  );

  const tabTitle = pointsTabsConfig[activeTab].title;

  return (
    <TableContextProvider>
      <TabsNgroup
        fullHeight={false}
        onTabChange={(value) => {
          if (value !== null) {
            setActiveTab(value);
          }
        }}
        config={pointsTabsConfig}
        toolbarComponent={
          <ReportPage hideNoData fullHeight={false}>
            <ReportPageItemContainer fullHeight={false}>
              <TableToolbar sx={{ pb: 0 }}>
                <TableTitle>{tabTitle}</TableTitle>
              </TableToolbar>
            </ReportPageItemContainer>
            <ReportPageItemContainer fullHeight={false}>
              <CostsFilterPanel
                hideSyncBtn
                filtersOptionsConfig={filtersOptionsConfig}
                onApplyFilters={(filtersToApply) => {
                  setAppliedFilters(filtersToApply);
                }}
                onRemoveAllFilters={() => setAppliedFilters(defaultFilters)}
              />
            </ReportPageItemContainer>
          </ReportPage>
        }
      />
    </TableContextProvider>
  );
};
