import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/joy';
import { usePickersSlots } from 'src/components/_ui-kit/Combobox/Pickers/pickers.hooks';
import {
  tryToGenerateDateFromAnything,
  format,
  composeDate,
  DATE_FORMAT,
} from 'src/modules/utils/dateWrapper';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { IDatePickerProps } from 'src/components/_ui-kit/Combobox/Pickers/Datepicker';
import { useFilterPanelPickersSx } from '../components/filterPanel.hooks';
import { Pencil02Svg } from 'src/components/svgIcons';

interface IFilterPanelDatePicker
  extends Omit<IDatePickerProps, 'id' | 'label' | 'size'> {
  filterId: string;
  label?: string;
  onRemoveFilter?: (filterId: string) => void;
}

export const FilterPanelDatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  parseDate,
  filterId,
  onRemoveFilter,
  label,
  ...rest
}: IFilterPanelDatePicker) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const {
    setEditingFiltersIds,
    elementsHeight,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
  } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  const onClick = () => {
    if (!isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);
  const sx = useFilterPanelPickersSx(elementsHeight);
  const slots = usePickersSlots();

  // Update selectedDate after props.value has changed
  useEffect(() => {
    if (
      value !==
      (selectedDate
        ? composeDate(selectedDate, format(DATE_FORMAT))
        : selectedDate)
    ) {
      const dateObj = parseDate
        ? parseDate(value)
        : tryToGenerateDateFromAnything(value);

      setSelectedDate(dateObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDateChange = (value: Date | string | null | undefined) => {
    const dateObj = tryToGenerateDateFromAnything(value);
    let formattedDate = null;

    if (dateObj) {
      formattedDate = composeDate(dateObj, format(DATE_FORMAT));
      setSelectedDate(dateObj);
    }

    onChange(formattedDate);
  };

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center' }}>
        {label && (
          <Typography
            level="text_xs"
            sx={{ pl: 1.5, color: 'var(--ngroup-palette-text-icon)' }}
          >
            {label}
          </Typography>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...rest}
            sx={{
              ...sx,
              '& input': { width: 70 },
              '& .MuiInputBase-root': { pr: 0 },
            }}
            slots={slots}
            minDate={minDate}
            maxDate={maxDate}
            value={selectedDate}
            format={DATE_FORMAT}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        <Box sx={{ display: 'flex', mr: 1 }}>
          <Pencil02Svg />
        </Box>
      </Box>
    </FilterPanelFilterContainer>
  );
};
