import React from 'react';
import {
  DepartmentComboboxOption,
  IDepartmentModel,
  IdsArray,
  IStoreState,
  IUpdateDepartment,
} from '../types';
import { createSelector } from 'reselect';
import { map, reduce, omit, memoize, flatten, get } from 'lodash';
import { filterListBySiteId } from '../utils/helpers/common';
import { getGloballySelectedSites } from './site';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getDepartmentRefreshKey = (state: IStoreState) =>
  state.department.refreshKey;

export const getDepartmentData = (state: IStoreState) => state.department;

/**
 * Get server error
 * @param department - State department
 */
export const getServerError = ({ department }: IStoreState) => department.error;

/**
 * Get department raw list
 * @param department - State department
 */
export const getDepartmentRawList = ({ department }: IStoreState) =>
  department.list;

/**
 * Get department list
 */
export const getDepartmentList = createSelector(
  getDepartmentRawList,
  (departments) =>
    map(departments, (department) => ({
      ...department,
      active: !!department.active,
      indirect: !!department.indirect,
      archived: !!department.archived,
      includeToUph: !!department.includeToUph,
    })),
);

export const getDepartmentsListForTable = createSelector(
  getDepartmentList,
  (list) =>
    list.map((item) => {
      const incentivePrice = get(item, [
        'incentiveSettings',
        'price',
        item.incentive || '',
      ]);

      return {
        ...item,
        active: item.active ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        archived: item.archived ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        includeToUph: item.includeToUph ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        includeToCostPlus: item.includeToCostPlus ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        incentivePrice: incentivePrice
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(incentivePrice)
          : '',
        costPlus: item.costPlus ? `${item.costPlus}%` : '',
        staticCostPlus: item.staticCostPlus ? `$${item.staticCostPlus}` : '',
        desiredEmployeesNumber: item.desiredEmployeesNumber
          ? item.desiredEmployeesNumber
          : '',
      };
    }),
);

/**
 * Get departments by array of ids
 * @param department - State department
 */
export const getDepartmentsByIds = createSelector(
  getDepartmentList,
  (departments) => (ids: IdsArray) => {
    const initial: IUpdateDepartment[] = [];
    return reduce(
      departments,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`) || ids.includes(cur.id)) {
          acc.push(
            omit(cur, ['parent', 'logtimes', 'employees', 'issues', 'site']),
          );
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get department count
 * @param department - State department
 */
export const getDepartmentCount = ({ department }: IStoreState) =>
  department.count;

/**
 * Get combobox list
 */
export const getDepartmentsList = ({ department }: IStoreState) =>
  department.comboboxList;

/**
 * Prepare combobox list
 */
export const getDepartmentsComboboxList = createSelector(
  getDepartmentsList,
  (departments): DepartmentComboboxOption[] => {
    return departments.map((department) => ({
      id: department.id as number,
      label: `${department.name} (${department.site?.name})` as string,
      name: department.name as string,
      siteId: department.siteId as number,
    }));
  },
);

export const getDepartmentsComboboxListBySites = createSelector(
  getDepartmentsComboboxList,
  (departments) =>
    memoize((sites: number[]) =>
      flatten(map(sites, (site) => filterListBySiteId(departments, site))),
    ),
);

/**
 * Get departments related to passed siteId
 * Or nothing if siteId is NaN
 * @param siteId
 * @return function that can be used as selector
 */
export const getDepartmentsComboboxListBySiteId = createSelector(
  getDepartmentsComboboxList,
  (departments) =>
    memoize((siteId: number): DepartmentComboboxOption[] =>
      filterListBySiteId(departments, siteId),
    ),
);

export const getDepartmentOptionsByGloballySites = createSelector(
  getDepartmentsComboboxList,
  getGloballySelectedSites,
  (departments, selectedSites) => {
    const departmentOptions = [];

    // fill departments combobox list by globally selected sites
    for (const department of departments) {
      if (selectedSites.includes(department.siteId)) {
        departmentOptions.push({
          id: department.id,
          name: department.name,
          label: department.label,
          siteId: department.siteId,
        });
      }
    }

    return departmentOptions;
  },
);

export const getDepartmentOptionsByGloballySitesWithPropAsId = createSelector(
  getDepartmentsComboboxList,
  getGloballySelectedSites,
  (departments, selectedSites) => (
    propertyAsId: keyof IDepartmentModel = 'id',
  ) => {
    const departmentOptions = [];

    // fill departments combobox list by globally selected sites
    for (const department of departments) {
      if (selectedSites.includes(department.siteId)) {
        departmentOptions.push({
          id: (department as any)[propertyAsId],
          name: department.label,
          siteId: department.siteId,
        });
      }
    }

    return departmentOptions;
  },
);

export const getIsDepartmentDataLoading = createSelector(
  getDepartmentData,
  (data) =>
    data.isDepartmentsCountInProgress ||
    data.isDepartmentsListInProgress ||
    data.isManageDepartmentOperationInProgress,
);
