import React from 'react';
import * as Yup from 'yup';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { addProcessStatus } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { IIpWhitelistModel } from 'src/modules/types/ip_whitelist';
import ResourcesWithActionsComboBox from 'src/components/Formik/comboboxes-with-entities/ResourcesWithActionsComboBox';
import { METHODS_TO_SCOPES, SCOPES_TO_METHODS } from 'src/config';

const formikFieldToOmit = 'toOmit';

interface IUpdateIpWhitelistItemsProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestStart: () => void;
  onRequestEnd: () => void;
  data: Array<IIpWhitelistModel>;
}

export const UpdateIpWhitelistItems = ({
  isOpen,
  onClose,
  onRequestEnd,
  onRequestStart,
  data,
}: IUpdateIpWhitelistItemsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const dataForForm = React.useMemo(() => {
    return data.map((item) => ({
      ...item,
      method: (METHODS_TO_SCOPES as any)[item.method],
      [formikFieldToOmit]: `${item.resource}_${
        (METHODS_TO_SCOPES as any)[item.method]
      }`,
    }));
  }, [data]);

  const ipWhiteListValidationSchema = Yup.array().of(
    Yup.object().shape({
      IPv4: Yup.string()
        .required(t('error.validation.required'))
        .matches(
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
          t('ip_whitelist.validation.valid_ipv4'),
        ),

      IPv6: Yup.string()
        .required(t('error.validation.required'))
        .matches(
          /^([a-f0-9:]+:+)+[a-f0-9]+$/,
          t('ip_whitelist.validation.valid_ipv6'),
        ),

      [formikFieldToOmit]: Yup.string().required(
        t('error.validation.required'),
      ),
    }),
  );

  const formik = useFormik({
    initialValues: dataForForm,
    validationSchema: ipWhiteListValidationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      onRequestStart();

      const dataToSend = data.map((item) => ({
        id: item.id,
        IPv4: item.IPv4,
        IPv6: item.IPv6,
        resource: item.resource,
        method: SCOPES_TO_METHODS[item.method],
      }));

      try {
        await Api.IpWhitelist.bulkUpdate({ items: dataToSend });
        onClose();
        onRequestEnd();
        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch {
        onClose();
        onRequestEnd();
      }

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('ip_whitelist.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.map((item, index) => (
              <DrawerBodySectionContent key={item.id}>
                <DrawerBodySectionTitle>{`${item.id}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.IPv4`}
                    name={`${index}.IPv4`}
                    label="IPv4"
                    formik={formik}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.IPv6`}
                    name={`${index}.IPv6`}
                    label="IPv6"
                    formik={formik}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ResourcesWithActionsComboBox
                    id={`${index}.${formikFieldToOmit}`}
                    formik={formik}
                    action={`${index}.method`}
                    resource={`${index}.resource`}
                    label={t('ip_whitelist.resource')}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
