import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { useSiteRelationInclusion } from '.';
import { defaultListOrder } from '../helpers/filter';
import { getQuestionListRequest } from 'src/modules/actions';
import { getQuestionsByIds } from 'src/modules/selectors/question';

export const useQuestionInclusions = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'questionCategory',
        scope: {
          include: [siteRelationInclusion],
        },
      },
    ],
    [siteRelationInclusion],
  );
};

export const useQuestionDefaultFilters = () => {
  const include = useQuestionInclusions();

  return React.useMemo(
    () => ({
      order: defaultListOrder,
      include,
    }),
    [include],
  );
};

export const useFetchQuestionsByIds = (ids: IdsArray) => {
  const questionInclusion = useQuestionInclusions();
  const dispatcher = useDispatch();
  const questions = useSelector(
    (state) => getQuestionsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(questions)) {
      dispatcher(
        getQuestionListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: questionInclusion,
          },
        }),
      );
    }
  };
};

export const useFetchQuestionsByCategoryIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();

  React.useEffect(() => {
    dispatcher(
      getQuestionListRequest({
        filter: {
          include: [
            {
              relation: 'questionCategory',
              scope: {
                where: {
                  id: {
                    inq: ids,
                  },
                },
              },
            },
          ],
        },
      }),
    );
  }, [dispatcher, ids]);
};
