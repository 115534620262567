import { useSelector } from 'react-redux';

import { getUserPolicies, getUserRoles } from 'src/modules/selectors/user';
import { paths, tabsPaths } from './paths';
import { roles } from './roles';
import {
  getManageEntitiesConfigData,
  getPageSettingPolicies,
} from 'src/modules/selectors/page-setting.selector';
import { PolicyConfigItem } from 'src/modules/types';

export const SCOPES = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  EXPORT: 'export',
} as const;

export const METHODS_TO_SCOPES = {
  get: SCOPES.VIEW,
  post: SCOPES.CREATE,
  patch: SCOPES.UPDATE,
  delete: SCOPES.DELETE,
} as const;

export const SCOPES_TO_METHODS = Object.fromEntries(
  Object.entries(METHODS_TO_SCOPES).map(([method, scope]) => [scope, method]),
);

export const useHasUserAccessToResource = (
  resourceUrl: typeof paths[keyof typeof paths],
  action: typeof SCOPES[keyof typeof SCOPES],
) => {
  const userRoles = useSelector(getUserRoles);
  const userPolicies = useSelector(getUserPolicies);

  const isSuperAdmin = userRoles && userRoles.includes(roles.SUPER_ADMIN);
  const hasRequiredResourceAndAction = userPolicies?.some(
    (policy) => policy.resource === resourceUrl && policy.action === action,
  );

  if (isSuperAdmin || hasRequiredResourceAndAction) {
    return true;
  }

  return false;
};

export const useHasUserAccessToResources = (
  data: Array<{
    resourceUrl: typeof paths[keyof typeof paths];
    action: typeof SCOPES[keyof typeof SCOPES];
  }>,
) => {
  const userRoles = useSelector(getUserRoles);
  const userPolicies = useSelector(getUserPolicies);

  const isSuperAdmin = userRoles && userRoles.includes(roles.SUPER_ADMIN);
  const hasRequiredResourcesAndActions = data.every(({ resourceUrl, action }) =>
    userPolicies?.some(
      (policy) => policy.resource === resourceUrl && policy.action === action,
    ),
  );

  if (isSuperAdmin || hasRequiredResourcesAndActions) {
    return true;
  }

  return false;
};

export const useHasUserAccessToViewTab = (
  path: typeof tabsPaths[keyof typeof tabsPaths],
) => {
  const userRoles = useSelector(getUserRoles) ?? [];

  const pagesRequiredPolicesConfig = useSelector(getPageSettingPolicies);

  const pathRequiredPolicies = pagesRequiredPolicesConfig[path];

  const userPolicies = useSelector(getUserPolicies) ?? [];

  const isSuperAdmin = userRoles.includes(roles.SUPER_ADMIN);

  if (isSuperAdmin) {
    return true;
  }

  // If we don't have polices for tab it is restricted
  if (!pathRequiredPolicies) {
    return false;
  }
  const hasRequiredResourcesAndActions = pathRequiredPolicies.every(
    ({ resource, action }) =>
      userPolicies.some(
        (policy) => policy.resource === resource && policy.action === action,
      ),
  );

  if (hasRequiredResourcesAndActions) {
    return true;
  }

  return false;
};

export const useHasUserAccessToAction = (action: string) => {
  const userRoles = useSelector(getUserRoles);
  const userPolicies = useSelector(getUserPolicies);
  const manageEntitiesConfig = useSelector(getManageEntitiesConfigData);

  const isSuperAdmin = userRoles && userRoles.includes(roles.SUPER_ADMIN);
  if (isSuperAdmin) {
    return true;
  }

  const actionRequiredPolicies: PolicyConfigItem[] = (manageEntitiesConfig as any)[
    action
  ];
  if (!actionRequiredPolicies?.length) {
    // Everything that has not polices is restricted
    return false;
  }

  const hasRequiredResourcesAndActions = actionRequiredPolicies.every(
    ({ resource, action }) =>
      userPolicies?.some(
        (policy) => policy.resource === resource && policy.action === action,
      ),
  );
  if (hasRequiredResourcesAndActions) {
    return true;
  }

  return false;
};

/**
 * Array of path to skip authorization checking
 */
export const skipAuthorization = [paths.LANDING, paths.PROFILE];
