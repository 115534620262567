import { filter, isEmpty, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  // ISkillForm,
  IStoreState,
  IUpdateSkill,
  SkillComboboxOption,
} from '../types';

export const getSkillRefreshKey = (state: IStoreState) =>
  state.skill.refreshKey;

export const getSkillData = (state: IStoreState) => state.skill;

/**
 * Get server error
 * @param skill - State skill
 */
export const getServerError = ({ skill }: IStoreState) => skill.error;

/**
 * Get skill list
 * @param skill - State skill
 */
export const getSkillList = ({ skill }: IStoreState) => skill.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param skill - State skill
 */
export const getSkillTable = createSelector(getSkillList, (skill) =>
  map(filter(skill, (s) => !isEmpty(s.site))),
);

export const getSkillTablePreparedData = createSelector(getSkillTable, (data) =>
  data.map((item) => ({
    ...item,
    expirationPeriod: item.expirationPeriod ?? (item.isPermanent ? 'N/A' : ''),
  })),
);

/**
 * Get skills by array of ids
 * @param skill - State skill
 */
export const getSkillsByIds = createSelector(
  getSkillList,
  (skills) => (ids: IdsArray) => {
    const initial: IUpdateSkill[] = [];
    return reduce(
      skills,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push(omit(cur, ['site']));
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get skill count
 * @param skill - State skill
 */
export const getSkillCount = ({ skill }: IStoreState) => skill.count;

/**
 * Get combobox list
 */
export const getSkillsComboboxList = ({ skill }: IStoreState) =>
  map(skill.comboboxList, (skillItem) => ({
    id: skillItem.id,
    name: `${skillItem.name} (${skillItem.site?.name})`,
    siteId: skillItem.siteId,
  })) as SkillComboboxOption[];

export const getSkillsComboboxListWithNameAsId = ({ skill }: IStoreState) =>
  map(skill.comboboxList, (skillItem) => ({
    id: skillItem['name'] as any,
    name: skillItem.name,
    siteId: skillItem.siteId,
  })) as SkillComboboxOption[];

export const getIsSkillDataLoading = createSelector(getSkillData, (data) => {
  return (
    data.isSkillDataLoading ||
    data.isSkillDataCountLoading ||
    data.isSkillDataManaging
  );
});
