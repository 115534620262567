import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateStaffingProviderRequest,
  IdsArray,
  IFilter,
  IStaffingProviderModel,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushStaffingProviderState = createAction(
  'Flush staffingProvider data in store',
);

// Creation actions
export const createStaffingProviderRequest = createAction<
  PayloadWithFilters<ICreateStaffingProviderRequest>
>('Make request to create a new staffingProvider');
export const createStaffingProviderSuccess = createAction<
  IStaffingProviderModel
>('New staffingProvider has been created successfully');
export const createStaffingProviderFailed = createAction(
  'Cannot create a new staffingProvider',
);

// Fetch staffingProviders actions
export const getStaffingProviderListRequest = createAction<IFilter>(
  'Make request to fetch staffingProvider list',
);
export const getStaffingProviderListSuccess = createAction<
  IStaffingProviderModel[]
>('StaffingProviders list has been fetched successfully');
export const getStaffingProviderListFailed = createAction(
  'Cannot fetch staffingProvider list',
);

// get staffingProviders count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getStaffingProviderCountRequest = createAction<IFilter>(
  'Make request to get staffingProvider count',
);
export const getStaffingProviderCountSuccess = createAction<ICountResponse>(
  'StaffingProviders count has been fetched successfully',
);
export const getStaffingProviderCountFailed = createAction(
  'Cannot get staffingProvider count',
);

// delete actions
export const deleteStaffingProviderRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete staffingProviders');
export const deleteStaffingProviderSuccess = createAction(
  'StaffingProviders deleted successfully',
);
export const deleteStaffingProviderFailed = createAction(
  'Cannot delete staffingProviders',
);

// Updating actions
export const updateStaffingProvidersRequest = createAction<
  PayloadWithFilters<IStaffingProviderModel[]>
>('Make request to update staffingProviders');
export const updateStaffingProvidersSuccess = createAction(
  'StaffingProviders updated successfully',
);
export const updateStaffingProvidersFailed = createAction(
  'Cannot update staffingProviders',
);

// Fetch combobox list actions
export const getStaffingProviderComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of staffingProviders',
);
export const getStaffingProviderComboboxListSuccess = createAction<
  Partial<IStaffingProviderModel>[]
>('Combobox list of staffingProviders has been fetched successfully');
export const getStaffingProviderComboboxListFailed = createAction(
  'Cannot fetch combobox staffingProviders list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('StaffingProvider').takeExportActions();

export const exportStaffingProviderRequest = exportReportRequest;
export const exportStaffingProviderSuccess = exportReportSuccess;
export const exportStaffingProviderFailed = exportReportFailed;
