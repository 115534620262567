import React from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import { Checkbox, ICheckboxProps } from '../_ui-kit/Checkbox';

interface IProps extends ICheckboxProps {
  id: string;
  formik: AnyObject;
  disabled?: boolean;
  errorMode?: ErrorMode;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function FormikCheckbox(props: IProps) {
  const {
    id,
    formik,
    label,
    errorMode = 'onFormSubmit',
    required = false,
    onChange,
    ...rest
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, event.target.checked);
    onChange && onChange(event);
  };

  const option = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  return (
    <Checkbox
      label={label}
      required={required}
      checked={option || false}
      onChange={handleChange}
      helperText={showError ? error : undefined}
      helperTextProps={{
        sx: { color: 'colors.text.text_error_primary.main', ml: 0 },
      }}
      {...rest}
    />
  );
}

export default React.memo(FormikCheckbox, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    prev.disabled === cur.disabled &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
