import React from 'react';
import Highcharts, { SeriesAreaOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/joy';
import { highchartsReactComponentProps } from '../common';

exporting(Highcharts);

interface IStackedAreaChartProps {
  title?: string;
  height: string;
  categories: string[];
  series: SeriesAreaOptions[];
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  xAxis?: string;
}

const StackedAreaChart = React.forwardRef<
  Highcharts.Chart,
  IStackedAreaChartProps
>(({ series, title, categories, yAxis, xAxis, height }, chartRef) => {
  const options: Highcharts.Options = {
    chart: {
      zooming: { type: 'x' },
      type: 'area',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: title,
    },
    accessibility: {
      enabled: false,
    },
    yAxis: yAxis
      ? yAxis
      : {
          title: {
            enabled: false,
          } as Highcharts.YAxisTitleOptions,
        },
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      title: {
        text: xAxis ?? undefined,
      },
    },
    tooltip: {
      split: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666',
        },
      },
    },
    series,
    exporting: {
      enabled: false,
    },
  };

  return (
    <Box sx={{ minWidth: '100%', height }}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={options}
        containerProps={highchartsReactComponentProps.containerProps}
      />
    </Box>
  );
});

export default StackedAreaChart;
