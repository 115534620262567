import React from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  addProcessStatus,
  exportBaselineRequest,
} from '../../../../modules/actions';
import {
  useFetchMetatagsByIds,
  useFetchSitesCombobox,
  useCreateExportProps,
} from '../../../../modules/utils/hooks';
import { getMetatagsNames } from '../../../../modules/selectors/metatag';
import { ModelsToDelete, useHasUserAccessToAction } from '../../../../config';
import { getGloballySelectedSites } from '../../../../modules/selectors/site';
import { MainContentLoader } from '../../../../components/Layout/components/PageTour/MainContentLoader';
import {
  AnyObject,
  IBaselineModel,
  IBaselineWithMeta,
  ICreateBaselineRequest,
} from '../../../../modules/types';
import { PageContentChildContainer } from '../../../../components/PageContent';
import { manageEntitiesConfig } from '../../../../config/manageEntitiesConfig';
import { SelectSiteAlert } from 'src/components/SelectSiteAlert';
import {
  IPricingFilterPanelFilters,
  PricingFilterPanel,
} from '../../PricingFilterPanel';
import {
  Pagination,
  TableActions,
  TableBottom,
  TableComponent,
  TableContent,
  TableContext,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { BaselineCreateRecord } from './BaselineCreateRecord';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { BaselineUpdateRecords } from './BaselineUpdateRecords';
import { Api } from 'src/modules/utils';

const PricingComponent = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [siteId, setSiteId] = React.useState<number | undefined>(undefined);
  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [
    isCreatePricingRecordOpen,
    setIsCreatePricingRecordOpen,
  ] = React.useState(false);
  const [isUpdateRecordOpened, setIsUpdateRecordOpened] = React.useState(false);
  const [isDataManaging, setIsDataManaging] = React.useState(false);
  const shouldDateBeFetchedRef = React.useRef(false);
  const { filters: filterFieldsWithCurrentValues } = React.useContext(
    TableContext,
  );
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const allowedToCreateRecords = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.create.id,
  );
  const allowedToDeleteRecords = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.delete.id,
  );
  const allowedToUpdateRecords = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.update.id,
  );

  const siteFilterConfig: Array<{
    id: keyof IPricingFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
        isPermanent: true,
      },
    ],
    [t],
  );

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useCreateExportProps(exportBaselineRequest);

  const metatagNames = useSelector(getMetatagsNames);

  const metaTags = React.useMemo(
    () => (siteId && metatagNames[siteId] ? metatagNames[siteId] : []),
    [siteId, metatagNames],
  );

  const headCellsConfig = React.useMemo(
    () => [
      {
        id: 'id',
        label: t('pricing.id'),
      },
      {
        id: 'baseline',
        label: t('baseline.main'),
      },
      {
        id: 'minCpu',
        label: t('baseline.min_cpu'),
      },
      {
        id: 'maxCpu',
        label: t('baseline.max_cpu'),
      },
      ...map(metatagNames[siteId as number] ?? [], (field) => ({
        id: field,
        label: field,
      })),
    ],
    [metatagNames, siteId, t],
  );

  const filterFieldsConfiguration = React.useMemo(() => {
    return {
      id: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
      },
      baseline: {
        value: '',
        property: 'baseline',
        operator: 'eq' as const,
      },
      ...(metatagNames[siteId as number] ?? []).reduce((all, metaTag) => {
        all[metaTag] = {
          value: '',
          property: metaTag,
          operator: 'eq' as const,
        };

        return all;
      }, {}),
    };
  }, [metatagNames, siteId]);

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: 'asc',
    defaultOrderBy: 'id',
  });

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IBaselineWithMeta
  >(`/baseline/${siteId}`, ({
    data: [],
    count: 0,
  } as unknown) as IBaselineWithMeta);

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
          include: {
            client: {
              relationType: 'left',
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(
      Object.entries(filters).reduce((all, [key, value]) => {
        all[key] = { ...filterFieldsConfiguration[key], value };

        return all;
      }, {}),
    );
  };

  const onUpdateClick = () => {
    setIsUpdateRecordOpened(true);
  };

  const onDeleteClick = async () => {
    setIsDataManaging(true);

    await Api.Baseline.delete({
      where: { id: { inq: selectedEntitiesIds } },
    });

    shouldDateBeFetchedRef.current = true;

    setIsDataManaging(false);
    setSelectedEntitiesIds([]);
  };

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    inclusionObj,
    filterFieldsConfiguration,
    filterFields: appliedFilters,
    headCellsOrderDetails,
  });

  const isLoading = isDataLoading || isDataManaging;

  const onUpdate = async (data: IBaselineModel[]) => {
    try {
      setIsUpdateRecordOpened(false);
      setIsDataManaging(true);

      await Api.Baseline.bulkUpdate(data);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
      setIsDataManaging(false);

      fetchData({ filter });
    } catch {
      setIsDataManaging(false);
    }
  };

  const onCreate = async (data: ICreateBaselineRequest) => {
    try {
      setIsCreatePricingRecordOpen(false);
      setIsDataManaging(true);
      await Api.Baseline.create(data);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
      setIsDataManaging(false);

      if (data.siteId !== siteId) {
        setSiteId(data.siteId);
      } else {
        fetchData({ filter });
      }
    } catch {
      setIsDataManaging(false);
    }
  };

  const actionsConfiguration = [];
  if (allowedToUpdateRecords) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  React.useEffect(() => {
    fetchMetatags();
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (siteId) {
      fetchData({ filter });
    }
  }, [filter, fetchData, siteId]);

  if (siteId && shouldDateBeFetchedRef.current === true) {
    fetchData({ filter });
    shouldDateBeFetchedRef.current = false;
  }

  return (
    <>
      <TableToolbar>
        <TableTitle>{t('baseline.main')}</TableTitle>

        <TableActions>
          <Filter onSubmit={handleFiltersSubmit}>
            <FormFieldContainer>
              <TextFieldFilter
                name="id"
                label={`${t('pricing.id')} =`}
                value={filterFieldsWithCurrentValues.id}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="baseline"
                label={`${t('baseline.baseline')} =`}
                value={filterFieldsWithCurrentValues.baseline}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="baseTime"
                label={`${t('baseline.base_time')} =`}
                value={filterFieldsWithCurrentValues.baseTime}
              />
            </FormFieldContainer>
            {metaTags.map((metaTag) => (
              <FormFieldContainer key={metaTag}>
                <TextFieldFilter
                  name={metaTag}
                  label={metaTag}
                  value={filterFieldsWithCurrentValues[metaTag]}
                />
              </FormFieldContainer>
            ))}
          </Filter>

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={{
              ...filter,
              where: { ...filter.where, siteId: { eq: siteId } },
            }}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />

          {allowedToCreateRecords && (
            <Button
              sx={{ ml: 1 }}
              onClick={() => setIsCreatePricingRecordOpen(true)}
            >
              {t('common.create')}
            </Button>
          )}
        </TableActions>
      </TableToolbar>

      <PageContentChildContainer fullHeight={false} sx={{ px: 0, pb: 1 }}>
        <PricingFilterPanel
          onApplyFilters={({ siteId }) => setSiteId(siteId)}
          onRemoveAllFilters={() => setSiteId(undefined)}
          filtersOptionsConfig={siteFilterConfig}
          defaultFilters={{ siteId }}
        />
      </PageContentChildContainer>

      {!siteId && (
        <PageContentChildContainer sx={{ px: 0 }}>
          <SelectSiteAlert />
        </PageContentChildContainer>
      )}

      {siteId && (
        <PageContentChildContainer sx={{ px: 0 }}>
          <TableComponent
            tableUniqueKey="setup/baseline"
            sx={{
              height: isLoading || !data.data.length ? '100%' : 'auto',
            }}
          >
            {isLoading && <MainContentLoader />}

            <TableContent
              data={data.data}
              headCells={headCells}
              isDataLoading={isLoading}
              order={order}
              orderBy={orderBy}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                deleteModelName={ModelsToDelete.Baseline}
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteActionConfiguration={
                  allowedToDeleteRecords
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDeleteClick,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                count={data.count}
                rowsPerPage={limit}
                currentPageNumber={page}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={(limit) => {
                  setLimit(limit);
                  setPage(DEFAULT_PAGE);
                }}
              />
            </TableBottom>
          </TableComponent>
        </PageContentChildContainer>
      )}

      <BaselineCreateRecord
        isOpen={isCreatePricingRecordOpen}
        onClose={() => setIsCreatePricingRecordOpen(false)}
        onSubmit={onCreate}
      />
      <BaselineUpdateRecords
        isOpen={isUpdateRecordOpened}
        onSubmit={onUpdate}
        onClose={() => setIsUpdateRecordOpened(false)}
        baselines={
          data.data.filter((item) =>
            selectedEntitiesIds.includes(item.id),
          ) as any
        }
      />
    </>
  );
};

export const Baseline = () => {
  return (
    <TableContextProvider>
      <PricingComponent />
    </TableContextProvider>
  );
};
