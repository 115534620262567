import { Grid, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { Alert, Box, Button } from '@mui/joy';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map, reduce } from 'lodash';

import { getProductionRefreshKey } from 'src/modules/selectors/production.selector';
import {
  useProductionsList,
  useProductionPermissions,
  useCreateExportProps,
  useProductionsTableFiltersConfiguration,
  useProductionsTableInclusionObject,
  useProductionFilter,
} from '../../modules/utils/hooks';
import { ModelsToDelete, MUI_SIZE_12, paths } from 'src/config';

import {
  AnyObject,
  IProductionModel,
  IUpdateProduction,
} from 'src/modules/types';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPage } from 'src/components/ReportPage';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useFetchReportTableDataAndCountEffect,
} from 'src/modules/utils/hooks/reportPage.hooks';
import {
  IProductionFilterPanelFilters,
  ProductionFilterPanel,
} from 'src/pages/Productions/components/ProductionFilterPanel';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateRequestFilter,
  useTableCommonHandlers,
  useTableData,
  useTableFilter,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { TableWithActions } from 'src/components/TableWithActions';
import { ProductionsCreate } from './ProductionsCreate';
import { ProductionsUpdate } from './ProductionsUpdate';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { exportProductionRequest } from 'src/modules/actions';
import { IHeadCell, Order } from 'src/modules/types/table';
import { TableWithActionsDeleteModal } from '../../components/TableWithActions/components/TableWithActionsDeleteModal';
import { ReportPageActionsGroupItem } from '../../components/ReportPage/ReportPageActionsGroupItem';
import { NoIcon, YesIcon } from 'src/components/Icons';

interface IProductionsListTableProps {
  order?: Order;
  orderBy?: string;
  setOrder(v: Order): void;
  defaultOrderDetails: {
    order: Order;
    orderBy: string;
  };
  headCells: IHeadCell[];
  limit: number;
  setLimit(v: number): void;
  page: number;
  setPage(v: number): void;
  setOrderBy(v: string): void;
  allowedToUpdate: boolean;
  allowedToDelete: boolean;
  appliedFilters: IProductionFilterPanelFilters;
  handleUpdating(productions: IUpdateProduction[]): void;
}

const ProductionsListTable: React.FC<IProductionsListTableProps> = ({
  appliedFilters,
  setOrderBy,
  order,
  orderBy,
  limit,
  setPage,
  setLimit,
  page,
  setOrder,
  headCells,
  defaultOrderDetails,
  handleUpdating,
  allowedToDelete,
  allowedToUpdate,
}) => {
  const [selectedProductions, setSelectedProductions] = React.useState<
    Array<IUpdateProduction>
  >([]);

  const { where, include } = useProductionFilter(appliedFilters);
  const filter = useTableFilter({
    where,
    include,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
    order: order ?? defaultOrderDetails.order,
    limit,
  });

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const SHORT_COMMENT_LENGTH = 50;

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData<{
    data: IProductionModel[];
    count: number;
  }>(paths.PRODUCTIONS, {
    data: [],
    count: 0,
  });

  const onTableRowCheckboxClick = (productions: Array<IUpdateProduction>) => {
    setSelectedProductions(productions);
  };

  const handleDelete = async () => {
    const ids = selectedProductions.map((p) => p.id);
    await removeDataByIds(ids);
    await fetchData(filter);
    setShowDeleteModal(false);
  };

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedItems = useMemo(
    () =>
      map(data.data, (item: AnyObject) => ({
        ...item,
        date: item.date,
        shift: item.shiftName,
        isPartialContainer: item.isPartialContainer ? <YesIcon /> : <NoIcon />,
        isPartialContainerBoolean: Boolean(item.isPartialContainer),
        employees: reduce(
          item.employees,
          (acc, cur) => {
            const employeeToPush = `(${cur.badge || '-'}) ${
              cur.firstName || ''
            } ${cur.lastName || ''}`;
            acc.push(employeeToPush);
            return acc;
          },
          [] as string[],
        ).join(', '),
        comment:
          item.comment && item.comment?.length > SHORT_COMMENT_LENGTH ? (
            <Tooltip title={item.comment}>
              <span>
                {item.comment.substring(0, SHORT_COMMENT_LENGTH) + '...'}
              </span>
            </Tooltip>
          ) : (
            item.comment
          ),
      })),
    [data],
  );

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
    where,
    include,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    setPage,
    where,
    include,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  return (
    <>
      <TableWithActionsDeleteModal
        open={showDeleteModal}
        onOk={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        deleteModelName={ModelsToDelete.Production}
      />
      <TableWithActions<IUpdateProduction>
        selectedIndex="id"
        data={computedItems as any}
        count={data.count}
        isDataLoading={isDataLoading}
        tableUniqueKey={paths.PRODUCTIONS}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        limit={limit}
        page={page}
        onSort={handleSort}
        selectedItems={selectedProductions}
        onRowCheckboxClick={onTableRowCheckboxClick}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(page) => handlePageChange(page)}
        {...(allowedToUpdate
          ? {
              updateActionConfiguration: {
                onClick: () => {
                  handleUpdating(selectedProductions);
                },
              },
            }
          : {})}
        {...(allowedToDelete
          ? {
              deleteActionConfiguration: {
                onClick: () => setShowDeleteModal(true),
              },
            }
          : {})}
      />
    </>
  );
};

const defaultFilters: IProductionFilterPanelFilters = {
  date: undefined,
  siteId: undefined,
  shiftId: undefined,
  departmentId: undefined,
  bomId: undefined,
  container: undefined,
  lineOrDoor: undefined,
  comment: undefined,
};

const ProductionsList: React.FC = () => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'id',
  };

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
  } = useProductionPermissions();

  const [
    isCreateProductionFormVisible,
    setIsCreateProductionFormVisible,
  ] = React.useState(false);

  const [
    isUpdateProductionFormVisible,
    setIsUpdateProductionFormVisible,
  ] = React.useState(false);

  const [appliedFilters, setAppliedFilters] = React.useState<
    IProductionFilterPanelFilters
  >(defaultFilters);

  const { headCells, headCellsOrderDetails } = useProductionsList(
    appliedFilters?.siteId ?? NaN,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const productionsTableFiltersConfiguration = useProductionsTableFiltersConfiguration();
  const productionsTableInclusionObj = useProductionsTableInclusionObject(
    appliedFilters?.siteId ?? NaN,
  );

  const { filterFields } = useFilterFieldsData({
    filterFieldsConfiguration: productionsTableFiltersConfiguration,
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    inclusionObj: productionsTableInclusionObj,
    filterFieldsConfiguration: productionsTableFiltersConfiguration,
  });

  const [selectedProductions, setSelectedProductions] = React.useState<
    IUpdateProduction[]
  >([]);

  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IProductionFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
        isPermanent: true,
      },
      { id: 'shiftId', label: t('productions.shift.name') },
      { id: 'departmentId', label: t('productions.department') },
      { id: 'date', label: t('productions.date') },
      { id: 'container', label: t('productions.container') },
      { id: 'lineOrDoor', label: t('productions.lineOrDoor') },
      { id: 'comment', label: t('productions.comment') },
    ],
    [t],
  );

  const exportProps = useCreateExportProps(exportProductionRequest);

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const isSiteSelected = !!selectedSites.length && appliedFilters?.siteId;

  // handle updating
  const handleUpdating = (productions: IUpdateProduction[]) => {
    setIsUpdateProductionFormVisible(true);
    setSelectedProductions(productions);
  };

  return (
    <>
      <PageContentWithTopToolbar>
        <ReportPage hideNoData>
          <ReportTableToolbar reportName={t('productions.table_name')}>
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>

              <ReportPageActionsGroupItem>
                <TableActionsExport
                  requestFilters={requestFilters}
                  exportBtnContainerProps={{ ml: 3 }}
                  {...exportProps}
                />
              </ReportPageActionsGroupItem>

              {allowedToCreate && (
                <ReportPageActionsGroupItem>
                  <Tooltip
                    title={
                      isSiteSelected
                        ? ''
                        : t('productions.select_site_to_create_production')
                    }
                  >
                    <Box>
                      <Button
                        disabled={!isSiteSelected}
                        onClick={() => setIsCreateProductionFormVisible(true)}
                      >
                        {t('common.create')}
                      </Button>
                    </Box>
                  </Tooltip>
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
          <ReportPageItemContainer>
            <ProductionFilterPanel
              hideSyncBtn
              filtersOptionsConfig={filtersOptionsConfig}
              onApplyFilters={(filtersToApply) => {
                setAppliedFilters(filtersToApply);
              }}
              onRemoveAllFilters={() => setAppliedFilters(defaultFilters)}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            {!isSiteSelected && (
              <Grid container={true}>
                <Grid item={true} xs={MUI_SIZE_12}>
                  <Alert variant="soft">
                    {t('productions.select_global_site_by_client')}
                  </Alert>
                </Grid>
              </Grid>
            )}
            {isSiteSelected && (
              <>
                <ProductionsListTable
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  defaultOrderDetails={defaultOrderDetails}
                  setOrder={setOrder}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  setOrderBy={setOrderBy}
                  appliedFilters={appliedFilters}
                  allowedToUpdate={allowedToUpdate}
                  allowedToDelete={allowedToDelete}
                  handleUpdating={handleUpdating}
                />

                <ProductionsCreate
                  siteId={appliedFilters.siteId!}
                  isOpen={isCreateProductionFormVisible}
                  onClose={() => setIsCreateProductionFormVisible(false)}
                />

                <ProductionsUpdate
                  productions={selectedProductions}
                  siteId={appliedFilters.siteId!}
                  isOpen={isUpdateProductionFormVisible}
                  onClose={() => setIsUpdateProductionFormVisible(false)}
                />
              </>
            )}
          </ReportPageItemContainer>
        </ReportPage>
      </PageContentWithTopToolbar>
    </>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function ProductionsListRefreshable() {
  return <ProductionsList key={useSelector(getProductionRefreshKey)} />;
}
