import React from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import { TextField, ITextFieldProps } from '../_ui-kit/TextField';
import { useTranslation } from 'react-i18next';

export type IFormikNumberFieldProps = Omit<
  ITextFieldProps,
  'onChange' | 'type'
> & {
  id: string;
  name: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
};

function FormikCustomNumberField({
  id,
  name,
  formik,
  label,
  placeholder,
  ...restProps
}: IFormikNumberFieldProps) {
  const { t } = useTranslation();

  const value = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError = touched && Boolean(error);

  const handleKeyDown = (event: any) => {
    const key = event.key;
    const isNumber = key >= '0' && key <= '9';

    // Handle ArrowUp and ArrowDown for incrementing/decrementing the value
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      event.preventDefault();
      const newValue = parseFloat(value) || 0;
      formik.setFieldValue(id, key === 'ArrowUp' ? newValue + 1 : newValue - 1);
      return;
    }

    // Allow basic control keys
    if (
      [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
    ) {
      return;
    }

    // Handle '-' only at the beginning of the input and when there isn't already a '-'
    if (
      key === '-' &&
      event.target.selectionStart === 0 &&
      !value.includes('-')
    ) {
      return;
    }

    // Prevent adding '-' if it's not at the start or already exists
    if (
      key === '-' &&
      (event.target.selectionStart !== 0 || value.includes('-'))
    ) {
      event.preventDefault();
      return;
    }

    // Allow decimal point only if it doesn't already exist and is not preceded by multiple zeros
    if (key === '.' && (value.includes('.') || /^0{2,}/.test(value))) {
      event.preventDefault();
      return;
    }

    // Ensure only numbers are allowed
    if (!isNumber && key !== '.') {
      event.preventDefault();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const isEndsWithDot = /\.$/.test(value);
    const isEndsWithDotAndZeros = /\.0+$/.test(value);

    const processedValue =
      typeof value !== 'string'
        ? ''
        : !isEndsWithDot && !isEndsWithDotAndZeros && !isNaN(parseFloat(value))
        ? Number(value)
        : value;

    formik.setFieldValue(id, processedValue);
  };

  return (
    <TextField
      {...restProps}
      placeholder={placeholder ?? t('common.add_your_value_here')}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      error={showError}
      helperText={showError && error}
      onKeyDown={handleKeyDown}
      type="text"
    />
  );
}

export default React.memo(FormikCustomNumberField, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    prev.label === cur.label &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
