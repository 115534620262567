/**
 * Map of models we are going to get relations by when a super-admin removes something
 */
export enum ModelsToDelete {
  Employee = 'Employee',
  OpenPositions = 'OpenPosition',
  ClosedPositions = 'OpenPosition',
  Applicant = 'Applicant',
  EmployeeSkill = 'EmployeeSkill',
  TerminationReason = 'TerminationReason',
  Logtime = 'Logtime',
  Skill = 'Skill',
  Role = 'Role',
  User = 'User',
  StaffingProvider = 'Staffing',
  Position = 'Position',
  Shift = 'Shift',
  QuestionCategoryType = 'QuestionCategoryType',
  Client = 'Client',
  Site = 'Site',
  Department = 'Department',
  IssueType = 'IssueType',
  Issue = 'Issue',
  PointCollection = 'PointCollection',
  PointType = 'PointType',
  Point = 'Point',
  Pricing = 'Pricing',
  Standard = 'Standard',
  Metatag = 'Metatag',
  Policy = 'Policy',
  Bom = 'Bom',
  SiteImportingSetting = 'SiteImportingSetting',
  TaktSiteImportingSetting = 'TaktSiteImportingSetting',
  Production = 'Production',
  QuestionCategory = 'QuestionCategory',
  Question = 'Question',
  CostPlusSettings = 'CostPlusSettings',
  OtherCosts = 'OtherCosts',
  Baseline = 'Baseline',
  OptiturnCredentials = 'OptiturnCredentials',
  IncentivePrice = 'IncentivePrice',
  Workdays = 'Workdays',
  AutomaticPointsRejectReason = 'AutomaticPointsRejectReason',
}
