import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/joy';

import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';

import { AnyObject, IPointCollectionModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  usePointCollection,
  usePointCollectionFilter,
  usePointCollectionsPermissions,
  usePointCollectionTableFiltersConfiguration,
} from 'src/modules/utils/hooks/point-collection.hooks';
import { PointCollectionFilter } from './components/PointCollectionFilter';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { PointCollectionUpsert } from './components/PointCollectionUpsert';

const reportUrl = '/point-collections';

export const PointCollection = () => {
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useReportExportProps(reportUrl, true);

  const { allowedToUpsert, allowedToDelete } = usePointCollectionsPermissions();

  const [selectedItem, setSelectedItem] = React.useState<
    IPointCollectionModel
  >();

  const [isUpsertOpen, setIsUpsertOpen] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const filter = usePointCollectionFilter(appliedFilters);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    sendToServer,
    fetchData,
    removeDataByIds,
  } = usePointCollection(reportUrl, filter!.where!, filter!.include!);

  const filterFieldsConfiguration = usePointCollectionTableFiltersConfiguration();

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(filters);
  };

  const handleTableRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: IPointCollectionModel,
  ) => {
    setSelectedItem(row);
    setIsUpsertOpen(true);
  };

  const handleDelete = async () => {
    await removeDataByIds([selectedItem!.id!]);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);
    setIsUpsertOpen(false);
    setSelectedItem(undefined);
  };

  const handleUpsert = async (data: IPointCollectionModel) => {
    sendToServer([data]);
    setSelectedItem(undefined);
    setIsUpsertOpen(false);
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: isDataLoading ? 400 : 'auto',
              height: isDataLoading || !data.length ? '100%' : 'auto',
            }}
          >
            <TableToolbar>
              <TableTitle>{t('point_collections.table_name')}</TableTitle>

              <TableActions>
                <PointCollectionFilter
                  getLabel={getLabel}
                  onFiltersFormSubmit={handleFiltersSubmit}
                  getFilterCommonPropsByFilterName={
                    getFilterCommonPropsByFilterName
                  }
                />

                <SelectHeadCells />

                <TableActionsExport
                  requestFilters={requestFilters}
                  exportBtnContainerProps={{ ml: 3 }}
                  {...exportProps}
                />

                {allowedToUpsert && (
                  <Button sx={{ ml: 1 }} onClick={() => setIsUpsertOpen(true)}>
                    {t('point_collections.manage')}
                  </Button>
                )}
              </TableActions>
            </TableToolbar>

            <TableWithActionsDeleteModal
              open={showDeleteModal}
              onOk={handleDelete}
              onCancel={() => setShowDeleteModal(false)}
              deleteModelName={ModelsToDelete.PointCollection}
            />

            <TableWithActions<IPointCollectionModel>
              heightWithTabs
              data={data as any}
              count={countData.count}
              isDataLoading={isDataLoading || isCountDataLoading}
              tableUniqueKey={reportUrl}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              limit={limit}
              page={page}
              onSort={handleSort}
              onTableRowClick={handleTableRowClick}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={(page) => handlePageChange(page)}
              {...(allowedToDelete
                ? {
                    deleteActionConfiguration: {
                      onClick: () => setShowDeleteModal(true),
                    },
                  }
                : {})}
            />

            <PointCollectionUpsert
              isOpen={isUpsertOpen}
              onUpdate={handleUpsert}
              onDelete={handleDelete}
              onClose={() => setIsUpsertOpen(false)}
              row={selectedItem}
            />
          </Box>
        </TableContextProvider>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
