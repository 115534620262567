import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  usePrepareUserWithAttributes,
  useUpdateUserValidationScheme,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  IFilterData,
  IStoreState,
  IUpdateUser,
  IUpdateUserWithAttributes,
  IWhere,
  IdsArray,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateUsersRequest } from 'src/modules/actions';
import { roles } from 'src/config';
import { getDepartmentsComboboxListBySites } from 'src/modules/selectors/department';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getUsersByIds } from 'src/modules/selectors/user';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { getShiftsComboboxListBySites } from 'src/modules/selectors/shift';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { useRenderUserOtherFields } from 'src/modules/utils/hooks/useRenderUserOtherFields';
import { reduce, set } from 'lodash';
import RolesCombobox from 'src/components/Formik/comboboxes-with-entities/RolesCombobox';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  wmsLogin: '',
  shiftId: NaN,
  departmentId: NaN,
  supervisorId: '',
  activated: false,
  locked: false,
  role: roles.GM,
  completeStatus: false,
  lang: '',
};

interface IUpdateUsersProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  usersToUpdateIds: IdsArray;
}

export const UpdateUsers = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  usersToUpdateIds,
}: IUpdateUsersProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const usersToUpdateFullData = useSelector((state) =>
    getUsersByIds(state as IStoreState)(usersToUpdateIds.map(String)),
  );

  const [roles, setRoles] = React.useState<string[]>([]);

  const schema = useUpdateUserValidationScheme(roles);

  const prepareRequestData = usePrepareUserWithAttributes<
    IUpdateUserWithAttributes
  >();

  const validate = useValidate(schema);

  const formik = useFormik({
    initialValues: usersToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateUsersRequest({
          data: data.map((item) =>
            prepareRequestData(item, {
              siteId: true,
              clientId: true,
              staffingId: true,
            }),
          ),
          filters: { count: filterCount, list: filterList },
        }),
      );

      onClose();
    },
  });
  const siteIds = React.useMemo(
    () =>
      reduce<IUpdateUser & { sites?: number[] }, number[]>(
        formik.values as any,
        (acc, cur) => {
          acc = cur.sites ? [...acc, ...cur.sites] : [...acc];
          return acc;
        },
        [],
      ),
    [formik.values],
  );
  const sites = useSelector(getSitesComboboxList);
  const shifts = useSelector(getShiftsComboboxListBySites)(siteIds);
  const departments = useSelector(getDepartmentsComboboxListBySites)(siteIds);
  const clients = useSelector(getClientsComboboxList) as ComboBoxOption[];
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
  ) as ComboBoxOption[];

  const getOtherFields = useRenderUserOtherFields({
    formik,
    sites,
    shifts,
    departments,
    staffingProviders,
    clients,
  });

  const onRoleChange = (index: number) => (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    if (value?.id) {
      setRoles((prev) => {
        const newRoles = [...prev];
        set(newRoles, [index], value.id);
        return newRoles;
      });
    }
  };

  const onReset = () => {
    formik.setValues(
      formik.values.map(({ id, ...rest }) => ({
        id,
        ...rest,
        ...initialValues,
      })) as any,
    );
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('users.bulk_update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {usersToUpdateFullData.map((user, index) => (
              <DrawerBodySectionContent key={user.id}>
                <DrawerBodySectionTitle>{`${user.firstName} ${user.lastName}`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <RolesCombobox
                    required
                    id={`${index}.role`}
                    name={`${index}.role`}
                    onChange={onRoleChange(index)}
                    label={t('common.role')}
                    placeholder={t('common.role')}
                    fieldAsId="key"
                    formik={formik}
                  />
                </FormFieldContainer>

                {getOtherFields(index)}
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
