import React from 'react';
import { get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { exportPricingRequest } from '../../../../modules/actions';
import {
  useFetchMetatagsByIds,
  useFetchSitesCombobox,
  useCreateExportProps,
} from '../../../../modules/utils/hooks';
import { getMetatagsNames } from '../../../../modules/selectors/metatag';
import { useHasUserAccessToAction } from '../../../../config';
import { getGloballySelectedSites } from '../../../../modules/selectors/site';
import { MainContentLoader } from '../../../../components/Layout/components/PageTour/MainContentLoader';
import { AnyObject, IPricingWithMeta } from '../../../../modules/types';
import { PageContentChildContainer } from '../../../../components/PageContent';
import { manageEntitiesConfig } from '../../../../config/manageEntitiesConfig';
import { SelectSiteAlert } from 'src/components/SelectSiteAlert';
import {
  IPricingFilterPanelFilters,
  PricingFilterPanel,
} from '../../PricingFilterPanel';
import {
  TableActions,
  TableComponent,
  TableContent,
  TableContext,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { PricingCreateRecord } from './PricingCreateRecord';
import { PricingCreateSettings } from './PricingCreateSettings';
import { PricingUpdateRecords } from './PricingUpdateRecords';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { usePricingSettingsForm } from '../../../../modules/utils/hooks/pricing-settings';

const PricingComponent = () => {
  const { t } = useTranslation();

  const [siteId, setSiteId] = React.useState<number | undefined>(undefined);
  const [
    isCreatePricingRecordOpen,
    setIsCreatePricingRecordOpen,
  ] = React.useState(false);
  const [
    isCreatePricingSettingsOpen,
    setIsCreatePricingSettingsOpen,
  ] = React.useState(false);
  const shouldDateBeFetchedRef = React.useRef(false);
  const { filters: filterFieldsWithCurrentValues } = React.useContext(
    TableContext,
  );
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const allowedToCreateRecords = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.create.id,
  );
  const allowedToCreateSettings = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.create_settings.id,
  );
  const allowedToUpdateRecords = useHasUserAccessToAction(
    manageEntitiesConfig.pricing.update.id,
  );

  const siteFilterConfig: Array<{
    id: keyof IPricingFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
        isPermanent: true,
      },
    ],
    [t],
  );

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});
  const [clickedRowData, setClickedRowData] = React.useState<AnyObject | null>(
    null,
  );
  const exportProps = useCreateExportProps(exportPricingRequest);

  const metatagNames = useSelector(getMetatagsNames);

  const { settings } = usePricingSettingsForm(siteId);

  const metaTags = React.useMemo(
    () =>
      (siteId && settings && metatagNames[siteId]
        ? metatagNames[siteId]
        : []
      ).filter((mt) => !get(settings, [mt, 'disabled'])),
    [siteId, settings, metatagNames],
  );

  const headCellsConfig = React.useMemo(
    () => [
      {
        id: 'id',
        label: t('pricing.id'),
      },
      {
        id: 'price',
        label: t('pricing.price'),
      },
      {
        id: 'incentivePrice',
        label: t('pricing.incentivePrice'),
      },
      {
        id: 'overtimePrice',
        label: t('pricing.overtimePrice'),
      },
      {
        id: 'minUph',
        label: t('pricing.minUph'),
      },
      {
        id: 'maxUph',
        label: t('pricing.maxUph'),
      },
      {
        id: 'description',
        label: t('issues.description'),
      },
      ...map(metaTags, (field) => ({
        id: field,
        label: field,
      })),
    ],
    [metaTags, t],
  );

  const filterFieldsConfiguration = React.useMemo(() => {
    return {
      id: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
      },
      price: {
        value: '',
        property: 'price',
        operator: 'eq' as const,
      },
      incentivePrice: {
        value: '',
        property: 'incentivePrice',
        operator: 'eq' as const,
      },
      overtimePrice: {
        value: '',
        property: 'overtimePrice',
        operator: 'eq' as const,
      },
      minUph: {
        value: '',
        property: 'minUph',
        operator: 'eq' as const,
      },
      maxUph: {
        value: '',
        property: 'maxUph',
        operator: 'eq' as const,
      },
      description: {
        value: '',
        property: 'description',
        operator: 'eq' as const,
      },
      ...metaTags.reduce((all, metaTag) => {
        all[metaTag] = {
          value: '',
          property: metaTag,
          operator: 'eq' as const,
        };

        return all;
      }, {}),
    };
  }, [metaTags]);

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const { order, orderBy, setOrder, setOrderBy } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: 'asc',
    defaultOrderBy: 'id',
  });

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IPricingWithMeta
  >(`/pricing/${siteId}`, ({
    data: [],
    count: 0,
  } as unknown) as IPricingWithMeta);

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
          include: {
            client: {
              relationType: 'left',
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const filter = useGenerateRequestFilter({
    order,
    orderBy,
    inclusionObj,
    filterFieldsConfiguration,
    filterFields: appliedFilters,
    headCellsOrderDetails,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(
      Object.entries(filters).reduce((all, [key, value]) => {
        all[key] = { ...filterFieldsConfiguration[key], value };

        return all;
      }, {}),
    );
  };

  const handleRowClick = (_: any, row: any) => {
    setClickedRowData(row);
  };

  const onCreateRecordSuccess = (newRecordForSiteId: number) => {
    setSiteId(newRecordForSiteId);

    if (siteId) {
      fetchData({ filter });
    }

    setIsCreatePricingRecordOpen(false);
  };

  const onUpdateRecordsSuccess = (newRecordForSiteId: number) => {
    setSiteId(newRecordForSiteId);

    fetchData({ filter });

    setClickedRowData(null);
  };

  React.useEffect(() => {
    fetchMetatags();
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (siteId) {
      fetchData({ filter });
    }
  }, [filter, fetchData, siteId]);

  if (siteId && shouldDateBeFetchedRef.current === true) {
    fetchData({ filter });
    shouldDateBeFetchedRef.current = false;
  }

  return (
    <>
      <TableToolbar>
        <TableTitle>{t('pricing.pricing')}</TableTitle>

        <TableActions>
          <Filter onSubmit={handleFiltersSubmit}>
            <FormFieldContainer>
              <TextFieldFilter
                name="id"
                label={`${t('pricing.id')} =`}
                value={filterFieldsWithCurrentValues.id}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="price"
                label={`${t('pricing.price')} =`}
                value={filterFieldsWithCurrentValues.price}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="incentivePrice"
                label={`${t('pricing.incentivePrice')} =`}
                value={filterFieldsWithCurrentValues.incentivePrice}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="overtimePrice"
                label={`${t('pricing.overtimePrice')} =`}
                value={filterFieldsWithCurrentValues.overtimePrice}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="minUph"
                label={`${t('pricing.minUph')} =`}
                value={filterFieldsWithCurrentValues.minUph}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="maxUph"
                label={`${t('pricing.maxUph')} =`}
                value={filterFieldsWithCurrentValues.maxUph}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                name="description"
                label={`${t('issues.description')} =`}
                value={filterFieldsWithCurrentValues.description}
              />
            </FormFieldContainer>
            {metaTags.map((metaTag) => (
              <FormFieldContainer key={metaTag}>
                <TextFieldFilter
                  name={metaTag}
                  label={metaTag}
                  value={filterFieldsWithCurrentValues[metaTag]}
                />
              </FormFieldContainer>
            ))}
          </Filter>

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={{
              ...filter,
              where: { ...filter.where, siteId: { eq: siteId } },
            }}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />

          {allowedToCreateSettings && (
            <Button
              sx={{ ml: 1 }}
              onClick={() => setIsCreatePricingSettingsOpen(true)}
            >
              {t('pricing.create_settings_btn')}
            </Button>
          )}
          {allowedToCreateRecords && (
            <Button
              sx={{ ml: 1 }}
              onClick={() => setIsCreatePricingRecordOpen(true)}
            >
              {t('common.create')}
            </Button>
          )}
        </TableActions>
      </TableToolbar>

      <PageContentChildContainer fullHeight={false} sx={{ px: 0, pb: 1 }}>
        <PricingFilterPanel
          onApplyFilters={({ siteId }) => setSiteId(siteId)}
          onRemoveAllFilters={() => setSiteId(undefined)}
          filtersOptionsConfig={siteFilterConfig}
          defaultFilters={{ siteId }}
        />
      </PageContentChildContainer>

      {!siteId && (
        <PageContentChildContainer sx={{ px: 0 }}>
          <SelectSiteAlert />
        </PageContentChildContainer>
      )}

      {siteId && (
        <PageContentChildContainer sx={{ px: 0 }}>
          <TableComponent
            tableUniqueKey="setup/pricing"
            sx={{
              height: isDataLoading || !data.data.length ? '100%' : 'auto',
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data.data}
              headCells={headCells}
              isDataLoading={isDataLoading}
              order={order}
              orderBy={orderBy}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
              onTableRowClick={
                allowedToUpdateRecords ? handleRowClick : undefined
              }
            />
          </TableComponent>
        </PageContentChildContainer>
      )}

      <PricingCreateRecord
        onSuccess={onCreateRecordSuccess}
        isOpen={isCreatePricingRecordOpen}
        onClose={() => setIsCreatePricingRecordOpen(false)}
      />
      <PricingUpdateRecords
        onSuccess={onUpdateRecordsSuccess}
        isOpen={Boolean(clickedRowData)}
        onClose={() => setClickedRowData(null)}
        clickedRowData={clickedRowData}
        data={data.data}
      />
      <PricingCreateSettings
        isOpen={isCreatePricingSettingsOpen}
        onClose={() => setIsCreatePricingSettingsOpen(false)}
      />
    </>
  );
};

export const Pricing = () => {
  return (
    <TableContextProvider>
      <PricingComponent />
    </TableContextProvider>
  );
};
