import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IStorageUtilizationTotalDataItem } from 'src/modules/types/sony';
import { paths } from 'src/config';
import StackedAreaChart from 'src/components/Charts/2d/StackedAreaChart';
import {
  sonyInventoryMaxValue,
  sonyInventoryOrder,
  sonyInventoryThreshold,
} from './sonyInventory.constants';
import { useTheme } from '@mui/joy';

interface ISonyInventoryTotalChartProps {
  startDate?: string;
  endDate?: string;
}

export const SonyInventoryTotalChart = ({
  startDate,
  endDate,
}: ISonyInventoryTotalChartProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const where = React.useMemo(() => {
    const formattedStartDate = startDate
      ? startDate.replaceAll('-', '')
      : undefined;
    const formattedEndDate = endDate ? endDate.replaceAll('-', '') : undefined;

    if (formattedStartDate && formattedEndDate) {
      return {
        'ID DATE': { between: [formattedStartDate, formattedEndDate] },
      };
    }

    if (formattedStartDate) {
      return { 'ID DATE': { gte: formattedStartDate } };
    }

    if (formattedEndDate) {
      return { 'ID DATE': { lte: formattedEndDate } };
    }

    return undefined;
  }, [endDate, startDate]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    Array<IStorageUtilizationTotalDataItem>
  >(paths.SONY_INVENTORY_STORAGE_UTILIZATION_TOTAL, []);

  const categories = data.map((item) => item['ID DATE']);
  const series = [
    {
      type: 'area' as const,
      name: t('common.total'),
      color: '#D0E7FA',
      data: data.map((item) => item.total),
      yAxis: 0,
      marker: {
        radius: 3,
        symbol: 'circle',
      },
    },
  ];

  React.useEffect(() => {
    fetchData({ filter: { where, order: sonyInventoryOrder } });
  }, [fetchData, where]);

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  return (
    <ChartContainer fullHeigh>
      <ChartContainerHeader title={t('charts.storage_utilization_total')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`downtime`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isDataLoading}
        showNoData={!data.length && !isDataLoading}
        noDataContainerSx={{ bgcolor: 'white' }}
      >
        <StackedAreaChart
          ref={chartRef}
          height={'100%'}
          categories={categories}
          series={series}
          yAxis={[
            {
              // Primary Y-axis (numbers)
              title: {
                text: 'Cube ft',
              },
              labels: {
                format: '{value}',
              },
              min: 0,
              max: sonyInventoryMaxValue,
              plotLines: [
                {
                  value: sonyInventoryThreshold,
                  color: theme.palette.warning[400],
                  width: 1,
                  zIndex: 5,
                  label: {
                    text: 'Overutilized Storage Level',
                    align: 'left',
                    style: {
                      color: theme.palette.warning[600],
                      fontSize: '11px',
                    },
                  },
                },
                {
                  value: sonyInventoryMaxValue,
                  color: theme.palette.error[600],
                  width: 1,
                  zIndex: 5,
                  label: {
                    text: 'Max Storage Level',
                    align: 'left',
                    style: {
                      fontSize: '11px',
                      color: theme.palette.error[600],
                    },
                  },
                },
              ],
            },
            {
              // Secondary Y-axis (percentages)
              title: {
                text: '',
              },
              opposite: true,
              max: 100,
              min: 0,
              linkedTo: 0,
              labels: {
                formatter: function () {
                  // Convert the number value into a percentage based on a threshold of 700,000
                  return (
                    (
                      ((this.value as number) / sonyInventoryMaxValue) *
                      100
                    ).toFixed(0) + '%'
                  );
                },
              },
            },
          ]}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
