import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import {
  Edit02Svg,
  KeysSvg,
  LockSvg,
  Trash04Svg,
  UnLockSvg,
  UserCheckSvg,
  UserXIconSvg,
} from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getUserListRequest,
  getUserCountRequest,
  activateUserRequest,
  deactivateUserRequest,
  resetPasswordByAdminRequest,
  unlockUserRequest,
  lockUserRequest,
  exportUserRequest,
  deleteUserRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  useCreateExportProps,
  useFetchActiveDepartmentsCombobox,
  useFetchClientsCombobox,
  useFetchRolesCombobox,
  useFetchShiftsCombobox,
  useFetchStaffingProvidersCombobox,
  useSupervisorCombobox,
  useUsersInclusionForTable,
  useUsersTableDefaultWhere,
} from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsData,
  useTableData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { DepartmentsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DepartmentsComboBoxFilter';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import {
  getIsUsersLoading,
  getUserCount,
  getUsersComboboxList,
  getUsersListForTable,
  getUsersMap,
} from 'src/modules/selectors/user';
import { getRolesComboboxListByFieldId } from 'src/modules/selectors/role';
import { UpdateUsers } from './UsersForms/UpdateUsers';
import { IdsArray } from 'src/modules/types';
import { ActionConfirmation } from 'src/components/ActionConfirmation';
import { CreateUser } from './UsersForms/CreateUser';

export const UsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);
  const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = React.useState(false);

  const exportProps = useCreateExportProps(exportUserRequest);

  const isDataLoading = useSelector(getIsUsersLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.user.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.user.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.user.delete.id,
  );
  const allowedToActivate = useHasUserAccessToAction(
    manageEntitiesConfig.user.activate.id,
  );
  const allowedToDeactivate = useHasUserAccessToAction(
    manageEntitiesConfig.user.deactivate.id,
  );
  const allowedToLock = useHasUserAccessToAction(
    manageEntitiesConfig.user.lock.id,
  );
  const allowedToUnlock = useHasUserAccessToAction(
    manageEntitiesConfig.user.unlock.id,
  );
  const allowedToResetPassword = useHasUserAccessToAction(
    manageEntitiesConfig.user.reset_password.id,
  );

  const usersMap = useSelector(getUsersMap);

  const getUsersList = React.useCallback(
    (ids: IdsArray) => (
      <ul>
        {ids
          .map((id) => usersMap[id])
          .map((u) => (
            <li key={u.id}>
              #{u.id} {u.firstName} {u.lastName} ({u.email}) - {u.role}
            </li>
          ))}
      </ul>
    ),
    [usersMap],
  );

  const where = useUsersTableDefaultWhere();

  const shiftsOptions = useSelector(getShiftsComboboxList);
  const clientsOptions = useSelector(getClientsComboboxList);
  const supervisorsOptions = useSelector(getUsersComboboxList);
  const staffingProvidersOptions = useSelector(
    getStaffingProvidersComboboxList,
  );

  const fetchRolesCombobox = useFetchRolesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchClientsCombobox = useFetchClientsCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const rolesOptions = useSelector(getRolesComboboxListByFieldId)('key');
  const fetchStaffingProviderCombobox = useFetchStaffingProvidersCombobox();

  const inclusionObj = useUsersInclusionForTable();

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('users.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('users.last_name'),
      },
      {
        id: 'email',
        orderConfig: {
          orderBy: 'email',
        },
        label: t('users.email'),
      },
      {
        id: 'activated',
        orderConfig: { orderBy: 'activated' },
        label: t('users.activated'),
      },
      {
        id: 'locked',
        orderConfig: { orderBy: 'locked' },
        label: t('users.locked'),
      },
      {
        id: 'role',
        orderConfig: { orderBy: 'role' },
        label: t('users.role'),
      },
      {
        id: 'siteName',
        label: t('users.site_name'),
      },
      {
        id: 'clientName',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: [
            'userAttributes',
            'scope',
            'include',
            'client',
            'scope',
          ],
        },
        label: t('users.client_name'),
      },
      {
        id: 'staffingName',
        orderConfig: {
          orderBy: 'staffingProvider',
          orderByInclusionPath: [
            'userAttributes',
            'scope',
            'include',
            'staffing',
            'scope',
          ],
        },
        label: t('users.staff_prov'),
      },
      {
        id: 'phoneNumber',
        orderConfig: { orderBy: 'phoneNumber' },
        label: t('users.phoneNumber'),
      },
      {
        id: 'wmsLogin',
        orderConfig: { orderBy: 'wmsLogin' },
        label: t('users.wmsLogin'),
      },
      {
        id: 'shift.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['shift', 'scope'],
        },
        label: t('users.shiftId'),
      },
      {
        id: 'department.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['department', 'scope'],
        },
        label: t('users.departmentId'),
      },
      {
        id: 'supervisor',
        orderConfig: {
          orderBy: 'firstName',
          orderByInclusionPath: ['supervisor', 'scope'],
        },
        label: t('users.supervisorId'),
      },
      {
        id: 'createdAt',
        orderConfig: { orderBy: 'createdAt' },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
      email: {
        value: '',
        property: 'email',
        operator: 'like' as const,
      },
      activated: {
        value: '',
        property: 'activated',
        operator: 'eq' as const,
      },
      locked: {
        value: '',
        property: 'locked',
        operator: 'eq' as const,
      },
      role: {
        value: '',
        property: 'role',
        operator: 'eq' as const,
      },
      userAttributesSiteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: [
          'userAttributes',
          'scope',
          'include',
          'site',
          'scope',
        ] as InclusionPath,
      },
      userAttributesClientId: {
        value: '',
        property: 'clientId',
        operator: 'eq' as const,
        inclusionPath: ['userAttributes', 'scope'] as InclusionPath,
      },
      userAttributesStaffingId: {
        value: '',
        property: 'staffingId',
        operator: 'eq' as const,
        inclusionPath: ['userAttributes', 'scope'] as InclusionPath,
      },
      phoneNumber: {
        value: '',
        property: 'phoneNumber',
        operator: 'like' as const,
      },
      wmsLogin: {
        value: '',
        property: 'wmsLogin',
        operator: 'like' as const,
      },
      supervisorId: {
        value: '',
        property: 'supervisorId',
        operator: 'like' as const,
      },
      shiftId: {
        value: '',
        property: 'shiftId',
        operator: 'eq' as const,
      },
      departmentId: {
        value: '',
        property: 'departmentId',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    clearFilterFieldsData,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
    defaultWhere: where,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getUsersListForTable,
    selectCount: getUserCount,
    getEntitiesRequest: getUserListRequest,
    getEntitiesCountRequest: getUserCountRequest,
  });

  const onDelete = () => {
    clearFilterFieldsData();

    dispatch(
      deleteUserRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const onActivate = () => {
    dispatch(activateUserRequest(selectedEntitiesIds));
  };

  const onDeactivate = () => {
    dispatch(deactivateUserRequest(selectedEntitiesIds));
  };

  const onUnlock = () => {
    dispatch(unlockUserRequest(selectedEntitiesIds));
  };

  const onLock = () => {
    dispatch(lockUserRequest(selectedEntitiesIds));
  };

  const onResetPassword = () => {
    dispatch(resetPasswordByAdminRequest(selectedEntitiesIds));

    setSelectedEntitiesIds([]);

    setIsConfirmLogoutOpen(false);
  };

  const actionsConfiguration = [];
  if (allowedToActivate) {
    actionsConfiguration.push({
      tooltip: t('users.activate'),
      onClick: onActivate,
      icon: <UserCheckSvg />,
    });
  }
  if (allowedToDeactivate) {
    actionsConfiguration.push({
      tooltip: t('users.deactivate'),
      onClick: onDeactivate,
      icon: <UserXIconSvg />,
    });
  }
  if (allowedToUnlock) {
    actionsConfiguration.push({
      tooltip: t('users.unlock'),
      onClick: onUnlock,
      icon: <UnLockSvg />,
    });
  }
  if (allowedToLock) {
    actionsConfiguration.push({
      tooltip: t('users.lock'),
      onClick: onLock,
      icon: <LockSvg />,
    });
  }
  if (allowedToResetPassword) {
    actionsConfiguration.push({
      tooltip: t('users.reset_password'),
      onClick: () => setIsConfirmLogoutOpen(true),
      icon: <KeysSvg />,
    });
  }
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  React.useEffect(() => {
    fetchRolesCombobox();
    fetchShiftsCombobox();
    fetchClientsCombobox();
    fetchDepartmentsCombobox();
    fetchSupervisorsCombobox();
    fetchStaffingProviderCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('users.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    label={getLabel({
                      filterName: 'userAttributesClientId',
                      labelPrefix: t('users.client'),
                    })}
                    options={clientsOptions as any}
                    {...getFilterCommonPropsByFilterName(
                      'userAttributesClientId',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'userAttributesSiteId',
                      labelPrefix: t('users.site'),
                    })}
                    {...getFilterCommonPropsByFilterName(
                      'userAttributesSiteId',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <DepartmentsComboBoxFilter
                    label={getLabel({
                      filterName: 'departmentId',
                      labelPrefix: t('users.departmentId'),
                    })}
                    {...getFilterCommonPropsByFilterName('departmentId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    label={getLabel({
                      filterName: 'shiftId',
                      labelPrefix: t('users.shiftId'),
                    })}
                    options={shiftsOptions as any}
                    {...getFilterCommonPropsByFilterName('shiftId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    label={getLabel({
                      filterName: 'supervisorId',
                      labelPrefix: t('users.supervisorId'),
                    })}
                    options={supervisorsOptions as any}
                    {...getFilterCommonPropsByFilterName('supervisorId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    label={getLabel({
                      filterName: 'role',
                      labelPrefix: t('users.role'),
                    })}
                    options={rolesOptions as any}
                    {...getFilterCommonPropsByFilterName('role')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    label={getLabel({
                      filterName: 'userAttributesStaffingId',
                      labelPrefix: t('users.staff_prov'),
                    })}
                    options={staffingProvidersOptions as any}
                    {...getFilterCommonPropsByFilterName(
                      'userAttributesStaffingId',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'firstName',
                      labelPrefix: t('users.first_name'),
                    })}
                    {...getFilterCommonPropsByFilterName('firstName')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'lastName',
                      labelPrefix: t('users.last_name'),
                    })}
                    {...getFilterCommonPropsByFilterName('lastName')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'email',
                      labelPrefix: t('users.email'),
                    })}
                    {...getFilterCommonPropsByFilterName('email')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'wmsLogin',
                      labelPrefix: t('users.wmsLogin'),
                    })}
                    {...getFilterCommonPropsByFilterName('wmsLogin')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'phoneNumber',
                      labelPrefix: t('users.phoneNumber'),
                    })}
                    {...getFilterCommonPropsByFilterName('phoneNumber')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'activated',
                      labelPrefix: t('users.activated'),
                    })}
                    {...getFilterCommonPropsByFilterName('activated')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'locked',
                      labelPrefix: t('users.locked'),
                    })}
                    {...getFilterCommonPropsByFilterName('locked')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/users"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.Department}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, pageNumber) => setPage(pageNumber)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateUser
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateUsers
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        usersToUpdateIds={selectedEntitiesIds}
      />

      <ActionConfirmation
        type="success"
        open={isConfirmLogoutOpen}
        onCancel={() => setIsConfirmLogoutOpen(false)}
        onOk={() => onResetPassword()}
      >
        {t('users.confirm_reset_password')}
        {getUsersList(selectedEntitiesIds)}
      </ActionConfirmation>
    </PageContentWithTopToolbar>
  );
};
