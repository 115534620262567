import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import Api from 'src/modules/utils/Api';
import {
  AnyObject,
  ExportType,
  ICountResponse,
  IdsArray,
  IExportColumnsData,
  IExportResponse,
  IFilter,
  IFilterInclude,
  IQSJWT,
  WhereFilter,
} from 'src/modules/types';
import * as actions from 'src/modules/actions';
import { i18n } from 'src/modules/utils/i18n';
import {
  useDataFetcherWithData,
  useReportDefaultFilter,
} from './common/reports';
import {
  composeDate,
  DATE_FORMAT,
  format,
  sub,
} from 'src/modules/utils/dateWrapper';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  IActiveReportExportData,
  ReportPageContext,
} from 'src/components/ReportPage/ReportPageContext';
import {
  IHeadCell,
  IHeadCellOrderDetails,
  IHeadCellWithOrderConfig,
  IInclusionObject,
  Order,
} from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableCommonHandlers,
  useTableData,
} from './table';
import { useFetchReportTableDataAndCountEffect } from './reportPage.hooks';
import { usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration } from './payboards.hooks';

const exportFileTypes: [string, ExportType][] = [
  ['onExportCSV', 'csv'],
  ['onExportPDF', 'pdf'],
  ['onExportExcel', 'xls'],
  ['onExportEmail', 'email'],
];

const FUNCTION_NAME = 0;
const FILE_TYPE = 1;

export const useReportExportProps = (
  reportUrl: string,
  ignoreGlobalSites?: boolean,
) => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  const dispatcher = useDispatch();

  return exportFileTypes.reduce((acc, exportType) => {
    acc[exportType[FUNCTION_NAME]] = async ({
      filter,
      ...restColumnData
    }: IExportColumnsData) => {
      dispatcher(actions.setIsDataRequested(true));

      const exportData = {
        exportType: exportType[FILE_TYPE],
        ...restColumnData,
        filter: filter
          ? {
              ...filter,
              where: {
                ...filter.where,
                ...(!ignoreGlobalSites
                  ? { siteId: { inq: globallySelectedSiteIds } }
                  : {}),
              },
            }
          : undefined,
      };

      const { content, fileName }: IExportResponse = await Api.Report.export(
        reportUrl,
        exportData,
      );

      dispatcher(actions.setIsDataRequested(false));

      if (fileName) {
        fileDownload(content, fileName);
      }

      if (exportType[FILE_TYPE] === 'email') {
        dispatcher(
          actions.addProcessStatus({
            variant: 'success',
            message: i18n.t('export.email.success'),
          }),
        );
      }
    };
    return acc;
  }, {});
};

interface IUseByEmployeeDetailsReportDefaultFiltersArgs {
  employeeName: string;
  defaultOrderBy: string;
}
export const useByEmployeeDetailsReportDefaultFilters = ({
  employeeName,
  defaultOrderBy,
}: IUseByEmployeeDetailsReportDefaultFiltersArgs) => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderBy} desc`],
      ...reportDefaultFilter,
      where: {
        ...reportDefaultFilter.where,
        employee: { like: `%${employeeName}%` },
      },
    }),
    [defaultOrderBy, employeeName, reportDefaultFilter],
  );
};

export const useByEmployeeDetailsReportDefaultChartWhereFilter = ({
  siteId,
  employeeName,
}: {
  employeeName: string | null;
  siteId?: number;
}) => {
  const FOURTEEN_DAYS_AGO_DATE = composeDate(
    new Date(),
    sub({ days: 14 }),
    format(DATE_FORMAT),
  );

  return React.useMemo(
    () => ({
      where: {
        date: { gte: FOURTEEN_DAYS_AGO_DATE },
        siteId: { inq: [siteId] },
        employee: employeeName ? { like: `%${employeeName}%` } : undefined,
      },
    }),
    [FOURTEEN_DAYS_AGO_DATE, employeeName, siteId],
  );
};

export const useAddReportTableDataToReportExportStateEffect = (
  data: IActiveReportExportData | null,
) => {
  const { addActiveReportExportData } = React.useContext(ReportPageContext);

  React.useEffect(() => {
    if (data) {
      addActiveReportExportData({
        filter: data.filter,
        localization: data.localization,
      });
    } else {
      addActiveReportExportData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addActiveReportExportData, data?.filter, data?.localization]);
};

interface IUseBaseReportProps<T = AnyObject> {
  reportUrl: string;
  headCellsConfig: IHeadCellWithOrderConfig[];
  where: WhereFilter;
  include?: IFilterInclude[];
  inclusionObj?: IInclusionObject;
  defOrder?: Order;
  defOrderBy?: string;
  omitCount?: boolean;
  ignoreInitialRequest?: boolean;
  initialData?: T;
}

interface IUseDynamicBaseReportProps<T = AnyObject> {
  fetchData(filterList: IFilter & IQSJWT): Promise<void>;
  fetchCountData(filterList: IFilter & IQSJWT): Promise<void>;
  removeDataByIds(ids: IdsArray): Promise<void>;
  data: T;
  countData: ICountResponse;
  isDataLoading: boolean;
  isCountDataLoading: boolean;
  headCells: IHeadCell[];
  headCellsOrderDetails: IHeadCellOrderDetails[];
}

export const useDynamicBaseReport = <T extends object = AnyObject>({
  where,
  include,
  defOrder,
  defOrderBy,
  omitCount = false,
  ignoreInitialRequest = false,
  inclusionObj,
  fetchData,
  fetchCountData,
  data,
  countData,
  isDataLoading,
  isCountDataLoading,
  removeDataByIds,
  headCells,
  headCellsOrderDetails,
}: IUseBaseReportProps<T> & IUseDynamicBaseReportProps) => {
  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defOrder,
    defaultOrderBy: defOrderBy,
  });

  const filterFieldsConfiguration = usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    defaultWhere: where,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    include,
    fetchData,
    headCellsOrderDetails,
  });

  useFetchReportTableDataAndCountEffect({
    setPage,
    where,
    include,
    limit,
    order: order ?? defOrder,
    orderBy: orderBy ?? defOrderBy,
    ...(!ignoreInitialRequest ? { fetchData } : {}),
    ...(!omitCount && !ignoreInitialRequest ? { fetchCountData } : {}),
  });

  return {
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    headCells,
    order,
    orderBy,
    limit,
    page,
    requestFilters,
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    removeDataByIds,
    fetchData,
    filters: {
      getLabel,
      filterFields,
      onFiltersFormSubmit,
      getFilterCommonPropsByFilterName,
    },
  };
};

export const useBaseReport = <T extends object = AnyObject>({
  reportUrl,
  headCellsConfig,
  where,
  include,
  defOrder,
  defOrderBy,
  omitCount = false,
  ignoreInitialRequest = false,
  initialData = ([] as unknown) as T,
  inclusionObj,
}: IUseBaseReportProps<T>) => {
  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData<T>(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    include,
    defOrder,
    defOrderBy,
    omitCount,
    ignoreInitialRequest,
    initialData,
    inclusionObj,
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });
};
