import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg, Users01Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getPositionListRequest,
  getPositionCountRequest,
  deletePositionRequest,
  exportPositionRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  getIsPositionDataLoading,
  getPositionCount,
  getPositionList,
} from 'src/modules/selectors/position';
import { CreatePosition } from './PositionForms/CreatePosition';
import { UpdatePositions } from './PositionForms/UpdatePositions';
import { PositionsUpdateEmployeesNumber } from './PositionForms/PositionsUpdateEmployeesNumber';

export const PositionsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);
  const [
    isPositionUpdateEmployeesNumberVisible,
    setIsPositionUpdateEmployeesNumberVisible,
  ] = React.useState(false);

  const exportProps = useCreateExportProps(exportPositionRequest);

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const isDataLoading = useSelector(getIsPositionDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.position.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.position.update.id,
  );
  const allowedToUpdateDesiredEmployeesNumber = useHasUserAccessToAction(
    manageEntitiesConfig.position.updateDesiredEmployeesNumber.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.position.delete.id,
  );

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('positions.name'),
      },
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('positions.site'),
      },
      {
        id: 'desiredEmployeesNumber',
        orderConfig: { orderBy: 'desiredEmployeesNumber' },
        label: t('positions.desiredEmployeesNumber'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      desiredEmployeesNumber: {
        value: '',
        property: 'desiredEmployeesNumber',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getPositionList,
    selectCount: getPositionCount,
    getEntitiesRequest: getPositionListRequest,
    getEntitiesCountRequest: getPositionCountRequest,
  });

  const onDelete = () => {
    clearFilters();

    dispatch(
      deletePositionRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const onUpdateDesiredEmployeesNumberClick = () => {
    setIsPositionUpdateEmployeesNumberVisible(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdateDesiredEmployeesNumber) {
    actionsConfiguration.push({
      tooltip: t('departments.updateDesiredEmployeesNumber'),
      icon: <Users01Svg />,
      onClick: onUpdateDesiredEmployeesNumberClick,
    });
  }
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('positions.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('point_types.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'desiredEmployeesNumber',
                      labelPrefix: t('positions.desiredEmployeesNumber'),
                    })}
                    {...getFilterCommonPropsByFilterName(
                      'desiredEmployeesNumber',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'name',
                      labelPrefix: t('positions.name'),
                    })}
                    {...getFilterCommonPropsByFilterName('name')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/positions"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.Position}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreatePosition
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdatePositions
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        positionsToUpdateIds={selectedEntitiesIds}
      />

      <PositionsUpdateEmployeesNumber
        isOpen={isPositionUpdateEmployeesNumberVisible}
        onClose={() => setIsPositionUpdateEmployeesNumberVisible(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        entitiesToUpdateIds={selectedEntitiesIds}
      />
    </PageContentWithTopToolbar>
  );
};
