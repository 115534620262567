import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { fetchHRDashboardTerminatedByShiftData } from 'src/modules/utils/Api/hrDashboard';
import { PieChart } from 'src/components/Charts/2d/PieChart';
import { round } from 'lodash';
import {
  DoubledCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';
import {
  getLastNDaysStarEndDates,
  getYDTStarEndDates,
} from 'src/modules/utils/dateWrapper';

interface IHRDashboardTerminatedByShiftProps {
  siteId?: number;
}

export const HRDashboardTerminatedByShift = ({
  siteId,
}: IHRDashboardTerminatedByShiftProps) => {
  const { t } = useTranslation();

  const chartTypeOptions = React.useMemo(
    () => [
      { id: 'ydt' as const, label: t('common.ydt') },
      { id: 'last30days' as const, label: t('common.last_n_days', { n: 30 }) },
      { id: 'last7days' as const, label: t('common.last_n_days', { n: 7 }) },
    ],
    [t],
  );

  const [activeChartType, setActiveChartType] = React.useState<
    IAutocompleteOption<'ydt' | 'last30days' | 'last7days'>
  >(chartTypeOptions[0]);

  const ydt = getYDTStarEndDates({ suDaysFromEndDate: 1 });
  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRDashboardTerminatedByShiftData,
    {
      initialData: [],
    },
  );

  const series = React.useMemo(() => {
    const totalActiveEmployees = data.reduce((total, item) => {
      total += item.totalTerminatedEmployees;

      return total;
    }, 0);

    return [
      {
        type: 'pie' as const,
        innerSize: '40%',
        data: data.map((item) => ({
          name: item.shiftName,
          y: round(
            (item.totalTerminatedEmployees * 100) / totalActiveEmployees,
            2,
          ),
        })),
      },
    ];
  }, [data]);

  React.useEffect(() => {
    let startDate = ydt.startDateFormatted;
    let endDate = ydt.endDateFormatted;
    if (activeChartType.id === 'last30days') {
      startDate = last30Days.startDateFormatted;
      endDate = last30Days.endDateFormatted;
    }
    if (activeChartType.id === 'last7days') {
      startDate = last7Days.startDateFormatted;
      endDate = last7Days.endDateFormatted;
    }

    getData({
      siteId,
      separationdate: { between: [startDate, endDate] },
    });
  }, [
    getData,
    siteId,
    activeChartType,
    ydt.startDateFormatted,
    ydt.endDateFormatted,
    last7Days.startDateFormatted,
    last7Days.endDateFormatted,
    last30Days.startDateFormatted,
    last30Days.endDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('dashboard.terminated_employees_by_shift')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('terminated_by_shift')}
          />
          <DoubledCombobox>
            <RightCombobox
              disableClearable
              value={activeChartType}
              options={chartTypeOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveChartType(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <PieChart
          fullscreenTitle={t('dashboard.terminated_employees_by_shift')}
          ref={chartRef}
          height={chartHeight}
          series={series}
          plotOptions={{
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              showInLegend: true,
              dataLabels: {
                style: {
                  fontSize: '10px',
                },
                enabled: true,
                format: '<b>{point.y}</b>%',
                distance: '10%',
              },
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
