import React from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { AnyObject } from 'src/modules/types';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { PointCollectionType } from 'src/config';
import { ComboBoxOption } from 'src/components/ComboBox';

interface IQuestionCategoryTypeFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const QuestionCategoryTypeFilter: React.FC<IQuestionCategoryTypeFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  const pointCollectionTypesOptions = React.useMemo(
    () =>
      Object.values(PointCollectionType).map((p) => ({
        id: p,
        name: p,
      })),
    [],
  );

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'name',
            labelPrefix: t('question_category_type.name'),
          })}
          {...getFilterCommonPropsByFilterName('name')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ComboboxTableFilter
          options={(pointCollectionTypesOptions as unknown) as ComboBoxOption[]}
          label={getLabel({
            filterName: 'pointCollectionType',
            labelPrefix: t('question_category_type.pointCollectionType'),
          })}
          {...getFilterCommonPropsByFilterName('pointCollectionType')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('question_category_type.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
