import React from 'react';
import { filter, isEmpty, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdatePointType } from '../types';
import { Tooltip } from '@mui/joy';

const SHORT_REASON_LENGTH = 50;

export const getPointTypeRefreshKey = (state: IStoreState) =>
  state.pointType.refreshKey;

export const getPointTypeData = (state: IStoreState) => state.pointType;

/**
 * Get server error
 * @param pointType - State pointType
 */
export const getServerError = ({ pointType }: IStoreState) => pointType.error;

/**
 * Get pointType list
 * @param pointType - State pointType
 */
export const getPointTypeList = ({ pointType }: IStoreState) => pointType.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param pointType - State pointType
 */
export const getPointTypesTable = createSelector(
  getPointTypeList,
  (pointTypes) => map(filter(pointTypes, (s) => !isEmpty(s.site))),
);

export const getPointTypesTableParsedData = createSelector(
  getPointTypesTable,
  (list) =>
    map(list, (item) => ({
      ...item,
      reason:
        item.reason && item.reason?.length > SHORT_REASON_LENGTH ? (
          <Tooltip title={item.reason}>
            <span>{item.reason.substring(0, SHORT_REASON_LENGTH) + '...'}</span>
          </Tooltip>
        ) : (
          item.reason
        ),
    })),
);

/**
 * Get pointTypes by array of ids
 * @param pointType - State pointType
 */
export const getPointTypesByIds = createSelector(
  getPointTypeList,
  (pointTypes) => (ids: IdsArray) => {
    const initial: IUpdatePointType[] = [];
    return reduce(
      pointTypes,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const pointTypeData = omit(cur, ['site']);
          acc.push(pointTypeData);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get pointType count
 * @param pointType - State pointType
 */
export const getPointTypeCount = ({ pointType }: IStoreState) =>
  pointType.count;

/**
 * Get combobox list
 */
export const getPointTypesComboboxList = ({ pointType }: IStoreState) =>
  map(pointType.comboboxList, (pointTypeItem) => ({
    id: pointTypeItem.id,
    name: pointTypeItem.reason,
    siteId: pointTypeItem.site?.id,
    site: pointTypeItem.site,
  }));

export const getIsGetPointTypeComboboxListRequestInProgress = createSelector(
  getPointTypeData,
  ({ isGetPointTypeComboboxListRequestInProgress }) =>
    isGetPointTypeComboboxListRequestInProgress,
);

export const getIsPointTypeLoading = createSelector(
  getPointTypeData,
  (data) => {
    return (
      data.isPointTypeDataLoading ||
      data.isPointTypeDataCountLoading ||
      data.isPointTypeDataManaging
    );
  },
);
