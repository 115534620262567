import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  DataGridProps,
  GridRowModesModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModel,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { Box, useTheme } from '@mui/joy';
import { IPaginationProps, ITableHeadProps, Pagination } from '../Table';
import { Edit02Svg, SaveSvg, Trash04Svg, XCloseSvg } from '../svgIcons';
import { useTranslation } from 'react-i18next';
import { ISortDetails, Order } from 'src/modules/types/table';
import { MainContentLoader } from '../Layout/components/PageTour/MainContentLoader';
import { NoData } from '../NoData';
import { SxProps } from '@mui/joy/styles/types';
import { TableWithActionsDeleteModal } from '../TableWithActions/components/TableWithActionsDeleteModal';

type DataGridNgroupTableCommonProps = DataGridProps & {
  isDataLoading: boolean;
  containerSx?: SxProps;
  sortProps?: ISortDetails & Pick<ITableHeadProps, 'onSort'>;
  onRowDelete?: (id: GridRowId) => void;
  onProcessRowUpdate?: (data: GridRowModel) => void;
};

interface IDataGridNgroupTableWithPaginationProps
  extends DataGridNgroupTableCommonProps {
  disablePagination: false;
  paginationProps: IPaginationProps;
}
interface IDataGridNgroupTableWithoutPaginationProps
  extends DataGridNgroupTableCommonProps {
  disablePagination: true;
  paginationProps?: undefined;
}

type DataGridNgroupTableProps =
  | IDataGridNgroupTableWithPaginationProps
  | IDataGridNgroupTableWithoutPaginationProps;

export const DataGridNgroupTable = ({
  sx,
  containerSx,
  sortProps,
  paginationProps,
  rows = [],
  columns = [],
  isDataLoading = false,
  disableColumnMenu = true,
  disablePagination = false,
  disableRowSelectionOnClick = true,
  onRowDelete,
  onProcessRowUpdate,
  ...rest
}: DataGridNgroupTableProps) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const iconStyle = React.useMemo(() => ({ height: 20, width: 20 }), []);

  const [itemToDeleteId, setItemToDeleteId] = React.useState<null | GridRowId>(
    null,
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel],
  );

  const handleSaveClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel],
  );

  const handleDeleteClick = (id: GridRowId) => () => {
    setItemToDeleteId(id);
  };

  const handleDeleteConfirm = () => {
    if (onRowDelete && itemToDeleteId) {
      onRowDelete(itemToDeleteId);
    }

    setItemToDeleteId(null);
  };

  const handleCancelClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew && onRowDelete) {
        onRowDelete(editedRow.id);
      }
    },
    [onRowDelete, rowModesModel, rows],
  );

  const processRowUpdate = (rowData: GridRowModel) => {
    const updatedRow = { ...rowData, isNew: rowData.isNew ?? false };

    if (onProcessRowUpdate) {
      onProcessRowUpdate(updatedRow);
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actionsColumn: GridColDef = React.useMemo(
    () => ({
      field: 'actions',
      type: 'actions',
      headerName: t('common.actions'),
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        const actions = [];

        if (isInEditMode) {
          if (onProcessRowUpdate) {
            actions.push(
              <GridActionsCellItem
                key={'1'}
                icon={<SaveSvg style={iconStyle} />}
                label={t('common.save')}
                onClick={handleSaveClick(id)}
              />,
            );
          }

          actions.push(
            <GridActionsCellItem
              key={'2'}
              icon={<XCloseSvg style={iconStyle} />}
              label={t('common.cancel')}
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          );
        } else {
          if (onProcessRowUpdate) {
            actions.push(
              <GridActionsCellItem
                key={'1'}
                icon={<Edit02Svg style={iconStyle} />}
                label={t('common.edit')}
                onClick={handleEditClick(id)}
                color="inherit"
              />,
            );
          }
          if (onRowDelete) {
            actions.push(
              <GridActionsCellItem
                key={'2'}
                icon={<Trash04Svg style={iconStyle} />}
                label={t('common.delete')}
                onClick={handleDeleteClick(id)}
              />,
            );
          }
        }

        return actions;
      },
    }),
    [
      handleCancelClick,
      handleEditClick,
      handleSaveClick,
      iconStyle,
      onProcessRowUpdate,
      onRowDelete,
      rowModesModel,
      t,
    ],
  );

  const columnsToRender = React.useMemo(() => {
    return onProcessRowUpdate || onRowDelete
      ? [...columns, actionsColumn]
      : columns;
  }, [actionsColumn, columns, onProcessRowUpdate, onRowDelete]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        maxHeight: '100%',
        ...containerSx,
      }}
    >
      {isDataLoading && <MainContentLoader />}

      <DataGrid
        rows={rows}
        columns={columnsToRender}
        editMode="row"
        sortingMode="server"
        rowModesModel={rowModesModel}
        disableColumnMenu={disableColumnMenu}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowModesModelChange={handleRowModesModelChange}
        onSortModelChange={(data) => {
          if (sortProps?.onSort && data.length) {
            sortProps.onSort(data[0].field, data[0].sort as Order);
          }
        }}
        initialState={{
          sorting:
            sortProps && sortProps.order && sortProps.orderBy
              ? {
                  sortModel: [
                    { field: sortProps.orderBy, sort: sortProps.order },
                  ],
                }
              : undefined,
        }}
        slots={{
          pagination: () => {
            if (disablePagination || !paginationProps) {
              return null;
            }

            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Pagination {...paginationProps} />
              </Box>
            );
          },
          noRowsOverlay: () => (
            <Box
              sx={{
                minWidth: '100%',
                minHeight: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                backgroundColor: 'transparent',
              }}
            >
              <NoData
                description={t('common.no_data_in_grid')}
                sx={{ bgcolor: 'transparent' }}
              />
            </Box>
          ),
        }}
        sx={{
          height: 'auto',
          maxHeight: '100%',
          flex: 1,
          overflow: 'hidden',
          minHeight: !isDataLoading ? '100%' : '0',
          fontFamily:
            'var(--ngroup-fontFamily-body, "Inter", var(--ngroup-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
          borderRadius: 'var(--ngroup-radius-md)',
          borderColor: 'var(--ngroup-palette-colors-border-border_primary)',

          '& .MuiDataGrid-main': {
            overflow: 'auto',
          },

          '& .MuiDataGrid-columnHeaders': {
            minHeight: '45px !important',
            maxHeight: '45px !important',
            backgroundColor: '#fff',
            borderColor: '#EAECF0',
            borderTopRightRadius: 'var(--ngroup-radius-md)',
            borderTopLeftRadius: 'var(--ngroup-radius-md)',
          },

          '& .MuiDataGrid-columnHeader': {
            minHeight: '45px !important',
            maxHeight: '45px !important',
          },

          '& .MuiDataGrid-virtualScroller': {
            overflow: 'visible',
          },

          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            boxShadow: theme.shadow.sm,
            border:
              '1px solid var(--ngroup-palette-colors-border-border_primary)',
          },

          '& .MuiDataGrid-row': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: '#F9FAFB',
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#fff',
          },
          '& .MuiDataGrid-row:last-child': {
            borderBottomRightRadius: 'var(--ngroup-radius-md)',
            borderBottomLeftRadius: 'var(--ngroup-radius-md)',
          },
          '& .MuiDataGrid-row--editing': {
            boxShadow: 'none',
          },

          '& .MuiDataGrid-cell': {
            borderRadius: 'var(--ngroup-radius-md)',
            borderColor: 'transparent',
            bgcolor: 'transparent',
            minHeight: '46px !important',
            maxHeight: '46px !important',
          },

          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing ': {
            boxShadow: 'none',
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 'var(--Typography-fontSize, 0.75rem)',
            lineHeight: '1.125rem',
            fontWeight: 'var(--ngroup-fontWeight-semi_bold) !important',
            color: 'var(--ngroup-palette-colors-text-text_tertiary-main)',
          },

          '& .MuiDataGrid-cellContent': {
            fontSize: 'var(--Typography-fontSize, 0.875rem)',
            lineHeight: '1.25rem',
            fontWeight: 'var(--ngroup-fontWeight-regular)',
            color: 'var(--ngroup-palette-colors-text-text_tertiary-main)',
          },

          '& .MuiDataGrid-footerContainer': {
            borderColor: 'transparent',
            borderBottomRightRadius: 'var(--ngroup-radius-md)',
            borderBottomLeftRadius: 'var(--ngroup-radius-md)',
          },

          '& .MuiTablePagination-selectLabel': {
            fontSize: 'var(--Typography-fontSize, 0.75rem)',
            lineHeight: '1.125rem',
            fontWeight: 'var(--ngroup-fontWeight-regular)',
            color: 'var(--ngroup-palette-gray-600)',
          },

          '& .MuiTablePagination-displayedRows': {
            fontSize: 'var(--Typography-fontSize, 0.75rem)',
            lineHeight: '1.125rem',
            fontWeight: 'var(--ngroup-fontWeight-regular)',
            color: 'var(--ngroup-palette-gray-600)',
          },

          '& .MuiTablePagination-select': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 'var(--ngroup-fontWeight-regular)',
          },

          ...sx,
        }}
        {...rest}
      />

      <TableWithActionsDeleteModal
        open={Boolean(itemToDeleteId)}
        onOk={handleDeleteConfirm}
        onCancel={() => setItemToDeleteId(null)}
      />
    </Box>
  );
};
