import React from 'react';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchHRDashboardTerminationReasonsBySiteAndShift } from 'src/modules/utils/Api/hrDashboard';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { HorizontalBarChart } from 'src/components/Charts/2d/HorizontalBarChart';
import {
  DoubledCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';
import {
  getLastNDaysStarEndDates,
  getYDTStarEndDates,
} from 'src/modules/utils/dateWrapper';

interface IHRDashboardTerminationReasonProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardTerminationReason = ({
  siteId,
  shiftId,
}: IHRDashboardTerminationReasonProps) => {
  const { t } = useTranslation();

  const chartTypeOptions = React.useMemo(
    () => [
      { id: 'ydt' as const, label: t('common.ydt') },
      { id: 'last30days' as const, label: t('common.last_n_days', { n: 30 }) },
      { id: 'last7days' as const, label: t('common.last_n_days', { n: 7 }) },
    ],
    [t],
  );

  const [activeChartType, setActiveChartType] = React.useState<
    IAutocompleteOption<'ydt' | 'last30days' | 'last7days'>
  >(chartTypeOptions[0]);

  const ydt = getYDTStarEndDates({ suDaysFromEndDate: 1 });
  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const {
    data: reasonsBySite,
    getData: getReasonsBySite,
    isLoading: isReasonsBySiteLoading,
  } = useGetDataForCharts(fetchHRDashboardTerminationReasonsBySiteAndShift, {
    initialData: [],
  });

  const categories = React.useMemo(() => {
    const reasonsSet = new Set(
      reasonsBySite.map((reason) => reason.terminationReason),
    );
    return [...reasonsSet];
  }, [reasonsBySite]);

  const series = React.useMemo(() => {
    let data = reasonsBySite
      .filter((reason) => reason.shiftId === shiftId)
      .map((reason) => reason.employeesCount);

    // If shiftId filter not provided
    // we sum up employees quantity by site and termination reason
    if (!shiftId) {
      const totalCountBySiteData = reasonsBySite.reduce<
        Array<{ siteId: number; count: number; terminationReason: string }>
      >((all, reasonDetails) => {
        const existedDataIndex = all.findIndex(
          (item) =>
            item.siteId === reasonDetails.siteId &&
            item.terminationReason === reasonDetails.terminationReason,
        );

        if (existedDataIndex !== -1) {
          all[existedDataIndex].count =
            all[existedDataIndex].count + reasonDetails.employeesCount;
        } else {
          all.push({
            siteId: reasonDetails.siteId,
            count: reasonDetails.employeesCount,
            terminationReason: reasonDetails.terminationReason,
          });
        }

        return all;
      }, []);

      data = totalCountBySiteData.map((item) => item.count);
    }

    return [
      {
        data,
        name: t('dashboard.employees_count'),
        type: 'bar' as const,
      },
    ];
  }, [reasonsBySite, shiftId, t]);

  React.useEffect(() => {
    let startDate = ydt.startDateFormatted;
    let endDate = ydt.endDateFormatted;
    if (activeChartType.id === 'last30days') {
      startDate = last30Days.startDateFormatted;
      endDate = last30Days.endDateFormatted;
    }
    if (activeChartType.id === 'last7days') {
      startDate = last7Days.startDateFormatted;
      endDate = last7Days.endDateFormatted;
    }

    getReasonsBySite({
      siteId,
      shiftId,
      separationdate: { between: [startDate, endDate] },
    });
  }, [
    getReasonsBySite,
    siteId,
    shiftId,
    activeChartType,
    ydt.startDateFormatted,
    ydt.endDateFormatted,
    last7Days.startDateFormatted,
    last7Days.endDateFormatted,
    last30Days.startDateFormatted,
    last30Days.endDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.termination_reasons')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`termination_reasons`)}
          />
          <DoubledCombobox>
            <RightCombobox
              disableClearable
              value={activeChartType}
              options={chartTypeOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveChartType(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isReasonsBySiteLoading}
        showNoData={!reasonsBySite.length}
      >
        <HorizontalBarChart
          fullscreenTitle={t('dashboard.termination_reasons')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
          legend={{ enabled: false }}
          yAxis={{
            allowDecimals: false,
            title: {
              text: '',
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
