import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from '../../config';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { AnyObject, IListWithSummary } from '../../modules/types';
import { IHeadCellWithOrderConfig, Order } from '../../modules/types/table';
import {
  useGenerateHeadCellsData,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  siteId?: number;
  yearWeek?: number;
  staffingId?: number;
}

export const PayboardExport: React.FC<IProps> = ({
  yearWeek,
  siteId,
  staffingId,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      yearWeek,
      staffingId,
    }),
    [staffingId, siteId, yearWeek],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >('/sony/payboard/weekly-kronos', {
    items: [],
    summary: [],
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'socialSecurityNumber',
        orderConfig: { orderBy: 'extEmployeeId' },
        label: t('payboard.socialSecurityNumber'),
      },
      {
        id: 'company',
        orderConfig: { orderBy: 'company' },
        label: t('payboard.company'),
      },
      {
        id: 'orderBranch',
        orderConfig: { orderBy: 'orderBranch' },
        label: t('payboard.orderBranch'),
      },
      {
        id: 'order',
        orderConfig: { orderBy: 'department' },
        label: t('payboard.order'),
      },
      {
        id: 'assignment',
        orderConfig: { orderBy: 'assignment' },
        label: t('payboard.assignment'),
      },
      {
        id: 'wageCode',
        orderConfig: { orderBy: 'wageCode' },
        label: t('payboard.wageCode'),
      },
      {
        id: 'totalEarnedHours',
        orderConfig: { orderBy: 'totalEarnedHours' },
        label: t('payboard.totalEarnedHours'),
      },
      {
        id: 'totalActualHours',
        orderConfig: { orderBy: 'totalActualHours' },
        label: t('payboard.totalActualHours'),
      },
      {
        id: 'totalOvertimeHours',
        orderConfig: { orderBy: 'totalOvertimeHours' },
        label: t('payboard.totalOvertimeHours'),
      },
      {
        id: 'totalDoubleTimeHours',
        orderConfig: { orderBy: 'totalDoubleTimeHours' },
        label: t('payboard.totalDoubleTimeHours'),
      },
      {
        id: 'payRate',
        orderConfig: { orderBy: 'payRate' },
        label: t('payboard.payRate'),
      },
      {
        id: 'equivalentBillRate',
        orderConfig: { orderBy: 'equivalentBillRate' },
        label: t('payboard.equivalentBillRate'),
      },
      {
        id: 'workWeek',
        orderConfig: { orderBy: 'workWeek' },
        label: t('payboard.workWeek'),
      },
      {
        id: 'deductionCode',
        orderConfig: { orderBy: 'deductionCode' },
        label: t('payboard.deductionCode'),
      },
      {
        id: 'deductionAmount',
        orderConfig: { orderBy: 'deductionAmount' },
        label: t('payboard.deductionAmount'),
      },
      {
        id: 'userDefined1',
        orderConfig: { orderBy: 'userDefined1' },
        label: t('payboard.userDefined1'),
      },
      {
        id: 'employeeId',
        orderConfig: { orderBy: 'employeeID' },
        label: t('payboard.employee_id'),
      },
      {
        id: 'userDefined3',
        orderConfig: { orderBy: 'userDefined3' },
        label: t('payboard.userDefined3'),
      },

      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('payboard.last_name'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('payboard.first_name'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy: headCellsConfigItemId,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);
  };

  let orderBy: string | undefined = undefined;
  const configItem = headCellsConfig.find(
    (item) => item.id === headCellsConfigItemId,
  );
  if (configItem?.orderConfig) {
    orderBy = configItem.orderConfig.orderBy;
  }

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        where,
        order: [
          `${orderBy ?? defaultOrderDetails.orderBy} ${
            order ?? defaultOrderDetails.order
          }`,
        ],
      }),
      [
        defaultOrderDetails.order,
        defaultOrderDetails.orderBy,
        order,
        orderBy,
        where,
      ],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title) ?? item.title;
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  React.useEffect(() => {
    fetchData({
      filter: {
        where,
        order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
      },
    });
  }, [fetchData, order, orderBy, where]);

  return (
    <ReportTable
      data={data.items as any}
      summaryData={data.summary}
      isDataLoading={isDataLoading}
      tableUniqueKey={paths.TARGET_DAILY_CPU}
      headCells={headCells}
      order={order}
      orderBy={headCellsConfigItemId}
      disablePagination={true}
      onSort={handleSort}
    />
  );
};
