import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import {
  deleteQuestionRequest,
  exportQuestionRequest,
  getQuestionCountRequest,
  getQuestionListRequest,
} from 'src/modules/actions';
import {
  getIsQuestionsDataLoading,
  getQuestionCount,
  getQuestionList,
} from 'src/modules/selectors/question';
import { getQuestionCategoriesComboboxList } from 'src/modules/selectors/questionCategory';
import { IdsArray } from 'src/modules/types';
import { useCreateExportProps, useFilter } from 'src/modules/utils';
import { useQuestionDefaultFilters } from 'src/modules/utils/hooks/question';
import { useFetchQuestionCategoriesCombobox } from 'src/modules/utils/hooks/questionCategory';
import { CreateQuestion, questionTypeNamesMapper } from './CreateQuestion';
import { QuestionsUpdate } from './QuestionsUpdate';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';

export const Questions = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const fetchQuestionCategoriesCombobox = useFetchQuestionCategoriesCombobox();

  const [
    isCreateQuestionFormVisible,
    setIsCreateQuestionFormVisible,
  ] = React.useState(false);
  const [
    isUpdateQuestionsPointFormVisible,
    setIsUpdateQuestionsFormVisible,
  ] = React.useState(false);

  const [selectedQuestionsIds, setSelectedQuestionsIds] = React.useState<
    IdsArray
  >([]);

  const hasUserAccessToUpdateQuestions = useHasUserAccessToAction(
    manageEntitiesConfig.question.update.id,
  );
  const hasUserAccessToCreateQuestions = useHasUserAccessToAction(
    manageEntitiesConfig.question.create.id,
  );
  const hasUserAccessToDeleteQuestions = useHasUserAccessToAction(
    manageEntitiesConfig.question.delete.id,
  );

  const defaultFilter = useQuestionDefaultFilters();

  const { filterCount, filterList } = useFilter(defaultFilter);

  const exportProps = useCreateExportProps(exportQuestionRequest);

  const isTableDataLoading = useSelector(getIsQuestionsDataLoading);

  const list = useSelector(getQuestionList);
  const count = useSelector(getQuestionCount);

  const questionCategoryOptions = useSelector(
    getQuestionCategoriesComboboxList,
  );

  const questionCategoryOptionsWithSites = questionCategoryOptions.map(
    (option) => ({ ...option, name: `${option.name} (${option.site.name})` }),
  );

  const parsedList = React.useMemo(() => {
    return list.map((item) => ({
      ...item,
      questionType:
        item.questionType === 'options'
          ? questionTypeNamesMapper.options
          : questionTypeNamesMapper.text,
    }));
  }, [list]);

  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('question.name') },
    {
      id: 'questionCategory.name',
      disablePadding: false,
      label: t('question_category.name'),
    },
    {
      id: 'questionType',
      disablePadding: false,
      label: t('question.question_type'),
    },
  ];

  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('question.name'),
      operator: 'like',
    },
    {
      name: 'questionCategory.id',
      label: t('question.question_category_name'),
      operator: 'eq',
      type: 'combobox',
      options: questionCategoryOptionsWithSites,
    },
  ];

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatch(
      getQuestionListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );

    dispatch(
      getQuestionCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  const handleDeletion = (ids: IdsArray) => {
    dispatch(
      deleteQuestionRequest({
        data: ids,
        filters: { list: filterList.filter ?? {}, count: filterCount.filter },
      }),
    );
  };

  const handleUpdating = (ids: IdsArray) => {
    setSelectedQuestionsIds(ids);
    setIsUpdateQuestionsFormVisible(true);
  };

  React.useEffect(() => {
    fetchQuestionCategoriesCombobox();

    dispatch(getQuestionListRequest(filterList));
    dispatch(getQuestionCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EnhancedTable
        data={parsedList}
        count={count}
        selectIndex="id"
        disableQsFilters
        tableName={t('question.table_name')}
        isTableDataLoading={isTableDataLoading}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteQuestions ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateQuestions ? handleUpdating : undefined}
        include={defaultFilter.include}
        exportProps={exportProps}
        deleteModelName={ModelsToDelete.Question}
        createEntityBtnProps={
          hasUserAccessToCreateQuestions
            ? {
                title: t('question.create'),
                onClick: () => setIsCreateQuestionFormVisible((prev) => !prev),
              }
            : undefined
        }
      />

      <>
        <CreateQuestion
          isOpen={isCreateQuestionFormVisible}
          onClose={() => setIsCreateQuestionFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <QuestionsUpdate
          questionsIds={selectedQuestionsIds}
          isOpen={isUpdateQuestionsPointFormVisible}
          onClose={() => setIsUpdateQuestionsFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />
      </>
    </>
  );
};
