import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { createQuestionScheme } from 'src/modules/schemes/question/create-question.scheme';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { useFetchQuestionCategoriesCombobox } from 'src/modules/utils/hooks/questionCategory';
import { getQuestionCategoriesComboboxList } from 'src/modules/selectors/questionCategory';
import { ComboBoxOption } from 'src/components/ComboBox';
import { IFilterData, IWhere } from 'src/modules/types';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { createQuestionRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';

export const questionTypeNamesMapper = {
  options: 'Yes/No/Not Applicable',
  text: 'Text',
};

const initialValues = {
  questionCategoryId: NaN,
  name: '',
  questionType: 'options',
};

interface ICreateQuestionProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateQuestion = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateQuestionProps) => {
  const { t } = useTranslation();

  const fetchQuestionCategoriesCombobox = useFetchQuestionCategoriesCombobox();

  const questionCategoryOptions = useSelector(
    getQuestionCategoriesComboboxList,
  );
  const questionCategoryOptionsWithSites = questionCategoryOptions.map(
    (option) => ({ ...option, name: `${option.name} (${option.site.name})` }),
  );

  const dispatcher = useDispatch();

  const validate = useValidate(createQuestionScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(
        createQuestionRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      formik.resetForm();

      onClose();
    },
  });

  const questionTypeOptions = React.useMemo(
    () => [
      { id: 'options', name: questionTypeNamesMapper.options },
      { id: 'text', name: questionTypeNamesMapper.text },
    ],
    [],
  );

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    fetchQuestionCategoriesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('question.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikTextField
              required
              fullWidth
              id="name"
              label={t('question.name')}
              placeholder={t('common.add_your_text_here')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              id="questionType"
              required={true}
              formik={formik}
              label={t('question.question_type')}
              placeholder={t('common.select')}
              options={(questionTypeOptions as unknown) as ComboBoxOption[]}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              id="questionCategoryId"
              required={true}
              formik={formik}
              label={t('question_category.name')}
              placeholder={t('common.select')}
              options={questionCategoryOptionsWithSites}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
