import React from 'react';
import { SonyInventoryTotalChart } from './SonyInventoryTotalChart';
import { SonyInventoryChart } from './SonyInventoryChart';
import {
  ChartsDashboard,
  ChartsDashboardChart,
} from 'src/components/ChartsDashboard';

interface ISonyInventoryProps {
  startDate?: string;
  endDate?: string;
}

export const SonyInventory = (props: ISonyInventoryProps) => {
  return (
    <ChartsDashboard>
      <ChartsDashboardChart>
        <SonyInventoryTotalChart {...props} />
      </ChartsDashboardChart>
      <ChartsDashboardChart>
        <SonyInventoryChart {...props} />
      </ChartsDashboardChart>
    </ChartsDashboard>
  );
};
