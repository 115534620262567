import './assets/css/index.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import { map } from 'lodash';
import ReactDOM from 'react-dom';
import { ScrollToTop, PrivateRoute } from './components';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import CompleteProfile from './pages/CompleteProfile';
import ConfirmForgotPassword from './pages/ConfirmForgotPassword';
import Error404 from './pages/Error404';
import App from './App';
import { getUserObjectFromStorage } from './modules/utils/user';
import * as serviceWorker from './serviceWorker';
import { paths } from './config';
import { getLandingRouteBasedOnRole } from './modules/utils/helpers/route.helper';
import { privateRoutes } from './config/privateRoutes';
import FinancialCharts from './pages/FinancialTracker/FinancialCharts';
import { PackagesInitializer } from './components/PackagesInitializer';
import { ProductionDashboard } from './pages/ProductionDashboard';

// default redirect to employees list from /
const MainPageRedirect = () => {
  // unfortunately we cannot use hooks here to fetch current user role
  // but we can get it from localStorage directly
  const { auth: { role = '' } = {} } = getUserObjectFromStorage();

  // default route might differ dependently on a user role
  const landingRoute: string = getLandingRouteBasedOnRole(role);

  return <Navigate to={landingRoute} />;
};

ReactDOM.render(
  <App>
    <PackagesInitializer>
      <ScrollToTop>
        <Routes>
          <Route path={paths.ROOT} element={<MainPageRedirect />} />

          <Route path={paths.LOGIN} element={<Login />} />

          <Route path={paths.FORGOT_PASSWORD} element={<ForgotPassword />} />

          <Route path={paths.FINANCIAL_CHARTS} element={<FinancialCharts />} />

          <Route
            path={paths.FORGOT_PASSWORD_RESET_TOKEN}
            element={<ConfirmForgotPassword />}
          />

          <Route
            path={paths.COMPLETE_PROFILE_ACTIVATE_TOKEN}
            element={<CompleteProfile />}
          />

          <Route
            path={paths.PRODUCTION_DASHBOARD}
            element={<ProductionDashboard />}
          />

          {/** --------------- Private routes --------------- */}
          <Route path="*" element={<PrivateRoute />}>
            {map(privateRoutes, ({ element }, path) => (
              <Route key={path} path={preparePath(path)} element={element} />
            ))}

            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </PackagesInitializer>
  </App>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function preparePath(path: string) {
  return path.replace('/', '');
}
