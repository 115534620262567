import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateSkillRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  ISkillModel,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new skill
 */
export const createSkillRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateSkillRequest>>): SagaIterator {
  try {
    const response: ISkillModel = yield call(Api.Skill.create, data);

    yield put(actions.getSkillListRequest({ filter: list }));

    yield put(actions.getSkillCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createSkillSuccess(response));
  } catch (e) {
    yield put(actions.createSkillFailed());
  }
};

/**
 * Get skill list
 */
export const getSkillListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ISkillModel[] = yield call(Api.Skill.list, action.payload);
    yield put(actions.getSkillListSuccess(response));
  } catch (e) {
    yield put(actions.getSkillListFailed());
  }
};

/**
 * Get skill count
 */
export const getSkillCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Skill.count,
      action?.payload,
    );
    yield put(actions.getSkillCountSuccess(response));
  } catch (e) {
    yield put(actions.getSkillCountFailed());
  }
};

/**
 * Delete skills
 */
export const deleteSkillRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Skill.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getSkillListRequest({ filter: list }));

    yield put(actions.getSkillCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteSkillSuccess());
  } catch (e) {
    yield put(actions.deleteSkillFailed());
  }
};

/**
 * Bulk update skills
 */
export const updateSkillsRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ISkillModel[]>>): SagaIterator {
  try {
    yield call(Api.Skill.bulkUpdate, data);

    yield put(actions.getSkillListRequest({ filter: list }));

    yield put(actions.getSkillCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateSkillsSuccess());
  } catch (e) {
    yield put(actions.updateSkillsFailed());
  }
};

/**
 * Get combobox list
 */
export const getSkillComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<ISkillModel>[] = yield call(
      Api.Skill.list,
      action.payload,
    );
    yield put(actions.getSkillComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getSkillComboboxListFailed());
  }
};

/**
 * Export
 */
export const {
  exportRequestSaga: exportSkillRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Skill.export,
  actionFailed: actions.exportSkillFailed,
  actionSuccess: actions.exportSkillSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createSkillRequest, saga: createSkillRequestSaga },
  { action: actions.getSkillListRequest, saga: getSkillListRequestSaga },
  { action: actions.getSkillCountRequest, saga: getSkillCountRequestSaga },
  { action: actions.deleteSkillRequest, saga: deleteSkillRequestSaga },
  // bulk updating skills
  { action: actions.updateSkillsRequest, saga: updateSkillsRequestSaga },
  {
    action: actions.getSkillComboboxListRequest,
    saga: getSkillComboboxListRequestSaga,
  },
  { action: actions.exportSkillRequest, saga: exportSkillRequestSaga },
];
