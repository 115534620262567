import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isArray from 'lodash/isArray';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { setIsDataRequested } from '../../../actions';
import Api from '../../Api';

import {
  IdsArray,
  IFilter,
  IFilterWhereValue,
  IQSJWT,
  SummaryInfo,
} from '../../../types';
import {
  composeDate,
  DATE_FORMAT,
  format,
  isBefore,
  parse,
} from '../../dateWrapper';
import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';

export interface ISignalDict {
  signalList?: AbortSignal;
  signalCount?: AbortSignal;
}

export type FetchedData<T> = {
  items: T[];
  count: number;
  summary?: SummaryInfo[];
};

export type FetchedRawData<T> = {
  items: T[];
  count: number;
};

export const useReportDefaultFilter = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({ where: { siteId: { inq: globallySelectedSiteIds } } }),
    [globallySelectedSiteIds],
  );
};

export const useStateOnMountedOnly = <T,>(initialState: T) => {
  const shouldUpdate = React.useRef<boolean>(true);
  const [data, _setData] = React.useState(initialState);

  React.useEffect(
    () => () => {
      shouldUpdate.current = false;
    },
    [],
  );

  const setData = React.useCallback((data: React.SetStateAction<T>) => {
    if (!shouldUpdate.current) {
      return;
    }

    _setData(data);
  }, []);

  return { data, setData };
};

export const useFetchReport = <T,>(reportUrl: string) => {
  const {
    data: isDataLoading,
    setData: setIsDataLoading,
  } = useStateOnMountedOnly(false);

  const { data, setData } = useStateOnMountedOnly<FetchedData<T>>({
    items: [],
    count: 0,
    summary: [],
  });

  const fetchData = React.useCallback(
    async (
      filterList: IFilter & IQSJWT,
      filterCount: IFilter & IQSJWT,
      abortConfig?: ISignalDict,
    ) => {
      setIsDataLoading(true);

      try {
        const [data, countData] = await Promise.all([
          Api.Report.list<T>(reportUrl, filterList, {
            signal: abortConfig?.signalList,
          }),
          Api.Report.count(reportUrl, filterCount, {
            signal: abortConfig?.signalCount,
          }),
        ]);

        if (
          abortConfig?.signalList?.aborted ||
          abortConfig?.signalCount?.aborted
        ) {
          return;
        }

        setIsDataLoading(false);

        if (isArray(data)) {
          setData({ items: data, count: countData.count });
        } else {
          const { items, summary } = data;
          setData({ items, count: countData.count, summary });
        }
      } catch (e) {
        setIsDataLoading(false);
      }
    },
    [reportUrl, setData, setIsDataLoading],
  );

  return { data, fetchData, isDataLoading };
};

export const useReportWithOwnState = <T,>(reportUrl: string) => {
  const { data, fetchData, isDataLoading } = useFetchReport<T>(reportUrl);
  const deleteRows = React.useCallback(async (ids: IdsArray) => {
    await Api.Report.delete(reportUrl, { where: { id: { inq: ids } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, fetchData, deleteRows, isDataLoading };
};

export const useFetchRawReport = <T,>(reportUrl: string) => {
  const dispatch = useDispatch();
  const { data, setData } = useStateOnMountedOnly<FetchedRawData<T>>({
    items: [],
    count: 0,
  });

  const fetchData = React.useCallback(
    async (filterList: IFilter) => {
      dispatch(setIsDataRequested(true));
      try {
        const { data, count } = await Api.Report.data<T[]>(
          reportUrl,
          filterList,
        );
        dispatch(setIsDataRequested(false));

        setData({ items: data, count });
      } catch (e) {
        dispatch(setIsDataRequested(false));
      }
    },
    [dispatch, reportUrl, setData],
  );

  return { data, fetchData };
};

export const useDataFetcherWithData = <T,>(url: string, initialData: T) => {
  const dispatch = useDispatch();

  const {
    data: isDataLoading,
    setData: setIsDataLoading,
  } = useStateOnMountedOnly(false);

  const shouldUpdate = React.useRef<boolean>(true);
  const { data, setData: _setData } = useStateOnMountedOnly<T>(initialData);

  React.useEffect(
    () => () => {
      shouldUpdate.current = false;
    },
    [],
  );

  const setData = React.useCallback(
    (_data: React.SetStateAction<T>) => {
      if (!shouldUpdate.current) {
        return;
      }

      _setData(_data);
    },
    [_setData],
  );

  const fetchData = React.useCallback(
    async (filterList: IFilter & IQSJWT) => {
      dispatch(setIsDataRequested(true));
      setIsDataLoading(true);
      try {
        const data = await Api.Report.dataRaw<T>(url, filterList);
        dispatch(setIsDataRequested(false));
        setIsDataLoading(false);

        setData(data);
      } catch (e) {
        dispatch(setIsDataRequested(false));
        setIsDataLoading(false);
      }
    },
    [dispatch, setIsDataLoading, url, setData],
  );

  const removeDataByIds = React.useCallback(
    async (ids: IdsArray) => {
      dispatch(setIsDataRequested(true));
      setIsDataLoading(true);
      try {
        await Api.Report.delete(url, {
          where: {
            id: {
              inq: ids,
            },
          },
        });
        dispatch(setIsDataRequested(false));
        setIsDataLoading(false);
      } catch (e) {
        dispatch(setIsDataRequested(false));
        setIsDataLoading(false);
      }
    },
    [dispatch, setIsDataLoading, url],
  );

  return { data, fetchData, removeDataByIds, isDataLoading };
};

interface IRequestData {
  reportUrl: string;
  filter?: IFilter['filter'];
}

interface IChartOptions {
  yAxisTitle?: string;
}

export const useReportEmployeeDetailsDailyChartDataEffect = <
  T extends {
    employee: string;
    id: string;
    date: string;
    uph: string;
    siteName: string;
  }
>(
  requestData: IRequestData,
  chartOptions?: IChartOptions,
) => {
  const dispatch = useDispatch();

  const [isDataLoading, setIsDataLoading] = React.useState(false);

  const { data, setData } = useStateOnMountedOnly<
    Pick<Highcharts.Options, 'title' | 'xAxis' | 'yAxis' | 'series'>
  >({
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: chartOptions?.yAxisTitle,
      },
    },
    series: [],
  });

  React.useEffect(() => {
    // Since this hook should retrieve and parse data
    // for a specific employee, we need to request
    // data only if employee name is provided
    if (requestData.filter?.where?.employee) {
      fetchData();
    }

    async function fetchData() {
      try {
        setIsDataLoading(true);

        const { items } = await Api.Report.list<T>(requestData.reportUrl, {
          filter: requestData.filter,
        });

        setIsDataLoading(false);

        const categories = generateChartCategoriesForDailyReport(
          items,
          requestData.filter?.where?.date,
        );

        const siteNamesSet = new Set(items.map((item) => item.siteName));
        const uniqueSiteNames = [...siteNamesSet];
        const seriesObjectWithNullValues = uniqueSiteNames.reduce<{
          [siteName: string]: { [date: string]: number | null };
        }>((acc, siteName) => {
          acc[siteName] = categories.reduce((all, category) => {
            all[category] = null;
            return all;
          }, {});
          return acc;
        }, {});

        items.forEach((item) => {
          if (seriesObjectWithNullValues[item.siteName][item.date] === null) {
            seriesObjectWithNullValues[item.siteName][item.date] = parseFloat(
              item.uph,
            );
          }
        });

        const series = Object.entries(seriesObjectWithNullValues).reduce<
          Array<{ name: string; data: Array<number | null> }>
        >((acc, [siteName, dateWithValue]) => {
          const item = {
            type: 'line',
            name: siteName,
            data: Object.values(dateWithValue),
          };

          acc.push(item);

          return acc;
        }, []);

        setData(
          (prev) =>
            ({
              ...prev,
              xAxis: {
                ...prev.xAxis,
                categories,
              },
              series,
              exporting: {
                enabled: false,
              },
            } as any),
        );
      } catch (e) {
        setIsDataLoading(false);
      }
    }
  }, [
    dispatch,
    requestData,
    requestData.filter,
    requestData.reportUrl,
    setData,
  ]);

  return { data, isDataLoading };
};

export const useReportEmployeeDetailsWeeklyChartDataEffect = <
  T extends {
    employee: string;
    id: string;
    week: string;
    year: string;
    uph: string;
    siteName: string;
  }
>(
  requestData: IRequestData,
  chartOptions?: IChartOptions,
) => {
  const dispatch = useDispatch();

  const [isDataLoading, setIsDataLoading] = React.useState(false);

  const { data, setData } = useStateOnMountedOnly<
    Pick<Highcharts.Options, 'title' | 'xAxis' | 'yAxis' | 'series'>
  >({
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: chartOptions?.yAxisTitle,
      },
    },
    series: [],
  });

  React.useEffect(() => {
    // Since this hook should retrieve and parse data
    // for a specific employee, we need to request
    // data only if employee name is provided
    if (requestData.filter?.where?.employee) {
      fetchData();
    }

    async function fetchData() {
      try {
        setIsDataLoading(true);

        const { items } = await Api.Report.list<T>(requestData.reportUrl, {
          filter: requestData.filter,
        });

        setIsDataLoading(false);

        const categories = generateChartCategoriesForWeeklyReport(items);

        const siteNamesSet = new Set(items.map((item) => item.siteName));
        const uniqueSiteNames = [...siteNamesSet];
        const seriesObjectWithNullValues = uniqueSiteNames.reduce<{
          [siteName: string]: { [category: string]: number | null };
        }>((acc, siteName) => {
          acc[siteName] = categories.reduce((all, category) => {
            all[category] = null;
            return all;
          }, {});
          return acc;
        }, {});

        items.forEach((item) => {
          const category = `${item.week} (${item.year})`;
          if (seriesObjectWithNullValues[item.siteName][category] === null) {
            seriesObjectWithNullValues[item.siteName][category] = parseFloat(
              item.uph,
            );
          }
        });

        const series = Object.entries(seriesObjectWithNullValues).reduce<
          Array<{ name: string; data: Array<number | null> }>
        >((acc, [siteName, categoryWithValue]) => {
          const item = {
            type: 'line',
            name: siteName,
            data: Object.values(categoryWithValue),
          };

          acc.push(item);

          return acc;
        }, []);

        setData(
          (prev) =>
            ({
              ...prev,
              xAxis: {
                ...prev.xAxis,
                categories,
              },
              series,
            } as any),
        );
      } catch (e) {
        setIsDataLoading(false);
      }
    }
  }, [dispatch, requestData.filter, requestData.reportUrl, setData]);

  return { data, isDataLoading };
};

function generateChartCategoriesForWeeklyReport(
  items: Array<{ week: string; year: string }>,
) {
  const sortedItems = [...items].sort((a, b) => {
    if (a.year === b.year) {
      return Number(a.week) < Number(b.week) ? -1 : 1;
    }

    return Number(a.year) < Number(b.year) ? -1 : 1;
  });

  const categoriesSet = new Set(
    [...sortedItems].map((item) => `${item.week} (${item.year})`),
  );

  return [...categoriesSet];
}

function generateChartCategoriesForDailyReport(
  items: Array<{ date: string }>,
  dateFilter?: IFilterWhereValue | null,
) {
  const categories: Array<string> = [];

  // When there is no filters we just extract dates
  // from response to generate categories, but it should never happened
  // because by default chart should show data for last 14 days
  if (!dateFilter) {
    return items.map((item) => item.date);
  }

  if (dateFilter['gte']) {
    const endDate = new Date();

    let startDate = composeDate(dateFilter['gte'], parse(DATE_FORMAT));

    while (isBefore(startDate, endDate)) {
      categories.push(composeDate(startDate, format(DATE_FORMAT)));
      startDate = addDays(startDate, 1);
    }

    return categories;
  }

  if (dateFilter['between']) {
    const [startDateString, endDateString] = dateFilter['between'];

    let startDate = composeDate(startDateString, parse(DATE_FORMAT));

    const endDate = composeDate(endDateString, parse(DATE_FORMAT));

    while (isBefore(startDate, endDate)) {
      categories.push(composeDate(startDate, format(DATE_FORMAT)));
      startDate = addDays(startDate, 1);
    }

    return categories;
  }

  return categories;
}

export const useByEmployeeDetailsDailyReportFilters = (): ITableFilter[] => {
  const { t } = useTranslation();

  return [
    {
      name: 'date',
      label: t('productions_uph_reports.date'),
      type: 'daterange',
      operator: 'between',
    },
    {
      name: 'siteName',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
      useReports: true,
    },
  ];
};

export const useByEmployeeDetailsWeeklyReportFilters = (): ITableFilter[] => {
  const { t } = useTranslation();

  return [
    {
      name: 'year',
      label: t('productions_uph_reports.year'),
      operator: 'eq',
    },
    {
      name: 'week',
      label: t('productions_uph_reports.week'),
      operator: 'eq',
    },
    {
      name: 'siteName',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
      useReports: true,
    },
  ];
};
