import React from 'react';
import { map, memoize, reduce } from 'lodash';
import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import {
  IdsArray,
  IStoreState,
  IUpdateRole,
  RoleComboboxOption,
} from '../types';
import { additionalUserProperties } from '../../config/userFields';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getRoleRefreshKey = (state: IStoreState) => state.role.refreshKey;

export const getRoleData = (state: IStoreState) => state.role;

/**
 * Get server error
 * @param role - State role
 */
export const getServerError = ({ role }: IStoreState) => role.error;

/**
 * Get role list
 * @param role - State role
 */
export const getRoleList = ({ role }: IStoreState) => role.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param role - State role
 */
export const getRoleTable = createSelector(getRoleList, (role) => role);

export const getRoleTableWithIcons = createSelector(getRoleTable, (list) =>
  list.map((item) => ({
    ...item,
    ...['isBase', ...additionalUserProperties].reduce((acc, cur) => {
      (acc as any)[cur] = !!(item as any)[cur] ? (
        <YesIcon color="primary" />
      ) : (
        <NoIcon color="primary" />
      );
      return acc;
    }, {}),
  })),
);

/**
 * Get roles by array of ids
 * @param role - State role
 */
export const getRolesByIds = createSelector(
  getRoleList,
  (roles) => (ids: IdsArray) => {
    const initial: IUpdateRole[] = [];
    return reduce(
      roles,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push(cur);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get role count
 * @param role - State role
 */
export const getRoleCount = ({ role }: IStoreState) => role.count;

/**
 * Get combobox list
 */
export const getRolesComboboxList = ({ role }: IStoreState) =>
  map(role.comboboxList, (roleItem) => ({
    ...pick(roleItem, additionalUserProperties),
    id: roleItem.id,
    name: roleItem.name,
    key: roleItem.key,
  })) as RoleComboboxOption[];

export const getRolesComboboxListWithNameAsId = ({ role }: IStoreState) =>
  map(role.comboboxList, (roleItem) => ({
    id: roleItem['name'] as any,
    name: roleItem.name,
  })) as RoleComboboxOption[];

export const getRolesComboboxListByFieldId = createSelector(
  getRolesComboboxList,
  (roles) =>
    memoize((key: string) =>
      map(roles, (role) => ({
        ...pick(role, additionalUserProperties),
        id: (role as any)[key],
        name: role.name,
        key: role.key,
      })),
    ),
);

export const getIsRoleDataLoading = createSelector(getRoleData, (data) => {
  return (
    data.isRoleDataCountLoading ||
    data.isRoleDataLoading ||
    data.isRoleDataManaging
  );
});
