import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import {
  IGridColOrderDetails,
  GridColWithOrderConfig,
  IGridValidationError,
} from 'src/modules/types/data-grid';
import { Box, FormHelperText, useTheme } from '@mui/joy';

export const useGenerateGridColumnsData = (
  tableCellsWithOrderConfig: Array<GridColWithOrderConfig>,
  validationErrors?: IGridValidationError | null,
) => {
  const theme = useTheme();

  return React.useMemo(() => {
    return tableCellsWithOrderConfig?.reduce<{
      columns: GridColDef[];
      gridColumnsOrderDetails: IGridColOrderDetails[];
    }>(
      (result, tableCellWithOrderConfig) => {
        const { orderConfig, ...tableCell } = tableCellWithOrderConfig;
        if (orderConfig) {
          result.gridColumnsOrderDetails.push({
            id: tableCell.field,
            field: tableCell.field,
            ...orderConfig,
          });
        }

        if (tableCell.headerName !== undefined) {
          result.columns.push({
            ...(tableCell as any),
            disableSorting: !orderConfig,
            cellClassName: (params) =>
              validationErrors &&
              validationErrors[params.row.id] &&
              validationErrors[params.row.id][params.field]
                ? 'data-grid-cell-validation-error'
                : undefined,

            renderCell: validationErrors
              ? (params) => {
                  return (
                    <Box>
                      <Box sx={{ minHeight: 12 }}></Box>
                      {params.value}
                      {validationErrors[params.row.id] &&
                        validationErrors[params.row.id][params.field] && (
                          <FormHelperText
                            sx={{
                              minHeight: 12,
                              fontSize: 12,
                              color:
                                theme.palette.colors.text.text_error_primary
                                  .main,
                            }}
                          >
                            {validationErrors[params.row.id][params.field]}
                          </FormHelperText>
                        )}
                    </Box>
                  );
                }
              : undefined,
          });
        }

        return result;
      },
      {
        columns: [],
        gridColumnsOrderDetails: [],
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCellsWithOrderConfig, validationErrors]);
};
