import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { IOptiturnCredentialsModel } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { updateOptiturnCredentialsScheme } from 'src/modules/schemes';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

interface IUpdateOptiturnCredentialsProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestStart: () => void;
  onRequestEnd: () => void;
  credentials: Array<IOptiturnCredentialsModel>;
}

export const UpdateOptiturnCredentials = ({
  isOpen,
  onClose,
  onRequestEnd,
  onRequestStart,
  credentials,
}: IUpdateOptiturnCredentialsProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateOptiturnCredentialsScheme);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: credentials,
    validate,
    onSubmit: async (data) => {
      onRequestStart();

      try {
        await Api.OptiturnCredentials.bulkUpdate(
          data.map((item) => ({
            id: item.id,
            login: item.login,
            password: item.password,
            siteId: item.siteId,
          })),
        );
        onClose();
        onRequestEnd();
      } catch {
        onClose();
        onRequestEnd();
      }
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('optiturn-credentials.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {credentials.map((credential, index) => (
              <DrawerBodySectionContent key={credential.id}>
                <DrawerBodySectionTitle>{`${credential.id}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    label={t('optiturn-credentials.site')}
                    placeholder={t('optiturn-credentials.site')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.login`}
                    variant="outlined"
                    required
                    fullWidth
                    label={t('optiturn-credentials.login')}
                    name={`${index}.login`}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.password`}
                    variant="outlined"
                    required
                    fullWidth
                    label={t('optiturn-credentials.password')}
                    name={`${index}.password`}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
