import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import {
  useCostPlusWeekly,
  useCostPlusWeeklyComposeWhere,
  useCostPlusWeeklyTableFiltersConfiguration,
} from 'src/modules/utils/hooks/weekly-cost-plus.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { ReportPage } from 'src/components/ReportPage';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { CostPlusWeeklyFilterFilter } from 'src/pages/CustomerReports/components/CostPlusWeeklyFilter';
import { HEADER_HEIGHT } from 'src/config';
import { TABS_HEIGHT } from 'src/components/Layout/MainLayout/MainLayoutContext';

const reportUrl = '/cost-plus-weekly';

export const CostPlusWeeklyList = () => {
  const { t } = useTranslation();
  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useCostPlusWeeklyTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useCostPlusWeeklyComposeWhere({
    employeeId: +filterFields.employeeId.value,
    siteId: +filterFields.siteId.value,
    shift: filterFields.shift.value,
    department: filterFields.department.value,
    year: +filterFields.year.value,
    week: +filterFields.week.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useCostPlusWeekly(reportUrl, where);

  const maxHeight = React.useMemo(
    () =>
      `calc(100vh - var(--bottom-copyright-height) - ${
        HEADER_HEIGHT + TABS_HEIGHT + 12
      }px)`,
    [],
  );

  return (
    <PageContentWithTopToolbar>
      <ReportPage hideNoData>
        <TableToolbar>
          <TableTitle>
            {t('main_menu.customer_reports.cost_plus_weekly')}
          </TableTitle>

          <TableActions>
            <CostPlusWeeklyFilterFilter
              getLabel={getLabel}
              onFiltersFormSubmit={onFiltersFormSubmit}
              getFilterCommonPropsByFilterName={
                getFilterCommonPropsByFilterName
              }
            />

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />
          </TableActions>
        </TableToolbar>

        <ReportTable
          heightWithTabs
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
          sx={{ maxHeight }}
        />
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
