import React from 'react';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { ICreateQuestionCategoryTypeRequest } from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import { createQuestionCategoryTypeScheme } from 'src/modules/schemes';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { PointCollectionType } from 'src/config';

const initialValues = {
  name: '',
  siteId: NaN,
};

interface IQuestionCategoryTypeCreateProps {
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}

export const QuestionCategoryTypeCreate: React.FC<IQuestionCategoryTypeCreateProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(createQuestionCategoryTypeScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: ICreateQuestionCategoryTypeRequest) => {
      await Api.QuestionCategoryType.create(data);
      formik.resetForm();
      onCreate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  const pointCollectionTypesOptions = React.useMemo(
    () =>
      Object.values(PointCollectionType).map((p) => ({
        id: p,
        name: p,
      })),
    [],
  );

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('question_category_type.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikTextField
              id="name"
              name="name"
              required={true}
              formik={formik}
              variant="outlined"
              fullWidth={true}
              label={t('question_category_type.name')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('question_category_type.site')}
              placeholder={t('question_category_type.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              id="pointCollectionType"
              label={t('question_category_type.pointCollectionType')}
              options={
                (pointCollectionTypesOptions as unknown) as ComboBoxOption[]
              }
              formik={formik}
              placeholder={t('common.select')}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
