import React from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { AnyObject } from 'src/modules/types';
import { EmployeesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EmployeesComboBoxFilter';

interface IPointReviewsFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const PointReviewsFilter: React.FC<IPointReviewsFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('point_reviews.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <EmployeesComboBoxFilter
          label={getLabel({
            filterName: 'employeeId',
            labelPrefix: t('point_reviews.employee'),
          })}
          {...getFilterCommonPropsByFilterName('employeeId')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
