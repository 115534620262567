import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg } from 'src/components/svgIcons';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getRoleCountRequest,
  getRoleListRequest,
  exportRoleRequest,
} from 'src/modules/actions';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  getIsRoleDataLoading,
  getRoleCount,
  getRoleTableWithIcons,
} from 'src/modules/selectors/role';
import { additionalUserProperties } from 'src/config/userFields';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { CreateRole } from './RolesForms/CreateRole';
import { UpdateRoles } from './RolesForms/UpdateRoles';

export const RolesList = () => {
  const { t } = useTranslation();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportRoleRequest);

  const isDataLoading = useSelector(getIsRoleDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.role.create.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.role.update.id,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('roles.name'),
      },
      {
        id: 'key',
        orderConfig: { orderBy: 'key' },
        label: t('roles.key'),
      },
      {
        id: 'isBase',
        orderConfig: { orderBy: 'isBase' },
        label: t('roles.isBase'),
      },
      {
        id: 'level',
        orderConfig: { orderBy: 'level' },
        label: t('roles.level'),
      },
      ...additionalUserProperties.map((i) => ({
        id: i,
        orderConfig: { orderBy: 'level' },
        label: t(`roles.${i}`),
      })),
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      key: {
        value: '',
        property: 'key',
        operator: 'like' as const,
      },
      isBase: {
        value: '',
        property: 'isBase',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getRoleTableWithIcons,
    selectCount: getRoleCount,
    getEntitiesRequest: getRoleListRequest,
    getEntitiesCountRequest: getRoleCountRequest,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];

  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('roles.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'name',
                      labelPrefix: t('roles.name'),
                    })}
                    {...getFilterCommonPropsByFilterName('name')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'key',
                      labelPrefix: t('roles.key'),
                    })}
                    {...getFilterCommonPropsByFilterName('key')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'isBase',
                      labelPrefix: t('roles.isBase'),
                    })}
                    {...getFilterCommonPropsByFilterName('isBase')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/roles"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateRole
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateRoles
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        rolesToUpdateIds={selectedEntitiesIds}
      />
    </PageContentWithTopToolbar>
  );
};
