import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import {
  IdsArray,
  IQuestionCategoryTypeModel,
  IUpdateQuestionCategoryType,
} from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import { ifSiteHasLunch } from 'src/modules/selectors/site';
import { updateQuestionCategoryTypesScheme } from 'src/modules/schemes';
import { PointCollectionType } from 'src/config';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';

interface IQuestionCategoryTypeUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const QuestionCategoryTypeUpdate: React.FC<IQuestionCategoryTypeUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const hasLunch = useSelector(ifSiteHasLunch);

  const [initialValues, setInitialValues] = React.useState<
    Array<IQuestionCategoryTypeModel>
  >([]);

  React.useEffect(() => {
    if (isOpen) {
      Api.QuestionCategoryType.list({
        filter: { where: { id: { inq: ids } } },
      }).then((data) => setInitialValues(data));
    }
  }, [hasLunch, ids, isOpen]);

  const validate = useValidate(updateQuestionCategoryTypesScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IUpdateQuestionCategoryType[]) => {
      await Api.QuestionCategoryType.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  const pointCollectionTypesOptions = React.useMemo(
    () =>
      Object.values(PointCollectionType).map((p) => ({
        id: p,
        name: p,
      })),
    [],
  );

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('question_category_type.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (questionCategoryType, index) => (
          <DrawerBodyContent key={questionCategoryType.id}>
            <FormFieldContainer>
              <FormikTextField
                id={`${index}.name`}
                name={`${index}.name`}
                formik={formik}
                required={true}
                variant="outlined"
                fullWidth={true}
                label={t('question_category_type.name')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id={`${index}.siteId`}
                required={true}
                formik={formik}
                label={t('question_category_type.site')}
                placeholder={t('question_category_type.site')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                id={`${index}.pointCollectionType`}
                label={t('question_category_type.pointCollectionType')}
                options={
                  (pointCollectionTypesOptions as unknown) as ComboBoxOption[]
                }
                formik={formik}
                placeholder={t('common.select')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
