import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updatePositionsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { omit } from 'lodash';
import { updatePositionsScheme } from 'src/modules/schemes/positions';
import { getPositionList } from 'src/modules/selectors/position';

const initialValues = {
  siteId: NaN,
  name: '',
};

interface IUpdatePositionsProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  positionsToUpdateIds: IdsArray;
}

export const UpdatePositions = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  positionsToUpdateIds,
}: IUpdatePositionsProps) => {
  const { t } = useTranslation();

  const allPositions = useSelector(getPositionList);

  const positionsToUpdateFullData = allPositions.filter((position) =>
    positionsToUpdateIds.includes(position.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updatePositionsScheme);

  const formik = useFormik({
    initialValues: positionsToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updatePositionsRequest({
          data: data.map((item) => omit(item, 'site')),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('point_types.bulk_update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {positionsToUpdateFullData.map((position, index) => (
              <DrawerBodySectionContent key={position.id}>
                <DrawerBodySectionTitle>{`${position.name} (${position.site?.name})`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth={true}
                    id={`${index}.name`}
                    label={t('positions.name')}
                    name={`${index}.name`}
                    autoComplete="name"
                    errorMode="onFieldChange"
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    errorMode="onFieldChange"
                    label={t('positions.site')}
                    placeholder={t('positions.site')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.desiredEmployeesNumber`}
                    name={`${index}.desiredEmployeesNumber`}
                    label={t('departments.desiredEmployeesNumber')}
                    autoComplete="desiredEmployeesNumber"
                    formik={formik}
                    type="number"
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
