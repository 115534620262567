import React from 'react';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportDefaultFilterWithPreselectedSite,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { clients } from 'src/modules/constants';
import { Report, ReportPage } from 'src/components/ReportPage';
import { WeeklyCpu } from './WeeklyCpu';
import { WeeklyGrossMargin } from './WeeklyGrossMargin';
import { WeeklyGrossMarginDetailed } from './WeeklyGrossMarginDetailed';
import { PayboardExport } from './PayboardExport';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useHasUserAccessToAction } from 'src/config';
import {
  ISonyReportsFilterPanelFilters,
  SonyReportsFilterPanel,
} from './SonyReportsFilterPanel';
import { useTranslation } from 'react-i18next';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';
import { format, getISOWeek, getYear, startOfYear } from 'date-fns';
import { SonyInventory } from './SonyInventory';

const currentDate = new Date();
const startOfCurrentYear = startOfYear(currentDate);
const defaultYearWeek = Number(
  `${getYear(currentDate)}${String(getISOWeek(currentDate)).padStart(2, '0')}`,
);

const reportsDefaultFilters: ISonyReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  yearWeek: defaultYearWeek,
  siteId: undefined,
  startDate: format(startOfCurrentYear, 'yyyy-MM-dd'),
  endDate: format(currentDate, 'yyyy-MM-dd'),
};

const sonyClient = clients.sony;

const SonyReportsComponent = () => {
  const { t } = useTranslation();

  const {
    appliedFilters,
    setAppliedFilters,
    isFilterInitializing,
  } = useReportDefaultFilterWithPreselectedSite(reportsDefaultFilters);

  const reportProps = useReportsProps(sonyClient);

  const hasUserAccessToUploadSonyProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.sony].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof ISonyReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
      },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        isPreselected: true,
      },
      { id: 'startDate', label: t('common.startDate'), isPreselected: true },
      { id: 'endDate', label: t('common.endDate'), isPreselected: true },
      {
        id: 'staffingId',
        label: t('productions_uph_reports.staffing'),
      },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  return (
    <>
      <ReportTableToolbar reportName={t('main_menu.reports.sony_main')}>
        <ReportPageActionsGroupContainer>
          <ReportPageActionsGroupItem>
            <SelectHeadCells />
          </ReportPageActionsGroupItem>
        </ReportPageActionsGroupContainer>

        <ReportPageActionsGroupContainer>
          <ReportPageActionsGroupItem>
            <ReportPageExport reportProps={reportProps} />
          </ReportPageActionsGroupItem>

          {hasUserAccessToUploadSonyProductionData && (
            <ReportPageActionsGroupItem>
              <UploadImportingFile clientKey={clients.sony} />
            </ReportPageActionsGroupItem>
          )}
        </ReportPageActionsGroupContainer>
      </ReportTableToolbar>

      <ReportPageItemContainer>
        <SonyReportsFilterPanel
          reportsDefaultFilters={appliedFilters}
          filtersOptionsConfig={filtersOptionsConfig}
          onApplyFilters={(filtersToApply) => {
            setAppliedFilters(filtersToApply);
          }}
          onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
        />
      </ReportPageItemContainer>
      <Report {...reportProps.weeklyCpu}>
        <ReportPageItemContainer fullHeight>
          <WeeklyCpu
            year={appliedFilters.year}
            week={appliedFilters.year}
            siteId={appliedFilters.siteId}
            yearWeek={appliedFilters.yearWeek}
            showLoader={isFilterInitializing}
          />
        </ReportPageItemContainer>
      </Report>

      <Report {...reportProps.weeklyGrossMargin}>
        <ReportPageItemContainer fullHeight>
          <WeeklyGrossMargin />
        </ReportPageItemContainer>
      </Report>

      <Report {...reportProps.weeklyGrossMarginDetailed}>
        <ReportPageItemContainer fullHeight>
          <WeeklyGrossMarginDetailed />
        </ReportPageItemContainer>
      </Report>

      <Report {...reportProps.sonyPayboardExport}>
        <ReportPageItemContainer fullHeight>
          <PayboardExport
            yearWeek={appliedFilters.yearWeek}
            siteId={appliedFilters.siteId}
            staffingId={appliedFilters.staffingId}
          />
        </ReportPageItemContainer>
      </Report>

      <Report {...reportProps.sonyInventoryStorageUtilization}>
        <ReportPageItemContainer fullHeight>
          <SonyInventory
            startDate={appliedFilters.startDate}
            endDate={appliedFilters.endDate}
          />
        </ReportPageItemContainer>
      </Report>
    </>
  );
};

export const SonyReports = () => {
  return (
    <PageContentWithTopToolbar>
      <ReportPage client={sonyClient}>
        <SonyReportsComponent />
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
