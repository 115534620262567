import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSkillsByIds,
  getSkillsComboboxList,
  getSkillsComboboxListWithNameAsId,
} from '../../selectors/skill';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getSkillComboboxListRequest,
  getSkillListRequest,
} from '../../actions/skill';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

export const skillsInclusions = [
  {
    relation: 'site',
  },
];

const useSkillsComboboxFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteRelationInclusion],
        order: ['name ASC'],
      },
    }),
    [siteRelationInclusion],
  );
};

/**
 * A custom hook to fetch skills from store if they exist otherwise to make a request to fetch needed skills from
 * the server
 */
export const useFetchSkillsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch skills list from store
  const skills = useSelector(
    (state) => getSkillsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(skills)) {
      dispatcher(
        getSkillListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: skillsInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch skills combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchSkillsCombobox = () => {
  const dispatcher = useDispatch();
  const skillsComboboxFilter = useSkillsComboboxFilter();

  const dispatchGetSkillsComboboxListRequest = React.useCallback(() => {
    dispatcher(getSkillComboboxListRequest(skillsComboboxFilter));
  }, [skillsComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetSkillsComboboxListRequest();
  }, [dispatchGetSkillsComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getSkillsComboboxList);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetSkillsComboboxListRequest();
    }
  };
};

export const useFetchSkillsComboboxWithNameAsId = () => {
  const dispatcher = useDispatch();
  const skillsComboboxFilter = useSkillsComboboxFilter();

  const dispatchGetSkillsComboboxListRequest = React.useCallback(() => {
    dispatcher(getSkillComboboxListRequest(skillsComboboxFilter));
  }, [skillsComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetSkillsComboboxListRequest();
  }, [dispatchGetSkillsComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getSkillsComboboxListWithNameAsId);
  return () => {
    if (isEmpty(combobox)) {
      dispatcher(getSkillComboboxListRequest(skillsComboboxFilter));
    }
  };
};

export const useSkillsDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};
