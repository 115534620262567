import React from 'react';
import { ICountResponse, ListWithSummary } from '../../../../../modules/types';
import { useTranslation } from 'react-i18next';
import { PageContentChildContainer } from 'src/components/PageContent';
import {
  Pagination,
  TableActions,
  TableBottom,
  TableComponent,
  TableContent,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableContextProvider } from 'src/components/Table/TableContext';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { EmployeesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EmployeesComboBoxFilter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { IInsertedPunchesCountResponseItemData } from 'src/modules/types/reports';
import { TableSummary } from 'src/components/Table/components/TableSummary';
import {
  useAddReportTableDataToReportExportStateEffect,
  useReportExportProps,
} from 'src/modules/utils/hooks/reports.hooks';
import { SupervisorsComboBoxFilter } from '../../../../../components/Table/components/TableActions/components/Filter/components/SupervisorsComboBoxFilter';

const InsertedPunchesCountComponent = () => {
  const { t } = useTranslation();

  const [siteId, setSiteId] = React.useState<number>();

  const { data, isDataLoading, fetchData } = useDataFetcherWithData<
    ListWithSummary<Array<IInsertedPunchesCountResponseItemData>>
  >(apiEndpoints.INSERTED_PUNCHES_COUNT_REPORT, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    isDataLoading: isCountDataLoading,
    fetchData: fetchCountData,
  } = useDataFetcherWithData<ICountResponse>(
    apiEndpoints.INSERTED_PUNCHES_COUNT_REPORT_COUNT,
    {
      count: 0,
    },
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('inserted_punches_count.siteName'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('inserted_punches_count.firstName'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('inserted_punches_count.lastName'),
      },
      {
        id: 'supFirstName',
        orderConfig: { orderBy: 'supFirstName' },
        label: t('inserted_punches_count.supFirstName'),
      },
      {
        id: 'supLastName',
        orderConfig: { orderBy: 'supLastName' },
        label: t('inserted_punches_count.supLastName'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('inserted_punches_count.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('inserted_punches_count.week'),
      },
      {
        id: 'count',
        orderConfig: { orderBy: 'count' },
        label: t('inserted_punches_count.count'),
      },
    ],
    [t],
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
        // inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      supervisorId: {
        value: '',
        property: 'supervisorId',
        operator: 'eq' as const,
      },
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
  });

  const exportProps = useReportExportProps(
    apiEndpoints.INSERTED_PUNCHES_COUNT_REPORT,
  );

  const listFilter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });
  const countFilter = useGenerateRequestFilter({
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          (acc as any)[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  React.useEffect(() => {
    fetchData({ filter: listFilter });
  }, [fetchData, listFilter]);

  React.useEffect(() => {
    fetchCountData({ filter: countFilter });
  }, [countFilter, fetchCountData]);

  return (
    <PageContentChildContainer sx={{ px: 0 }}>
      <TableToolbar>
        <TableTitle>{t('inserted_punches_count.main')}</TableTitle>
        <TableActions>
          <Filter onSubmit={onFiltersFormSubmit}>
            <FormFieldContainer>
              <SitesComboBoxFilter
                label={getLabel({
                  filterName: 'siteId',
                  labelPrefix: t('inserted_punches_count.siteName'),
                })}
                onChange={(event, site) => setSiteId(site?.id)}
                {...getFilterCommonPropsByFilterName('siteId')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <EmployeesComboBoxFilter
                label={getLabel({
                  filterName: 'employeeId',
                  labelPrefix: t('inserted_punches_count.employee'),
                })}
                {...getFilterCommonPropsByFilterName('employeeId')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'year',
                  labelPrefix: t('inserted_punches_count.year'),
                })}
                {...getFilterCommonPropsByFilterName('year')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'week',
                  labelPrefix: t('inserted_punches_count.week'),
                })}
                {...getFilterCommonPropsByFilterName('week')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SupervisorsComboBoxFilter
                siteId={siteId}
                label={getLabel({
                  filterName: 'supervisorId',
                  labelPrefix: t('inserted_punches_count.supervisor'),
                })}
                {...getFilterCommonPropsByFilterName('supervisorId')}
              />
            </FormFieldContainer>
          </Filter>

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={listFilter as any}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />
        </TableActions>
      </TableToolbar>

      <TableComponent
        tableUniqueKey="time_keeping/inserted_punches_count"
        sx={{
          height:
            isDataLoading || isCountDataLoading || !data.items.length
              ? '100%'
              : 'auto',
          maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
        }}
      >
        {(isDataLoading || isCountDataLoading) && <MainContentLoader />}

        <TableContent
          data={data.items as any}
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          isDataLoading={isDataLoading || isCountDataLoading}
          onSort={(orderBy, order) => {
            setOrder(order);
            setOrderBy(orderBy);
          }}
        >
          {data.summary &&
            data.summary.map((item) => (
              <TableSummary key={item.title} {...item} />
            ))}
        </TableContent>

        <TableBottom>
          <Pagination
            currentPageNumber={page}
            count={countData.count}
            rowsPerPage={limit}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={setLimit}
          />
        </TableBottom>
      </TableComponent>
    </PageContentChildContainer>
  );
};

export const InsertedPunchesCount = () => {
  return (
    <TableContextProvider>
      <InsertedPunchesCountComponent />
    </TableContextProvider>
  );
};
