import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { map, pick } from 'lodash';
import { updateFinancialTrackerScheme } from 'src/modules/schemes';
import FormikNumberField from 'src/components/Formik/FormikNumberField';
import { IFinancialReportTableDataItem } from 'src/modules/types/financial-report';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import FormikCustomNumberField from 'src/components/Formik/FormikCustomNumberField';

export const formFieldsTranslationDictionary = {
  'financial-tracker.yearNumber': 'productions_uph_reports.year',
  'financial-tracker.weekNumber': 'productions_uph_reports.week',
  'financial-tracker.revenue': 'reports.headers.revenue',
  'financial-tracker.staffingCost': 'reports.headers.staffing_cost',
  'financial-tracker.internalCost': 'reports.headers.internal_cost',
  'financial-tracker.otherCosts': 'reports.headers.other_costs',
};

interface IFinancialReportsUpdateRecordFormProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (data: any) => void;
  itemsToUpdate: Array<IFinancialReportTableDataItem>;
}

export const FinancialReportsUpdateRecordForm = ({
  isOpen,
  onClose,
  onUpdate,
  itemsToUpdate,
}: IFinancialReportsUpdateRecordFormProps) => {
  const { t } = useTranslation();

  const financialTrackers = React.useMemo(
    () =>
      itemsToUpdate.map((item: any) => ({
        ...pick(item, ['id', 'siteId', 'yearNumber', 'weekNumber']),
        revenue: item.revenueRaw,
        staffingCost: item.staffingCostRaw,
        internalCost: item.internalCostRaw,
        otherCosts: item.otherCostsRaw,
      })) || [],
    [itemsToUpdate],
  );

  const validate = useValidate(updateFinancialTrackerScheme);

  const formik = useFormik({
    initialValues: financialTrackers,
    enableReinitialize: true,
    validate,
    onSubmit: (data) => {
      onUpdate(data);

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('financial_reports.update_financial_records')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {financialTrackers.map((value, index) => (
            <DrawerBodySectionContent key={value.id}>
              <DrawerBodySectionTitle>
                {`${itemsToUpdate[index].site.name} ${value.yearNumber}-W${value.weekNumber}`}
              </DrawerBodySectionTitle>

              <FormFieldContainer>
                <SitesComboBox
                  id={generateFullIdInMultiEntitiesForm('siteId', index)}
                  required
                  formik={formik}
                  label={t('employees.site')}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              {map(['yearNumber', 'weekNumber'], (key) => (
                <FormFieldContainer key={key}>
                  <FormikNumberField
                    key={key}
                    variant="outlined"
                    required={['yearNumber', 'weekNumber'].includes(key)}
                    fullWidth
                    id={`${index}.${key}`}
                    label={t(
                      (formFieldsTranslationDictionary as any)[
                        `financial-tracker.${key}`
                      ],
                    )}
                    name={`${index}.${key}`}
                    formik={formik}
                  />
                </FormFieldContainer>
              ))}

              {map(
                ['revenue', 'staffingCost', 'internalCost', 'otherCosts'],
                (key) => (
                  <FormFieldContainer key={key}>
                    <FormikCustomNumberField
                      key={key}
                      variant="outlined"
                      required={[
                        'revenue',
                        'staffingCost',
                        'internalCost',
                        'otherCosts',
                      ].includes(key)}
                      fullWidth
                      id={`${index}.${key}`}
                      label={t(
                        (formFieldsTranslationDictionary as any)[
                          `financial-tracker.${key}`
                        ],
                      )}
                      name={`${index}.${key}`}
                      formik={formik}
                    />
                  </FormFieldContainer>
                ),
              )}
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
