import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import {
  exportPayboardWeeklyDetailedRequest,
  getPayboardWeeklyDetailedListRequest,
  getPayboardWeeklyDetailedCountRequest,
} from '../../../../../modules/actions';
import {
  getPayboardDetailsData,
  getIsPayboardSummaryDataLoading,
  getIsDetailsViewDataLoading,
  getPayboardWeeklyDetailedList,
  getPayboardWeeklyDetailedCount,
} from '../../../../../modules/selectors/payboard';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { map } from 'lodash';
import {
  AnyObject,
  IStoreState,
  PayboardDetailsRequest,
} from '../../../../../modules/types';
import {
  useFetchPayboardDetails,
  useFetchStaffingProvidersCombobox,
  useFilter,
  useCreateExportProps,
  useFetchSitesCombobox,
  usePayboardListDefaultFilter,
  // useBrowserHistoryFunctions,
  useSupervisorCombobox,
  useFetchActiveDepartmentsCombobox,
} from '../../../../../modules/utils/hooks';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { getStaffingProvidersComboboxList } from '../../../../../modules/selectors/staffingProvider';
import { getUsersComboboxList } from '../../../../../modules/selectors/user';
import { useTranslation } from 'react-i18next';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import { PayboardDetailsTable } from '../../modals/PayboardDetailsTable';
import { getDepartmentOptionsByGloballySites } from '../../../../../modules/selectors/department';
import { isTableHasDefaultOrder } from 'src/modules/utils/hooks/table';

const PayboardByWeekDetailed = () => {
  // const { updateFilterDataInQueryParams } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  // week object that is used to determine which week and badge is selected by a user
  const [week, setWeek] = useState<PayboardDetailsRequest>({
    year: NaN,
    week: NaN,
    badge: NaN,
  });

  const defaultFilter = usePayboardListDefaultFilter();

  // that's used to show week modal dialog
  const [weekModal, setWeekModal] = useState<boolean>(false);

  const fetchPayboardDetails = useFetchPayboardDetails(week);
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchSitesComboBox = useFetchSitesCombobox();

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesComboBox();
    fetchStaffingProvidersCombobox();
    fetchSupervisorsCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // fetch staffing list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  // fetch supervisors providers list from store
  const supervisors = useSelector(getUsersComboboxList, shallowEqual);
  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // get payboard weekly data from storage
  const weekData = useSelector(
    (state) => getPayboardDetailsData(state as IStoreState)(week),
    shallowEqual,
  );

  // make request to fetch payboard information by week from the server if we don't have it in the store
  useEffect(() => {
    fetchPayboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, defaultFilter]);

  const list = useSelector(getPayboardWeeklyDetailedList, shallowEqual);
  const count = useSelector(getPayboardWeeklyDetailedCount, shallowEqual);
  const exportProps = useCreateExportProps(exportPayboardWeeklyDetailedRequest);
  const isTableDataLoading = useSelector(getIsPayboardSummaryDataLoading);
  const isDetailsViewDataLoading = useSelector(getIsDetailsViewDataLoading);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'extEmployeeId',
      disablePadding: false,
      label: t('payboard.extEmployeeId'),
    },
    { id: 'badge', disablePadding: false, label: t('payboard.badge') },
    { id: 'firstName', disablePadding: false, label: t('payboard.first_name') },
    { id: 'lastName', disablePadding: false, label: t('payboard.last_name') },
    {
      id: 'staffingProvider',
      disablePadding: false,
      label: 'Staffing Provider',
    },
    { id: 'payRate', disablePadding: false, label: t('payboard.payrate') },
    {
      id: 'supervisor',
      disablePadding: false,
      label: t('payboard.supervisor'),
    },
    {
      id: 'department',
      disablePadding: false,
      label: t('payboard.department'),
    },
    { id: 'site', disablePadding: false, label: t('payboard.site') },
    {
      id: 'year',
      disablePadding: false,
      label: t('payboard.year'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('payboard.week'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'weekEndingDate',
      disablePadding: false,
      label: t('payboard.week_ending_date'),
      orderByAnotherField: 'yearWeek',
    },
    { id: 'markup', disablePadding: false, label: t('payboard.markup') },
    { id: 'client', disablePadding: false, label: t('payboard.client') },
    {
      id: 'sumTimeTotal',
      disablePadding: false,
      label: t('payboard.time_total'),
    },
    {
      id: 'regularHoursDec',
      disablePadding: false,
      label: t('payboard.reg_hrs'),
      orderByAnotherField: 'regularHoursDec',
    },
    {
      id: 'overtimeHoursDec',
      disablePadding: false,
      label: t('payboard.ot_hrs'),
    },
    {
      id: 'regularPay',
      disablePadding: false,
      label: t('payboard.reg_pay'),
      orderByAnotherField: 'regularPay',
    },
    {
      id: 'overtimePay',
      disablePadding: false,
      label: t('payboard.ot_pay'),
      orderByAnotherField: 'overtimePay',
    },
    {
      id: 'overtimePremiumPay',
      disablePadding: false,
      label: t('payboard.ot_premium_pay'),
      orderByAnotherField: 'overtimePremiumPay',
    },
    {
      id: 'totalPay',
      disablePadding: false,
      label: t('payboard.total_pay'),
      orderByAnotherField: 'totalPay',
    },
    {
      id: 'markupCost',
      disablePadding: false,
      label: t('payboard.markup_cost'),
      orderByAnotherField: 'markupCost',
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(sites, (site) => ({
            id: site.name,
            name: site.name,
          })),
        [sites],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'yearWeek',
      label: t('payboard.year_week'),
      operator: 'eq',
      type: 'comboboxYearWeek',
    },
    {
      name: 'supervisor',
      label: t('payboard.supervisor'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(supervisors, (supervisor) => ({
            id: supervisor.name,
            name: supervisor.name,
          })),
        [supervisors],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'staffingProvider',
      label: t('payboard.staffing_provider'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(staffingProviders, (staffingProvider) => ({
            id: staffingProvider.name,
            name: staffingProvider.name,
          })),
        [staffingProviders],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'badge',
      label: t('payboard.employee'),
      operator: 'like',
      type: 'comboboxEmployee',
      propertyAsID: 'badge',
      siteFieldName: 'site',
      siteAs: 'name',
      onlyActive: false,
    },
    {
      name: 'extEmployeeId',
      label: t('payboard.extEmployeeId'),
      operator: 'like',
    },
    {
      name: 'department',
      label: t('payboard.department'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(departments, (department) => ({
            id: department.name,
            name: department.label,
          })),
        [departments],
      ) as unknown) as ComboBoxOption[],
    },
  ];

  // make request to fetch payboards when component is mounted
  useEffect(() => {
    dispatcher(getPayboardWeeklyDetailedListRequest(filterList));
    // get total count
    dispatcher(getPayboardWeeklyDetailedCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order: tableOrder, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    const order = isTableHasDefaultOrder(tableOrder)
      ? ['firstName ASC, lastName ASC, yearWeek DESC']
      : tableOrder;

    const filter = {
      limit: rowsPerPage,
      offset,
      where,
      order,
    };

    dispatcher(
      getPayboardWeeklyDetailedListRequest({
        filter,
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getPayboardWeeklyDetailedCountRequest({ filter: { where } }));

    // updateFilterDataInQueryParams(filter);
  };

  // handle row click
  const hanleRowClick = (row: AnyObject) => {
    setWeek({ year: row.year, badge: row.badge, week: row.week });
    setWeekModal(true);
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={list.items}
        summaryRows={list.summary}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="" // if we pass an empty string it will use loop index for the key
        tableName={t('payboard.summary')}
        headCells={headCells}
        filters={filters}
        disableSelection={true}
        sxOfTableContainer={{
          '& tbody tr:hover': {
            cursor: 'pointer',
          },
        }}
        onSync={onSync}
        onRowClick={hanleRowClick}
        exportProps={exportProps}
        additionalWhereForExport={defaultFilter.where}
        defaultOrder="desc"
        defaultOrderBy={'yearWeek' as any}
      />
      <>
        <PayboardDetailsTable
          title={t('payboard.details_title', week)}
          isOpen={weekModal}
          onClose={() => setWeekModal(false)}
          items={weekData.items}
          count={weekData.count}
          total={weekData.total}
          isTableDataLoading={isDetailsViewDataLoading}
        />
      </>
    </>
  );
};

export default PayboardByWeekDetailed;
