import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import {
  exportPayboardDailyHoursRequest,
  getPayboardDailyHoursDashboardInReportDataRequest,
  getPayboardDailyHoursDashboardInReportCountRequest,
} from 'src/modules/actions';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  useCreateExportProps,
  useFetchShiftsCombobox,
  useFetchActiveDepartmentsCombobox,
  useFetchStaffingProvidersCombobox,
  usePayboardDailyHoursSummaryDefaultFilter,
} from 'src/modules/utils';
import {
  DEFAULT_PAGE,
  useTableData,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { DatetimeRangePicker } from 'src/components/Table/components/TableActions/components/Filter/components/DatetimeRangePickerFilter';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import {
  getPayboardDailyHoursDashboardInDataLoading,
  getPayboardDailyHoursDashboardInReportCount,
  getPayboardDailyHoursDashboardInReportData,
} from 'src/modules/selectors/payboard';
import { TableSummary } from 'src/components/Table/components/TableSummary';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';
import { makeArrayOfUniqueObjectsByField } from 'src/modules/utils/helpers/common';

export const DailyHrsDashboardInTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultFilter = usePayboardDailyHoursSummaryDefaultFilter();
  const exportProps = useCreateExportProps(exportPayboardDailyHoursRequest);

  const shifts = useSelector(getShiftOptionsByGloballySites);
  const companies = useSelector(getStaffingProvidersComboboxList);
  const departments = useSelector(getDepartmentOptionsByGloballySites);

  const uniqueShifts = makeArrayOfUniqueObjectsByField(shifts, 'name');
  const uniqueDepartments = makeArrayOfUniqueObjectsByField(
    departments,
    'name',
  );

  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  const { items, summary } = useSelector(
    getPayboardDailyHoursDashboardInReportData,
  );
  const count = useSelector(getPayboardDailyHoursDashboardInReportCount);

  const isDataLoading = useSelector(
    getPayboardDailyHoursDashboardInDataLoading,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('payboard.date'),
      },
      {
        id: 'department',
        orderConfig: { orderBy: 'department' },
        label: t('payboard.department'),
      },
      {
        id: 'site',
        orderConfig: { orderBy: 'site' },
        label: t('payboard.site'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('payboard.week'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('payboard.year'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('payboard.badge'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('payboard.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('payboard.last_name'),
      },
      {
        id: 'wmsLogin',
        orderConfig: {
          orderBy: 'wmsLogin',
        },
        label: t('payboard.wms_login'),
      },
      {
        id: 'employeeId',
        orderConfig: { orderBy: 'employeeId' },
        label: t('payboard.staffing_id'),
      },
      {
        id: 'supervisor',
        orderConfig: { orderBy: 'supervisor' },
        label: t('payboard.supervisor'),
      },
      {
        id: 'payRate',
        orderConfig: {
          orderBy: 'payRate',
        },
        label: t('payboard.pay_rate'),
      },
      {
        id: 'shift',
        orderConfig: {
          orderBy: 'shift',
        },
        label: t('payboard.shift'),
      },
      {
        id: 'staffing',
        orderConfig: {
          orderBy: 'staffing',
        },
        label: t('payboard.company'),
      },
      {
        id: 'dailyHrs',
        orderConfig: {
          orderBy: 'dailyHrs',
        },
        label: t('payboard.daily_hrs'),
      },
      {
        id: 'stdMarkup',
        orderConfig: {
          orderBy: 'stdMarkup',
        },
        label: t('payboard.std_markup'),
      },
      {
        id: 'costPlus',
        orderConfig: {
          orderBy: 'costPlus',
        },
        label: t('payboard.costPlus'),
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      date: {
        property: 'date',
        operator: 'between' as const,
        value: defaultFilter.where.date.between,
      },
      department: {
        value: '',
        property: 'department',
        operator: 'eq' as const,
      },
      site: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'like' as const,
      },
      wmsLogin: {
        value: '',
        property: 'wmsLogin',
        operator: 'like' as const,
      },
      supervisor: {
        value: '',
        property: 'supervisor',
        operator: 'like' as const,
      },
      shift: {
        value: '',
        property: 'shift',
        operator: 'eq' as const,
      },
      staffing: {
        value: '',
        property: 'staffing',
        operator: 'like' as const,
      },
    }),
    [defaultFilter.where.date],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'date',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
    defaultWhere: defaultFilter.where,
  });

  React.useEffect(() => {
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(
      getPayboardDailyHoursDashboardInReportDataRequest({
        filter,
      }),
    );
  }, [dispatch, filter]);

  React.useEffect(() => {
    dispatch(
      getPayboardDailyHoursDashboardInReportCountRequest({
        filter: { where: filter.where },
      }),
    );
  }, [dispatch, filter.where]);

  return (
    <>
      <TableToolbar>
        <TableTitle>
          {t('payboard.daily_hours_summary_in_table_name')}
        </TableTitle>
        <TableActions>
          <Filter
            onSubmit={(...data) => {
              setLimit(limit);
              setPage(DEFAULT_PAGE);
              onFiltersFormSubmit(...data);
            }}
          >
            <FormFieldContainer>
              <DatetimeRangePicker
                label={getLabel({
                  filterName: 'date',
                  labelPrefix: t('payboard.date'),
                })}
                {...getFilterCommonPropsByFilterName('date')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <SitesComboBoxFilter
                label={getLabel({
                  filterName: 'site',
                  labelPrefix: t('payboard.site'),
                })}
                {...getFilterCommonPropsByFilterName('site')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <ComboboxTableFilter
                label={getLabel({
                  filterName: 'department',
                  labelPrefix: t('payboard.department'),
                })}
                options={React.useMemo(
                  () =>
                    uniqueDepartments.map(
                      (department) =>
                        ({
                          id: department.name,
                          name: department.name,
                        } as any),
                    ),
                  [uniqueDepartments],
                )}
                {...getFilterCommonPropsByFilterName('department')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <ComboboxTableFilter
                label={getLabel({
                  filterName: 'shift',
                  labelPrefix: t('payboard.shift'),
                })}
                options={React.useMemo(
                  () =>
                    uniqueShifts.map(
                      (shift) =>
                        ({
                          id: shift.name,
                          name: shift.name,
                        } as any),
                    ),
                  [uniqueShifts],
                )}
                {...getFilterCommonPropsByFilterName('shift')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <ComboboxTableFilter
                label={getLabel({
                  filterName: 'staffing',
                  labelPrefix: t('payboard.company'),
                })}
                options={React.useMemo(
                  () =>
                    companies.map(
                      (company) =>
                        ({
                          id: company.name,
                          name: company.name,
                        } as any),
                    ),
                  [companies],
                )}
                {...getFilterCommonPropsByFilterName('staffing')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'week',
                  labelPrefix: t('payboard.week'),
                })}
                {...getFilterCommonPropsByFilterName('week')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'year',
                  labelPrefix: t('payboard.year'),
                })}
                {...getFilterCommonPropsByFilterName('year')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'badge',
                  labelPrefix: t('payboard.badge'),
                })}
                {...getFilterCommonPropsByFilterName('badge')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'employeeId',
                  labelPrefix: t('payboard.staffing_id'),
                })}
                {...getFilterCommonPropsByFilterName('employeeId')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'wmsLogin',
                  labelPrefix: t('payboard.wms_login'),
                })}
                {...getFilterCommonPropsByFilterName('wmsLogin')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                label={getLabel({
                  filterName: 'supervisor',
                  labelPrefix: t('payboard.supervisor'),
                })}
                {...getFilterCommonPropsByFilterName('supervisor')}
              />
            </FormFieldContainer>
          </Filter>

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={filter}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />
        </TableActions>
      </TableToolbar>

      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <TableComponent
          tableUniqueKey="setup/time_keeping"
          sx={{
            flexGrow: 0,
            height: isDataLoading || !items.length ? '100%' : 'auto',
            maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
          }}
        >
          {isDataLoading && <MainContentLoader />}

          <TableContent
            data={items as any}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            isDataLoading={isDataLoading}
            onSort={(orderBy, order) => {
              setOrder(order);
              setOrderBy(orderBy);
            }}
          >
            {summary?.length &&
              summary.map((item) => (
                <TableSummary key={item.title} {...item} />
              ))}
          </TableContent>

          <TableBottom>
            <Pagination
              currentPageNumber={page}
              count={count}
              rowsPerPage={limit}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={setLimit}
            />
          </TableBottom>
        </TableComponent>
      </Box>
    </>
  );
};

export const DailyHrsDashboardIn = () => {
  return (
    <TableContextProvider>
      <DailyHrsDashboardInTable />
    </TableContextProvider>
  );
};
