import React from 'react';
import { Box, Typography } from '@mui/joy';
import { AlertTriangleSvg } from 'src/components/svgIcons';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import StackedColumnChart from 'src/components/Charts/2d/StackedColumnChart';
import { SelectForChart } from 'src/components/SelectForChart';
import { OptionForChart } from 'src/components/SelectForChart/OptionForChart';

interface ChartData {
  categories: string[];
  series: Array<{ name: string; data: number[]; type: 'column' }>;
}

export interface IProductionDashboardChartProps {
  isLoading: boolean;
  error: null | Error;
  data: ChartData;
  title: string;
  exportName: string;
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  topNOptions: Array<{ id: string; label: string }>;
  activeTopN: string;
  onActiveTopNChange: (topN: string) => void;
}

export const ProductionDashboardChart = ({
  data,
  error,
  title,
  isLoading,
  exportName,
  yAxis,
  topNOptions,
  activeTopN,
  onActiveTopNChange,
}: IProductionDashboardChartProps) => {
  const [width, setWidth] = React.useState<null | number>(null);

  const {
    toggleFullScreen,
    chartRef,
    exportChart,
    chartHeight,
  } = useChartRequiredUtils();

  React.useEffect(() => {
    if (window.innerWidth) {
      setWidth(window.innerWidth);
    }
  }, []);

  if (error) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <AlertTriangleSvg fill="red" />
            <Box sx={{ ml: 1 }}>
              <Typography color="danger">{error.name ?? 'Error'}</Typography>
            </Box>
          </Box>
          <Typography>{error.message ?? 'Something went wrong'}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <ChartContainer fullHeigh>
      <ChartContainerHeader title={title}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(exportName)}
          />

          <SelectForChart
            value={activeTopN}
            onChange={(_, value) => {
              if (value) {
                onActiveTopNChange(value);
              }
            }}
          >
            {topNOptions.map((option) => (
              <OptionForChart key={option.id} value={option.id}>
                {option.label}
              </OptionForChart>
            ))}
          </SelectForChart>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isLoading}
        showNoData={!data.categories.length && !isLoading}
      >
        <StackedColumnChart
          ref={chartRef}
          height={chartHeight}
          categories={data.categories}
          data={data.series}
          plotOptions={{
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              },
            },
          }}
          yAxis={
            yAxis ?? [
              {
                title: {
                  text: '',
                },
                labels: {
                  format: '{value}',
                },
              },
            ]
          }
          xAxis={
            width && width >= 1550
              ? {
                  labels: {
                    step: 1, // Ensures all categories are displayed
                    rotation: -45,
                  },
                }
              : undefined
          }
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
