import React from 'react';
import { Box, Grid } from '@mui/joy';

import { useTurnoverByReason } from 'src/modules/utils/hooks/turnover.hooks';
import { MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import { TurnoverByReasonChart } from 'src/pages/TurnoverDashboard/components/TurnoverByReasonChart';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  DashboardTurnoverByReasonFilterPanel,
  IDashboardTurnoverByReasonFilters,
} from './DashboardTurnoverByReasonFilterPanel';

export const DashboardTurnoverByReason: React.FC = () => {
  const [filters, setFilters] = React.useState<
    IDashboardTurnoverByReasonFilters
  >({
    siteId: undefined,
    year: new Date().getFullYear(),
  });

  const { data, isDataLoading } = useTurnoverByReason(filters);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <DashboardTurnoverByReasonFilterPanel
          filterInitialData={filters}
          onApplyFilters={setFilters}
          onRemoveFilters={() =>
            setFilters({ siteId: undefined, year: undefined })
          }
        />
      </Box>

      <Grid
        container
        spacing={1}
        sx={{
          position: 'relative',
          minHeight: isDataLoading ? 'calc(100% - 94px)' : 'auto',
        }}
      >
        {isDataLoading && <MainContentLoader />}

        {data.map(({ series, siteName, categories }) => (
          <Grid key={siteName} xs={MUI_SIZE_12} md={MUI_SIZE_6}>
            <TurnoverByReasonChart
              title={siteName}
              categories={categories ?? []}
              showNoData={!series.length}
              series={series ?? []}
              isLoading={isDataLoading}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
