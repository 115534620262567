import React from 'react';
import { SelectProps, Select as JoyUiSelect, Box } from '@mui/joy';
import { ChevronDownSvg } from 'src/components/svgIcons';

export const Select = ({
  listboxOpen,
  onListboxOpenChange,
  indicator,
  ...props
}: SelectProps<any, any>) => {
  const [_listboxOpen, _onListboxOpenChange] = React.useState(
    listboxOpen ?? false,
  );

  const rotate = _listboxOpen ? -180 : 0;

  const _indicator = (
    <Box
      onClick={() => {
        _onListboxOpenChange((prev) => {
          onListboxOpenChange && onListboxOpenChange(!prev);

          return !prev;
        });
      }}
      sx={{
        display: 'flex',
        transform: `rotate(${rotate}deg)`,
        transition: 'transform 200ms',
      }}
    >
      <ChevronDownSvg />
    </Box>
  );

  React.useEffect(() => {
    if (listboxOpen === true || listboxOpen === false) {
      _onListboxOpenChange(listboxOpen);
    }
  }, [listboxOpen]);

  return (
    <>
      {_listboxOpen && (
        <Box
          onClick={() => {
            _onListboxOpenChange((prev) => {
              onListboxOpenChange && onListboxOpenChange(!prev);

              return !prev;
            });
          }}
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}
        />
      )}

      <JoyUiSelect
        listboxOpen={_listboxOpen}
        onListboxOpenChange={(...data) => {
          _onListboxOpenChange((prev) => !prev);
          onListboxOpenChange && onListboxOpenChange(...data);
        }}
        indicator={indicator ?? _indicator}
        {...props}
      />
    </>
  );
};
