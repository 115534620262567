import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { DailyHours as DailyHoursTab } from 'src/pages/TimeKeeping/DailyHours/panels/DailyHours';
import { DailyHrsDashboardIn } from 'src/pages/TimeKeeping/DailyHours/panels/DailyHrsDashboardIn';
import { DailyHrsDashboardTable } from 'src/pages/TimeKeeping/DailyHours/panels/DailyHrsDashboard/DailyHrsDashboardTable';
import { DailyHrsDashboardCharts } from 'src/pages/TimeKeeping/DailyHours/panels/DailyHrsDashboard/DailyHrsDashboardCharts';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { PayboardSummaryByWorkAndSupervisorWeek } from 'src/pages/TimeKeeping/DailyHours/panels/PayboardSummaryByWorkAndSupervisorWeek';
import { InsertedPunchesCount } from './panels/InsertedPunchesCount';

export const DailyHours = () => {
  const { t } = useTranslation();

  const hasUserAccessToDailyHoursTabTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_DAILY_HRS_TAB,
  );
  const hasUserAccessToDailyHrsDashboardTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_TAB,
  );
  const hasUserAccessToDailyHrsDashboardChartTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_CHARTS_TAB,
  );
  const hasUserAccessToDailyHrsDashboardInTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_IN_TAB,
  );
  const hasUserAccessToPayboardByWeekSupervisorTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_BY_WEEK_SUPERVISOR_TAB,
  );
  const hasUserAccessToInsertedPunchesCountTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_INSERTED_PUNCHES_COUNT_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.chart_reports.daily_hours'),
          hasUserAccess: hasUserAccessToDailyHoursTabTab,
          component: <DailyHoursTab />,
        },
        {
          title: t('main_menu.chart_reports.daily_hours_dashboard'),
          hasUserAccess: hasUserAccessToDailyHrsDashboardTab,
          component: <DailyHrsDashboardTable />,
        },
        {
          title: t('main_menu.chart_reports.daily_hours_charts'),
          hasUserAccess: hasUserAccessToDailyHrsDashboardChartTab,
          component: <DailyHrsDashboardCharts />,
        },
        {
          title: t('main_menu.chart_reports.daily_hours_dashboard_in'),
          hasUserAccess: hasUserAccessToDailyHrsDashboardInTab,
          component: <DailyHrsDashboardIn />,
        },
        {
          title: t('main_menu.payboard_summary_by_work_week_and_supervisor'),
          hasUserAccess: hasUserAccessToPayboardByWeekSupervisorTab,
          component: <PayboardSummaryByWorkAndSupervisorWeek />,
        },
        {
          title: t('main_menu.inserted_punches_count'),
          hasUserAccess: hasUserAccessToInsertedPunchesCountTab,
          component: <InsertedPunchesCount />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToDailyHoursTabTab,
      hasUserAccessToPayboardByWeekSupervisorTab,
      hasUserAccessToDailyHrsDashboardChartTab,
      hasUserAccessToDailyHrsDashboardInTab,
      hasUserAccessToDailyHrsDashboardTab,
      hasUserAccessToInsertedPunchesCountTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
