import React from 'react';
import get from 'lodash/get';

import { IHeadCell, Order } from 'src/modules/types/table';
import {
  ITableBodyItem,
  Pagination,
  TableComponent,
  TableContent,
} from 'src/components/Table';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  ITableSelectedItemsActionsProps,
  TableSelectedItemsActions,
} from 'src/components/Table/components/TableSelectedItemsActions';
import { IdsArray } from 'src/modules/types';
import { SxProps } from '@mui/joy/styles/types';

interface ITableWithActionsProps<E, T = E>
  extends Omit<ITableSelectedItemsActionsProps, 'selectedItemsQuantity'> {
  onPageChange: (page: number) => void;
  onSort: (orderBy: string, order: Order) => void;
  onRowsPerPageChange: (rowsCount: number) => void;
  onRowCheckboxClick?: (items: Array<T>) => void;
  selectedItems?: Array<E>;
  data: Array<E>;
  tableUniqueKey: string;
  count: number;
  isDataLoading: boolean;
  selectedIndex?: keyof E;
  limit: number;
  page: number;
  headCells: IHeadCell[];
  order?: Order;
  orderBy?: string;
  heightWithTabs?: boolean;
  sx?: SxProps;
  onTableRowClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: E & ITableBodyItem,
  ) => void;
}

export const TableWithActions = <E,>({
  onPageChange,
  onRowsPerPageChange,
  onSort,
  onRowCheckboxClick,
  order,
  limit,
  page,
  orderBy,
  headCells,
  data,
  count,
  isDataLoading,
  selectedIndex,
  tableUniqueKey,
  selectedItems,
  actionsConfiguration,
  deleteActionConfiguration,
  updateActionConfiguration,
  deleteModelName,
  heightWithTabs,
  onTableRowClick,
  sx,
}: ITableWithActionsProps<E>) => {
  const handlePageChange = (_: any, page: number) => {
    onPageChange(page);
  };

  const handleRowCheckboxClick = (ids: IdsArray) => {
    onRowCheckboxClick &&
      selectedIndex &&
      onRowCheckboxClick(
        data.filter((item) => ids.includes(get(item, [selectedIndex]))),
      );
  };

  const chosenRows = React.useMemo(
    () =>
      selectedIndex && selectedItems?.map((item) => get(item, [selectedIndex])),
    [selectedIndex, selectedItems],
  );

  return (
    <TableComponent
      heightWithTabs={heightWithTabs}
      tableUniqueKey={tableUniqueKey}
      sx={{
        height: isDataLoading || !data.length ? '100%' : 'auto',
        ...sx,
      }}
    >
      {isDataLoading && <MainContentLoader />}

      <TableContent
        data={data as any}
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onSort={onSort}
        {...(onRowCheckboxClick && selectedIndex
          ? { onRowCheckboxClick: handleRowCheckboxClick }
          : {})}
        onTableRowClick={onTableRowClick}
        isDataLoading={isDataLoading}
        selectedRows={chosenRows}
      />

      {Boolean(selectedItems && selectedItems.length) && (
        <TableSelectedItemsActions
          deleteModelName={deleteModelName}
          selectedItemsQuantity={selectedItems?.length ?? 0}
          deleteActionConfiguration={deleteActionConfiguration}
          updateActionConfiguration={updateActionConfiguration}
          actionsConfiguration={actionsConfiguration}
        />
      )}

      <Pagination
        currentPageNumber={page}
        count={count}
        rowsPerPage={limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableComponent>
  );
};
