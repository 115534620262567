import { IWhere } from 'src/modules/types';
import Api from '.';
import {
  IActualVsNeededChartResponseDataItem,
  IHRDashboardEmployeeTurnover,
  ITerminatedByShiftResponseDataItem,
  ITerminationReasonBySiteAndShiftResponseDataItem,
  ITerminationReasonBySiteResponseDataItem,
} from 'src/modules/types/dashboard';

const orderByShift = ['shiftName ASC'];

export const fetchHRDashboardActualVsNeededChartData = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<IActualVsNeededChartResponseDataItem>
  >(`recruiting-dashboard/active-vs-needed`, {
    filter: { where, order: orderByShift },
  });

  return response;
};

export const fetchHRDashboardTerminatedByShiftData = async (where?: IWhere) => {
  const response = await Api.Request.get<
    Array<ITerminatedByShiftResponseDataItem>
  >(`/recruiting-dashboard/terminated-by-shift`, {
    filter: { where, order: orderByShift },
  });

  return response;
};

export const fetchHRDashboardTerminationReasonsBySite = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<ITerminationReasonBySiteResponseDataItem>
  >(`employees/termination-reason-by-site`, { filter: { where } });

  return response;
};

export const fetchHRDashboardTerminationReasonsBySiteAndShift = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<ITerminationReasonBySiteAndShiftResponseDataItem>
  >(`employees/termination-reason-by-site-and-shift`, {
    filter: { where },
  });

  return response;
};

export const fetchHRDashboardEmployeeTurnover = async (where?: IWhere) => {
  const response = await Api.Request.get<Array<IHRDashboardEmployeeTurnover>>(
    `turnover/by-site-and-shift`,
    { filter: { where } },
  );

  return response;
};
