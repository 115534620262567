import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import {
  exportPayboardDayRequest,
  getPayboardDayRequest,
  getPayboardDayCountRequest,
} from '../../../../../modules/actions';
import {
  getPayboardDays,
  getPayboardDayCount,
  getIsPayboardByDayDataLoading,
} from '../../../../../modules/selectors/payboard';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { map } from 'lodash';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFilter,
  useCreateExportProps,
  useFetchSitesCombobox,
  usePayboardDayDefaultFilter,
} from '../../../../../modules/utils/hooks';
import { getDepartmentOptionsByGloballySites } from '../../../../../modules/selectors/department';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import { getShiftOptionsByGloballySites } from '../../../../../modules/selectors/shift';
import { isTableHasDefaultOrder } from 'src/modules/utils/hooks/table';

const PayboardByDay = () => {
  const { t } = useTranslation();

  const defaultFilter = usePayboardDayDefaultFilter();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchDepartmentsCombobox();
    fetchSitesCombobox();
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // fetch seites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);
  // fetch employees shifts list from store
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);

  const dayData = useSelector(getPayboardDays, shallowEqual);
  const count = useSelector(getPayboardDayCount, shallowEqual);

  const exportProps = useCreateExportProps(exportPayboardDayRequest);
  const isTableDataLoading = useSelector(getIsPayboardByDayDataLoading);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'year', disablePadding: false, label: t('payboard.year') },
    { id: 'week', disablePadding: false, label: t('payboard.week') },
    { id: 'yearWeek', disablePadding: false, label: t('payboard.week') },
    { id: 'day', disablePadding: false, label: t('payboard.date') },
    {
      id: 'department',
      disablePadding: false,
      label: t('payboard.department'),
    },
    { id: 'siteName', disablePadding: false, label: t('payboard.site') },
    { id: 'shift', disablePadding: false, label: t('payboard.shift') },
    {
      id: 'totalRegularHrsDec',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.total_reg_hrs'),
    },
    {
      id: 'totalOvertimeHrsDec',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.total_ot_hrs'),
    },
    {
      id: 'totalOvertimePremiumPay',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.total_ot_premium_pay'),
    },
    {
      id: 'totalPay',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.total_pay'),
    },
    {
      id: 'totalMarkupCost',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.total_markup_cost'),
    },
    {
      id: 'otPercentage',
      disableSorting: true,
      disablePadding: false,
      label: t('payboard.overtime'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: sites as ComboBoxOption[],
    },
    {
      name: 'department',
      label: t('payboard.department'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(departments, (department) => ({
            id: department.name,
            name: department.label,
          })),
        [departments],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'shift',
      label: t('payboard.shift'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(shifts, (shift) => ({
            id: shift.name,
            name: shift.name,
          })),
        [shifts],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'year',
      label: t('payboard.year'),
      operator: 'like',
    },
    {
      name: 'week',
      label: t('payboard.week'),
      operator: 'eq',
    },
    {
      name: 'day',
      label: t('payboard.date'),
      type: 'date',
      operator: 'eq',
    },
  ];

  // make request to fetch payboards when component is mounted
  useEffect(() => {
    dispatcher(getPayboardDayRequest(filterList));
    // get total count
    dispatcher(getPayboardDayCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order: tableOrder, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    const order = isTableHasDefaultOrder(tableOrder)
      ? ['day DESC, siteName ASC']
      : tableOrder;

    const filter = {
      limit: rowsPerPage,
      offset,
      where,
      order,
    };

    dispatcher(
      getPayboardDayRequest({
        filter,
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getPayboardDayCountRequest({ filter: { where } }));
  };

  const onRowClickHandler = () => {
    // just a stub handler
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={dayData.items}
        summaryRows={dayData.summary}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="" // if we pass an empty string it will use loop index for the key
        tableName={t('payboard.by_day')}
        headCells={headCells}
        filters={filters}
        disableSelection={true}
        onSync={onSync}
        onRowClick={onRowClickHandler}
        exportProps={exportProps}
        additionalWhereForExport={defaultFilter.where}
        defaultOrder="desc"
        defaultOrderBy={'day' as any}
      />
    </>
  );
};

export default PayboardByDay;
