import React from 'react';
import { getUserObjectFromStorage } from '../../user';
import { AnyObject } from 'src/modules/types';
import { useStateOnMountedOnly } from './reports';

interface RequestParams {
  url: string;
  method?: string;
  headers?: Record<string, string>;
  body?: string | FormData | Record<string, unknown>;
  signal?: AbortSignal;
}

export const useRequest = () => {
  const request = React.useCallback(
    async <T extends AnyObject>({
      url,
      method,
      headers,
      body,
      signal,
    }: RequestParams): Promise<T> => {
      const baseURL = process.env.REACT_APP_API_URL;

      const { auth: { token = '' } = {} } = getUserObjectFromStorage();

      const rawHeaders = {
        'Content-Type': 'application/json',
        ...headers,
      };

      const headersInstance = new Headers();

      Object.keys(rawHeaders).forEach((key) => {
        headersInstance.append(key, (rawHeaders as any)[key]);
      });

      if (token) {
        headersInstance.append('Authorization', `Bearer ${token}`);
      }

      const fullUrl = `${baseURL}${url}`;

      const response = await fetch(fullUrl, {
        method,
        headers: headersInstance,
        body: JSON.stringify(body),
        signal,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData.error ? errorData.error : errorData;
      }

      return await response.json();
    },
    [],
  );

  return request;
};

export const useGetDataForCharts = <T, M extends any[]>(
  func: (...args: M) => Promise<T>,
  { initialData }: { initialData: T },
) => {
  const { data, setData } = useStateOnMountedOnly<T>(initialData);

  const { data: isLoading, setData: setIsLoading } = useStateOnMountedOnly(
    false,
  );

  const { data: error, setData: setError } = useStateOnMountedOnly<
    AnyObject | undefined
  >(undefined);

  const getData = React.useCallback(
    async (...args: M) => {
      try {
        setIsLoading(true);

        const response = await func(...args);

        setData(response);
        setIsLoading(false);
      } catch (e) {
        setError(e as AnyObject);
        setIsLoading(false);
      }
    },
    [func, setData, setError, setIsLoading],
  );

  return React.useMemo(() => ({ getData, isLoading, data, error, setData }), [
    data,
    error,
    isLoading,
    getData,
    setData,
  ]);
};
