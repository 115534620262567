import React from 'react';
import { Box, Typography } from '@mui/joy';
import { AlertTriangleSvg } from 'src/components/svgIcons';

export const ProductionDashboardNoUUIDWarning = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <AlertTriangleSvg fill="red" />
        <Box sx={{ mt: 2 }}>
          <Typography>
            Please include a valid <b>uuid</b> in the query parameters to
            continue
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
