import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPositionsByIds,
  getPositionsComboboxList,
} from '../../selectors/position';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getPositionComboboxListRequest,
  getPositionListRequest,
} from '../../actions/position';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

export const positionInclusions = [
  {
    relation: 'site',
  },
];

const usePositionsComboboxFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteRelationInclusion],
        order: ['name ASC'],
      },
    }),
    [siteRelationInclusion],
  );
};

/**
 * A custom hook to fetch positions from store if they exist otherwise to make a request to fetch needed positions from
 * the server
 */
export const useFetchPositionsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch positions list from store
  const positions = useSelector(
    (state) => getPositionsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(positions)) {
      dispatcher(
        getPositionListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: positionInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch position combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchPositionsCombobox = () => {
  const dispatcher = useDispatch();
  const positionComboboxFilter = usePositionsComboboxFilter();

  const dispatchGetPositionsComboboxListRequest = React.useCallback(() => {
    dispatcher(getPositionComboboxListRequest(positionComboboxFilter));
  }, [positionComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetPositionsComboboxListRequest();
  }, [dispatchGetPositionsComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getPositionsComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetPositionsComboboxListRequest();
    }
  };
};

export const usePositionsDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};
