import React from 'react';
import { Alert } from '@mui/joy';
import { AlertTriangleSvg } from '../svgIcons';
import { Trans, TransProps } from 'react-i18next';

export const RequiredFiltersAlert = (props: TransProps<string>) => {
  return (
    <Alert variant="soft">
      <AlertTriangleSvg />
      <Trans components={{ bold: <strong /> }} {...props} />
    </Alert>
  );
};
