import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  exportStaffingProviderRequest,
  getStaffingProviderListRequest,
  getStaffingProviderCountRequest,
  deleteStaffingProviderRequest,
} from 'src/modules/actions';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  getStaffingProviderCount,
  getStaffingProviderList,
  isStaffingProviderDataLoading,
} from 'src/modules/selectors/staffingProvider';
import { CreateStaffingProvider } from './StaffingProvidersForms/CreateStaffingProvider';
import { UpdateStaffingProviders } from './StaffingProvidersForms/UpdateStaffingProviders';

export const StaffingProvidersList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportStaffingProviderRequest);

  const isDataLoading = useSelector(isStaffingProviderDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.staffingProvider.create.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.staffingProvider.update.id,
  );

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.staffingProvider.delete.id,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'staffingProvider',
        orderConfig: { orderBy: 'staffingProvider' },
        label: t('staffing_prov.name'),
      },
      {
        id: 'markup',
        orderConfig: { orderBy: 'markup' },
        label: t('staffing_prov.markup'),
      },
      {
        id: 'ngroupMarkup',
        orderConfig: { orderBy: 'ngroupMarkup' },
        label: t('staffing_prov.ng_markup'),
      },
      {
        id: 'ptoMarkup',
        orderConfig: { orderBy: 'ptoMarkup' },
        label: t('staffing_prov.pto_markup'),
      },
      {
        id: 'holidayMarkup',
        orderConfig: { orderBy: 'holidayMarkup' },
        label: t('staffing_prov.holiday_markup'),
      },
      {
        id: 'etoMarkup',
        orderConfig: { orderBy: 'etoMarkup' },
        label: t('staffing_prov.eto_markup'),
      },
      {
        id: 'securityName',
        orderConfig: { orderBy: 'securityName' },
        label: t('staffing_prov.sec_name'),
      },
      {
        id: 'otMarkup',
        orderConfig: { orderBy: 'otMarkup' },
        label: t('staffing_prov.ot_markup'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      staffingProvider: {
        value: '',
        property: 'staffingProvider',
        operator: 'like' as const,
      },
      markup: {
        value: '',
        property: 'markup',
        operator: 'like' as const,
      },
      ngroupMarkup: {
        value: '',
        property: 'ngroupMarkup',
        operator: 'like' as const,
      },
      ptoMarkup: {
        value: '',
        property: 'ptoMarkup',
        operator: 'like' as const,
      },
      holidayMarkup: {
        value: '',
        property: 'holidayMarkup',
        operator: 'like' as const,
      },
      etoMarkup: {
        value: '',
        property: 'etoMarkup',
        operator: 'like' as const,
      },
      securityName: {
        value: '',
        property: 'securityName',
        operator: 'like' as const,
      },
      otMarkup: {
        value: '',
        property: 'otMarkup',
        operator: 'like' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getStaffingProviderList,
    selectCount: getStaffingProviderCount,
    getEntitiesRequest: getStaffingProviderListRequest,
    getEntitiesCountRequest: getStaffingProviderCountRequest,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];

  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  const onDelete = () => {
    clearFilters();

    dispatch(
      deleteStaffingProviderRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('staffing_prov.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'staffingProvider',
                      labelPrefix: t('staffing_prov.name'),
                    })}
                    {...getFilterCommonPropsByFilterName('staffingProvider')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'markup',
                      labelPrefix: t('staffing_prov.markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('markup')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'ngroupMarkup',
                      labelPrefix: t('staffing_prov.ng_markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('ngroupMarkup')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'ptoMarkup',
                      labelPrefix: t('staffing_prov.pto_markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('ptoMarkup')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'holidayMarkup',
                      labelPrefix: t('staffing_prov.holiday_markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('holidayMarkup')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'etoMarkup',
                      labelPrefix: t('staffing_prov.eto_markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('etoMarkup')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'securityName',
                      labelPrefix: t('staffing_prov.sec_name'),
                    })}
                    {...getFilterCommonPropsByFilterName('securityName')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'otMarkup',
                      labelPrefix: t('staffing_prov.ot_markup'),
                    })}
                    {...getFilterCommonPropsByFilterName('otMarkup')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/staffing_providers"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.StaffingProvider}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateStaffingProvider
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateStaffingProviders
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        staffingProvidersToUpdateIds={selectedEntitiesIds}
      />
    </PageContentWithTopToolbar>
  );
};
