import React from 'react';
import { Report, ReportPage } from 'src/components/ReportPage';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { WeeklyGrossMargin } from './WeeklyGrossMargin';
import { MonthlyGrossMargin } from './MonthlyGrossMargin';
import { PaymentStatistic } from './PaymentStatistic';
import { WeeklyGrossMarginChart } from './WeeklyGrossMarginChart';
import { PaymentStatisticChart } from './PaymentStatisticChart';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { useTranslation } from 'react-i18next';
import {
  ExecutivesReportsFilterPanel,
  IExecutivesReportsFilterPanelFilters,
} from './ExecutiveReportsFilterPanel';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import {
  EmployeesStatisticTable,
  EmployeesStatisticChart,
} from './EmployeesStatisticReport';

const executiveReportsDefaultFilters: IExecutivesReportsFilterPanelFilters = {
  yearNumber: undefined,
  weekNumber: undefined,
  siteId: undefined,
  yearWeekRange: {
    from: undefined,
    to: undefined,
  },
};

export const ExecutiveReports = () => {
  const { t } = useTranslation();

  const reportProps = useReportsProps();

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IExecutivesReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'yearNumber', label: t('productions_uph_reports.year') },
      { id: 'weekNumber', label: t('productions_uph_reports.week') },
      { id: 'yearWeekRange', label: t('common.year_week_range') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IExecutivesReportsFilterPanelFilters
  >(executiveReportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage>
        <ReportTableToolbar reportName={t('main_menu.executive_reports.main')}>
          <SelectHeadCells />
        </ReportTableToolbar>
        <ReportPageItemContainer>
          <ExecutivesReportsFilterPanel
            hideSyncBtn
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() =>
              setAppliedFilters(executiveReportsDefaultFilters)
            }
          />
        </ReportPageItemContainer>

        <Report {...reportProps.weeklyExecutiveGrossMargin}>
          <ReportPageItemContainer>
            <WeeklyGrossMarginChart
              from={appliedFilters.yearWeekRange?.from}
              to={appliedFilters.yearWeekRange?.to}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              yearNumber={appliedFilters.yearNumber}
              weekNumber={appliedFilters.weekNumber}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.monthlyExecutiveGrossMargin}>
          <ReportPageItemContainer>
            <MonthlyGrossMargin
              siteId={appliedFilters.siteId}
              year={appliedFilters.yearNumber}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.employeesStatistic}>
          <ReportPageItemContainer>
            <EmployeesStatisticChart
              from={appliedFilters.yearWeekRange?.from}
              to={appliedFilters.yearWeekRange?.to}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer>
            <EmployeesStatisticTable
              year={appliedFilters.yearNumber}
              week={appliedFilters.weekNumber}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportProps.paymentStatistic}>
          <ReportPageItemContainer>
            <PaymentStatisticChart
              from={appliedFilters.yearWeekRange?.from}
              to={appliedFilters.yearWeekRange?.to}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <PaymentStatistic
              siteId={appliedFilters.siteId}
              year={appliedFilters.yearNumber}
              week={appliedFilters.weekNumber}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
