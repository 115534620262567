import { JSONSchemaType } from 'ajv';
import { i18n } from 'src/modules/utils/i18n';

import { ICreateQuestionCategoryTypeRequest } from 'src/modules/types';

export const createQuestionCategoryTypeScheme: JSONSchemaType<ICreateQuestionCategoryTypeRequest> = {
  type: 'object',
  required: ['name', 'siteId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    pointCollectionType: {
      type: 'string',
      nullable: true,
    },
  },
};
