import React from 'react';
import { Box } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface IDataGridNgroupContainerProps {
  children: React.ReactNode;
  sx?: SxProps;
}

export const DataGridNgroupContainer = ({
  children,
  sx,
}: IDataGridNgroupContainerProps) => {
  return (
    <Box
      sx={{ height: '100%', maxHeight: 'calc(100% - 36px)', flex: 1, ...sx }}
    >
      {children}
    </Box>
  );
};
