import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { DepartmentsComboBoxFilter } from '../../../components/Table/components/TableActions/components/Filter/components/DepartmentsComboBoxFilter';
import { ShiftsComboBoxFilter } from '../../../components/Table/components/TableActions/components/Filter/components/ShiftsComboBoxFilter';
import { EmployeesComboBoxFilter } from '../../../components/Table/components/TableActions/components/Filter/components/EmployeesComboBoxFilter';

interface ICostPlusWeeklyFilterFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const CostPlusWeeklyFilterFilter: React.FC<ICostPlusWeeklyFilterFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('employees.terminated.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <EmployeesComboBoxFilter
          label={getLabel({
            filterName: 'employeeId',
            labelPrefix: t('cost_plus_weekly.employee'),
          })}
          {...getFilterCommonPropsByFilterName('employeeId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <DepartmentsComboBoxFilter
          propertyAsId="name"
          label={getLabel({
            filterName: 'department',
            labelPrefix: t('cost_plus_weekly.department'),
          })}
          {...getFilterCommonPropsByFilterName('department')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ShiftsComboBoxFilter
          propertyAsId="name"
          label={getLabel({
            filterName: 'shift',
            labelPrefix: t('cost_plus_weekly.shift'),
          })}
          {...getFilterCommonPropsByFilterName('shift')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'week',
            labelPrefix: t('cost_plus_weekly.week'),
          })}
          {...getFilterCommonPropsByFilterName('week')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'year',
            labelPrefix: t('cost_plus_weekly.year'),
          })}
          {...getFilterCommonPropsByFilterName('year')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
