import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import {
  ICreateSkillRequest,
  IFilterData,
  IWhere,
  SkillTerm,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createSkillRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { createSkillScheme } from 'src/modules/schemes/skills';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';

const initialValues: ICreateSkillRequest = {
  name: '',
  siteId: NaN,
  skillTerm: 'expiration', // 'expiration' | 'permanent'
  expirationPeriod: null,
};

export const isExpiration = (value: SkillTerm) => value === 'expiration';
export const isPermanent = (value: SkillTerm) => value === 'permanent';

interface ICreateSkillProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateSkill = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateSkillProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createSkillScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: ({ expirationPeriod, name, siteId, skillTerm }) => {
      const sendData = {
        expirationPeriod,
        name,
        siteId,
        hasExpiration: isExpiration(skillTerm),
        isPermanent: isPermanent(skillTerm),
      };

      dispatch(
        createSkillRequest({
          data: sendData,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('skills.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="name"
                label={t('skills.name')}
                name="name"
                autoComplete="name"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                label={t('skills.site')}
                placeholder={t('skills.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormFiledButtonsGroupItemContainer>
                <FormikButtonGroup
                  id={`skillTerm`}
                  label={t('emp_skills.has_expiration_date')}
                  formik={formik}
                  config={[
                    {
                      label: t('common.yes'),
                      value: 'expiration',
                    },
                    {
                      label: t('common.no'),
                      value: 'permanent',
                    },
                  ]}
                />
              </FormFiledButtonsGroupItemContainer>
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="expirationPeriod"
                name="expirationPeriod"
                label={t('emp_skills.expiration_period')}
                formik={formik}
                type="number"
                required={isExpiration(formik.values.skillTerm)}
                disabled={isPermanent(formik.values.skillTerm)}
                // required={formik.values.hasExpiration}
                // disabled={!formik.values.hasExpiration || formik.values.isPermanent}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
