import React from 'react';
import { Box } from '@mui/joy';
import { useSearchParams } from 'react-router-dom';
import { useRequest } from 'src/modules/utils/hooks/common/request';
import { ProductionDashboardChart } from './ProductionDashboardChart';
import { ProductionDashboardNoUUIDWarning } from './ProductionDashboardNoUUIDWarning';
import { useTranslation } from 'react-i18next';
import {
  getTopNData,
  mapTopNumberStringToNumber,
} from './productionDashboard.helpers';

interface IResponseChartData {
  categories: string[];
  series: Array<{ name: string; data: number[]; type: 'column' }>;
}

interface IChartData {
  isLoading: boolean;
  error: null | Error;
  data: IResponseChartData;
}

const defaultChartData = {
  isLoading: true,
  error: null,
  data: {
    series: [],
    categories: [],
  },
};

const defaultTopN = 'top10';

export const ProductionDashboard = () => {
  const { t } = useTranslation();

  const request = useRequest();

  const [
    activeHoursCurrentWeekTopN,
    setActiveHoursCurrentWeekTopN,
  ] = React.useState<string>(defaultTopN);
  const [
    activeEmployeesInDepartmentTopN,
    setActiveEmployeesInDepartmentTopN,
  ] = React.useState<string>(defaultTopN);
  const [
    activeHoursByDepartmentTopN,
    setActiveHoursByDepartmentTopN,
  ] = React.useState<string>(defaultTopN);
  const [
    activeOtHoursCurrentWeekTopN,
    setActiveOtHoursCurrentWeekTopN,
  ] = React.useState<string>(defaultTopN);

  const [searchParams] = useSearchParams();

  const uuid = searchParams.get('uuid');

  const topNOptions = React.useMemo(
    () => [
      { id: 'top10' as const, label: t('common.top_n', { n: 10 }) },
      { id: 'top30' as const, label: t('common.top_n', { n: 30 }) },
      { id: 'all' as const, label: t('common.all') },
    ],
    [t],
  );

  const [employeesInDepartment, setEmployeesInDepartment] = React.useState<
    IChartData
  >(defaultChartData);
  const [hoursByDepartment, setHoursByDepartment] = React.useState<IChartData>(
    defaultChartData,
  );
  const [hoursCurrentWeek, setHoursCurrentWeek] = React.useState<IChartData>(
    defaultChartData,
  );
  const [otHoursCurrentWeek, setOtHoursCurrentWeek] = React.useState<
    IChartData
  >(defaultChartData);

  const fetchChartData = React.useCallback(
    async (url: string) => {
      let error = null;
      let data: IResponseChartData = {
        series: [],
        categories: [],
      };

      try {
        data = await request<IResponseChartData>({ url, method: 'GET' });
      } catch (e) {
        error = e as Error;
      }

      return { data, error };
    },
    [request],
  );

  React.useEffect(() => {
    const fetchAllData = async () => {
      const employeesInDepartmentData = await fetchChartData(
        `/production-dashboard/employees-in-department-by-shift/${uuid}`,
      );
      const hoursByDepartmentData = await fetchChartData(
        `/production-dashboard/get-hours-by-department-and-shift/${uuid}`,
      );
      const hoursCurrentWeekData = await fetchChartData(
        `/production-dashboard/hours-by-department-and-shift-current-week/${uuid}`,
      );
      const otHoursCurrentWeekData = await fetchChartData(
        `/production-dashboard/ot-hours-by-department-and-shift-current-week/${uuid}`,
      );

      setEmployeesInDepartment({
        isLoading: false,
        ...employeesInDepartmentData,
      });
      setHoursByDepartment({
        isLoading: false,
        ...hoursByDepartmentData,
      });
      setHoursCurrentWeek({
        isLoading: false,
        ...hoursCurrentWeekData,
      });
      setOtHoursCurrentWeek({
        isLoading: false,
        ...otHoursCurrentWeekData,
      });
    };

    const intervalId = setInterval(() => {
      if (uuid) {
        fetchAllData();
      }
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Fetch data immediately when the component mounts or when uuid changes
    if (uuid) {
      fetchAllData();
    }

    // Cleanup function to clear the interval when the component unmounts or when uuid changes
    return () => clearInterval(intervalId);
  }, [fetchChartData, uuid]);

  const employeesInDepartmentWithTopFilter = React.useMemo(() => {
    return {
      ...employeesInDepartment,
      data: getTopNData(
        employeesInDepartment.data,
        mapTopNumberStringToNumber(
          employeesInDepartment.data,
          activeEmployeesInDepartmentTopN,
        ),
      ),
    };
  }, [activeEmployeesInDepartmentTopN, employeesInDepartment]);
  const hoursByDepartmentWithTopFilter = React.useMemo(() => {
    return {
      ...hoursByDepartment,
      data: getTopNData(
        hoursByDepartment.data,
        mapTopNumberStringToNumber(
          hoursByDepartment.data,
          activeHoursByDepartmentTopN,
        ),
      ),
    };
  }, [activeHoursByDepartmentTopN, hoursByDepartment]);
  const hoursCurrentWeekWithTopFilter = React.useMemo(() => {
    return {
      ...hoursCurrentWeek,
      data: getTopNData(
        hoursCurrentWeek.data,
        mapTopNumberStringToNumber(
          hoursCurrentWeek.data,
          activeHoursCurrentWeekTopN,
        ),
      ),
    };
  }, [activeHoursCurrentWeekTopN, hoursCurrentWeek]);
  const otHoursCurrentWeekWithTopFilter = React.useMemo(() => {
    return {
      ...otHoursCurrentWeek,
      data: getTopNData(
        otHoursCurrentWeek.data,
        mapTopNumberStringToNumber(
          otHoursCurrentWeek.data,
          activeOtHoursCurrentWeekTopN,
        ),
      ),
    };
  }, [activeOtHoursCurrentWeekTopN, otHoursCurrentWeek]);

  if (!uuid) {
    return <ProductionDashboardNoUUIDWarning />;
  }

  return (
    <Box
      sx={{
        margin: 0,
        p: 1,
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gap: 1,
      }}
    >
      <Box>
        <ProductionDashboardChart
          topNOptions={topNOptions}
          activeTopN={activeEmployeesInDepartmentTopN}
          onActiveTopNChange={setActiveEmployeesInDepartmentTopN}
          title="Employees in Departments"
          exportName="Employees in Departments"
          yAxis={{
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
            },
            tickInterval: 1,
          }}
          {...employeesInDepartmentWithTopFilter}
        />
      </Box>
      <Box>
        <ProductionDashboardChart
          topNOptions={topNOptions}
          activeTopN={activeHoursByDepartmentTopN}
          onActiveTopNChange={setActiveHoursByDepartmentTopN}
          title="Hours by Department"
          exportName="Hours by Department"
          {...hoursByDepartmentWithTopFilter}
        />
      </Box>
      <Box>
        <ProductionDashboardChart
          topNOptions={topNOptions}
          activeTopN={activeHoursCurrentWeekTopN}
          onActiveTopNChange={setActiveHoursCurrentWeekTopN}
          title="Hours Current Week"
          exportName="Hours Current Week"
          {...hoursCurrentWeekWithTopFilter}
        />
      </Box>
      <Box>
        <ProductionDashboardChart
          topNOptions={topNOptions}
          activeTopN={activeOtHoursCurrentWeekTopN}
          onActiveTopNChange={setActiveOtHoursCurrentWeekTopN}
          title="OT Hours Current Week"
          exportName="OT Hours Current Week"
          {...otHoursCurrentWeekWithTopFilter}
        />
      </Box>
    </Box>
  );
};
