import { i18n } from 'src/modules/utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';

import { IUpdateQuestionCategoryType } from 'src/modules/types';
import { createQuestionCategoryTypeScheme } from 'src/modules/schemes/question-category-type/create-question-category-type.scheme';

export const updateQuestionCategoryTypesScheme: PartialSchema<
  IUpdateQuestionCategoryType[]
> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createQuestionCategoryTypeScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createQuestionCategoryTypeScheme.properties,
    },
  },
};
