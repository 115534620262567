import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import {
  addProcessStatus,
  deleteQuestionEmployeeFailed,
  deleteQuestionEmployeeSuccess,
  getQuestionEmployeeHistoryDataCountRequest,
  getQuestionEmployeeHistoryDataRequest,
} from 'src/modules/actions';
import { getQuestionCategoriesComboboxList } from 'src/modules/selectors/questionCategory';
import {
  getEmployeesReview,
  getIsQuestionEmployeeHistoryDataRequestInProgress,
  getEmployeesReviewCount,
} from 'src/modules/selectors/questionEmployee';
import { IdsArray } from 'src/modules/types';
import { Api } from 'src/modules/utils';
import { useFetchQuestionCategoriesCombobox } from 'src/modules/utils/hooks/questionCategory';
import { UpdateReviews } from './UpdateReviews';
import { ICustomAction } from 'src/components/EnhancedTable/EnhancedTableSelectedItemsActions';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { EyeSvg } from 'src/components/svgIcons';
import { ViewReviews } from './ViewReviews';
import { useEmployeesReviewDefaultFilters } from 'src/modules/utils/hooks/employees-review.hooks';
import { FilesPreview } from 'src/components/FilesPreview';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const Reviews = () => {
  const { t } = useTranslation();

  const [selectedReviewsIds, setSelectedReviewsIds] = React.useState<IdsArray>(
    [],
  );
  const [
    isUpdateReviewsFormVisible,
    setIsUpdateReviewsFormVisible,
  ] = React.useState(false);
  const [isViewAnswersVisible, setIsViewAnswersVisible] = React.useState(false);

  const allowedToUpdateAnswers = useHasUserAccessToAction(
    manageEntitiesConfig.employee_review.update.id,
  );

  const defaultFilter = useEmployeesReviewDefaultFilters();

  const fetchQuestionCategoriesCombobox = useFetchQuestionCategoriesCombobox();

  const questionCategoryComboboxList = useSelector(
    getQuestionCategoriesComboboxList,
  );
  const questionCategoryOptionsWithSites = questionCategoryComboboxList.map(
    (option) => ({ ...option, name: `${option.name} (${option.site.name})` }),
  );

  const isRequestInProgress = useSelector(
    getIsQuestionEmployeeHistoryDataRequestInProgress,
  );

  const dispatch = useDispatch();

  const list = useSelector(getEmployeesReview);
  const count = useSelector(getEmployeesReviewCount);

  const tableList = React.useMemo(() => {
    return list.map((item) => ({
      ...item,
      isSigned: Boolean(item.signatureUrl) ? <YesIcon /> : <NoIcon />,
      attachments: item.attachments ? (
        <FilesPreview urls={item.attachments.map(({ url }) => url)} />
      ) : null,
    }));
  }, [list]);

  const filters: ITableFilter[] = [
    {
      name: 'answerDate',
      label: t('questionnaire_history.answer_date'),
      operator: 'like',
      type: 'date',
    },
    {
      name: 'employeeId',
      label: t('questionnaire_history.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
    },
    {
      name: 'questionCategoryId',
      label: t('questionnaire_history.question_category'),
      operator: 'eq',
      type: 'combobox',
      options: questionCategoryOptionsWithSites,
    },
  ];

  const headCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('emp_skills.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('emp_skills.emp_last_name'),
    },
    {
      id: 'questionCategory.name',
      disablePadding: false,
      label: t('questionnaire_history.question_category'),
    },
    {
      id: 'reviewedAt',
      disablePadding: false,
      label: t('questionnaire_history.answer_date'),
    },
    {
      id: 'isSigned',
      disablePadding: false,
      orderByAnotherField: 'signatureUrl',
      label: t('questionnaire_history.is_signed'),
    },
    {
      id: 'attachments',
      disablePadding: false,
      label: t('common.attachments'),
    },
  ];

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;

    dispatch(
      getQuestionEmployeeHistoryDataRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where: { ...defaultFilter.where, ...where },
          order,
          include: newInclude,
        },
      }),
    );

    dispatch(
      getQuestionEmployeeHistoryDataCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  const handleDeletion = async (elementIndexes: IdsArray) => {
    try {
      await Api.QuestionEmployee.deleteReview({
        where: {
          id: {
            inq: elementIndexes,
          },
        },
      });

      dispatch(deleteQuestionEmployeeSuccess());

      dispatch(
        getQuestionEmployeeHistoryDataRequest({ filter: defaultFilter }),
      );

      dispatch(
        getQuestionEmployeeHistoryDataCountRequest({ filter: defaultFilter }),
      );

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
    } catch {
      dispatch(deleteQuestionEmployeeFailed());

      dispatch(
        addProcessStatus({
          variant: 'error',
          title: 'common.error',
        }),
      );
    }
  };

  const handleUpdating = (ids: IdsArray) => {
    setSelectedReviewsIds(ids);
    setIsUpdateReviewsFormVisible(true);
  };

  const handleViewAnswersClick = (ids: IdsArray) => {
    setSelectedReviewsIds(ids);
    setIsViewAnswersVisible(true);
  };

  const customActions: Array<ICustomAction> = [
    {
      title: t('questionnaire_history.view_answers'),
      icon: <EyeSvg />,
      onClick: handleViewAnswersClick,
    },
  ];

  React.useEffect(() => {
    fetchQuestionCategoriesCombobox();

    dispatch(getQuestionEmployeeHistoryDataRequest({ filter: defaultFilter }));
    dispatch(
      getQuestionEmployeeHistoryDataCountRequest({ filter: defaultFilter }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EnhancedTable
        disableQsFilters
        isTableDataLoading={isRequestInProgress}
        data={tableList}
        count={count}
        selectIndex="id"
        tableName={t('questionnaire_history.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={handleDeletion}
        onUpdate={allowedToUpdateAnswers ? handleUpdating : undefined}
        customActions={customActions}
        defaultOrder="desc"
        defaultOrderBy="reviewedAt"
      />

      <UpdateReviews
        reviewsIds={selectedReviewsIds}
        isOpen={isUpdateReviewsFormVisible}
        onClose={() => setIsUpdateReviewsFormVisible(false)}
        filterList={defaultFilter ?? {}}
        filterCount={defaultFilter}
      />

      <ViewReviews
        reviewsIds={selectedReviewsIds}
        isOpen={isViewAnswersVisible}
        onClose={() => setIsViewAnswersVisible(false)}
      />
    </>
  );
};
