import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { IStandardMeta, IStandardModel } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { generateUpdateStandardScheme } from 'src/modules/schemes/standards';
import { map } from 'lodash';

interface IUpdateStandardsProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestStart: () => void;
  onRequestEnd: () => void;
  standards: (Pick<
    IStandardModel,
    'siteId' | 'id' | 'site' | 'standard' | 'baseTime'
  > &
    IStandardMeta)[];
}

export const UpdateStandards = ({
  isOpen,
  onClose,
  onRequestEnd,
  onRequestStart,
  standards,
}: IUpdateStandardsProps) => {
  const { t } = useTranslation();

  const metatagNames = useSelector(getMetatagsNames);

  const sitesIdSet = new Set<number>();
  standards.forEach(({ siteId }) => sitesIdSet.add(siteId));
  const sitesId = [...sitesIdSet.values()];
  const metatags = sitesId.reduce<string[]>((all, siteId) => {
    if (metatagNames[siteId]) {
      return [...all, ...metatagNames[siteId]];
    }
    return all;
  }, []);
  const updateStandardsScheme = generateUpdateStandardScheme(metatags);
  const validate = useValidate(updateStandardsScheme);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: standards,
    validate,
    onSubmit: async (data) => {
      onRequestStart();

      try {
        await Api.Standard.bulkUpdate(data);
        onClose();
        onRequestEnd();
      } catch {
        onClose();
        onRequestEnd();
      }
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  const getMetatagsBySite = React.useCallback(
    (siteId: number) => metatagNames[siteId] ?? [],
    [metatagNames],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('standards.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {standards.map((standard, index) => (
              <DrawerBodySectionContent key={standard.id}>
                <DrawerBodySectionTitle>{`${standard.id}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('standards.standard')}
                    id={`${index}.standard`}
                    name={`${index}.standard`}
                    autoComplete="standard"
                    formik={formik}
                    type="number"
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('standards.base_time')}
                    id={`${index}.baseTime`}
                    name={`${index}.baseTime`}
                    autoComplete="baseTime"
                    formik={formik}
                    type="number"
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                {map(getMetatagsBySite(standard.siteId), (metatag: string) => (
                  <FormFieldContainer key={`metatag-${metatag}`}>
                    <FormikTextField
                      variant="outlined"
                      required
                      fullWidth
                      label={metatag}
                      id={`${index}.${metatag}`}
                      name={`${index}.${metatag}`}
                      autoComplete={metatag}
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>
                ))}
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
