import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { omit } from 'lodash';
import { useFormik } from 'formik';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { IPointCollectionModel } from 'src/modules/types';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

interface IPointCollectionUpsertProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (data: IPointCollectionModel) => void;
  onDelete: () => void;
  row?: IPointCollectionModel;
}

const initialData: IPointCollectionModel = {
  siteId: NaN,
  noReturn: NaN,
  verbalWarning: NaN,
  writtenWarning: NaN,
};

export const PointCollectionUpsert = ({
  isOpen,
  onClose,
  onUpdate,
  onDelete,
  row,
}: IPointCollectionUpsertProps) => {
  const { t } = useTranslation();

  const validationSchemaForSiteId = Yup.object().shape({
    siteId: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required')),
    verbalWarning: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required')),
    writtenWarning: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required'))
      .moreThan(
        Yup.ref('verbalWarning'),
        t('error.should_more_than', { anotherProperty: 'Verbal Warning' }),
      ),
    noReturn: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required'))
      .moreThan(
        Yup.ref('writtenWarning'),
        t('error.should_more_than', { anotherProperty: 'Written Warning' }),
      ),
  });

  const initialValues = React.useMemo(() => row ?? initialData, [row]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaForSiteId,
    onSubmit: () => {
      formik.resetForm();
      onUpdate(omit(formik.values, ['site']) as IPointCollectionModel);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('point_collections.manage')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              formik={formik}
              label={t('point_collections.site')}
              placeholder={t('point_collections.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('point_collections.verb_warn')}
              id="verbalWarning"
              name="verbalWarning"
              type="number"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('point_collections.writ_warn')}
              id="writtenWarning"
              name="writtenWarning"
              type="number"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('point_collections.no_return')}
              id="noReturn"
              name="noReturn"
              type="number"
              formik={formik}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          applyButtonType="submit"
          onApply={formik.handleSubmit}
          {...(row ? { onDelete } : {})}
        />
      </DrawerFooter>
    </Drawer>
  );
};
