import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  useCreateUserValidationScheme,
  useGenerateInitialValues,
  usePrepareUserWithAttributes,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  ICreateUserBaseFields,
  IFilterData,
  IWhere,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createUserRequest } from 'src/modules/actions';
import { getDepartmentsComboboxListBySites } from 'src/modules/selectors/department';
import { roles } from 'src/config';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getShiftsComboboxListBySites } from 'src/modules/selectors/shift';
import { useRenderUserOtherFields } from 'src/modules/utils/hooks/useRenderUserOtherFields';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import RolesCombobox from 'src/components/Formik/comboboxes-with-entities/RolesCombobox';

interface ICreateUserProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateUser = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateUserProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const initialRole = roles.GM;

  const [role, setRole] = React.useState<string>(initialRole);

  const initialValues = useGenerateInitialValues(role);

  const getSchema = useCreateUserValidationScheme();

  const prepareRequestData = usePrepareUserWithAttributes();

  const validate = useValidate<ICreateUserBaseFields>(getSchema(role));

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (data) => {
      dispatch(
        createUserRequest({
          data: prepareRequestData(data, {
            siteId: true,
            clientId: true,
            staffingId: true,
          }),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const sites = useSelector(getSitesComboboxList);
  const shifts = useSelector(getShiftsComboboxListBySites)(
    formik.values.sites ?? [],
  );
  const departments = useSelector(getDepartmentsComboboxListBySites)(
    formik.values.sites ?? [],
  );
  const clients = useSelector(getClientsComboboxList) as ComboBoxOption[];
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
  ) as ComboBoxOption[];

  const getOtherFields = useRenderUserOtherFields({
    formik,
    sites,
    shifts,
    departments,
    staffingProviders,
    clients,
  });

  const onRoleChange = (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    if (value?.id) {
      setRole((value.id as unknown) as string);
    }
  };

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('users.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <RolesCombobox
                id="role"
                name="role"
                required
                onChange={onRoleChange}
                label={t('common.role')}
                placeholder={t('common.role')}
                fieldAsId="key"
                formik={formik}
              />
            </FormFieldContainer>

            {getOtherFields()}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
