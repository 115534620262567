import React from 'react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  TableTitle,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
  Pagination,
  TableBottom,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { addProcessStatus } from 'src/modules/actions';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { Api, useFetchResources } from 'src/modules/utils';
import {
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IIpWhitelistModel } from 'src/modules/types/ip_whitelist';
import { CreateIpWhitelistItem } from './IpWhitelistForms/CreateIpWhitelistItem';
import { UpdateIpWhitelistItems } from './IpWhitelistForms/UpdateIpWhitelistItems';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { AnyObject } from 'src/modules/types';

export const IpWhitelist = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const fetchResources = useFetchResources();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [isDataManaging, setIsDataManaging] = React.useState(false);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.ipWhitelist.create.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.ipWhitelist.update.id,
  );

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.ipWhitelist.delete.id,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'IPv4',
        label: 'IPv4',
      },
      {
        id: 'IPv6',
        label: 'IPv6',
      },
      {
        id: 'resource',
        label: t('ip_whitelist.resource'),
      },
      {
        id: 'method',
        label: t('ip_whitelist.method'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      IPv4: {
        value: '',
        property: 'IPv4',
        operator: 'like' as const,
      },
      IPv6: {
        value: '',
        property: 'IPv6',
        operator: 'like' as const,
      },
      resource: {
        value: '',
        property: 'resource',
        operator: 'like' as const,
      },
      method: {
        value: '',
        property: 'method',
        operator: 'like' as const,
      },
    }),
    [],
  );

  const {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    page,
    limit,
    setLimit,
    setPage,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'id',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    getFilterCommonPropsByFilterName,
    onFiltersFormSubmit,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    onFiltersFormSubmit(filters);
  };

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    Array<IIpWhitelistModel>
  >('/ip-white-list', []);

  const {
    data: { count },
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<{ count: number }>('/ip-white-list/count', {
    count: 0,
  });

  const selectedEntities = React.useMemo(() => {
    return data.filter((item) => selectedEntitiesIds.includes(item.id));
  }, [data, selectedEntitiesIds]);

  const onRequestStart = () => setIsDataManaging(true);
  const onRequestEnd = () => {
    setIsDataManaging(false);

    fetchData({
      filter,
    });

    fetchCountData({
      filter,
    });
  };

  const onDelete = async () => {
    onRequestStart();

    const query = new URLSearchParams(
      selectedEntitiesIds.map((id) => ['ids', id.toString()]),
    );
    await Api.IpWhitelist.delete(query.toString());

    fetchData({ filter });
    fetchCountData({ filter });

    dispatch(
      addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    setSelectedEntitiesIds([]);

    onRequestEnd();
  };

  React.useEffect(() => {
    fetchData({ filter });
    fetchCountData({ filter });
  }, [fetchData, fetchCountData, filter]);

  React.useEffect(() => {
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('ip_whitelist.main')}</TableTitle>

            <TableActions>
              {/**
               * TODO: there is no support for filters on server side in prisma,
               * uncomment it once it is implemented
               */}
              <Filter onSubmit={handleFiltersSubmit}>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'IPv4',
                      labelPrefix: 'IPv4',
                    })}
                    {...getFilterCommonPropsByFilterName('IPv4')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'IPv6',
                      labelPrefix: 'IPv6',
                    })}
                    {...getFilterCommonPropsByFilterName('IPv6')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'resource',
                      labelPrefix: t('ip_whitelist.resource'),
                    })}
                    {...getFilterCommonPropsByFilterName('resource')}
                  />
                </FormFieldContainer>
                {/*<FormFieldContainer>*/}
                {/*  <ComboboxTableFilter*/}
                {/*    options={methodOptions as any}*/}
                {/*    label={getLabel({*/}
                {/*      filterName: 'method',*/}
                {/*      labelPrefix: t('ip_whitelist.method'),*/}
                {/*    })}*/}
                {/*    {...getFilterCommonPropsByFilterName('method')}*/}
                {/*  />*/}
                {/*</FormFieldContainer>*/}
              </Filter>

              <SelectHeadCells />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/ip_whitelist"
            sx={{
              height:
                isDataLoading ||
                isDataManaging ||
                isCountDataLoading ||
                !data.length
                  ? '100%'
                  : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {(isDataLoading || isCountDataLoading || isDataManaging) && (
              <MainContentLoader />
            )}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading || isCountDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateIpWhitelistItem
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
      />

      <UpdateIpWhitelistItems
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        onRequestEnd={onRequestEnd}
        onRequestStart={onRequestStart}
        data={selectedEntities}
      />
    </PageContentWithTopToolbar>
  );
};
