import React from 'react';
import { useTranslation } from 'react-i18next';

import { ComboBoxOption } from 'src/components/ComboBox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { YearWeekRangePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/YearWeekRangePickerFilter';
import { InclusionPath } from 'src/modules/types/table';
import { AnyObject } from 'src/modules/types';
import { YearMonthRangePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/YearMonthRangePickerFilter';
import { composeDate, format, sub } from 'src/modules/utils/dateWrapper';
import { useKpiSettings } from '../../../modules/utils';

interface IProps {
  onSubmit(data: AnyObject): void;
  filters: AnyObject;
  weeks?: number;
  months?: number;
}

const KpiFilter: React.FC<IProps> = ({ onSubmit, filters, months, weeks }) => {
  const { t } = useTranslation();

  const WEEKS_AGO = 3;
  const MONTHS_AGO = 1;

  const [siteId, setSiteId] = React.useState<number>();

  const {
    month: monthBasis,
    monthCount,
    weekCount,
    week: weekBasis,
  } = useKpiSettings(siteId);

  const weekYearFrom = React.useMemo(
    () =>
      composeDate(
        new Date(),
        sub({ weeks: weeks ?? weekCount ?? WEEKS_AGO }),
        format('yyyy-II'),
      ),
    [weekCount, weeks],
  );

  const weekYearTo = React.useMemo(
    () => composeDate(new Date(), format('yyyy-II')),
    [],
  );

  const [yearWFrom, weekFrom] = React.useMemo(() => weekYearFrom.split('-'), [
    weekYearFrom,
  ]);
  const [yearWTo, weekTo] = React.useMemo(() => weekYearTo.split('-'), [
    weekYearTo,
  ]);

  const monthYearFrom = React.useMemo(
    () =>
      composeDate(
        new Date(),
        sub({ months: months ?? monthCount ?? MONTHS_AGO }),
        format('yyyy-MM'),
      ),
    [monthCount, months],
  );
  const monthYearTo = React.useMemo(
    () => composeDate(new Date(), format('yyyy-MM')),
    [],
  );

  const [yearMFrom, monthFrom] = React.useMemo(() => monthYearFrom.split('-'), [
    monthYearFrom,
  ]);
  const [yearMTo, monthTo] = React.useMemo(() => monthYearTo.split('-'), [
    monthYearTo,
  ]);

  const basisOptions = React.useMemo(() => {
    const options: ComboBoxOption[] = [];

    if (weekBasis) {
      options.push(({ id: 'week', name: 'Week' } as unknown) as ComboBoxOption);
    }

    if (monthBasis) {
      options.push(({
        id: 'month',
        name: 'Month',
      } as unknown) as ComboBoxOption);
    }

    return options;
  }, [monthBasis, weekBasis]);

  const filtersConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: siteId ?? filters.siteId ?? '',
        property: 'siteId',
        operator: 'eq' as const,
        inclusionPath: ['employee', 'scope'] as InclusionPath,
      },
      basis: {
        value: filters.basis ?? null,
        property: 'basis',
        operator: 'eq' as const,
      },
      yearWeek: {
        value: filters.yearWeek ?? [],
        property: 'yearWeek',
        operator: 'between' as const,
      },
      yearMonth: {
        value: filters.yearMonth ?? [],
        property: 'yearMonth',
        operator: 'between' as const,
      },
    }),
    [
      filters.basis,
      filters.siteId,
      filters.yearMonth,
      filters.yearWeek,
      siteId,
    ],
  );

  const {
    getLabel,
    filterFieldsValues,
    getFilterCommonPropsByFilterName,
    setFilterFieldByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration: filtersConfiguration,
  });

  const onBasisChange = () => {
    setFilterFieldByFilterName({ filterName: 'yearWeek', value: [] });
    setFilterFieldByFilterName({ filterName: 'yearMonth', value: [] });
  };

  return (
    <Filter onSubmit={(data) => onSubmit({ ...data, siteId })}>
      <FormFieldContainer>
        <SitesComboBoxFilter
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            value: ComboBoxOption | null,
          ) => setSiteId(value?.id)}
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('kpi.settings.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ComboboxTableFilter
          options={basisOptions}
          label={getLabel({
            filterName: 'basis',
            labelPrefix: t('kpi.basis'),
          })}
          onChange={onBasisChange}
          {...getFilterCommonPropsByFilterName('basis')}
        />
      </FormFieldContainer>

      {filterFieldsValues.basis === 'week' && (
        <FormFieldContainer>
          <YearWeekRangePickerFilter
            fromWeek={+weekFrom}
            toWeek={+weekTo}
            fromYear={+yearWFrom}
            toYear={+yearWTo}
            label={getLabel({
              filterName: 'yearWeek',
              labelPrefix: t('kpi.yearWeek'),
            })}
            {...getFilterCommonPropsByFilterName('yearWeek')}
          />
        </FormFieldContainer>
      )}

      {filterFieldsValues.basis === 'month' && (
        <FormFieldContainer>
          <YearMonthRangePickerFilter
            fromMonth={+monthFrom}
            toMonth={+monthTo}
            fromYear={+yearMFrom}
            toYear={+yearMTo}
            label={getLabel({
              filterName: 'yearMonth',
              labelPrefix: t('kpi.yearMonth'),
            })}
            {...getFilterCommonPropsByFilterName('yearMonth')}
          />
        </FormFieldContainer>
      )}
    </Filter>
  );
};

export default KpiFilter;
