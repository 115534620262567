import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { useSelector } from 'react-redux';
import { useFetchStaffingProvidersCombobox } from 'src/modules/utils';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { ComboBoxOption } from '../../../ComboBox';

type IFilterPanelStaffingProvidersComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  propertyAsId?: 'id' | 'name';
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  value?: number | string | null;
};

export const FilterPanelStaffingProvidersCombobox = ({
  filterId,
  value,
  propertyAsId = 'id',
  ...props
}: IFilterPanelStaffingProvidersComboboxProps) => {
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  React.useEffect(() => {
    fetchStaffingProvidersCombobox();
  }, [fetchStaffingProvidersCombobox]);

  const staffingProviders = useSelector(getStaffingProvidersComboboxList);

  const options = React.useMemo<ComboBoxOption[]>(() => {
    return staffingProviders.map((staffingProvider) => ({
      id: staffingProvider[propertyAsId] as number,
      name: staffingProvider.name!,
    }));
  }, [propertyAsId, staffingProviders]);

  const comboboxValue = options.find(
    (staffingProvider) => staffingProvider.id === value,
  );

  return (
    <FilterPanelCombobox
      value={comboboxValue}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
