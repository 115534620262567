import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateQuestionCategoriesRequest } from 'src/modules/actions';
import { useFetchQuestionCategoriesCombobox } from 'src/modules/utils/hooks/questionCategory';
import { getQuestionCategoryList } from 'src/modules/selectors/questionCategory';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { useFormik } from 'formik';
import { Drawer } from '@mui/joy';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { updateQuestionCategoriesScheme } from 'src/modules/schemes/question-category/update-question-categories.scheme';
import QuestionCategoryTypeComboBox from 'src/components/Formik/comboboxes-with-entities/QuestionCategoryTypeCombobox';

interface IQuestionCategoriesUpdateProps {
  questionCategoriesIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const QuestionCategoriesUpdate = ({
  questionCategoriesIds,
  isOpen,
  onClose,
  filterCount,
  filterList,
}: IQuestionCategoriesUpdateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const questionCategoriesIdsNumber = questionCategoriesIds.map(Number);

  const allQuestionCategories = useSelector(getQuestionCategoryList);

  const questions = allQuestionCategories.filter((questionCategory) =>
    questionCategoriesIdsNumber.includes(questionCategory.id),
  );

  const fetchQuestionsCombobox = useFetchQuestionCategoriesCombobox();

  const validate = useValidate(updateQuestionCategoriesScheme);

  const formik = useFormik({
    initialValues: questions,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      const parsedData = data.map((item) => ({
        id: item.id,
        name: item.name,
        siteId: item.siteId,
        questionCategoryTypeId: item.questionCategoryTypeId,
      }));

      dispatch(
        updateQuestionCategoriesRequest({
          data: parsedData,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  React.useEffect(() => {
    fetchQuestionsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('question.update_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {questions.map((question, index) => (
            <DrawerBodySectionContent key={question.id}>
              <DrawerBodySectionTitle>{question.name}</DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikTextField
                  required
                  fullWidth
                  label={t('question_category.name')}
                  id={generateFullIdInMultiEntitiesForm('name', index)}
                  name={generateFullIdInMultiEntitiesForm('name', index)}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <SitesComboBox
                  placeholder={t('common.select')}
                  id={generateFullIdInMultiEntitiesForm('siteId', index)}
                  name={generateFullIdInMultiEntitiesForm('siteId', index)}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <QuestionCategoryTypeComboBox
                  placeholder={t('common.select')}
                  id={generateFullIdInMultiEntitiesForm(
                    'questionCategoryTypeId',
                    index,
                  )}
                  name={generateFullIdInMultiEntitiesForm(
                    'questionCategoryTypeId',
                    index,
                  )}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
