import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/joy';

import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';

import { AnyObject, IQuestionCategoryTypeModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useQuestionCategoryType,
  useQuestionCategoryTypeFilter,
  useQuestionCategoryTypePermissions,
  useQuestionCategoryTypeTableFiltersConfiguration,
} from 'src/modules/utils/hooks/question-category-type.hooks';
import { QuestionCategoryTypeFilter } from './components/QuestionCategoryTypeFilter';
import { QuestionCategoryTypeCreate } from './QuestionCategoryTypeCreate';
import { QuestionCategoryTypeUpdate } from './QuestionCategoryTypeUpdate';

const reportUrl = '/question-category-type';

export const QuestionCategoryType = () => {
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useReportExportProps(reportUrl, true);

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
  } = useQuestionCategoryTypePermissions();

  const [selectedItems, setSelectedItems] = React.useState<
    IQuestionCategoryTypeModel[]
  >([]);

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState(false);

  const filter = useQuestionCategoryTypeFilter(appliedFilters);

  const filterFieldsConfiguration = useQuestionCategoryTypeTableFiltersConfiguration();

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    removeDataByIds,
    fetchData,
  } = useQuestionCategoryType(reportUrl, filter!.where!, filter!.include!);

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(filters);
  };

  const onTableRowCheckboxClick = (
    items: Array<IQuestionCategoryTypeModel>,
  ) => {
    setSelectedItems(items);
  };

  const handleDelete = async () => {
    const ids = selectedItems.map((i) => +i.id!);
    await removeDataByIds(ids);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);
  };

  // handle updating
  const handleUpdating = (items: IQuestionCategoryTypeModel[]) => {
    setIsUpdateFormVisible(true);
    setSelectedItems(items);
  };

  const handleCreate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsCreateFormVisible(false);
  };

  const handleUpdate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsUpdateFormVisible(false);
  };

  const ids = React.useMemo(() => selectedItems.map((i) => +i.id!), [
    selectedItems,
  ]);

  return (
    <TableContextProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: isDataLoading ? 400 : 'auto',
          height: isDataLoading || !data.length ? '100%' : 'auto',
        }}
      >
        <TableToolbar>
          <TableTitle>{t('question_category_type.table_name')}</TableTitle>

          <TableActions>
            <QuestionCategoryTypeFilter
              getLabel={getLabel}
              onFiltersFormSubmit={handleFiltersSubmit}
              getFilterCommonPropsByFilterName={
                getFilterCommonPropsByFilterName
              }
            />

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />

            {allowedToCreate && (
              <ReportPageActionsGroupItem>
                <Button onClick={() => setIsCreateFormVisible(true)}>
                  {t('common.create')}
                </Button>
              </ReportPageActionsGroupItem>
            )}
          </TableActions>
        </TableToolbar>

        <TableWithActionsDeleteModal
          open={showDeleteModal}
          onOk={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          deleteModelName={ModelsToDelete.QuestionCategoryType}
        />

        <TableWithActions<IQuestionCategoryTypeModel>
          heightWithTabs
          selectedIndex="id"
          data={data as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          limit={limit}
          page={page}
          onSort={handleSort}
          selectedItems={selectedItems}
          onRowCheckboxClick={onTableRowCheckboxClick}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(page) => handlePageChange(page)}
          {...(allowedToUpdate
            ? {
                updateActionConfiguration: {
                  onClick: () => {
                    handleUpdating(selectedItems);
                  },
                },
              }
            : {})}
          {...(allowedToDelete
            ? {
                deleteActionConfiguration: {
                  onClick: () => setShowDeleteModal(true),
                },
              }
            : {})}
        />

        <QuestionCategoryTypeCreate
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
          onCreate={handleCreate}
        />

        <QuestionCategoryTypeUpdate
          ids={ids}
          isOpen={isUpdateFormVisible}
          onUpdate={handleUpdate}
          onClose={() => setIsUpdateFormVisible(false)}
        />
      </Box>
    </TableContextProvider>
  );
};
