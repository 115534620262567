import { GridValidRowModel } from '@mui/x-data-grid';
import { IGridValidationError } from 'src/modules/types/data-grid';
import { ObjectSchema, ValidationError } from 'yup';

export const NEW_GRID_ROW_ID_PREFIX = '_GRID_ROW_ID:';

export const validateGridData = async (
  validationSchema: ObjectSchema<GridValidRowModel>,
  row: GridValidRowModel,
) => {
  try {
    await validationSchema.validate(row, { abortEarly: false });

    return undefined;
  } catch (e) {
    if (e instanceof ValidationError) {
      const errors = e.inner.reduce((all, item) => {
        if (item.path) {
          all[item.path] = item.message;
        }
        return all;
      }, {});

      return {
        [row.id]: errors,
      } as IGridValidationError;
    }

    return undefined;
  }
};
