import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePointType,
  IFilter,
  IPointTypeModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new pointType
 */
export const createPointTypeRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreatePointType>>): SagaIterator {
  try {
    const response: IPointTypeModel = yield call(Api.PointType.create, data);

    yield put(actions.getPointTypeListRequest({ filter: list }));

    yield put(actions.getPointTypeCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createPointTypeSuccess(response));
  } catch (e) {
    yield put(actions.createPointTypeFailed());
  }
};

/**
 * Get pointType list
 */
export const getPointTypeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPointTypeModel[] = yield call(
      Api.PointType.list,
      action.payload,
    );
    yield put(actions.getPointTypeListSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeListFailed());
  }
};

/**
 * Get pointType count
 */
export const getPointTypeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.PointType.count,
      action?.payload,
    );
    yield put(actions.getPointTypeCountSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeCountFailed());
  }
};

/**
 * Delete pointTypes
 */
export const deletePointTypeRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreatePointType>>): SagaIterator {
  try {
    yield call(Api.PointType.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getPointTypeListRequest({ filter: list }));

    yield put(actions.getPointTypeCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deletePointTypeSuccess());
  } catch (e) {
    yield put(actions.deletePointTypeFailed());
  }
};

/**
 * Bulk update pointTypes
 */
export const updatePointTypesRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IPointTypeModel[]>>): SagaIterator {
  try {
    yield call(Api.PointType.bulkUpdate, data);

    yield put(actions.getPointTypeListRequest({ filter: list }));

    yield put(actions.getPointTypeCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updatePointTypesSuccess());
  } catch (e) {
    yield put(actions.updatePointTypesFailed());
  }
};

/**
 * Get combobox list
 */
export const getPointTypeComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IPointTypeModel>[] = yield call(
      Api.PointType.list,
      action.payload,
    );
    yield put(actions.getPointTypeComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.PointType.export,
  actionFailed: actions.exportPointTypeFailed,
  actionSuccess: actions.exportPointTypeSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPointTypeRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createPointTypeRequest, saga: createPointTypeRequestSaga },
  {
    action: actions.getPointTypeListRequest,
    saga: getPointTypeListRequestSaga,
  },
  {
    action: actions.getPointTypeCountRequest,
    saga: getPointTypeCountRequestSaga,
  },
  { action: actions.deletePointTypeRequest, saga: deletePointTypeRequestSaga },
  // bulk updating pointTypes
  {
    action: actions.updatePointTypesRequest,
    saga: updatePointTypesRequestSaga,
  },
  {
    action: actions.getPointTypeComboboxListRequest,
    saga: getPointTypeComboboxListRequestSaga,
  },
  { action: actions.exportPointTypeRequest, saga: exportPointTypeRequestSaga },
];
