import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { Reviews } from './panels/Reviews';
import { OverdueReviews } from './panels/OverdueReviews';
import { PointsReview } from './panels/PointsReviews';

export const EmployeesReviews = () => {
  const { t } = useTranslation();

  const hasUserAccessToEmployeesReviewsTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_REVIEWS,
  );
  const hasUserAccessToEmployeesOverdueReviewsTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_OVERDUE_REVIEWS,
  );
  const hasUserAccessToEmployeesPointsReviewsTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_POINTS_REVIEWS,
  );

  const questionsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.employees_questionnaire_history'),
          hasUserAccess: hasUserAccessToEmployeesReviewsTab,
          component: <Reviews />,
        },
        {
          title: t('main_menu.employees_questionnaire_overdue'),
          hasUserAccess: hasUserAccessToEmployeesOverdueReviewsTab,
          component: <OverdueReviews />,
        },
        {
          title: t('point_reviews.table_name'),
          hasUserAccess: hasUserAccessToEmployeesPointsReviewsTab,
          component: <PointsReview />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToEmployeesReviewsTab,
      hasUserAccessToEmployeesOverdueReviewsTab,
      hasUserAccessToEmployeesPointsReviewsTab,
      t,
    ],
  );

  return <TabsNgroup config={questionsTabsConfig} />;
};
