import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  IRecruitingReport,
} from 'src/modules/types';
import { ReportPage } from 'src/components/ReportPage';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import {
  IRecruitingReportFilterPanelFilters,
  RecruitingReportFilterPanel,
} from './RecruitingReportFilterPanel';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useFetchReportTableDataAndCountEffect,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { paths } from 'src/config';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import {
  useAddReportTableDataToReportExportStateEffect,
  useReportExportProps,
} from 'src/modules/utils/hooks/reports.hooks';
import { ModalDrillDown } from 'src/components/_ui-kit/ModalDrillDown';
import RecruitingDetailedReport from 'src/pages/RecruitingDashboard/RecruitingDetailedReport';
import { ITableBodyItem } from 'src/components/Table';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useRecruitingReportTableFiltersConfiguration } from 'src/modules/utils';
import { Box } from '@mui/joy';

const reportsDefaultFilters: IRecruitingReportFilterPanelFilters = {
  siteId: undefined,
};

export const RecruitingReport: React.FC = () => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'siteName',
  };

  const [selectedRow, setSelectedRow] = useState<IRecruitingReport>();
  const [weekModal, setWeekModal] = useState<boolean>(false);

  const exportProps = useReportExportProps(paths.RECRUITING_REPORT, true);

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IRecruitingReportFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [{ id: 'siteId', label: t('productions_uph_reports.site.name') }],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IRecruitingReportFilterPanelFilters
  >(reportsDefaultFilters);

  const where = React.useMemo(
    () => ({
      siteId: appliedFilters?.siteId,
    }),
    [appliedFilters?.siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(paths.RECRUITING_REPORT, {
    items: [],
    summary: [],
  });

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${paths.RECRUITING_REPORT}/count`,
    {
      count: 0,
    },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('recruiting_dashboards_report.siteName'),
      },
      {
        id: 'desiredHeadCount',
        orderConfig: { orderBy: 'desiredHeadCount' },
        label: t('recruiting_dashboards_report.desiredHeadCount'),
      },
      {
        id: 'absenteeism',
        orderConfig: { orderBy: 'absenteeism' },
        label: t('recruiting_dashboards_report.absenteeism'),
      },
      {
        id: 'optimalHeadCount',
        orderConfig: { orderBy: 'optimalHeadCount' },
        label: t('recruiting_dashboards_report.optimalHeadCount'),
      },
      {
        id: 'countOfActiveEmployees',
        orderConfig: { orderBy: 'countOfActiveEmployees' },
        label: t('recruiting_dashboards_report.countOfActiveEmployees'),
      },
      {
        id: 'openOrders',
        orderConfig: { orderBy: 'openOrders' },
        label: t('recruiting_dashboards_report.openOrders'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const handleTableRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: IRecruitingReport,
  ) => {
    setSelectedRow(row);
    setWeekModal(true);
  };

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  const recruitingReportTableFiltersConfiguration = useRecruitingReportTableFiltersConfiguration();

  const { filterFields } = useFilterFieldsData({
    filterFieldsConfiguration: recruitingReportTableFiltersConfiguration,
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration: recruitingReportTableFiltersConfiguration,
  });

  return (
    <Box
      sx={{
        mx: -2,
        flexGrow: 1,
      }}
    >
      <ReportPage hideNoData>
        <ReportTableToolbar reportName=" ">
          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <SelectHeadCells />
            </ReportPageActionsGroupItem>
          </ReportPageActionsGroupContainer>

          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <TableActionsExport
                requestFilters={requestFilters}
                {...exportProps}
              />
            </ReportPageActionsGroupItem>
          </ReportPageActionsGroupContainer>
        </ReportTableToolbar>

        <ReportPageItemContainer>
          <RecruitingReportFilterPanel
            hideSyncBtn
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <ReportPageItemContainer fullHeight>
          <ModalDrillDown
            title={t('recruiting_dashboards_report.modal_title', {
              siteName: selectedRow?.siteName,
            })}
            open={weekModal}
            onClose={() => setWeekModal(false)}
          >
            <RecruitingDetailedReport summaryRow={selectedRow} />
          </ModalDrillDown>

          <ReportTable<IRecruitingReport & ITableBodyItem>
            sx={{
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 114px)`,
            }}
            data={data.items as any}
            count={countData.count}
            summaryData={data.summary}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={paths.RECRUITING_REPORT}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onTableRowClick={handleTableRowClick}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        </ReportPageItemContainer>
      </ReportPage>
    </Box>
  );
};

export default RecruitingReport;
