import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateTerminationReasonsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { updateTerminationReasonsScheme } from 'src/modules/schemes/termination-reason';
import { getTerminationReasonList } from 'src/modules/selectors/terminationReason';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { omit } from 'lodash';

interface IUpdateTerminationReasonsProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  terminationReasonsUpdateIds: IdsArray;
}

export const UpdateTerminationReasons = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  terminationReasonsUpdateIds,
}: IUpdateTerminationReasonsProps) => {
  const { t } = useTranslation();

  const allTerminationReasons = useSelector(getTerminationReasonList);

  const terminationReasonsUpdateFullData = allTerminationReasons.filter(
    (reason) => terminationReasonsUpdateIds.includes(reason.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateTerminationReasonsScheme);

  const formik = useFormik({
    initialValues: terminationReasonsUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateTerminationReasonsRequest({
          data: data.map((item) => omit(item, 'site')),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('terminationReasons.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {terminationReasonsUpdateFullData.map(
              (terminationReason, index) => (
                <DrawerBodySectionContent key={terminationReason.id}>
                  <DrawerBodySectionTitle>{`${terminationReason.name}`}</DrawerBodySectionTitle>

                  <FormFieldContainer>
                    <SitesComboBox
                      id={`${index}.siteId`}
                      required={true}
                      formik={formik}
                      errorMode="onFieldChange"
                      label={t('terminationReasons.site')}
                      placeholder={t('terminationReasons.site')}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer>
                    <FormikTextField
                      required
                      variant="outlined"
                      fullWidth
                      id={`${index}.name`}
                      label={t('terminationReasons.name')}
                      name={`${index}.name`}
                      autoComplete="name"
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikCheckbox
                      required
                      id={`${index}.voluntary`}
                      label={t('terminationReasons.voluntary')}
                      name={`${index}.voluntary`}
                      formik={formik}
                    />
                  </FormFieldContainer>
                </DrawerBodySectionContent>
              ),
            )}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
