import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, ISkillModel, ISkillState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: ISkillState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  name: '',
  skillTerm: 'expiration',
  hasExpiration: false,
  isPermanent: false,
  expirationPeriod: null,
  list: [],
  comboboxList: [],
  count: 0,
  isSkillDataLoading: false,
  isSkillDataCountLoading: false,
  isSkillDataManaging: false,
};

/**
 * Flush actions
 */
const flushSkillState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createSkillRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  isSkillDataManaging: true,
});

const createSkillSuccess = (state: ISkillState, payload: ISkillModel) => ({
  ...state,
  ...payload,
  error: false,
  isSkillDataManaging: false,
});

const createSkillFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  isSkillDataManaging: false,
});

/**
 * Get list actions
 */
const getSkillListRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  isSkillDataLoading: true,
});

const getSkillListSuccess = (state: ISkillState, payload: ISkillModel[]) => ({
  ...state,
  list: payload,
  error: false,
  isSkillDataLoading: false,
});

const getSkillListFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  isSkillDataLoading: false,
});

/**
 * Get count actions
 */
const getSkillCountRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  isSkillDataCountLoading: true,
});

const getSkillCountSuccess = (state: ISkillState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  isSkillDataCountLoading: false,
});

const getSkillCountFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  isSkillDataCountLoading: false,
});

/**
 * Deletion actions
 */
const deleteSkillRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  isSkillDataManaging: true,
});

const deleteSkillSuccess = (state: ISkillState) => ({
  ...state,
  isSkillDataManaging: false,
});

const deleteSkillFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  isSkillDataManaging: false,
});

/**
 * Updating actions
 */
const updateSkillsRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  isSkillDataManaging: true,
});

const updateSkillsSuccess = (state: ISkillState) => ({
  ...state,
  isSkillDataManaging: false,
});

const updateSkillsFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  isSkillDataManaging: false,
});

/**
 * Get combobox list actions
 */
const getSkillComboboxListRequest = (state: ISkillState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSkillComboboxListSuccess = (
  state: ISkillState,
  payload: Partial<ISkillModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getSkillComboboxListFailed = (state: ISkillState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ISkillState>();

/**
 * Skill reducer
 */
export const skill = createReducer<ISkillState>({}, defaultState)
  // flush actions
  .on(actions.flushSkillState, flushSkillState)
  // creation actions
  .on(actions.createSkillRequest, createSkillRequest)
  .on(actions.createSkillSuccess, createSkillSuccess)
  .on(actions.createSkillFailed, createSkillFailed)
  // get list actions
  .on(actions.getSkillListRequest, getSkillListRequest)
  .on(actions.getSkillListSuccess, getSkillListSuccess)
  .on(actions.getSkillListFailed, getSkillListFailed)
  // get count actions
  .on(actions.getSkillCountRequest, getSkillCountRequest)
  .on(actions.getSkillCountSuccess, getSkillCountSuccess)
  .on(actions.getSkillCountFailed, getSkillCountFailed)
  // deletion actions
  .on(actions.deleteSkillRequest, deleteSkillRequest)
  .on(actions.deleteSkillSuccess, deleteSkillSuccess)
  .on(actions.deleteSkillFailed, deleteSkillFailed)
  // updating actions
  .on(actions.updateSkillsRequest, updateSkillsRequest)
  .on(actions.updateSkillsSuccess, updateSkillsSuccess)
  .on(actions.updateSkillsFailed, updateSkillsFailed)
  // get combobox list actions
  .on(actions.getSkillComboboxListRequest, getSkillComboboxListRequest)
  .on(actions.getSkillComboboxListSuccess, getSkillComboboxListSuccess)
  .on(actions.getSkillComboboxListFailed, getSkillComboboxListFailed)
  // export actions
  .on(actions.exportSkillRequest, exportRequest)
  .on(actions.exportSkillSuccess, exportSuccess)
  .on(actions.exportSkillFailed, exportFailed);

export default skill;
