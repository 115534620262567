import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg, UsersDownSvg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getDepartmentListRequest,
  getDepartmentCountRequest,
  exportDepartmentRequest,
  deleteDepartmentRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsDataWithQueryParamsSynchronization,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  getDepartmentCount,
  getDepartmentsListForTable,
  getIsDepartmentDataLoading,
} from 'src/modules/selectors/department';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { DepartmentsFormsUpdateDepartment } from './DepartmentsForms/DepartmentsFormsUpdateDepartment';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { DepartmentsFormsCreateDepartment } from './DepartmentsForms/DepartmentsFormsCreateDepartment';
import { DepartmentsUpdateEmployeesNumber } from './DepartmentsForms/DepartmentUpdateEmployeesNumber';

export const DepartmentsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedDepartmentsIds, setSelectedDepartmentsIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);
  const [
    isUpdateDesiredEmployeeNumberFormOpened,
    setIsUpdateDesiredEmployeeNumberFormOpened,
  ] = React.useState(false);

  const exportProps = useCreateExportProps(exportDepartmentRequest);

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const isDataLoading = useSelector(getIsDepartmentDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.department.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.department.update.id,
  );
  const allowedToUpdateDesiredEmployees = useHasUserAccessToAction(
    manageEntitiesConfig.department.updateDesiredEmployeesNumber.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.department.delete.id,
  );

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
          include: {
            client: {
              relationType: 'left',
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'id',
        orderConfig: { orderBy: 'id' },
        label: t('departments.id'),
      },
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('departments.name'),
      },
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('departments.site'),
      },
      {
        id: 'active',
        orderConfig: { orderBy: 'active' },
        label: t('departments.active'),
      },
      {
        id: 'incentive',
        orderConfig: { orderBy: 'inactive' },
        label: t('departments.incentive'),
      },
      {
        id: 'incentivePrice',
        orderConfig: { orderBy: 'incentivePrice' },
        disableSorting: true,
        label: t('departments.incentivePrice'),
      },
      {
        id: 'archived',
        orderConfig: { orderBy: 'archived' },
        label: t('departments.archived'),
      },
      {
        id: 'desiredEmployeesNumber',
        orderConfig: { orderBy: 'desiredEmployeesNumber' },
        label: t('departments.desiredEmployeesNumber'),
      },
      {
        id: 'includeToUph',
        orderConfig: { orderBy: 'includeToUph' },
        label: t('departments.includeToUph'),
      },
      {
        id: 'includeToCostPlus',
        orderConfig: { orderBy: 'includeToCostPlus' },
        label: t('departments.includeToCostPlus'),
      },
      {
        id: 'costPlus',
        orderConfig: { orderBy: 'costPlus' },
        label: t('departments.cost_plus'),
      },
      {
        id: 'staticCostPlus',
        orderConfig: { orderBy: 'staticCostPlus' },
        label: t('departments.static_cost_plus'),
      },
      {
        id: 'parent.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['parent', 'scope'],
        },
        label: t('departments.parent'),
      },
      {
        id: 'createdAt',
        orderConfig: { orderBy: 'createdAt' },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      costPlus: {
        value: '',
        property: 'costPlus',
        operator: 'eq' as const,
      },
      staticCostPlus: {
        value: '',
        property: 'staticCostPlus',
        operator: 'eq' as const,
      },
      archived: {
        value: '',
        property: 'archived',
        operator: 'eq' as const,
      },
      desiredEmployeesNumber: {
        value: '',
        property: 'desiredEmployeesNumber',
        operator: 'eq' as const,
      },
      includeToUph: {
        value: '',
        property: 'includeToUph',
        operator: 'eq' as const,
      },
      includeToCostPlus: {
        value: '',
        property: 'includeToCostPlus',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getDepartmentsListForTable,
    selectCount: getDepartmentCount,
    getEntitiesRequest: getDepartmentListRequest,
    getEntitiesCountRequest: getDepartmentCountRequest,
  });

  const onDelete = () => {
    clearFilters();

    dispatch(
      deleteDepartmentRequest({
        data: selectedDepartmentsIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedDepartmentsIds([]);
  };
  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };
  const onUpdateDesiredEmployeeNumberClick = () => {
    setIsUpdateDesiredEmployeeNumberFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdateDesiredEmployees) {
    actionsConfiguration.push({
      tooltip: t('departments.updateDesiredEmployeesNumber'),
      onClick: onUpdateDesiredEmployeeNumberClick,
      icon: <UsersDownSvg />,
    });
  }
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('departments.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('departments.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'name',
                      labelPrefix: t('departments.name'),
                    })}
                    {...getFilterCommonPropsByFilterName('name')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'costPlus',
                      labelPrefix: t('departments.cost_plus'),
                    })}
                    {...getFilterCommonPropsByFilterName('costPlus')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'staticCostPlus',
                      labelPrefix: t('departments.static_cost_plus'),
                    })}
                    {...getFilterCommonPropsByFilterName('staticCostPlus')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'desiredEmployeesNumber',
                      labelPrefix: t('departments.desiredEmployeesNumber'),
                    })}
                    {...getFilterCommonPropsByFilterName(
                      'desiredEmployeesNumber',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'archived',
                      labelPrefix: t('departments.archived'),
                    })}
                    {...getFilterCommonPropsByFilterName('archived')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'includeToUph',
                      labelPrefix: t('departments.includeToUph'),
                    })}
                    {...getFilterCommonPropsByFilterName('includeToUph')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'includeToCostPlus',
                      labelPrefix: t('departments.includeToCostPlus'),
                    })}
                    {...getFilterCommonPropsByFilterName('includeToCostPlus')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('departments.create_btn_title')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/departments"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedDepartmentsIds(ids);
              }}
              selectedRows={selectedDepartmentsIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedDepartmentsIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedDepartmentsIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.Department}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <DepartmentsFormsCreateDepartment
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <DepartmentsFormsUpdateDepartment
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        departmentsToUpdateIds={selectedDepartmentsIds}
      />

      <DepartmentsUpdateEmployeesNumber
        isOpen={isUpdateDesiredEmployeeNumberFormOpened}
        onClose={() => setIsUpdateDesiredEmployeeNumberFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        entitiesToUpdateIds={selectedDepartmentsIds}
      />
    </PageContentWithTopToolbar>
  );
};
