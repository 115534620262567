import React from 'react';
import {
  Button,
  IconButton,
  Modal,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { OverridableStringUnion } from '@mui/types';
import { ColorPaletteProp } from '@mui/joy/styles/types';
import { ButtonPropsColorOverrides } from '@mui/joy/Button/ButtonProps';

import { InfoCircleSvg, Trash04Svg, XCloseSvg } from '../svgIcons';

interface IDeleteConfirmationProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;

  title?: string;
  children?: React.ReactNode;
  type?: 'error' | 'warning';
  confirmText?: string;
  confirmTextColor?: OverridableStringUnion<
    ColorPaletteProp,
    ButtonPropsColorOverrides
  >;
}

export const DeleteConfirmation = ({
  open,
  onOk,
  onCancel,
  title,
  children,
  confirmText,
  confirmTextColor,
  type = 'error',
}: IDeleteConfirmationProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  let iconContainerBg = theme.palette.colors.background.bg_error_secondary;
  let iconComponent = (
    <Trash04Svg
      style={{ width: 24, height: 24 }}
      fill={theme.palette.colors.icons.featured_icon_light_fg_error}
    />
  );
  if (type === 'warning') {
    iconContainerBg = theme.palette.colors.background.bg_warning_secondary;
    iconComponent = (
      <InfoCircleSvg
        style={{ width: 24, height: 24 }}
        fill={theme.palette.colors.icons.featured_icon_light_fg_warning}
      />
    );
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={onCancel}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 500,
          maxHeight: '100%',
          borderRadius: 'lg',
          p: 3,
          boxShadow: 'lg',
          backgroundImage: 'url(/images/background_for_delete_icon.png)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Sheet
          sx={{
            bgcolor: 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Sheet
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              bgcolor: iconContainerBg,
            }}
          >
            <IconButton
              onClick={onOk}
              sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
              {iconComponent}
            </IconButton>
          </Sheet>
          <Sheet>
            <IconButton onClick={onCancel}>
              <XCloseSvg />
            </IconButton>
          </Sheet>
        </Sheet>
        <Sheet
          sx={{ bgcolor: 'transparent', pt: 2, pb: 4, overflowX: 'scroll' }}
        >
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {title ?? t('common.confirm_your_action')}
          </Typography>
          <Typography>{children}</Typography>
        </Sheet>
        <Sheet sx={{ display: 'flex' }}>
          <Button
            fullWidth
            size="lg"
            color="neutral"
            variant="outlined"
            sx={{ mr: '6px' }}
            onClick={onCancel}
          >
            {t('common.cancel')}
          </Button>
          <Button
            fullWidth
            size="lg"
            color={confirmTextColor ?? 'danger'}
            sx={{ ml: '6px' }}
            onClick={onOk}
          >
            {confirmText ?? t('common.delete')}
          </Button>
        </Sheet>
      </Sheet>
    </Modal>
  );
};
