import { useTranslation } from 'react-i18next';
import React from 'react';
import { Grid } from '@mui/joy';
import { MUI_SIZE_12, paths } from '../../config';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { noop } from 'lodash';
import { Alert } from '@mui/joy';

interface IWeeklyCpuProps {
  siteId?: number;
  year?: number;
  week?: number;
  yearWeek?: number;
  showLoader?: boolean;
}

export const WeeklyCpu = ({
  siteId,
  year,
  week,
  yearWeek,
  showLoader,
}: IWeeklyCpuProps) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId: siteId ?? undefined,
      year: year ?? undefined,
      week: week ?? undefined,
      yearWeek: yearWeek ?? undefined,
    }),
    [siteId, year, week, yearWeek],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(paths.SONY_WEEKLY_CPU, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${paths.SONY_WEEKLY_CPU}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        label: t('productions_uph_reports.year'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'week',
        label: t('productions_uph_reports.week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'siteName',
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'productionType',
        label: t('productions_uph_reports.productionType'),
        orderConfig: { orderBy: 'productionType' },
      },
      {
        id: 'category',
        label: t('productions_uph_reports.category'),
        orderConfig: { orderBy: 'category' },
      },
      {
        id: 'qty',
        label: t('productions_uph_reports.qty'),
        orderConfig: { orderBy: 'qty' },
      },
      {
        id: 'price',
        label: t('productions_uph_reports.price'),
        orderConfig: { orderBy: 'price' },
      },
      {
        id: 'cpu',
        label: t('productions_uph_reports.cpu'),
        orderConfig: { orderBy: 'cpu' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData: siteId ? fetchData : noop,
    fetchCountData: siteId ? fetchCountData : noop,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <>
      {!siteId && !showLoader && (
        <Grid container={true}>
          <Grid xs={MUI_SIZE_12}>
            <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
          </Grid>
        </Grid>
      )}

      <ReportTable
        data={data.items as any}
        count={countData.count}
        summaryData={data.summary}
        isDataLoading={
          isDataLoading || isCountDataLoading || showLoader || !siteId
        }
        tableUniqueKey={paths.TARGET_WEEKLY_CPU}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        rowsPerPage={limit}
        currentPageNumber={page}
        onSort={handleSort}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(_, page) => handlePageChange(page)}
      />
    </>
  );
};
