import { Box, Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import {
  Edit02Svg,
  EyeIconSvg,
  FileCheck02Svg,
  FileX02Svg,
  Trash04Svg,
} from 'src/components/svgIcons';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getMonthInvoiceCountRequest,
  getMonthInvoiceListRequest,
  exportMonthInvoiceRequest,
  freezeMonthInvoiceRequest,
  unfreezeMonthInvoiceRequest,
  deleteMonthInvoiceRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  useBrowserHistoryFunctions,
  useCreateExportProps,
  useInvoiceInclusionObject,
  useMonthOptions,
} from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import {
  getIsMonthlyInvoicesDataLoading,
  getMonthInvoiceCount,
  getMonthInvoiceListForTable,
} from 'src/modules/selectors/invoice';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { CreateMonthlyInvoice } from './InvoicesForms/CreateMonthlyInvoice';
import { UpdateMonthlyInvoice } from './InvoicesForms/UpdateMonthlyInvoice';

export const MonthInvoicesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pushToHistory } = useBrowserHistoryFunctions();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportMonthInvoiceRequest);
  const inclusionObj = useInvoiceInclusionObject();
  const isDataLoading = useSelector(getIsMonthlyInvoicesDataLoading);
  const monthOptions = useMonthOptions();

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.create_month.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.update_month.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.delete_month.id,
  );
  const allowedToApprove = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.approve_month.id,
  );
  const allowedToReject = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.reject_month.id,
  );
  const allowedToPreview = useHasUserAccessToAction(
    manageEntitiesConfig.invoice.preview_month.id,
  );

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'yearNumber',
        orderConfig: { orderBy: 'yearNumber' },
        label: t('invoice.year_number'),
      },
      {
        id: 'monthName',
        orderConfig: { orderBy: 'monthName' },
        label: t('invoice.month'),
      },
      {
        id: 'monthEndingDate',
        orderConfig: { orderBy: 'monthEndingDate' },
        label: t('invoice.month_ending_date'),
      },
      {
        id: 'invoiceDate',
        orderConfig: { orderBy: 'invoiceDate' },
        label: t('invoice.invoice_date'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('invoice.site_name'),
      },
      {
        id: 'total',
        orderConfig: { orderBy: 'total' },
        label: t('invoice.total'),
      },
      {
        id: 'isUnchangeable',
        orderConfig: { orderBy: 'isUnchangeable' },
        label: t('invoice.approved'),
      },
      {
        id: 'purchaseOrder',
        orderConfig: { orderBy: 'purchaseOrder' },
        label: t('invoice.purchase_order'),
      },
      {
        id: 'backupInvoiceNumber',
        orderConfig: { orderBy: 'backupInvoiceNumber' },
        label: t('invoice.backup_invoice_number'),
      },
      {
        id: 'dueDate',
        orderConfig: { orderBy: 'dueDate' },
        label: t('invoice.due_date'),
      },
      {
        id: 'paymentTerms',
        orderConfig: { orderBy: 'paymentTerms' },
        label: t('invoice.payment_terms'),
      },
      {
        id: 'purchaseTerms',
        label: t('invoice.purchase_terms'),
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      yearNumber: {
        value: '',
        property: 'yearNumber',
        operator: 'like' as const,
      },
      monthNumber: {
        value: '',
        property: 'monthNumber',
        operator: 'like' as const,
      },
      paymentTerms: {
        value: '',
        property: 'paymentTerms',
        operator: 'like' as const,
      },
      purchaseTerms: {
        value: '',
        property: 'purchaseTerms',
        operator: 'like' as const,
      },
      invoiceDate: {
        value: '',
        property: 'invoiceDate',
        operator: 'like' as const,
      },
      dueDate: {
        value: '',
        property: 'dueDate',
        operator: 'eq' as const,
      },
      isUnchangeable: {
        value: '',
        property: 'isUnchangeable',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'yearNumber',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getMonthInvoiceListForTable,
    selectCount: getMonthInvoiceCount,
    getEntitiesRequest: getMonthInvoiceListRequest,
    getEntitiesCountRequest: getMonthInvoiceCountRequest,
  });

  const onDelete = () => {
    dispatch(
      deleteMonthInvoiceRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const onApproveClick = () => {
    dispatch(
      freezeMonthInvoiceRequest((selectedEntitiesIds[0] as unknown) as string),
    );
  };

  const onRejectClick = () => {
    dispatch(
      unfreezeMonthInvoiceRequest(
        (selectedEntitiesIds[0] as unknown) as string,
      ),
    );
  };

  const onPreviewClick = () => {
    pushToHistory({
      pathname: `/invoice/month/details/${selectedEntitiesIds[0]}`,
    });
  };

  const selectedItemFullData =
    selectedEntitiesIds.length === 1
      ? data.find((item) => item.id === selectedEntitiesIds[0])
      : null;

  const shouldApproveRejectBeShown =
    selectedEntitiesIds.length === 1 &&
    selectedItemFullData?.site?.client?.clientKey !== 'target';

  const actionsConfiguration = [];
  if (allowedToApprove && shouldApproveRejectBeShown) {
    actionsConfiguration.push({
      tooltip: t('common.approve'),
      onClick: onApproveClick,
      icon: <FileCheck02Svg />,
    });
  }
  if (allowedToReject && shouldApproveRejectBeShown) {
    actionsConfiguration.push({
      tooltip: t('common.reject'),
      icon: <FileX02Svg />,
      onClick: onRejectClick,
    });
  }
  if (allowedToPreview && selectedEntitiesIds.length === 1) {
    actionsConfiguration.push({
      tooltip: t('common.preview'),
      icon: <EyeIconSvg />,
      onClick: onPreviewClick,
    });
  }
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <>
      <TableContextProvider>
        <TableToolbar>
          <TableTitle>{t('invoice.week_table_name')}</TableTitle>
          <TableActions>
            <Filter onSubmit={onFiltersFormSubmit}>
              <FormFieldContainer>
                <SitesComboBoxFilter
                  label={getLabel({
                    filterName: 'siteId',
                    labelPrefix: t('invoice.site_name'),
                  })}
                  {...getFilterCommonPropsByFilterName('siteId')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'yearNumber',
                    labelPrefix: t('invoice.year_number'),
                  })}
                  {...getFilterCommonPropsByFilterName('yearNumber')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'monthNumber',
                    labelPrefix: t('invoice.month'),
                  })}
                  options={monthOptions}
                  {...getFilterCommonPropsByFilterName('monthNumber')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'paymentTerms',
                    labelPrefix: t('invoice.payment_terms'),
                  })}
                  {...getFilterCommonPropsByFilterName('paymentTerms')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'purchaseTerms',
                    labelPrefix: t('invoice.purchase_terms'),
                  })}
                  {...getFilterCommonPropsByFilterName('purchaseTerms')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'invoiceDate',
                    labelPrefix: t('invoice.invoice_date'),
                  })}
                  {...getFilterCommonPropsByFilterName('invoiceDate')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'dueDate',
                    labelPrefix: t('invoice.due_date'),
                  })}
                  {...getFilterCommonPropsByFilterName('dueDate')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <CheckboxFilter
                  label={getLabel({
                    filterName: 'isUnchangeable',
                    labelPrefix: t('invoice.is_unchangeable'),
                  })}
                  {...getFilterCommonPropsByFilterName('isUnchangeable')}
                />
              </FormFieldContainer>
            </Filter>

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={filter}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />

            {allowedToCreate && (
              <Button
                sx={{ ml: 1 }}
                onClick={() => setIsCreateFormOpened(true)}
              >
                {t('invoice.create')}
              </Button>
            )}
          </TableActions>
        </TableToolbar>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <TableComponent
            tableUniqueKey="setup/monthly_invoice"
            sx={{
              flexGrow: 0,
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </Box>
      </TableContextProvider>

      <CreateMonthlyInvoice
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateMonthlyInvoice
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        invoicesToUpdateIds={selectedEntitiesIds}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />
    </>
  );
};
