interface Series {
  name: string;
  type: 'column';
  data: number[];
}

interface ChartData {
  categories: string[];
  series: Series[];
}

export const getTopNData = (data: ChartData, topN: number): ChartData => {
  // Calculate total numbers
  const total = data.categories.map((_, index) => {
    return data.series.reduce((sum, series) => sum + series.data[index], 0);
  });

  // Get indexes of top N
  const topNIndices = total
    .map((total, index) => ({ index, total }))
    .sort((a, b) => b.total - a.total) // Sort descending by total
    .slice(0, topN) // Take top N
    .map((item) => item.index); // Get indexes of top N

  // Filter categories and series data by top N indexes
  const filteredCategories = topNIndices.map((index) => data.categories[index]);
  const filteredSeries = data.series.map((series) => ({
    ...series,
    data: topNIndices.map((index) => series.data[index]),
  }));

  return {
    categories: filteredCategories,
    series: filteredSeries,
  };
};

export const mapTopNumberStringToNumber = (
  chartData: ChartData,
  topNumber: string,
) => {
  switch (topNumber) {
    case 'top10':
      return 10;
    case 'top30':
      return 30;
    default:
      return chartData.categories.length - 1;
  }
};
