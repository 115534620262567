import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IStaffingProviderModel,
  IStaffingProviderState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IStaffingProviderState = {
  refreshKey: 0,
  id: NaN,
  staffingProvider: '',
  markup: NaN,
  ngroupMarkup: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  isStaffingProviderDataLoading: false,
  isStaffingProviderCountDataLoading: false,
  isStaffingProviderDataManaging: false,
};

/**
 * Flush actions
 */
const flushStaffingProviderState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createStaffingProviderRequest = (state: IStaffingProviderState) => ({
  ...state,
  error: false,
  isStaffingProviderDataManaging: true,
});

const createStaffingProviderSuccess = (
  state: IStaffingProviderState,
  payload: IStaffingProviderModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isStaffingProviderDataManaging: false,
});

const createStaffingProviderFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isStaffingProviderDataManaging: false,
});

/**
 * Get list actions
 */
const getStaffingProviderListRequest = (state: IStaffingProviderState) => ({
  ...state,
  error: false,
  isStaffingProviderDataLoading: true,
});

const getStaffingProviderListSuccess = (
  state: IStaffingProviderState,
  payload: IStaffingProviderModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isStaffingProviderDataLoading: false,
});

const getStaffingProviderListFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isStaffingProviderDataLoading: false,
});

/**
 * Get count actions
 */
const getStaffingProviderCountRequest = (state: IStaffingProviderState) => ({
  ...state,
  error: false,
  isStaffingProviderCountDataLoading: true,
});

const getStaffingProviderCountSuccess = (
  state: IStaffingProviderState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isStaffingProviderCountDataLoading: false,
});

const getStaffingProviderCountFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isStaffingProviderCountDataLoading: false,
});

/**
 * Deletion actions
 */
const deleteStaffingProviderRequest = (state: IStaffingProviderState) => ({
  ...state,
  error: false,
  isStaffingProviderDataManaging: true,
});

const deleteStaffingProviderSuccess = (state: IStaffingProviderState) => ({
  ...state,
  isStaffingProviderDataManaging: false,
});

const deleteStaffingProviderFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isStaffingProviderDataManaging: false,
});

/**
 * Updating actions
 */
const updateStaffingProvidersRequest = (state: IStaffingProviderState) => ({
  ...state,
  error: false,
  isStaffingProviderDataManaging: true,
});

const updateStaffingProvidersSuccess = (state: IStaffingProviderState) => ({
  ...state,
  isStaffingProviderDataManaging: false,
});

const updateStaffingProvidersFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isStaffingProviderDataManaging: false,
});

/**
 * Get combobox list actions
 */
const getStaffingProviderComboboxListRequest = (
  state: IStaffingProviderState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getStaffingProviderComboboxListSuccess = (
  state: IStaffingProviderState,
  payload: Partial<IStaffingProviderModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getStaffingProviderComboboxListFailed = (
  state: IStaffingProviderState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IStaffingProviderState>();

const exportStaffingProviderRequest = exportRequest;
const exportStaffingProviderSuccess = exportSuccess;
const exportStaffingProviderFailed = exportFailed;

/**
 * StaffingProvider reducer
 */
export const staffingProvider = createReducer<IStaffingProviderState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushStaffingProviderState, flushStaffingProviderState)
  // creation actions
  .on(actions.createStaffingProviderRequest, createStaffingProviderRequest)
  .on(actions.createStaffingProviderSuccess, createStaffingProviderSuccess)
  .on(actions.createStaffingProviderFailed, createStaffingProviderFailed)
  // get list actions
  .on(actions.getStaffingProviderListRequest, getStaffingProviderListRequest)
  .on(actions.getStaffingProviderListSuccess, getStaffingProviderListSuccess)
  .on(actions.getStaffingProviderListFailed, getStaffingProviderListFailed)
  // get count actions
  .on(actions.getStaffingProviderCountRequest, getStaffingProviderCountRequest)
  .on(actions.getStaffingProviderCountSuccess, getStaffingProviderCountSuccess)
  .on(actions.getStaffingProviderCountFailed, getStaffingProviderCountFailed)
  // deletion actions
  .on(actions.deleteStaffingProviderRequest, deleteStaffingProviderRequest)
  .on(actions.deleteStaffingProviderSuccess, deleteStaffingProviderSuccess)
  .on(actions.deleteStaffingProviderFailed, deleteStaffingProviderFailed)
  // updating actions
  .on(actions.updateStaffingProvidersRequest, updateStaffingProvidersRequest)
  .on(actions.updateStaffingProvidersSuccess, updateStaffingProvidersSuccess)
  .on(actions.updateStaffingProvidersFailed, updateStaffingProvidersFailed)
  // get combobox list actions
  .on(
    actions.getStaffingProviderComboboxListRequest,
    getStaffingProviderComboboxListRequest,
  )
  .on(
    actions.getStaffingProviderComboboxListSuccess,
    getStaffingProviderComboboxListSuccess,
  )
  .on(
    actions.getStaffingProviderComboboxListFailed,
    getStaffingProviderComboboxListFailed,
  )
  // export actions
  .on(actions.exportStaffingProviderRequest, exportStaffingProviderRequest)
  .on(actions.exportStaffingProviderSuccess, exportStaffingProviderSuccess)
  .on(actions.exportStaffingProviderFailed, exportStaffingProviderFailed);

export default staffingProvider;
