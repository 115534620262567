import { createAction } from 'redux-act';
import {
  ICreateStandardRequest,
  IFilter,
  IStandardDeleteRequest,
  IStandardListResponse,
  IStandardModel,
  IUpdateStandard,
  PayloadWithFilters,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushStandardState = createAction('Flush standard data in store');

// Creation actions
export const createStandardRequest = createAction<
  PayloadWithFilters<ICreateStandardRequest>
>('Make request to create a new standard');
export const createStandardSuccess = createAction<IStandardModel>(
  'New standard has been created successfully',
);
export const createStandardFailed = createAction(
  'Cannot create a new standard',
);

// Fetch standards actions
export const getStandardListRequest = createAction<{
  siteId: number;
  filter: IFilter;
}>('Make request to fetch standard list');
export const getStandardListSuccess = createAction<IStandardListResponse>(
  'Standards list has been fetched successfully',
);
export const getStandardListFailed = createAction('Cannot fetch standard list');

// delete actions
export const deleteStandardRequest = createAction<
  PayloadWithFilters<IStandardDeleteRequest>
>('Make request to delete standards');
export const deleteStandardSuccess = createAction(
  'Standards successfully deleted',
);
export const deleteStandardFailed = createAction('Cannot delete standards');

// Updating actions
export const updateStandardsRequest = createAction<
  PayloadWithFilters<IUpdateStandard[]>
>('Make request to update standards');
export const updateStandardsSuccess = createAction(
  'Standards successfully updated',
);
export const updateStandardsFailed = createAction('Cannot update standards');

// Fetch combobox list actions
export const getStandardComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of standards',
);
export const getStandardComboboxListSuccess = createAction<
  Partial<IStandardModel>[]
>('Combobox list of standards has been fetched successfully');
export const getStandardComboboxListFailed = createAction(
  'Cannot fetch combobox standards list',
);

// export actions
export const {
  exportReportRequest: exportStandardRequest,
  exportReportSuccess: exportStandardSuccess,
  exportReportFailed: exportStandardFailed,
} = new ExportActionCreator('Standard').takeExportActions();
