import React from 'react';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { Box, BoxProps } from '@mui/joy';
import { NoData } from 'src/components/NoData';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/joy/styles/types';
import { useIsTabsOnSmallDevice } from '../../../PageContent/PageContentWithTabs/pageContentWithTabs.constants';
// If you change something in this file please verify
// that charts after switch to full size and back shows correctly
interface IChartContainerBody extends BoxProps {
  children: React.ReactNode;
  isLoading?: boolean;
  showNoData?: boolean;
  childrenContainerSx?: SxProps;
  noDataContainerSx?: SxProps;
}

export const ChartContainerBody = React.forwardRef<
  HTMLDivElement,
  IChartContainerBody
>(
  (
    {
      children,
      isLoading,
      sx,
      showNoData,
      childrenContainerSx,
      noDataContainerSx,
      ...boxProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const isSmallDevice = useIsTabsOnSmallDevice();

    return (
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: '100%',
          ...(isSmallDevice ? { minHeight: '250px' } : {}),
          width: '100%',
          position: 'relative',
          ...sx,
        }}
        ref={ref}
        {...boxProps}
      >
        <Box
          sx={{
            pt: 2,
            pl: 2,
            pr: 2,
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            ...childrenContainerSx,
          }}
        >
          {children}
        </Box>

        {isLoading && <MainContentLoader />}

        {!isLoading && showNoData && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 'modal',
              bgcolor: 'transparent',
              overflow: 'hidden',
              borderRadius: 8,
              ...noDataContainerSx,
            }}
          >
            <NoData description="" title={t('common.no_data_found')} />
          </Box>
        )}
      </Box>
    );
  },
);
