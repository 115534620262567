import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  IFilterData,
  IFilterWhere,
  IListWithCount,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useGenerateHeadCellsData } from 'src/modules/utils/hooks/table';
import { useDynamicBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export interface IPointsReviewsFilterPanelFilters {
  siteId?: number;
  employeeId?: number;
  yearWeek?: [number, number];
}

export const usePointReviewsTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const usePointsReviewComposeWhere = (
  where: Partial<IPointsReviewsFilterPanelFilters>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(!isEmpty(selectedSites)
        ? {
            siteId: {
              inq: selectedSites,
            },
          }
        : {}),
      ...(where.employeeId
        ? {
            employeeId: {
              eq: where.employeeId,
            },
          }
        : {}),
    }),
    [selectedSites, where.employeeId],
  );
};

export const usePointsReviewFilter = (
  appliedFilters: IPointsReviewsFilterPanelFilters,
) => {
  const where = usePointsReviewComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where,
      order: ['siteName asc'],
    }),
    [where],
  );
};

export const usePointsReview = (reportUrl: string, where: IFilterWhere) => {
  const { t } = useTranslation();
  const initialData = {
    data: [],
    count: 0,
  };

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData<IListWithCount<AnyObject>>(reportUrl, initialData);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: {
          orderBy: 'siteName',
        },
        label: t('point_reviews.site'),
      },
      {
        id: 'firstName',
        orderConfig: {
          orderBy: 'firstName',
        },
        label: t('point_reviews.employeeFirstName'),
      },
      {
        id: 'lastName',
        orderConfig: {
          orderBy: 'lastName',
        },
        label: t('point_reviews.employeeLastName'),
      },
      {
        id: 'badge',
        orderConfig: {
          orderBy: 'badge',
        },
        label: t('point_reviews.badge'),
      },
      {
        id: 'type',
        orderConfig: {
          orderBy: 'type',
        },
        label: t('point_reviews.type'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'asc',
    defOrderBy: 'siteName',
    omitCount: false,
    data: data.data,
    fetchData,
    isDataLoading,
    removeDataByIds,
    headCells,
    headCellsOrderDetails,
    fetchCountData: async () => {
      //
    },
    countData: { count: data.count },
    isCountDataLoading: false,
  });
};
