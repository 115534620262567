import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICreateRoleRequest,
  IFilterData,
  IUpdateRole,
  IWhere,
  IdsArray,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateRolesRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { pick } from 'lodash';
import { updateRolesScheme } from 'src/modules/schemes';
import { getRoleList } from 'src/modules/selectors/role';
import { additionalUserProperties } from 'src/config/userFields';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';

const initialValues: ICreateRoleRequest = {
  name: '',
  shouldExtend: false,
};

interface IUpdateRolesProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  rolesToUpdateIds: IdsArray;
}

export const UpdateRoles = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  rolesToUpdateIds,
}: IUpdateRolesProps) => {
  const { t } = useTranslation();

  const allRoles = useSelector(getRoleList);

  const rolesToUpdateFullData = allRoles.filter((role) =>
    rolesToUpdateIds.includes(role.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateRolesScheme);

  const formik = useFormik({
    initialValues: rolesToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateRolesRequest({
          data: data.map(
            (i) =>
              pick(i, [
                'id',
                'name',
                ...additionalUserProperties,
              ]) as IUpdateRole,
          ),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  const getCheckboxes = React.useCallback(
    (index: number) =>
      additionalUserProperties.map((i) => (
        <FormFieldContainer key={`${index}.${i}`}>
          <FormikCheckbox
            id={`${index}.${i}`}
            formik={formik}
            label={t(`roles.${i}`)}
          />
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, t],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>{t('roles.update')}</DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {rolesToUpdateFullData.map((role, index) => (
              <DrawerBodySectionContent key={role.id}>
                <DrawerBodySectionTitle>{`${role.name}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.name`}
                    label={t('roles.name')}
                    name={`${index}.name`}
                    autoComplete="name"
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                {getCheckboxes(index)}
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
