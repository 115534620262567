import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getClientListRequest,
  getClientCountRequest,
  exportClientRequest,
  deleteClientRequest,
} from 'src/modules/actions';
import {
  getClientCount,
  getClientList,
  getIsClientsTableDataRequestInProgress,
} from 'src/modules/selectors/client';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsDataWithQueryParamsSynchronization,
} from 'src/modules/utils/hooks/table';
import { ClientsFormsCreateClient } from './ClientsForms/ClientsFormsCreateClient';
import { ClientsFormsUpdateClient } from './ClientsForms/ClientsFormsUpdateClient';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';

const ClientsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedClientsIds, setSelectedClientsIds] = React.useState<
    Array<number>
  >([]);
  const [
    isCreateClientFormOpened,
    setIsCreateClientFormOpened,
  ] = React.useState(false);
  const [
    isUpdateClientsFormOpened,
    setIsUpdateClientsFormOpened,
  ] = React.useState(false);

  const exportProps = useCreateExportProps(exportClientRequest);

  const isDataLoading = useSelector(getIsClientsTableDataRequestInProgress);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.client.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.client.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.client.delete.id,
  );

  const clientsHeadCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        label: t('clients.name'),
        orderConfig: { orderBy: 'name' },
      },
      {
        id: 'clientKey',
        label: t('clients.clientKey'),
        orderConfig: { orderBy: 'clientKey' },
      },
      {
        id: 'createdAt',
        orderConfig: { orderBy: 'createdAt' },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    clientsHeadCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      clientKey: {
        value: '',
        property: 'clientKey',
        operator: 'like' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getClientList,
    selectCount: getClientCount,
    getEntitiesRequest: getClientListRequest,
    getEntitiesCountRequest: getClientCountRequest,
  });

  const onDeleteClients = () => {
    dispatch(
      deleteClientRequest({
        data: selectedClientsIds,
        filters: {
          list: { include: filter.include, order: filter.order },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedClientsIds([]);
  };
  const onUpdateClientClick = () => {
    setIsUpdateClientsFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClientClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('clients.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'name',
                      labelPrefix: t('clients.name_short'),
                    })}
                    {...getFilterCommonPropsByFilterName('name')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'clientKey',
                      labelPrefix: t('clients.key_short'),
                    })}
                    {...getFilterCommonPropsByFilterName('clientKey')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateClientFormOpened(true)}
                >
                  {t('client.create_client')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/clients"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedClientsIds(ids);
              }}
              selectedRows={selectedClientsIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedClientsIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedClientsIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.Client}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDeleteClients,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <ClientsFormsCreateClient
        isOpen={isCreateClientFormOpened}
        onClose={() => setIsCreateClientFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where }}
      />

      <ClientsFormsUpdateClient
        isOpen={isUpdateClientsFormOpened}
        onClose={() => setIsUpdateClientsFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where }}
        clientsToUpdateIds={selectedClientsIds}
      />
    </PageContentWithTopToolbar>
  );
};

export default ClientsList;
