import React from 'react';
import { intersection, isEmpty, map, pick, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { supervisorRoles } from '../../config';
import {
  IdsArray,
  IStoreState,
  IUpdateUser,
  IUserAttributesNames,
  IUserModel,
  UserComboboxOption,
} from '../types';
import { getGloballySelectedSites } from './site';
import { ComboBoxOption } from '../../components/ComboBox';
import { Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { Lock, LockOpen } from '@mui/icons-material';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { i18n } from '../utils';

export const getUserRefreshKey = (state: IStoreState) => state.user.refreshKey;

export const getUser = (state: IStoreState) => state.user;

/**
 * Get auth's full name
 * @param auth - State auth
 */
export const userFullName = ({ user }: IStoreState) =>
  [user.firstName, user.lastName].join(' '); // we use such a way to simplify handling whitespace between firstName and lastName if lastName is null

/**
 * Get auth's short name
 * @param auth - State auth
 */
export const userShortName = ({ user }: IStoreState) =>
  [
    user.firstName || '', // firstName can be null so make it an empty string by default
    user.lastName || '', // lastName can be null so make it an empty string by default
  ]
    .map(
      (n) =>
        n
          .charAt(0) // get the first letter from firstName/lastName
          .toUpperCase(), // convert it to upper case
    )
    .join(''); // join letters together

/**
 * Get profile data
 * @param auth - State auth
 */
export const profileData = ({ user }: IStoreState) =>
  pick(user, ['firstName', 'lastName', 'lang', 'phoneNumber']);

/**
 * Get server error
 * @param auth - State auth
 */
export const getServerError = ({ user }: IStoreState) => user.error;

/**
 * Get user list
 * @param user - State user
 */
export const getUserList = ({ user }: IStoreState) => user.list;

export const getUserListWithAttributesNames = createSelector(
  getUserList,
  (users) =>
    reduce(
      users,
      (acc, cur) => {
        const { userAttributes, ...item } = cur;
        const attributes = reduce(
          userAttributes,
          (acc, cur) => {
            if (cur.site?.name) {
              acc.siteNames.push(cur.site?.name);
            }
            if (cur.client?.name) {
              acc.clientNames.push(cur.client?.name);
            }
            if (cur.staffing?.staffingProvider) {
              acc.staffingNames.push(cur.staffing?.staffingProvider);
            }
            return acc;
          },
          { siteNames: [], clientNames: [], staffingNames: [] } as {
            siteNames: string[];
            clientNames: string[];
            staffingNames: string[];
          },
        );

        acc.push({
          ...item,
          siteName: attributes.siteNames.join(', '),
          clientName: attributes.clientNames.join(', '),
          staffingName: attributes.staffingNames.join(', '),
        });
        return acc;
      },
      [] as (IUserModel & IUserAttributesNames)[],
    ),
);

export const getUsersListForTable = createSelector(
  getUserListWithAttributesNames,
  (users) => {
    return users.map((item) => {
      return {
        ...item,
        role: item.role || (
          <Tooltip
            arrow
            placement="top"
            title={i18n.t('users.role_needed') as string}
          >
            <FontAwesomeIcon color="red" fontSize={18} icon={faCommentAlt} />
          </Tooltip>
        ),
        locked: item.locked ? (
          <Lock color="error" />
        ) : (
          <LockOpen color="primary" />
        ),
        activated: item.activated ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        supervisor: item.supervisorId
          ? `${item.supervisor?.firstName} ${item.supervisor?.lastName}`
          : null,
      };
    });
  },
);

/**
 * Get users list as map where key is id and value is IUserModel
 */
export const getUsersMap = createSelector(
  getUserList,
  (users): Record<number, IUserModel> =>
    Object.fromEntries(users.map((u) => [u.id, u])),
);

/**
 * Get users fields based on their roles by array of ids
 * @param user - State user
 */
export const getUsersByIds = createSelector(
  getUserList,
  (users) => (ids: IdsArray) => {
    const initial: IUpdateUser[] = [];
    return reduce(
      users,
      // tslint:disable-next-line:cyclomatic-complexity
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          const user = pick(cur, [
            'id',
            'firstName',
            'lastName',
            'role',
            'email',
            'lang',
            'phoneNumber',
            'wmsLogin',
            'shiftId',
            'departmentId',
            'supervisorId',
          ]);
          const attributes = reduce(
            cur.userAttributes,
            (acc, cur) => {
              if (cur.siteId) {
                acc.sites.push(cur.siteId);
              }
              if (cur.clientId) {
                acc.clients.push(cur.clientId);
              }
              if (cur.staffingId) {
                acc.staffings.push(cur.staffingId);
              }

              return acc;
            },
            {
              sites: [],
              clients: [],
              staffings: [],
            } as {
              sites: number[];
              clients: number[];
              staffings: number[];
            },
          );

          acc.push({
            ...user,
            ...(!isEmpty(attributes.sites) ? { sites: attributes.sites } : {}),
            ...(!isEmpty(attributes.clients)
              ? { clients: attributes.clients }
              : {}),
            ...(!isEmpty(attributes.staffings)
              ? { staffings: attributes.staffings }
              : {}),
          } as IUpdateUser);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get user count
 * @param user - State user
 */
export const getUserCount = ({ user }: IStoreState) => user.count;

/**
 * Get combobox list
 */
export const getUsersList = ({ user }: IStoreState) => user.comboboxList;

/**
 * Prepare combobox list
 */
export const getUsersComboboxList = createSelector(
  getUsersList,
  (users): UserComboboxOption[] => {
    return map(users, (user) => ({
      id: user.id as number,
      name: `${user.firstName} ${user.lastName}`,
      sites: reduce(
        user.userAttributes,
        (acc, { siteId }) => {
          // check for not nullable value to prevent push null, undefined etc.
          if (siteId || siteId === 0) {
            acc.push(Number(siteId));
          }
          return acc;
        },
        [] as number[],
      ),
    }));
  },
);

export const getSupervisorsAndGMsComboboxList = createSelector(
  getUsersList,
  (users) =>
    users.reduce<UserComboboxOption[]>((supervisorsAndGMs, user) => {
      if (user.role && supervisorRoles.includes(user.role)) {
        return [
          ...supervisorsAndGMs,
          {
            id: user.id as number,
            name: `${user.firstName} ${user.lastName}`,
            sites: map(user.userAttributes, 'siteId') as number[],
          },
        ];
      }

      return supervisorsAndGMs;
    }, []),
);

export const getSupervisorsAndGMsBySitesComboboxList = createSelector(
  getUsersList,
  getGloballySelectedSites,
  (users, globallySelectedSites) => {
    return users.reduce<UserComboboxOption[]>((supervisorsAndGMs, user) => {
      const userSiteIds = user?.userAttributes?.map((u) => u.siteId);
      if (
        user.role &&
        supervisorRoles.includes(user.role) &&
        !!intersection(globallySelectedSites, userSiteIds).length
      ) {
        return [
          ...supervisorsAndGMs,
          {
            id: user.id as number,
            name: `${user.firstName} ${user.lastName}`,
            sites: map(user.userAttributes, 'siteId') as number[],
          },
        ];
      }

      return supervisorsAndGMs;
    }, []);
  },
);

export const getSupervisorsAndGMsNamesBySitesComboboxList = createSelector(
  getSupervisorsAndGMsBySitesComboboxList,
  (options: ComboBoxOption[]) => {
    return options.map(
      ({ name }) => (({ id: name, name } as unknown) as ComboBoxOption),
    );
  },
);

/**
 * Get users combobox list as map where key is id and value is UserComboboxOption
 */
export const getUsersComboboxMap = createSelector(
  getUsersComboboxList,
  (users): Record<number, UserComboboxOption> =>
    Object.fromEntries(users.map((u) => [u.id, u])),
);

/**
 * Fetch a status of completing user creation
 * @param user - State user
 */
export const getCompleteCreationStatus = ({ user }: IStoreState) =>
  user.completeStatus;

/**
 * Fetch user policies
 * @param user - State user
 */
export const getUserPolicies = ({ user }: IStoreState) => user.policy?.policies;

/**
 * Fetch user roles
 * @param user - State user
 */
export const getUserRoles = ({ user }: IStoreState) => user.policy?.roles;

export const getNotificationsPreferences = createSelector(
  getUser,
  ({ profile }) => ({
    smsNotificationsEnabled: profile.smsNotificationsEnabled,
    webNotificationsEnabled: profile.webNotificationsEnabled,
    emailNotificationsEnabled: profile.emailNotificationsEnabled,
  }),
);

export const getLoggedInUserData = createSelector(
  getUser,
  userShortName,
  ({ email, firstName, lastName, policy }, shortName) => {
    return {
      email,
      firstName,
      lastName,
      shortName,
      role: policy?.roles[0],
    };
  },
);

/**
 * Fetch user prefilled data
 * @param user - State user
 */
export const getUserPrefilledData = ({ user }: IStoreState) =>
  user.prefilledData;

export const getIsUsersLoading = createSelector(
  getUser,
  (user) =>
    user.isUsersDataProcessing ||
    user.isUsersDataCountLoading ||
    user.isUsersDataLoading,
);
