import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateSkillsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { updateSkillsScheme } from 'src/modules/schemes/skills';
import { getSkillList } from 'src/modules/selectors/skill';
import { isExpiration, isPermanent } from '../CreateSkill';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';

interface IUpdateSkillsProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  skillsToUpdateIds: IdsArray;
}

export const UpdateSkills = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  skillsToUpdateIds,
}: IUpdateSkillsProps) => {
  const { t } = useTranslation();

  const allSkills = useSelector(getSkillList);

  const skillsToUpdateFullData = allSkills
    .filter((skill) => skillsToUpdateIds.includes(skill.id))
    .map((skill) => ({
      ...skill,
      skillTerm: skill.isPermanent ? 'permanent' : 'expiration',
    }));

  const dispatch = useDispatch();

  const validate = useValidate(updateSkillsScheme);

  const formik = useFormik({
    initialValues: skillsToUpdateFullData as any,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      const sendData = data.map(
        ({ id, expirationPeriod, name, siteId, skillTerm }) => ({
          id,
          expirationPeriod,
          name,
          siteId,
          hasExpiration: isExpiration(skillTerm),
          isPermanent: isPermanent(skillTerm),
        }),
      );
      dispatch(
        updateSkillsRequest({
          data: sendData,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('skills.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {skillsToUpdateFullData.map((skill, index) => (
              <DrawerBodySectionContent key={skill.id}>
                <DrawerBodySectionTitle>{`${skill.name}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.name`}
                    label={t('skills.name')}
                    name={`${index}.name`}
                    autoComplete="name"
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    errorMode="onFieldChange"
                    label={t('skills.site')}
                    placeholder={t('skills.site')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormFiledButtonsGroupItemContainer>
                    <FormikButtonGroup
                      id={`${index}.skillTerm`}
                      label={t('emp_skills.has_expiration_date')}
                      formik={formik}
                      config={[
                        {
                          label: t('common.yes'),
                          value: 'expiration',
                        },
                        {
                          label: t('common.no'),
                          value: 'permanent',
                        },
                      ]}
                    />
                  </FormFiledButtonsGroupItemContainer>
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    id={`${index}.expirationPeriod`}
                    name={`${index}.expirationPeriod`}
                    label={t('emp_skills.expiration_period')}
                    formik={formik}
                    type="number"
                    required={isExpiration(formik.values[index]?.skillTerm)}
                    disabled={isPermanent(formik.values[index]?.skillTerm)}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
