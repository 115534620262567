import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import {
  addProcessStatus,
  getOverdueQuestionnairesCountRequest,
  getOverdueQuestionnairesFailed,
  getOverdueQuestionnairesRequest,
} from 'src/modules/actions';
import { Box, Drawer } from '@mui/material';
import { getOverdueEmployeesReview } from 'src/modules/selectors/questionEmployee';
import { QuestionnaireRadioButton } from '../../../components/QuestionnaireRadioButton';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { AnyObject, IFilterData, IWhere, IdsArray } from 'src/modules/types';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { FormikFilesUpload } from 'src/components/Formik/FormikFilesUpload';
import { FileData } from 'src/components/UploadFiles';
import { Api } from 'src/modules/utils';

interface IUpdateOverdueReviewsProps {
  reviewsIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdateOverdueReviews = ({
  reviewsIds,
  isOpen,
  onClose,
  filterList,
  filterCount,
}: IUpdateOverdueReviewsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allOverdueReviews = useSelector(getOverdueEmployeesReview);

  const reviewsToUpdate = React.useMemo(
    () =>
      allOverdueReviews
        .filter((review) => reviewsIds.includes(review.id))
        .map((item) => ({
          ...item,
          attachments: [],
          files: [] as Array<FileData>,
        })),
    [allOverdueReviews, reviewsIds],
  );

  const formik = useFormikInUpdateForm({
    initialValues: reviewsToUpdate,
    enableReinitialize: true,
    onSubmit: async (data) => {
      const formData = new FormData();

      data.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          if (
            key === 'id' ||
            key === 'files' ||
            key === 'questionsEmployees' ||
            key === 'questionCategoryId' ||
            key === 'employeeId'
          ) {
            if (key === 'questionsEmployees') {
              item.questionsEmployees.forEach(
                (answer: AnyObject, answerIndex: number) => {
                  Object.keys(answer).forEach((answerKey) => {
                    if (answerKey === 'id' || answerKey === 'value') {
                      formData.append(
                        `reviews[${index}][questionsEmployees][${answerIndex}][${answerKey}]`,
                        answer[answerKey] as any,
                      );
                    }
                  });
                },
              );
            } else if (key === 'files') {
              item.files.forEach((file, fileIndex) => {
                if (file.file) {
                  formData.append(
                    `reviews[${index}][files][${fileIndex}]`,
                    file.file,
                  );
                }
              });
            } else {
              formData.append(`reviews[${index}][${key}]`, item[key] as any);
            }
          }
        });
      });

      try {
        await Api.QuestionEmployee.fillInOverdueQuestionnaire(formData);

        dispatch(getOverdueQuestionnairesRequest({ filter: filterList }));

        dispatch(getOverdueQuestionnairesCountRequest(filterCount));

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch (e) {
        dispatch(getOverdueQuestionnairesFailed());

        dispatch(
          addProcessStatus({
            variant: 'error',
            title: 'common.error',
          }),
        );
      }

      formik.setValues([]);

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('main_menu.employees_questionnaire_history_update')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {reviewsToUpdate.map((review, index) => (
            <DrawerBodySectionContent key={review.id}>
              <DrawerBodySectionTitle>
                {`${review.employee.firstName} ${review.employee.lastName}`}
              </DrawerBodySectionTitle>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 500,
                }}
              >
                {review.questionsEmployees.map(
                  (answer: AnyObject, i: number) => {
                    if (answer.questionType === 'options') {
                      return (
                        <FormFieldContainer key={answer.id}>
                          <QuestionnaireRadioButton
                            label={answer.questionName}
                            id={`${index}.questionsEmployees.${i}.value`}
                            formik={formik}
                          />
                        </FormFieldContainer>
                      );
                    }

                    return (
                      <FormFieldContainer key={answer.id}>
                        <FormikTextarea
                          id={`${index}.questionsEmployees.${i}.value`}
                          name={`${index}.questionsEmployees.${i}.value`}
                          key={answer.id}
                          formik={formik}
                          label={answer.questionName}
                          minRows={2}
                        />
                      </FormFieldContainer>
                    );
                  },
                )}

                <Box
                  sx={{
                    px: 4,
                    bgcolor: 'inherit',
                    mt: 2,
                  }}
                >
                  <FormikFilesUpload
                    name={`${index}.files`}
                    inputId={`review_upload_files_${index}`}
                    formik={formik}
                    accept={{
                      'image/*': ['.png', '.jpg', '.jpeg'],
                      'application/pdf': ['.pdf'],
                      'application/msword': ['.doc'],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.docx',
                      ],
                      'application/vnd.ms-excel': ['.xls'],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                        '.xlsx',
                      ],
                    }}
                  />
                </Box>
              </Box>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
