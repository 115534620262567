import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { generateCreateStandardScheme } from 'src/modules/schemes/standards';
import { map } from 'lodash';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

const generateInitialValues = (metatags: string[], siteId: number) => {
  const meta = metatags.reduce((all, metatag) => {
    (all as any)[metatag] = '';

    return all;
  }, {});

  return {
    standard: NaN,
    baseTime: NaN,
    siteId,
    ...meta,
  };
};

interface ICreateStandardProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestStart: () => void;
  onRequestEnd: () => void;
}

export const CreateStandard = ({
  isOpen,
  onClose,
  onRequestStart,
  onRequestEnd,
}: ICreateStandardProps) => {
  const { t } = useTranslation();

  const [siteId, setSiteId] = React.useState<number>(NaN);

  const metatagNames = useSelector(getMetatagsNames);
  const metatags = React.useMemo(
    () => (siteId && metatagNames[siteId] ? metatagNames[siteId] : []),
    [siteId, metatagNames],
  );

  const createStandardScheme = generateCreateStandardScheme(metatags);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues(metatags, siteId),
    validate: useValidate(createStandardScheme),
    onSubmit: async (data) => {
      onRequestStart();

      try {
        await Api.Standard.create(data);
        onClose();
        onRequestEnd();
      } catch {
        onClose();
        onRequestEnd();
      }
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('standards.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                onChange={(_, value) => setSiteId(value?.id ?? NaN)}
                label={t('standards.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={t('standards.standard')}
                id="standard"
                name="standard"
                autoComplete="standard"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={t('standards.base_time')}
                id="baseTime"
                name="baseTime"
                autoComplete="baseTime"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            {map(metatags, (metatag: string) => (
              <FormFieldContainer key={`metatag-${metatag}`}>
                <FormikTextField
                  required
                  variant="outlined"
                  fullWidth
                  label={metatag}
                  id={metatag}
                  name={metatag}
                  autoComplete={metatag}
                  formik={formik}
                />
              </FormFieldContainer>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
