import React from 'react';
import Highcharts, { SeriesColumnOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/joy';
import { highchartsReactComponentProps } from '../common';

exporting(Highcharts);

interface IStackedColumnChartProps {
  title?: string;
  height: string;
  categories: string[];
  data: SeriesColumnOptions[];
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  xAxis?: Highcharts.XAxisOptions | Array<Highcharts.XAxisOptions>;
  plotOptions?: Highcharts.PlotOptions;
}

const StackedColumnChart = React.forwardRef<
  Highcharts.Chart,
  IStackedColumnChartProps
>(
  (
    { title, height, categories, data, yAxis, xAxis, plotOptions },
    chartRef,
  ) => {
    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        style: {
          fontFamily: "Inter, 'sans serif'",
        },
      },
      title: {
        text: title,
      },
      yAxis: yAxis
        ? yAxis
        : {
            title: {
              enabled: false,
            } as Highcharts.YAxisTitleOptions,
          },
      xAxis: xAxis
        ? { ...xAxis, categories }
        : {
            categories,
          },
      credits: {
        enabled: false,
      },
      plotOptions: plotOptions
        ? plotOptions
        : {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
              },
            },
          },
      series: data,

      exporting: {
        enabled: false,
      },
    };

    return (
      <Box sx={{ minWidth: '100%', height }}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
          containerProps={highchartsReactComponentProps.containerProps}
        />
      </Box>
    );
  },
);

export default StackedColumnChart;
