import React from 'react';
import { HRDashboardActiveVsNeeded } from './HRDashboardActiveVsNeeded';
import {
  HRDashboardFilterPanel,
  IHrDashboardFilters,
} from './HRDashboardFilterPanel';
import { useSelector } from 'react-redux';
import {
  getGloballySelectedSites,
  getNonTestSitesComboboxList,
} from 'src/modules/selectors/site';
import { Box } from '@mui/joy';
import { HRDashboardTerminationReason } from './HRDashboardTerminationReason';
import { HRDashboardEmployeeTurnover } from './HRDashboardEmployeeTurnover';
import { HRDashboardNetEmployees } from './HRDashboardNetEmployees';
import { HRDashboardActiveByShift } from './HRDashboardActiveByShift';
import { HRDashboardTerminationReasonPie } from './HRDashboardTerminationReasonPie';
import { HRDashboardTerminatedByShift } from './HRDashboardTerminatedByShift';

const hrDashboardFilterInitialValue = {
  siteId: undefined,
};

export const HRDashboard = () => {
  const sites = useSelector(getNonTestSitesComboboxList);
  const globalSitesIds = useSelector(getGloballySelectedSites);

  const [filters, setFilters] = React.useState<IHrDashboardFilters>({
    siteId: sites[0]?.id ?? globalSitesIds[0],
  });

  const gridItemSx = {
    minHeight: 480,
    gridColumn: {
      sm: 'span 12',
      md: 'span 6',
    },
  };

  const gridItem3columnsSx = {
    minHeight: 480,
    gridColumn: {
      sm: 'span 12',
      md: 'span 4',
    },
  };

  return (
    <>
      <HRDashboardFilterPanel
        filterInitialData={filters}
        onApplyFilters={setFilters}
        onRemoveFilters={() => setFilters(hrDashboardFilterInitialValue)}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: 1,
          pt: 1,
        }}
      >
        <Box sx={gridItemSx}>
          <HRDashboardActiveVsNeeded
            siteId={filters.siteId}
            shiftId={filters.shiftId}
          />
        </Box>
        <Box sx={gridItemSx}>
          <HRDashboardTerminationReason
            siteId={filters.siteId}
            shiftId={filters.shiftId}
          />
        </Box>
        <Box sx={gridItemSx}>
          <HRDashboardEmployeeTurnover
            siteId={filters.siteId}
            shiftId={filters.shiftId}
          />
        </Box>
        <Box sx={gridItemSx}>
          <HRDashboardNetEmployees
            siteId={filters.siteId}
            shiftId={filters.shiftId}
          />
        </Box>

        <Box sx={gridItem3columnsSx}>
          <HRDashboardActiveByShift siteId={filters.siteId} />
        </Box>
        <Box sx={gridItem3columnsSx}>
          <HRDashboardTerminatedByShift siteId={filters.siteId} />
        </Box>
        <Box sx={gridItem3columnsSx}>
          <HRDashboardTerminationReasonPie siteId={filters.siteId} />
        </Box>
      </Box>
    </>
  );
};
