import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useFetchRolesCombobox, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ICreateRoleRequest, IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createRoleRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { createRoleScheme } from 'src/modules/schemes';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { additionalUserProperties } from 'src/config/userFields';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getRolesComboboxList } from 'src/modules/selectors/role';

const initialValues: ICreateRoleRequest = {
  name: '',
  shouldExtend: false,
};

interface ICreateRoleProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateRole = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateRoleProps) => {
  const { t } = useTranslation();

  const roles = useSelector(getRolesComboboxList);

  const fetchRolesCombobox = useFetchRolesCombobox();

  const dispatch = useDispatch();

  const validate = useValidate(createRoleScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createRoleRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const checkboxes = React.useMemo(
    () =>
      additionalUserProperties.map((i) => (
        <FormFieldContainer key={i}>
          <FormikCheckbox id={i} formik={formik} label={t(`roles.${i}`)} />
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, t],
  );

  const showToExtend = React.useMemo(() => !!formik.values.shouldExtend, [
    formik.values.shouldExtend,
  ]);

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>{t('roles.create')}</DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="name"
                label={t('roles.name')}
                name="name"
                autoComplete="name"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                id="level"
                name="level"
                required={true}
                formik={formik}
                variant="outlined"
                fullWidth={true}
                label={t('roles.level')}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="shouldExtend"
                formik={formik}
                label={t('roles.shouldExtend')}
              />
            </FormFieldContainer>

            {showToExtend && (
              <FormFieldContainer>
                <FormikCombobox
                  id="toExtend"
                  placeholder={t('roles.toExtend')}
                  options={roles as ComboBoxOption[]}
                  formik={formik}
                />
              </FormFieldContainer>
            )}

            <DrawerBodySectionTitle>
              {t('roles.additional_fields')}
            </DrawerBodySectionTitle>

            {checkboxes}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
