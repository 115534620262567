import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateRoleRequest,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IRoleModel,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new role
 */
export const createRoleRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateRoleRequest>>): SagaIterator {
  try {
    const response: IRoleModel = yield call(Api.Role.create, data);

    yield put(actions.getRoleListRequest({ filter: list }));

    yield put(actions.getRoleCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createRoleSuccess(response));
  } catch (e) {
    yield put(actions.createRoleFailed());
  }
};

/**
 * Get role list
 */
export const getRoleListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IRoleModel[] = yield call(Api.Role.list, action.payload);
    yield put(actions.getRoleListSuccess(response));
  } catch (e) {
    yield put(actions.getRoleListFailed());
  }
};

/**
 * Get role count
 */
export const getRoleCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Role.count,
      action?.payload,
    );
    yield put(actions.getRoleCountSuccess(response));
  } catch (e) {
    yield put(actions.getRoleCountFailed());
  }
};

/**
 * Bulk update roles
 */
export const updateRolesRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IRoleModel[]>>): SagaIterator {
  try {
    yield call(Api.Role.bulkUpdate, data);

    yield put(actions.getRoleListRequest({ filter: list }));

    yield put(actions.getRoleCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateRolesSuccess());
  } catch (e) {
    yield put(actions.updateRolesFailed());
  }
};

/**
 * Get combobox list
 */
export const getRoleComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IRoleModel>[] = yield call(
      Api.Role.list,
      action.payload,
    );
    yield put(actions.getRoleComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getRoleComboboxListFailed());
  }
};

/**
 * Export
 */
export const {
  exportRequestSaga: exportRoleRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Role.export,
  actionFailed: actions.exportRoleFailed,
  actionSuccess: actions.exportRoleSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createRoleRequest, saga: createRoleRequestSaga },
  { action: actions.getRoleListRequest, saga: getRoleListRequestSaga },
  { action: actions.getRoleCountRequest, saga: getRoleCountRequestSaga },
  // bulk updating roles
  { action: actions.updateRolesRequest, saga: updateRolesRequestSaga },
  {
    action: actions.getRoleComboboxListRequest,
    saga: getRoleComboboxListRequestSaga,
  },
  { action: actions.exportRoleRequest, saga: exportRoleRequestSaga },
];
