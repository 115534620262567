import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { useSiteRelationInclusion } from '.';
import { defaultListOrder } from '../helpers/filter';
import {
  getQuestionCategoryComboboxListRequest,
  getQuestionCategoryListRequest,
} from 'src/modules/actions';
import {
  getQuestionCategoriesByIds,
  getQuestionCategoriesComboboxList,
} from 'src/modules/selectors/questionCategory';

const useQuestionCategoryComboboxFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteRelationInclusion],
      },
      order: ['name ASC'],
    }),
    [siteRelationInclusion],
  );
};

export const useQuestionCategoryDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: defaultListOrder,
      include: [
        siteInclusion,
        {
          relation: 'questionCategoryType',
          relationType: 'left',
        },
      ],
    }),
    [siteInclusion],
  );
};

export const useFetchQuestionCategoriesCombobox = () => {
  const dispatch = useDispatch();
  const questionCategoryComboboxFilter = useQuestionCategoryComboboxFilter();

  const dispatchGetQuestionCategoryComboboxListRequest = React.useCallback(() => {
    dispatch(
      getQuestionCategoryComboboxListRequest(questionCategoryComboboxFilter),
    );
  }, [questionCategoryComboboxFilter, dispatch]);

  React.useEffect(() => {
    dispatchGetQuestionCategoryComboboxListRequest();
  }, [dispatchGetQuestionCategoryComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getQuestionCategoriesComboboxList, shallowEqual);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetQuestionCategoryComboboxListRequest();
    }
  };
};

export const useFetchQuestionCategoriesByIds = (ids: IdsArray) => {
  const siteInclusion = useSiteRelationInclusion();

  const dispatch = useDispatch();

  const questionCategories = useSelector(
    (state) => getQuestionCategoriesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  return () => {
    if (isEmpty(questionCategories)) {
      dispatch(
        getQuestionCategoryListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: [siteInclusion],
          },
        }),
      );
    }
  };
};
